import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Notiflix from 'notiflix';
import Input from '../../components/Input';
import Button from '../../components/Button';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/Title';
import { FormContent, Container } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';
import PasswordList from '../../components/PasswordList';

interface FormNewPassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { changePass } = useAuth();
  const [allValid, setAllValid] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormNewPassword) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          currentPassword: Yup.string().required('Informe sua senha atual'),
          newPassword: Yup.string().min(6, 'Mínimo 6 caracteres'),
          confirmNewPassword: Yup.string().oneOf(
            [Yup.ref('newPassword'), null],
            'A confirmação e a nova senha devem ser iguais.',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        Notiflix.Loading.circle();
        await changePass(data.currentPassword, data.newPassword);
        Notiflix.Notify.success('Senha alterada com sucesso!');

        formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          Notiflix.Notify.failure(
            'Ops, estamos com problemas, tente novamente mais tarde',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [changePass],
  );

  return (
    <PageWrapper gridTemplateColumns="1fr 6fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        <Title>Alterar minha senha</Title>
        <FormContent formLook ref={formRef} onSubmit={handleSubmit}>
          <Input
            id="currentPassword"
            name="currentPassword"
            type="password"
            title="Senha Atual"
            formLook
          />
          <Input
            name="newPassword"
            id="newPassword"
            type="password"
            title="Nova Senha"
            formLook
          />
          <Input
            name="confirmNewPassword"
            type="password"
            title="Confirmar Nova Senha"
            formLook
          />
          <PasswordList query="newPassword" validation={setAllValid} />
          <Button
            disabled={!allValid}
            type="submit"
            grayButton
            autoWidth
            formLook
            className="notiflix-change-password-button"
          >
            Salvar
          </Button>
        </FormContent>
      </Container>
    </PageWrapper>
  );
};

export default ChangePassword;
