import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import Notiflix from 'notiflix';
import { unMask } from 'remask';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { RowProps } from '.';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import {
  dateToPTBR,
  getAllDDIForSelectOptions,
  ptBRToDate,
} from '../../utils/formatt';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  OptionalParamsTerminateBeneficiaryContainer,
  ErrorWrapper,
} from './styles';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';

interface DateProps {
  value: Date | null;
  error: string;
}

interface TerminateBeneficiaryProps {
  defaultColor: string;
  callbackSetStep(prev: number): void;
  rowsSelecteds: RowProps[];
  onEdit: boolean;
  externalParams: {
    requestId?: string;
    userUnimedId?: string;
  };
}

interface EditProps {
  zipCode: string | null;
  ibgeCityCode: string | null;
  neighborhood: string | null;
  complement: string | null;
  address: string | null;
  ibgeCity: string | null;
  concessionDate: string | null;
  deathDate: string | null;
  email: string | null;
  returnCard: string | null;
  shippingMethod: string;
  userNameRequest: string;
  deathCertificateNumber: string | null;
  dddCellphone: string | null;
  dddPhone: string | null;
  ddiCellphone: string | null;
  ddiPhone: string | null;
  addressNumber: string | null;
  protocolId: string | null;
  terminationMotiveId: string | null;
  requestBeneficiaryId: string | null;
  requestContactId: string | null;
  phone: string | null;
  cellphone: string | null;
  observation: string | null;
  monthsOfContribution: string | null;
  releaseDate: string | null;
  requestDate: string | null;
  requestId: string | null;
  brazilianState: string | null;
  terminationDate: string | null;
  userUnimedId: string | null;
}

interface SelectWithApi {
  items: {
    title: string;
    value: string;
  }[];
  fromApi: any;
}

const DEATH = 4;
const FIRED_WITHOUT_CAUSE = 2;
const RESIGNATION = 15;

export function TerminateBeneficiary({
  defaultColor,
  callbackSetStep,
  rowsSelecteds,
  onEdit,
  externalParams: req,
}: TerminateBeneficiaryProps): JSX.Element {
  const { user } = useAuth();
  const history = useHistory();

  const [editMode] = useState(onEdit);
  const [externalParams, setExternalParams] = useState(req);

  const [saved, setSaved] = useState(false);
  const [savedParams, setSavedParams] = useState({
    requestBeneficiaryId: '',
    requestContactId: '',
    requestId: '',
  });

  const [dispatch, dispatchChange] = useState('');

  const [requestDate, setRequestDate] = useState<DateProps>({
    value: new Date(),
    error: '',
  });
  const [terminateDate, setTerminateDate] = useState<DateProps>({
    value: new Date(),
    error: '',
  });
  const [contactDetails, setContactDetails] = useState({} as any);
  const [contactDetailsEditMode, setContactDetailsEditMode] = useState(
    {} as EditProps,
  );
  const [beneficiaryInitiative, setBeneficiaryInitiative] = useState('S'); // S | N

  const formRef = useRef<FormHandles>(null);
  const reasonRequestRef = useRef<SelectHandles>(null);
  const contactFormRef = useRef<SelectHandles>(null);
  const stateRef = useRef<SelectHandles>(null);
  const countyRef = useRef<SelectHandles>(null);
  const DDIPhoneRef = useRef<SelectHandles>(null);
  const DDICellphoneRef = useRef<SelectHandles>(null);
  const attachmentRef = useRef<InputFileHandles>(null);
  const holderContributedRef = useRef<SelectHandles>(null);
  const holderRetiredRef = useRef<SelectHandles>(null);

  const [reasonRequestOptions, setReasonRequestOptions] =
    useState<SelectWithApi>({
      items: [{ title: 'Causas', value: '' }],
      fromApi: null,
    });
  const [contactFormOptions, setContactFormOptions] = useState([
    { title: 'Formas...', value: '' },
  ]);
  const [stateOptions, setStateOptions] = useState([
    { title: 'Estados...', value: '' },
  ]);
  const [countyOptions, setCountyOptions] = useState([
    { title: 'Municípios...', value: '' },
  ]);
  const [actualContactForm, setActualContactForm] = useState('');
  const [reasonCause, setReasonCause] = useState(-1);
  const [holderMonthly, setHolderMonthly] = useState(0);
  const [holderRetirement, setHolderRetirement] = useState(0);
  const [deathDate, setDeathDate] = useState<DateProps>({
    value: null,
    error: '',
  });
  const [concessionDatepicker, setConcessionDatepicker] = useState<DateProps>({
    value: null,
    error: '',
  });

  const [showMonthOfContribuition, setShowMonthOfContribuition] =
    useState(false);

  const [showConcessionDate, setShowConcessionDate] = useState(false);

  const sendNewRequest = useCallback(
    async (data, release = false) => {
      try {
        if (!requestDate.value) {
          setRequestDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        if (!terminateDate.value && beneficiaryInitiative !== 'S') {
          setTerminateDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        reasonRequestRef.current?.setError(
          !reasonRequestRef.current.value ? 'Informe a causa da rescisão.' : '',
        );

        /** @description if reason is death special validations  */
        if (reasonCause === DEATH) {
          const shape = Yup.object().shape({
            deathCertificate: Yup.string().required(
              'Informe a certidão de óbito.',
            ),
          });
          await shape.validate(data, {
            abortEarly: false,
          });
          if (!deathDate.value) {
            setDeathDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
            return;
          }
        }
        /** @description if reason is death special validations  */
        if (holderMonthly === 1 || holderRetirement === 1) {
          if (showMonthOfContribuition) {
            const shape = Yup.object().shape({
              monthsOfContribution: Yup.string().required(
                'Informe o tempo de contribuição.',
              ),
            });
            await shape.validate(data, {
              abortEarly: false,
            });
          }
          if (showConcessionDate && !concessionDatepicker.value) {
            setConcessionDatepicker((prev) => ({
              ...prev,
              error: 'Informe uma data.',
            }));
            return;
          }
        }

        if (
          !requestDate.value ||
          (!terminateDate.value && beneficiaryInitiative !== 'S') ||
          !reasonRequestRef.current?.value
        ) {
          return;
        }

        // const ddiPhone = DDIPhoneRef.current?.value;
        // const dddPhone = data.phone.slice(0, 2);
        // const phone = data.phone.slice(2);
        // const ddiCellPhone = DDICellphoneRef.current?.value;
        // const dddCellPhone = data.cellphone.slice(0, 2);
        // const cellphone = data.cellphone.slice(2);
        const terminationReasonId = reasonCause;
        const {
          observation,
          email,
          // zipCode,
          // addressStreet: address,
          // addressNumber,
          // addressComplement: complement,
          // neighborhood,
        } = data;
        // const ibgeCityCode = countyRef.current?.value;
        // const state = stateRef.current?.value;
        const shippingMethod = actualContactForm;
        const solicitationDate = dateToPTBR(requestDate.value, true);
        const terminateDt =
          beneficiaryInitiative === 'S'
            ? solicitationDate
            : terminateDate.value && dateToPTBR(terminateDate.value, true);

        const deathDateParam = deathDate.value
          ? dateToPTBR(deathDate.value)
          : '';
        const deathCertificateNumber = data.deathCertificate;
        const returnCard = data.cardDevolution ? 'S' : 'N';
        // const returnCard = String(null);
        const { monthsOfContribution } = data;
        const concessionDate =
          concessionDatepicker.value && dateToPTBR(concessionDatepicker.value);

        if (shippingMethod === '2') {
          const shape = Yup.object().shape({
            zipCode: Yup.string().required('Informe seu CEP'),
            neighborhood: Yup.string().required('Informe seu bairro'),
            addressStreet: Yup.string().required('Informe sua rua'),
            addressNumber: Yup.string().required(
              'Informe o nº da sua residência',
            ),
          });
          stateRef.current?.setError(
            !stateRef.current?.value ? 'Não há estado selecionado' : '',
          );
          countyRef.current?.setError(
            !countyRef.current?.value ? 'Não há cidade selecionada' : '',
          );

          await shape.validate(data, {
            abortEarly: false,
          });
          if (!stateRef.current?.value || !countyRef.current?.value) {
            return;
          }
        } else if (shippingMethod === '1') {
          const shape = Yup.object().shape({
            email: Yup.string()
              .required('Informe um email para contato.')
              .email('Informe um email válido'),
          });
          await shape.validate(data, { abortEarly: false });
        }

        Notiflix.Block.circle('.notiflix-submit-form');

        const response = await Promise.allSettled([
          ...rowsSelecteds.map((beneficiary) => {
            const formData = new FormData();
            formData.append('terminationReasonId', String(terminationReasonId));
            formData.append('observation', observation);
            formData.append('email', 'null');
            // formData.append('zipCode', zipCode);
            // formData.append('address', address);
            // formData.append('addressNumber', addressNumber);
            // formData.append('complement', complement);
            // formData.append('neighborhood', neighborhood);
            // formData.append('ibgeCityCode', ibgeCityCode ?? '');
            // formData.append('state', state ?? '');
            formData.append('shippingMethod', shippingMethod);
            formData.append('solicitationDate', terminateDt ?? '');
            formData.append('concessionDate', concessionDate ?? '');
            formData.append('deathDate', deathDateParam);
            formData.append('deathCertificateNumber', deathCertificateNumber);
            attachmentRef.current?.files?.forEach((file, index) => {
              formData.append(`rescisionImage[${index}]`, file);
            });
            formData.append('returnCard', returnCard);
            formData.append('monthsOfContribution', monthsOfContribution);
            // formData.append('ddiPhone', phone ? ddiPhone ?? '' : '');
            // formData.append('dddPhone', dddPhone);
            // formData.append('phone', phone);
            // formData.append(
            //   'ddiCellPhone',
            //   cellphone ? ddiCellPhone ?? '' : '',
            // );
            // formData.append('dddCellPhone', dddCellPhone);
            // formData.append('cellphone', cellphone);
            formData.append('userUnimedId', String(beneficiary.userUnimedId));
            formData.append(
              'beneficiaryName',
              String(beneficiary.beneficiaryName),
            );

            return api.post(
              `/company-rescission/register-request-to-terminate-the-beneficiary`,
              formData,
            );
          }),
        ]);
        Notiflix.Block.remove('.notiflix-submit-form');

        let requestBeneficiaryIdReturned = '';
        let requestContactIdReturned = '';
        let requestIdReturned = '';
        let successMessage = '';
        let allsuccess = true;
        const allErrors = [] as string[];
        await Promise.all(
          response.map(async (resp) => {
            if (resp.status === 'rejected') {
              allsuccess = false;
              if (resp.reason.response?.data?.message) {
                Notiflix.Notify.failure(
                  `Beneficiário(a): ${resp.reason?.response?.config?.data?.get(
                    'beneficiaryName',
                  )}. ${resp.reason?.response?.data?.message}`,
                );
                allErrors.push(
                  `Beneficiário(a): ${resp.reason?.response?.config?.data?.get(
                    'beneficiaryName',
                  )}. ${resp.reason?.response?.data?.message}`,
                );
              } else {
                Notiflix.Notify.failure(
                  'Ops... Algo deu errado ao tentar salvar o registro.',
                );
              }
            } else {
              requestIdReturned = resp.value.data.content[0].requestId;
              requestBeneficiaryIdReturned =
                resp.value.data.content[0].requestBeneficiaryId;
              requestContactIdReturned =
                resp.value.data.content[0].requestContactId;
              if (!release) {
                successMessage = resp.value.data.message;
              }
              if (release) {
                try {
                  const { data: dataRelease } = await api.put(
                    `/company-rescission/release-termination-record`,
                    {
                      requestId: requestIdReturned,
                    },
                  );
                  const { message } = dataRelease;
                  Notiflix.Notify.success('Rescisão enviada com sucesso');
                  successMessage = message;
                  callbackSetStep(1);
                } catch (err) {
                  allsuccess = false;
                  if (axios.isAxiosError(err) && err.response?.data?.message) {
                    Notiflix.Notify.failure(err.response.data.message);
                  } else {
                    Notiflix.Notify.failure(
                      `Ops... Algo deu errado ao tentar liberar o registro ${requestIdReturned}`,
                    );
                  }
                }
              }
            }
          }),
        );

        if (successMessage) {
          if (release && allsuccess) {
            Modal2.Success({
              closable: false,
              autoWidth: true,
              title: 'Perfeito!',
              text: successMessage,
              children: (
                <Button
                  modal
                  onClick={() => {
                    Modal2.Close();
                    if (editMode) {
                      history.goBack();
                    } else {
                      callbackSetStep(1);
                    }
                  }}
                >
                  Ok
                </Button>
              ),
            });

            return;
          }
          if (release && !allsuccess) {
            Modal2.Failure({
              closable: true,
              autoWidth: true,
              title: 'Oops...',
              text: 'Algumas solicitações não deram certo...',
              children: (
                <>
                  <ErrorWrapper>
                    {allErrors.map((er) => (
                      <p>{er}</p>
                    ))}
                  </ErrorWrapper>
                  <Button modal onClick={() => Modal2.Close()}>
                    Ok
                  </Button>
                </>
              ),
            });
            if (editMode) {
              history.goBack();
            } else {
              callbackSetStep(1);
            }
            return;
          }

          setSaved(true);
          setSavedParams({
            requestId: requestIdReturned,
            requestBeneficiaryId: requestBeneficiaryIdReturned,
            requestContactId: requestContactIdReturned,
          });
          Notiflix.Notify.success(successMessage);
          if (rowsSelecteds.length > 1) {
            Modal2.Success({
              closable: true,
              autoWidth: true,
              title: 'Perfeito!',
              text: successMessage,
              children: (
                <Button
                  modal
                  onClick={() => {
                    Modal2.Close();
                    callbackSetStep(1);
                  }}
                >
                  Ok
                </Button>
              ),
            });
          }
        }
      } catch (err) {
        console.log(err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Opss... Algo deu errado ao tentar solicitar a rescisão no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      }
    },
    [
      requestDate,
      terminateDate,
      beneficiaryInitiative,
      reasonCause,
      actualContactForm,
      deathDate,
      concessionDatepicker,
      rowsSelecteds,
      showMonthOfContribuition,
      showConcessionDate,
      editMode,
      history,
      callbackSetStep,
    ],
  );

  const sendSaveAndRelease = useCallback(
    async (data) => {
      // case save and release in new request
      if (!savedParams.requestId) {
        await sendNewRequest(data, true);
        return;
      }

      try {
        if (!requestDate.value) {
          setRequestDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        if (!terminateDate.value && beneficiaryInitiative !== 'S') {
          setTerminateDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        reasonRequestRef.current?.setError(
          !reasonRequestRef.current.value ? 'Informe a causa da rescisão.' : '',
        );

        /** @description if reason is death special validations  */
        if (reasonCause === DEATH) {
          const shape = Yup.object().shape({
            deathCertificate: Yup.string().required(
              'Informe a certidão de óbito.',
            ),
          });
          await shape.validate(data, {
            abortEarly: false,
          });
          if (!deathDate.value) {
            setDeathDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
            return;
          }
        }
        /** @description if reason is fired/resignation special validations  */
        if (holderMonthly === 1 || holderRetirement === 1) {
          if (showMonthOfContribuition) {
            const shape = Yup.object().shape({
              monthsOfContribution: Yup.string().required(
                'Informe o tempo de contribuição.',
              ),
            });
            await shape.validate(data, {
              abortEarly: false,
            });
          }
          if (showConcessionDate && !concessionDatepicker.value) {
            setConcessionDatepicker((prev) => ({
              ...prev,
              error: 'Informe uma data.',
            }));
            return;
          }
        }

        if (
          !requestDate.value ||
          (!terminateDate.value && beneficiaryInitiative !== 'S') ||
          !reasonRequestRef.current?.value
        ) {
          return;
        }

        // const ddiPhone = DDIPhoneRef.current?.value;
        // const dddPhone = data.phone.slice(0, 2);
        // const phone = data.phone.slice(2);
        // const ddiCellPhone = DDICellphoneRef.current?.value;
        // const dddCellPhone = data.cellphone.slice(0, 2);
        // const cellphone = data.cellphone.slice(2);
        const terminationReasonId = reasonCause;
        const {
          observation,
          email,
          // zipCode,
          // addressStreet: address,
          // addressNumber,
          // addressComplement: complement,
          // neighborhood,
        } = data;
        // const ibgeCityCode = countyRef.current?.value;
        // const state = stateRef.current?.value;
        const shippingMethod = actualContactForm;
        const solicitationDate = dateToPTBR(requestDate.value, true);
        const terminateDt =
          beneficiaryInitiative === 'S'
            ? solicitationDate
            : terminateDate.value && dateToPTBR(terminateDate.value, true);

        const deathDateParam = deathDate.value
          ? dateToPTBR(deathDate.value)
          : '';
        const deathCertificateNumber = data.deathCertificate;
        const returnCard = data.cardDevolution ? 'S' : 'N';
        // const returnCard = String(null);

        const { monthsOfContribution } = data;
        const concessionDate = formRef.current?.getFieldValue('holderRetired');

        if (shippingMethod === '2') {
          const shape = Yup.object().shape({
            zipCode: Yup.string().required('Informe seu CEP'),
            neighborhood: Yup.string().required('Informe seu bairro'),
            addressStreet: Yup.string().required('Informe sua rua'),
            addressNumber: Yup.string().required(
              'Informe o nº da sua residência',
            ),
          });
          stateRef.current?.setError(
            !stateRef.current?.value ? 'Não há estado selecionado' : '',
          );
          countyRef.current?.setError(
            !countyRef.current?.value ? 'Não há cidade selecionada' : '',
          );

          await shape.validate(data, {
            abortEarly: false,
          });
          if (!stateRef.current?.value || !countyRef.current?.value) {
            return;
          }
        } else if (shippingMethod === '1') {
          const shape = Yup.object().shape({
            email: Yup.string()
              .required('Informe um email para contato.')
              .email('Informe um email válido'),
          });
          await shape.validate(data, { abortEarly: false });
        }

        Notiflix.Block.circle('.notiflix-save-and-release-termination');

        const response = await Promise.allSettled([
          ...rowsSelecteds.map((beneficiary) => {
            const formData = new FormData();
            formData.append('terminationMotiveId', String(terminationReasonId));
            formData.append('observation', observation);
            formData.append('email', 'null');
            // formData.append('zipCode', zipCode);
            // formData.append('address', address);
            // formData.append('addressNumber', addressNumber);
            // formData.append('complement', complement);
            // formData.append('neighborhood', neighborhood);
            // formData.append('ibgeCity', ibgeCityCode ?? '');
            // formData.append('brazilianState', state ?? '');
            formData.append('sendMethod', shippingMethod);
            formData.append('requestDate', terminateDt ?? '');
            formData.append('grantDate', concessionDate);
            formData.append('dateOfDeath', deathDateParam);
            formData.append('deathCertificate', deathCertificateNumber);
            attachmentRef.current?.files?.forEach((file, index) => {
              formData.append(`rescisionImage[${index}]`, file);
            });
            formData.append('returnCard', returnCard);
            formData.append('amountOfContributionMonths', monthsOfContribution);
            // formData.append('ddiPhone', phone ? ddiPhone ?? '' : '');
            // formData.append('dddPhone', dddPhone);
            // formData.append('phone', phone);
            // formData.append(
            //   'ddiCellphone',
            //   cellphone ? ddiCellPhone ?? '' : '',
            // );
            // formData.append('dddCellPhone', dddCellPhone);
            // formData.append('cellphone', cellphone);
            formData.append('userUnimedId', String(beneficiary.userUnimedId));

            formData.append('requestId', savedParams.requestId);
            formData.append(
              'requestBeneficiaryId',
              savedParams.requestBeneficiaryId,
            );
            formData.append('requestContactId', savedParams.requestContactId);
            return api.post(`/company/termination-request-change`, formData);
          }),
        ]);

        let allsuccess = true;
        let successMessage = '';
        response.map(async (resp) => {
          if (resp.status === 'rejected') {
            allsuccess = false;
            if (resp.reason.response?.data?.message) {
              Notiflix.Notify.failure(resp.reason?.response?.data?.message);
            } else {
              Notiflix.Notify.failure(
                'Ops... Algo deu errado ao tentar salvar o registro.',
              );
            }
          } else {
            Notiflix.Notify.success(resp.value.data.message);
            try {
              const { data: dataRelease } = await api.put(
                `/company-rescission/release-termination-record`,
                {
                  requestId: savedParams.requestId,
                },
              );
              const { message } = dataRelease;

              Notiflix.Notify.success(message);
              successMessage = message;
            } catch (e) {
              allsuccess = false;
              if (axios.isAxiosError(e) && e.response?.data?.message) {
                Notiflix.Notify.info(e.response.data.message);
              } else {
                Notiflix.Notify.failure(
                  `Ops... Não conseguimos liberar o registro ${savedParams.requestId}`,
                );
              }
            }
          }
        });
        Notiflix.Block.remove('.notiflix-save-and-release-termination');
        if (allsuccess) {
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: successMessage,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
          if (editMode) {
            history.goBack();
          } else {
            callbackSetStep(1);
          }
          return;
        }
        callbackSetStep(1);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Opss... Algo deu errado ao tentar solicitar a rescisão no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      }
    },
    [
      actualContactForm,
      beneficiaryInitiative,
      concessionDatepicker,
      deathDate,
      reasonCause,
      requestDate,
      rowsSelecteds,
      savedParams,
      sendNewRequest,
      showConcessionDate,
      showMonthOfContribuition,
      terminateDate,
      history,
      editMode,
      callbackSetStep,
    ],
  );

  const sendSave = useCallback(
    async (data) => {
      try {
        if (!requestDate.value) {
          setRequestDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        if (!terminateDate.value && beneficiaryInitiative !== 'S') {
          setTerminateDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        reasonRequestRef.current?.setError(
          !reasonRequestRef.current.value ? 'Informe a causa da rescisão.' : '',
        );

        /** @description if reason is death special validations  */
        if (reasonCause === DEATH) {
          const shape = Yup.object().shape({
            deathCertificate: Yup.string().required(
              'Informe a certidão de óbito.',
            ),
          });
          await shape.validate(data, {
            abortEarly: false,
          });
          if (!deathDate.value) {
            setDeathDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
            return;
          }
        }
        /** @description if reason is death special validations  */
        if (holderMonthly === 1 || holderRetirement === 1) {
          if (showMonthOfContribuition) {
            const shape = Yup.object().shape({
              monthsOfContribution: Yup.string().required(
                'Informe o tempo de contribuição.',
              ),
            });
            await shape.validate(data, {
              abortEarly: false,
            });
          }
          if (showConcessionDate && !concessionDatepicker.value) {
            setConcessionDatepicker((prev) => ({
              ...prev,
              error: 'Informe uma data.',
            }));
            return;
          }
        }

        if (
          !requestDate.value ||
          (!terminateDate.value && beneficiaryInitiative !== 'S') ||
          !reasonRequestRef.current?.value
        ) {
          return;
        }

        // const ddiPhone = DDIPhoneRef.current?.value;
        // const dddPhone = data.phone.slice(0, 2);
        // const phone = data.phone.slice(2);
        // const ddiCellPhone = DDICellphoneRef.current?.value;
        // const dddCellPhone = data.cellphone.slice(0, 2);
        // const cellphone = data.cellphone.slice(2);
        const terminationReasonId = reasonCause;
        const {
          observation,
          email,
          // zipCode,
          // addressStreet: address,
          // addressNumber,
          // addressComplement: complement,
          // neighborhood,
        } = data;
        // const ibgeCityCode = countyRef.current?.value;
        // const state = stateRef.current?.value;
        const shippingMethod = actualContactForm;
        const solicitationDate = dateToPTBR(requestDate.value, true);
        const terminateDt =
          beneficiaryInitiative === 'S'
            ? solicitationDate
            : terminateDate.value && dateToPTBR(terminateDate.value, true);

        const deathDateParam = deathDate.value
          ? dateToPTBR(deathDate.value)
          : '';
        const deathCertificateNumber = data.deathCertificate;
        const returnCard = data.cardDevolution ? 'S' : 'N';
        // const returnCard = String(null);

        const { monthsOfContribution } = data;
        const concessionDate = formRef.current?.getFieldValue('holderRetired');

        if (shippingMethod === '2') {
          const shape = Yup.object().shape({
            zipCode: Yup.string().required('Informe seu CEP'),
            neighborhood: Yup.string().required('Informe seu bairro'),
            addressStreet: Yup.string().required('Informe sua rua'),
            addressNumber: Yup.string().required(
              'Informe o nº da sua residência',
            ),
          });
          stateRef.current?.setError(
            !stateRef.current?.value ? 'Não há estado selecionado' : '',
          );
          countyRef.current?.setError(
            !countyRef.current?.value ? 'Não há cidade selecionada' : '',
          );

          await shape.validate(data, {
            abortEarly: false,
          });
          if (!stateRef.current?.value || !countyRef.current?.value) {
            return;
          }
        } else if (shippingMethod === '1') {
          const shape = Yup.object().shape({
            email: Yup.string()
              .required('Informe um email para contato.')
              .email('Informe um email válido'),
          });
          await shape.validate(data, { abortEarly: false });
        }

        Notiflix.Block.circle('.notiflix-submit-form');

        const response = await Promise.allSettled([
          ...rowsSelecteds.map((beneficiary) => {
            const formData = new FormData();
            formData.append('terminationMotiveId', String(terminationReasonId));
            formData.append('observation', observation);
            formData.append('email', 'null');
            // formData.append('zipCode', zipCode);
            // formData.append('address', address);
            // formData.append('addressNumber', addressNumber);
            // formData.append('complement', complement);
            // formData.append('neighborhood', neighborhood);
            // formData.append('ibgeCity', ibgeCityCode ?? '');
            // formData.append('brazilianState', state ?? '');
            formData.append('sendMethod', shippingMethod);
            formData.append('requestDate', terminateDt ?? '');
            formData.append('grantDate', concessionDate);
            formData.append('dateOfDeath', deathDateParam);
            formData.append('deathCertificate', deathCertificateNumber);
            attachmentRef.current?.files?.forEach((file, index) => {
              formData.append(`rescisionImage[${index}]`, file);
            });
            formData.append('returnCard', returnCard);
            formData.append('amountOfContributionMonths', monthsOfContribution);
            // formData.append('ddiPhone', phone ? ddiPhone ?? '' : '');
            // formData.append('dddPhone', dddPhone);
            // formData.append('phone', phone);
            // formData.append(
            //   'ddiCellphone',
            //   cellphone ? ddiCellPhone ?? '' : '',
            // );
            // formData.append('dddCellPhone', dddCellPhone);
            // formData.append('cellphone', cellphone);
            formData.append('userUnimedId', String(beneficiary.userUnimedId));

            formData.append('requestId', savedParams.requestId);
            formData.append(
              'requestBeneficiaryId',
              savedParams.requestBeneficiaryId,
            );
            formData.append('requestContactId', savedParams.requestContactId);
            return api.post(`/company/termination-request-change`, formData);
          }),
        ]);
        Notiflix.Block.remove('.notiflix-submit-form');

        let successMessage = '';

        response.forEach(async (resp) => {
          if (resp.status === 'rejected') {
            if (resp.reason.response?.data?.message) {
              Notiflix.Notify.failure(resp.reason?.response?.data?.message);
            } else {
              Notiflix.Notify.failure(
                'Ops... Algo deu errado ao tentar salvar o registro.',
              );
            }
          } else {
            successMessage = resp.value.data.message;
          }
        });

        if (successMessage) {
          Notiflix.Notify.success(successMessage);
          // Modal2.Success({
          //   closable: true,
          //   autoWidth: true,
          //   title: 'Perfeito!',
          //   text: successMessage,
          //   children: (
          //     <Button modal onClick={() => Modal2.Close()}>
          //       Ok
          //     </Button>
          //   ),
          // });
        }
        callbackSetStep(1);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Opss... Algo deu errado ao tentar solicitar a rescisão no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      }
    },
    [
      actualContactForm,
      beneficiaryInitiative,
      concessionDatepicker.value,
      deathDate.value,
      reasonCause,
      requestDate.value,
      rowsSelecteds,
      savedParams.requestBeneficiaryId,
      savedParams.requestContactId,
      savedParams.requestId,
      showConcessionDate,
      showMonthOfContribuition,
      terminateDate.value,
    ],
  );

  const sendSaveOnEdit = useCallback(
    async (data) => {
      try {
        if (!requestDate.value) {
          setRequestDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        if (!terminateDate.value && beneficiaryInitiative !== 'S') {
          setTerminateDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        reasonRequestRef.current?.setError(
          !reasonRequestRef.current.value ? 'Informe a causa da rescisão.' : '',
        );

        /** @description if reason is death special validations  */
        if (reasonCause === DEATH) {
          const shape = Yup.object().shape({
            deathCertificate: Yup.string().required(
              'Informe a certidão de óbito.',
            ),
          });
          await shape.validate(data, {
            abortEarly: false,
          });
          if (!deathDate.value) {
            setDeathDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
            return;
          }
        }
        /** @description if reason is death special validations  */
        if (holderMonthly === 1 || holderRetirement === 1) {
          if (showMonthOfContribuition) {
            const shape = Yup.object().shape({
              monthsOfContribution: Yup.string().required(
                'Informe o tempo de contribuição.',
              ),
            });
            await shape.validate(data, {
              abortEarly: false,
            });
          }
          if (showConcessionDate && !concessionDatepicker.value) {
            setConcessionDatepicker((prev) => ({
              ...prev,
              error: 'Informe uma data.',
            }));
            return;
          }
        }

        if (
          !requestDate.value ||
          (!terminateDate.value && beneficiaryInitiative !== 'S') ||
          !reasonRequestRef.current?.value
        ) {
          return;
        }

        // const ddiPhone = DDIPhoneRef.current?.value;
        // const dddPhone = data.phone.slice(0, 2);
        // const phone = data.phone.slice(2);
        // const ddiCellPhone = DDICellphoneRef.current?.value;
        // const dddCellPhone = data.cellphone.slice(0, 2);
        // const cellphone = data.cellphone.slice(2);
        const terminationReasonId = reasonCause;
        const {
          observation,
          email,
          // zipCode,
          // addressStreet: address,
          // addressNumber,
          // addressComplement: complement,
          // neighborhood,
        } = data;
        // const ibgeCityCode = countyRef.current?.value;
        // const state = stateRef.current?.value;
        const shippingMethod = actualContactForm;
        const solicitationDate = dateToPTBR(requestDate.value, true);
        const terminateDt =
          beneficiaryInitiative === 'S'
            ? solicitationDate
            : terminateDate.value && dateToPTBR(terminateDate.value, true);

        const deathDateParam = deathDate.value
          ? dateToPTBR(deathDate.value)
          : '';
        const deathCertificateNumber = data.deathCertificate;
        const returnCard = data.cardDevolution ? 'S' : 'N';
        // const returnCard = String(null);

        const { monthsOfContribution } = data;
        const concessionDate = formRef.current?.getFieldValue('holderRetired');

        if (shippingMethod === '2') {
          const shape = Yup.object().shape({
            zipCode: Yup.string().required('Informe seu CEP'),
            neighborhood: Yup.string().required('Informe seu bairro'),
            addressStreet: Yup.string().required('Informe sua rua'),
            addressNumber: Yup.string().required(
              'Informe o nº da sua residência',
            ),
          });
          stateRef.current?.setError(
            !stateRef.current?.value ? 'Não há estado selecionado' : '',
          );
          countyRef.current?.setError(
            !countyRef.current?.value ? 'Não há cidade selecionada' : '',
          );

          await shape.validate(data, {
            abortEarly: false,
          });
          if (!stateRef.current?.value || !countyRef.current?.value) {
            return;
          }
        } else if (shippingMethod === '1') {
          const shape = Yup.object().shape({
            email: Yup.string()
              .required('Informe um email para contato.')
              .email('Informe um email válido'),
          });
          await shape.validate(data, { abortEarly: false });
        }

        Notiflix.Block.circle('.notiflix-submit-form');

        const formData = new FormData();
        formData.append('terminationMotiveId', String(terminationReasonId));
        formData.append('observation', observation);
        formData.append('email', 'null');
        // formData.append('zipCode', zipCode);
        // formData.append('address', address);
        // formData.append('addressNumber', addressNumber);
        // formData.append('complement', complement);
        // formData.append('neighborhood', neighborhood);
        // formData.append('ibgeCity', ibgeCityCode ?? '');
        // formData.append('brazilianState', state ?? '');
        formData.append('sendMethod', shippingMethod);
        formData.append('requestDate', terminateDt ?? '');
        formData.append('grantDate', concessionDate);
        formData.append('dateOfDeath', deathDateParam);
        formData.append('deathCertificate', deathCertificateNumber);
        attachmentRef.current?.files?.forEach((file, index) => {
          formData.append(`rescisionImage[${index}]`, file);
        });
        formData.append('returnCard', returnCard);
        formData.append('amountOfContributionMonths', monthsOfContribution);
        // formData.append('ddiPhone', phone ? ddiPhone ?? '' : '');
        // formData.append('dddPhone', dddPhone);
        // formData.append('phone', phone);
        // formData.append('ddiCellphone', cellphone ? ddiCellPhone ?? '' : '');
        // formData.append('dddCellPhone', dddCellPhone);
        // formData.append('cellphone', cellphone);
        formData.append('userUnimedId', String(externalParams.userUnimedId));

        formData.append('requestId', contactDetailsEditMode?.requestId ?? '');
        formData.append(
          'requestBeneficiaryId',
          contactDetailsEditMode?.requestBeneficiaryId ?? '',
        );
        formData.append(
          'requestContactId',
          contactDetailsEditMode?.requestContactId ?? '',
        );

        const { data: dataApi } = await api.post(
          `/company/termination-request-change`,
          formData,
        );
        Notiflix.Block.remove('.notiflix-submit-form');

        const successMessage = dataApi.message;

        if (successMessage) {
          Notiflix.Notify.success(successMessage);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.data?.message) {
          Notiflix.Block.remove('.notiflix-submit-form');
          // Modal2.Failure({
          //   closable: true,
          //   autoWidth: true,
          //   title: 'Ooops...',
          //   text: err.response.data.message,
          //   children: (
          //     <Button modal onClick={() => Modal2.Close()}>
          //       Ok
          //     </Button>
          //   ),
          // });
        } else {
          Notiflix.Block.remove('.notiflix-submit-form');
          Notiflix.Notify.failure(
            'Opss... Algo deu errado ao tentar solicitar a rescisão no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      }
    },
    [
      requestDate.value,
      terminateDate.value,
      beneficiaryInitiative,
      reasonCause,
      actualContactForm,
      deathDate.value,
      externalParams.userUnimedId,
      contactDetailsEditMode?.requestId,
      contactDetailsEditMode?.requestBeneficiaryId,
      contactDetailsEditMode?.requestContactId,
      showMonthOfContribuition,
      showConcessionDate,
      concessionDatepicker.value,
    ],
  );

  const sendSaveAndReleaseOnEdit = useCallback(
    async (data) => {
      try {
        if (!requestDate.value) {
          setRequestDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        if (!terminateDate.value && beneficiaryInitiative !== 'S') {
          setTerminateDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data.',
          }));
        }
        reasonRequestRef.current?.setError(
          !reasonRequestRef.current.value ? 'Informe a causa da rescisão.' : '',
        );

        /** @description if reason is death special validations  */
        if (reasonCause === DEATH) {
          const shape = Yup.object().shape({
            deathCertificate: Yup.string().required(
              'Informe a certidão de óbito.',
            ),
          });
          await shape.validate(data, {
            abortEarly: false,
          });
          if (!deathDate.value) {
            setDeathDate((prev) => ({ ...prev, error: 'Informe uma data.' }));
            return;
          }
        }
        /** @description if reason is death special validations  */
        if (holderMonthly === 1 || holderRetirement === 1) {
          if (showMonthOfContribuition) {
            const shape = Yup.object().shape({
              monthsOfContribution: Yup.string().required(
                'Informe o tempo de contribuição.',
              ),
            });
            await shape.validate(data, {
              abortEarly: false,
            });
          }
          if (showConcessionDate && !concessionDatepicker.value) {
            setConcessionDatepicker((prev) => ({
              ...prev,
              error: 'Informe uma data.',
            }));
            return;
          }
        }

        if (
          !requestDate.value ||
          (!terminateDate.value && beneficiaryInitiative !== 'S') ||
          !reasonRequestRef.current?.value
        ) {
          return;
        }

        // const ddiPhone = DDIPhoneRef.current?.value;
        // const dddPhone = data.phone.slice(0, 2);
        // const phone = data.phone.slice(2);
        // const ddiCellPhone = DDICellphoneRef.current?.value;
        // const dddCellPhone = data.cellphone.slice(0, 2);
        // const cellphone = data.cellphone.slice(2);
        const terminationReasonId = reasonCause;
        const {
          observation,
          email,
          // zipCode,
          // addressStreet: address,
          // addressNumber,
          // addressComplement: complement,
          // neighborhood,
        } = data;
        // const ibgeCityCode = countyRef.current?.value;
        // const state = stateRef.current?.value;
        const shippingMethod = actualContactForm;
        const solicitationDate = dateToPTBR(requestDate.value, true);
        const terminateDt =
          beneficiaryInitiative === 'S'
            ? solicitationDate
            : terminateDate.value && dateToPTBR(terminateDate.value, true);

        const deathDateParam = deathDate.value
          ? dateToPTBR(deathDate.value)
          : '';
        const deathCertificateNumber = data.deathCertificate;
        const returnCard = data.cardDevolution ? 'S' : 'N';
        // const returnCard = String(null);

        const { monthsOfContribution } = data;
        const concessionDate = formRef.current?.getFieldValue('holderRetired');

        if (shippingMethod === '2') {
          const shape = Yup.object().shape({
            zipCode: Yup.string().required('Informe seu CEP'),
            neighborhood: Yup.string().required('Informe seu bairro'),
            addressStreet: Yup.string().required('Informe sua rua'),
            addressNumber: Yup.string().required(
              'Informe o nº da sua residência',
            ),
          });
          stateRef.current?.setError(
            !stateRef.current?.value ? 'Não há estado selecionado' : '',
          );
          countyRef.current?.setError(
            !countyRef.current?.value ? 'Não há cidade selecionada' : '',
          );

          await shape.validate(data, {
            abortEarly: false,
          });
          if (!stateRef.current?.value || !countyRef.current?.value) {
            return;
          }
        } else if (shippingMethod === '1') {
          const shape = Yup.object().shape({
            email: Yup.string()
              .required('Informe um email para contato.')
              .email('Informe um email válido'),
          });
          await shape.validate(data, { abortEarly: false });
        }

        Notiflix.Block.circle('.notiflix-save-and-release-termination');

        const formData = new FormData();
        formData.append('terminationMotiveId', String(terminationReasonId));
        formData.append('observation', observation);
        formData.append('email', 'null');
        // formData.append('zipCode', zipCode);
        // formData.append('address', address);
        // formData.append('addressNumber', addressNumber);
        // formData.append('complement', complement);
        // formData.append('neighborhood', neighborhood);
        // formData.append('ibgeCity', ibgeCityCode ?? '');
        // formData.append('brazilianState', state ?? '');
        formData.append('sendMethod', shippingMethod);
        formData.append('requestDate', terminateDt ?? '');
        formData.append('grantDate', concessionDate);
        formData.append('dateOfDeath', deathDateParam);
        formData.append('deathCertificate', deathCertificateNumber);
        attachmentRef.current?.files?.forEach((file, index) => {
          formData.append(`rescisionImage[${index}]`, file);
        });
        formData.append('returnCard', returnCard);
        formData.append('amountOfContributionMonths', monthsOfContribution);
        // formData.append('ddiPhone', phone ? ddiPhone ?? '' : '');
        // formData.append('dddPhone', dddPhone);
        // formData.append('phone', phone);
        // formData.append('ddiCellphone', cellphone ? ddiCellPhone ?? '' : '');
        // formData.append('dddCellPhone', dddCellPhone);
        // formData.append('cellphone', cellphone);
        formData.append('userUnimedId', String(externalParams.userUnimedId));

        formData.append('requestId', contactDetailsEditMode?.requestId ?? '');
        formData.append(
          'requestBeneficiaryId',
          contactDetailsEditMode?.requestBeneficiaryId ?? '',
        );
        formData.append(
          'requestContactId',
          contactDetailsEditMode?.requestContactId ?? '',
        );
        const { data: dataApi } = await api.post(
          `/company/termination-request-change`,
          formData,
        );
        const { data: dataRelease } = await api.put(
          `/company-rescission/release-termination-record`,
          {
            requestId: contactDetailsEditMode.requestId,
          },
        );
        const { message } = dataApi;
        const { message: message2 } = dataRelease;

        Notiflix.Block.remove('.notiflix-save-and-release-termination');

        Modal2.Success({
          closable: true,
          autoWidth: true,
          title: 'Perfeito!',
          subtitle: message2,
          text: message2,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
        history.goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(err) && err.response?.data?.message) {
          Notiflix.Block.remove('.notiflix-save-and-release-termination');
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Block.remove('.notiflix-save-and-release-termination');
          Notiflix.Notify.failure(
            'Opss... Algo deu errado ao tentar solicitar a rescisão no servidor. Por favor, tente novamente mais tarde.',
          );
        }
      }
    },
    [
      actualContactForm,
      beneficiaryInitiative,
      concessionDatepicker,
      contactDetailsEditMode,
      deathDate,
      externalParams,
      reasonCause,
      requestDate,
      showConcessionDate,
      showMonthOfContribuition,
      terminateDate,
      history,
    ],
  );

  const handleViewTerminationReport = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-view-termination');
      const { data } = await api.get(
        '/report/list-of-company-portal-reports?exhibitionLocation=4',
      );
      const { content } = data;
      const { nrSeqRelatorio, Parametros } = content[0];

      const paramToSend = saved
        ? savedParams.requestId
        : externalParams.requestId;

      const { data: dataReport } = await api.post(
        `/report/generate-report`,
        {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            {
              id: Parametros[0].cdParametro,
              valor: paramToSend,
            },
          ],
        },
        {
          responseType: 'blob',
        },
      );
      downloadFileOfBlob(dataReport);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos baixar esse documento... Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-view-termination');
    }
  }, [externalParams, savedParams, saved]);

  const handleCancelRegister = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-cancel-termination');
      const { data } = await api.put('/company/cancel-termination-request', {
        requestId: externalParams.requestId,
      });
      const { message } = data;
      Modal2.Success({
        closable: true,
        autoWidth: true,
        title: 'Perfeito!',
        text: message,
        children: (
          <Button modal onClick={() => Modal2.Close()}>
            Ok
          </Button>
        ),
      });
      if (editMode) {
        history.goBack();
      } else {
        callbackSetStep(1);
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos cancelar essa rescisão no servidor... Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-cancel-termination');
    }
  }, [externalParams, editMode, history, callbackSetStep]);

  const getReasons = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-reasons');
      const { data } = await api.get(
        `/contract/list-of-termination-cause?accessType=E`,
      );
      const { content } = data;
      setReasonRequestOptions({
        items: content.reduce(
          (
            acc: {
              title: string;
              value: string;
            }[],
            act: {
              name: string;
              id: string;
            },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
        fromApi: content,
      });
    } catch (er) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar as causas de rescisão no servidor... Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-reasons');
    }
  }, []);

  const getAllStates = useCallback(async () => {
    try {
      const { data } = await api.get('/address/list-of-states');
      const { content } = data;
      setStateOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os Estados para selecionar no servidor. Por favor, tente novamente mais tarde.',
      );
    }
  }, []);

  const getAllCitysOfStateSelected = useCallback(async (state: string) => {
    try {
      Notiflix.Block.circle('.notiflix-contact-county');
      const { data } = await api.get(`/address/list-of-cities?state=${state}`);
      const { content } = data;
      setCountyOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { city: string; ibgeCityCode: string },
          ) => {
            acc.push({
              title: act.city,
              value: act.ibgeCityCode,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os Municípios para selecionar no servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-contact-county');
    }
  }, []);

  const getContactDetails = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/company-rescission/contact-details?userUnimedId=${rowsSelecteds[0].userUnimedId}`,
      );
      const { content } = data;
      if (content && content[0]) {
        setContactDetails(content[0]);
      }
    } catch (er) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os dados de contato no servidor... Por favor, tente novamente mais tarde.',
      );
    }
  }, [rowsSelecteds]);

  const getDetailsOnEdit = useCallback(async () => {
    try {
      const { data } = await api.get(
        `company/get-termination-request-to-edit?userUnimedId=${externalParams.userUnimedId}&requestId=${externalParams.requestId}`,
      );
      const { content } = data;
      if (content && content[0]) {
        const d = content[0];
        if (d.requestDate) {
          setRequestDate({
            value: ptBRToDate(
              d.requestDate.split(' ')[0],
              d.requestDate.split(' ')[1],
            ),
            error: '',
          });
        }
        if (d.userNameRequest) {
          formRef.current?.setFieldValue('requestUser', d.userNameRequest);
        }
        if (d.terminationMotiveId) {
          reasonRequestRef.current?.setValue('', d.terminationMotiveId);
          setReasonCause(Number(d.terminationMotiveId));
        }
        if (d.terminationDate) {
          setTerminateDate({
            value: ptBRToDate(
              d.terminationDate.split(' ')[0],
              d.terminationDate.split(' ')[1],
            ),
            error: '',
          });
          setBeneficiaryInitiative('N');
        }
        if (d.deathCertificateNumber) {
          formRef.current?.setFieldValue(
            'deathCertificate',
            d.deathCertificateNumber,
          );
        }
        if (d.deathDate) {
          setDeathDate({
            value: ptBRToDate(d.deathDate.split(' ')[0]),
            error: '',
          });
        }
        if (d.monthsOfContribution) {
          holderContributedRef.current?.setValue('Sim');
          formRef.current?.setFieldValue(
            'monthsOfContribution',
            d.monthsOfContribution,
          );
          setShowMonthOfContribuition(true);
        } else {
          holderContributedRef.current?.setValue('Não');
          formRef.current?.setFieldValue('monthsOfContribution', '');
        }
        if (d.concessionDate) {
          holderRetiredRef.current?.setValue('Sim');
          setConcessionDatepicker({
            value: ptBRToDate(d.concessionDate.split(' ')[0]),
            error: '',
          });
          setShowConcessionDate(true);
        } else {
          holderRetiredRef.current?.setValue('Não');
          setConcessionDatepicker({
            value: null,
            error: '',
          });
        }

        if (d.returnCard) {
          formRef.current?.setFieldValue(
            'cardDevolution',
            d.returnCard === 'S',
          );
        }

        if (d.observation) {
          formRef.current?.setFieldValue('observation', d.observation);
        }
        if (d.shippingMethod) {
          contactFormRef.current?.setValue('', d.shippingMethod);
          setActualContactForm(d.shippingMethod);
        }
        setContactDetailsEditMode(d);
      }
    } catch (er) {
      if (er.response?.data?.message) {
        Notiflix.Notify.failure(er.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados de contato no servidor... Por favor, tente novamente mais tarde.',
        );
      }
    }
  }, [externalParams]);

  const getContactForms = useCallback(async () => {
    try {
      // Notiflix.Block.circle('.notiflix-contact-form');
      const { data } = await api.get('/domain/company-contact-form');
      const { content } = data;

      setContactFormOptions(
        content
          .reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          )
          .reverse(),
      );
      setActualContactForm(content[2].id);
      contactFormRef.current?.setValue('Nenhum', content[2].id);
    } catch (er) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar as formas de contato no servidor... Por favor, tente novamente mais tarde.',
      );
    } finally {
      // Notiflix.Block.remove('.notiflix-contact-form');
    }
  }, []);

  const preloadAllSelects = useCallback(async () => {
    await Promise.all([getAllStates(), getReasons(), getContactForms()]);
  }, [getAllStates, getContactForms, getReasons]);

  const getHolderMonthlyAndRetirement = useCallback(
    (option) => {
      const optionHolder = reasonRequestOptions.fromApi.find(
        (i: { id: number }) => i.id === option,
      );
      if (option === optionHolder.id) {
        if (
          optionHolder.holderMonthly === 1 ||
          optionHolder.holderRetirement === 1
        ) {
          setHolderMonthly(optionHolder.holderMonthly);
          setHolderRetirement(optionHolder.holderRetirement);
        } else {
          setHolderMonthly(0);
          setHolderRetirement(0);
        }
      }
    },
    [reasonRequestOptions.fromApi],
  );

  useEffect(() => {
    preloadAllSelects().finally(() => {
      if (!editMode) {
        if (rowsSelecteds.length === 1) {
          getContactDetails();
        }
      } else {
        getDetailsOnEdit();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description on load all content set default values to selected contact method
   */
  useEffect(() => {
    if (contactDetails) {
      if (actualContactForm === '2') {
        if (stateOptions.length > 1 && contactDetails?.brazilianState) {
          stateRef.current?.setValue('', contactDetails.brazilianState);
          getAllCitysOfStateSelected(contactDetails.brazilianState);
        }
        if (contactDetails?.zipCode) {
          formRef.current?.setFieldValue('zipCode', contactDetails.zipCode);
        }
        if (contactDetails?.neighborhood) {
          formRef.current?.setFieldValue(
            'neighborhood',
            contactDetails.neighborhood,
          );
        }
        if (contactDetails?.address) {
          formRef.current?.setFieldValue(
            'addressStreet',
            contactDetails.address,
          );
        }
        if (contactDetails?.addressNumber) {
          formRef.current?.setFieldValue(
            'addressNumber',
            contactDetails.addressNumber,
          );
        }
        if (contactDetails?.complement) {
          formRef.current?.setFieldValue(
            'addressComplement',
            contactDetails.complement,
          );
        }
        DDIPhoneRef.current?.setValue('', '55');
        DDICellphoneRef.current?.setValue('', '55');
      } else if (actualContactForm === '1') {
        if (contactDetails?.email) {
          formRef.current?.setFieldValue('email', contactDetails.email);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetails, stateOptions, actualContactForm]);

  /**
   * @description on load all content set default values to selected contact method
   */
  useEffect(() => {
    if (contactDetailsEditMode) {
      if (actualContactForm === '2') {
        const d = contactDetailsEditMode;

        if (stateOptions.length > 1 && d?.brazilianState) {
          stateRef.current?.setValue('', d.brazilianState);
          getAllCitysOfStateSelected(d.brazilianState);
        }
        if (d?.zipCode) {
          formRef.current?.setFieldValue('zipCode', d.zipCode);
        }
        if (d?.neighborhood) {
          formRef.current?.setFieldValue('neighborhood', d.neighborhood);
        }
        if (d?.address) {
          formRef.current?.setFieldValue('addressStreet', d.address);
        }
        if (d?.addressNumber) {
          formRef.current?.setFieldValue('addressNumber', d.addressNumber);
        }
        if (d?.complement) {
          formRef.current?.setFieldValue('addressComplement', d.complement);
        }
        if (d?.ddiPhone) {
          DDIPhoneRef.current?.setValue('', d.ddiPhone);
        }
        if (d?.phone) {
          formRef.current?.setFieldValue('phone', `${d?.dddPhone}${d.phone}`);
        }
        if (d?.ddiCellphone) {
          DDICellphoneRef.current?.setValue('', d.ddiCellphone);
        }
        if (d?.cellphone) {
          formRef.current?.setFieldValue(
            'cellphone',
            `${d?.dddCellphone}${d.cellphone}`,
          );
        }
      } else if (actualContactForm === '1') {
        if (contactDetailsEditMode?.email) {
          formRef.current?.setFieldValue('email', contactDetailsEditMode.email);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetailsEditMode, stateOptions, actualContactForm]);

  /**
   * @description set default city loaded from details
   */
  useEffect(() => {
    if (contactDetails.cityId) {
      countyRef.current?.setValue('', contactDetails.cityId);
    }
  }, [countyOptions, contactDetails]);

  const [cepToSearch, setCepToSearch] = useState('');
  /**
   * @description Search CEP and in success set default values to State, County, Street and District
   */
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const timer = window.setTimeout(() => {
      if (!cepToSearch) {
        source.cancel();
        return;
      }
      Notiflix.Block.circle('.notiflix-cep-search');
      api
        .get(`/address/search-address-by-zip-code?zipCode=${cepToSearch}`, {
          cancelToken: source.token,
        })
        .then((resp) => {
          const { data } = resp;
          const { content } = data;
          if (content && content[0]) {
            formRef.current?.setFieldValue(
              'addressStreet',
              content[0].streetName,
            );
            formRef.current?.setFieldValue('neighborhood', content[0].district);
            stateRef.current?.setValue('', content[0].states);
            if (content[0].city) {
              api
                .get(`/address/list-of-cities?state=${content[0].states}`)
                .then((respCity) => {
                  if (respCity.data.content) {
                    setCountyOptions(
                      respCity.data.content.reduce(
                        (
                          acc: { title: string; value: string }[],
                          act: { city: string; ibgeCityCode: string },
                        ) => {
                          acc.push({
                            title: act.city,
                            value: act.ibgeCityCode,
                          });
                          return acc;
                        },
                        [],
                      ),
                    );
                    countyRef.current?.setValue(
                      content[0].city.toUpperCase(),
                      '',
                    );
                  }
                });
            }
          }
        })
        .finally(() => {
          Notiflix.Block.circle('.notiflix-cep-search');
          Notiflix.Block.remove('.notiflix-cep-search');
        });
    }, 1000);

    return () => {
      source.cancel();
      window.clearTimeout(timer);
    };
  }, [cepToSearch]);

  const footerActions = useMemo(() => {
    if (editMode) {
      return (
        <>
          <Button
            secondary
            autoWidth
            formLook
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
          <Button
            orangeButton
            autoWidth
            formLook
            className="notiflix-cancel-termination"
            onClick={handleCancelRegister}
          >
            Cancelar
          </Button>
          <Button
            orangeButton
            autoWidth
            formLook
            className="notiflix-view-termination"
            onClick={handleViewTerminationReport}
          >
            Visualizar formulário de exclusão
          </Button>
          <Button
            type="button"
            greenCriticalButton
            autoWidth
            formLook
            className="notiflix-submit-form"
            onClick={() => sendSaveOnEdit(formRef.current?.getData())}
          >
            Salvar
          </Button>
          {/* {dispatch &&
            attachmentRef.current?.files &&
            attachmentRef.current?.files[0] && ( */}
          <Button
            greenButton
            autoWidth
            formLook
            className="notiflix-save-and-release-termination"
            onClick={() => sendSaveAndReleaseOnEdit(formRef.current?.getData())}
          >
            Salvar e Liberar
          </Button>
          {/* )} */}
        </>
      );
    }
    if (saved) {
      return (
        <>
          <Button
            secondary
            autoWidth
            formLook
            onClick={() => {
              if (callbackSetStep) callbackSetStep(1);
            }}
          >
            Voltar
          </Button>
          {rowsSelecteds.length === 1 && (
            <Button
              orangeButton
              autoWidth
              formLook
              className="notiflix-view-termination"
              onClick={handleViewTerminationReport}
            >
              Visualizar formulário de exclusão
            </Button>
          )}
          <Button
            type="button"
            greenCriticalButton
            autoWidth
            formLook
            className="notiflix-submit-form"
            onClick={() => sendSave(formRef.current?.getData())}
          >
            Salvar
          </Button>
          {/* {dispatch &&
            attachmentRef.current?.files &&
            !!attachmentRef.current?.files[0] && ( */}
          <Button
            greenButton
            autoWidth
            formLook
            className="notiflix-save-and-release-termination"
            onClick={() => sendSaveAndRelease(formRef.current?.getData())}
          >
            Salvar e Liberar
          </Button>
          {/* )} */}
        </>
      );
    }
    return (
      <>
        <Button
          secondary
          autoWidth
          formLook
          onClick={() => {
            if (callbackSetStep) callbackSetStep(1);
          }}
        >
          Voltar
        </Button>
        <Button
          type="submit"
          greenCriticalButton
          autoWidth
          formLook
          className="notiflix-submit-form"
        >
          Salvar
        </Button>
        {/* {dispatch &&
          attachmentRef.current?.files &&
          !!attachmentRef.current?.files[0] && ( */}
        <Button
          greenButton
          autoWidth
          formLook
          className="notiflix-save-and-release-termination"
          onClick={() => sendSaveAndRelease(formRef.current?.getData())}
        >
          Salvar e Liberar
        </Button>
        {/* )} */}
      </>
    );
  }, [
    editMode,
    saved,
    handleCancelRegister,
    handleViewTerminationReport,
    // dispatch,
    history,
    sendSaveOnEdit,
    sendSaveAndReleaseOnEdit,
    rowsSelecteds.length,
    callbackSetStep,
    sendSave,
    sendSaveAndRelease,
  ]);

  return (
    <FormWrapper
      onSubmit={() => sendNewRequest(formRef.current?.getData(), false)}
      formLook
      ref={formRef}
      className="form-termination-beneficiary"
    >
      <DatePicker
        title="Data da solicitação:"
        name="requestDate"
        locale="pt-BR"
        showTimeSelect
        timeFormat="p"
        timeIntervals={1}
        dateFormat="Pp"
        timeCaption="Horário"
        selected={requestDate.value}
        minDate={new Date()}
        maxDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 90,
          )
        }
        onChange={(date: Date) => setRequestDate({ value: date, error: '' })}
        formLook
        themeColor={defaultColor}
        widthContainerDesktop="25%"
        icon
        disabled
      />

      <Input
        title="Usuário solicitação:"
        name="requestUser"
        disabled
        formLook
        themeColor={defaultColor}
        widthContainerDesktop="25%"
        value={user?.webUsername ?? ''}
      />

      <Select
        title="Causa da rescisão:"
        name="reasonRequest"
        ref={reasonRequestRef}
        options={reasonRequestOptions.items}
        themeColor={defaultColor}
        className="notiflix-reasons"
        formLook
        changeCallback={(option) => {
          setReasonCause(+option);
          const rsn = reasonRequestOptions.fromApi.find(
            (i: { id: string }) => i.id === option,
          );
          if (rsn) {
            setBeneficiaryInitiative(rsn.beneficiaryInitiative);
          }
          getHolderMonthlyAndRetirement(option);
        }}
        widthContainerDesktop="25%"
      />

      <DatePicker
        title="Data da rescisão:"
        name="terminateDate"
        locale="pt-BR"
        showTimeSelect
        timeFormat="p"
        timeIntervals={1}
        timeCaption="Horário"
        dateFormat="Pp"
        selected={terminateDate.value}
        error={beneficiaryInitiative === 'S' ? '' : terminateDate.error}
        disabled={beneficiaryInitiative === 'S'}
        onChange={(date: Date) => setTerminateDate({ value: date, error: '' })}
        icon
        formLook
        minDate={reasonCause === 4 ? null : new Date()}
        maxDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 90,
          )
        }
        themeColor={defaultColor}
        widthContainerDesktop="25%"
      />

      <OptionalParamsTerminateBeneficiaryContainer
        className={reasonCause === DEATH ? 'visible' : ''}
      >
        <Input
          title="Certidão de óbito:"
          name="deathCertificate"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="60%"
        />
        <DatePicker
          title="Data do óbito:"
          name="deathDate"
          selected={deathDate.value}
          error={deathDate.error}
          onChange={(date: Date) => setDeathDate({ value: date, error: '' })}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="10%"
          icon
        />
      </OptionalParamsTerminateBeneficiaryContainer>

      <OptionalParamsTerminateBeneficiaryContainer
        className={
          holderMonthly === 1 || holderRetirement === 1 ? 'visible' : ''
        }
      >
        <Select
          title="Titular contribuiu no pagamento da mensalidade do plano:"
          name="holderContributed"
          ref={holderContributedRef}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="30%"
          options={[
            { title: '', value: '' },
            { title: 'Sim', value: 'S' },
            { title: 'Não', value: 'N' },
          ]}
          changeCallback={(option) => {
            setShowMonthOfContribuition(option === 'S');
          }}
        />
        <Input
          title="Tempo de contribuição (em meses):"
          name="monthsOfContribution"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="20%"
          containerClassName={
            !showMonthOfContribuition ? 'contentNotVisible' : ''
          }
          type="number"
        />
        <Select
          title="Titular se aposentou na empresa:"
          name="holderRetired"
          ref={holderRetiredRef}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="30%"
          options={[
            { title: '', value: '' },
            { title: 'Sim', value: 'S' },
            { title: 'Não', value: 'N' },
          ]}
          changeCallback={(option) => setShowConcessionDate(option === 'S')}
        />
        <DatePicker
          title="Data de concessão:"
          name="concessionDate"
          selected={concessionDatepicker.value}
          error={concessionDatepicker.error}
          onChange={(date: Date) =>
            setConcessionDatepicker({ value: date, error: '' })
          }
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="20%"
          icon
          className={!showConcessionDate ? 'contentNotVisible' : ''}
        />
      </OptionalParamsTerminateBeneficiaryContainer>

      {/* <Checkbox name="cardDevolution" value="Devolução Cartão" /> */}

      <Input
        name="observation"
        title="Observação:"
        formLook
        themeColor={defaultColor}
        type="textarea"
      />

      {/* <Select
        title="Forma de Contato:"
        name="contactForm"
        ref={contactFormRef}
        options={contactFormOptions}
        themeColor={defaultColor}
        className="notiflix-contact-form"
        changeCallback={setActualContactForm}
        liveReload
        formLook
        widthContainerDesktop="100%"
      />

      <OptionalParamsTerminateBeneficiaryContainer
        className={actualContactForm === '1' ? 'visible' : ''}
      >
        <Input
          name="email"
          title="E-mail:"
          formLook
          themeColor={defaultColor}
          className="notiflix-contact-detail"
        />
      </OptionalParamsTerminateBeneficiaryContainer>
      <OptionalParamsTerminateBeneficiaryContainer
        className={actualContactForm === '2' ? 'visible' : ''}
      >
        <Input
          name="zipCode"
          title="CEP:"
          formLook
          themeColor={defaultColor}
          className="notiflix-contact-detail"
          widthContainerDesktop="33%"
          masks={['99999-999']}
          onInput={(e) => setCepToSearch(unMask(e.currentTarget.value))}
        />
        <Select
          ref={stateRef}
          name="states"
          title="Estados:"
          options={stateOptions}
          formLook
          themeColor={defaultColor}
          className="notiflix-contact-states"
          liveReload
          changeCallback={getAllCitysOfStateSelected}
          inputMode
          widthContainerDesktop="33%"
        />
        <Select
          ref={countyRef}
          name="county"
          title="Município:"
          options={countyOptions}
          formLook
          themeColor={defaultColor}
          className="notiflix-contact-county"
          liveReload
          inputMode
          widthContainerDesktop="33%"
        />
        <Input
          name="neighborhood"
          title="Bairro:"
          themeColor={defaultColor}
          formLook
          widthContainerDesktop="20%"
        />
        <Input
          name="addressStreet"
          title="Rua:"
          themeColor={defaultColor}
          formLook
          widthContainerDesktop="40%"
        />
        <Input
          name="addressNumber"
          title="Nº:"
          themeColor={defaultColor}
          formLook
          widthContainerDesktop="10%"
        />
        <Input
          name="addressComplement"
          title="Complemento:"
          themeColor={defaultColor}
          formLook
          widthContainerDesktop="25%"
        />

        <Select
          name="DDIPhone"
          title="DDI - Telefone:"
          ref={DDIPhoneRef}
          options={getAllDDIForSelectOptions()}
          formLook
          widthContainerDesktop="15%"
          themeColor={defaultColor}
        />
        <Input
          name="phone"
          title="DDD/Telefone:"
          formLook
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          widthContainerDesktop="35%"
          themeColor={defaultColor}
        />
        <Select
          name="DDDCellphone"
          title="DDI - Celular:"
          ref={DDICellphoneRef}
          options={getAllDDIForSelectOptions()}
          formLook
          widthContainerDesktop="15%"
          themeColor={defaultColor}
        />
        <Input
          name="cellphone"
          title="DDD/Celular:"
          formLook
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          widthContainerDesktop="35%"
          themeColor={defaultColor}
        />
      </OptionalParamsTerminateBeneficiaryContainer> */}

      <InputFile
        name="attachments"
        placeholder="Anexo"
        ref={attachmentRef}
        callbackHasChanged={() => dispatchChange(v4())}
        themeColor={defaultColor}
        multiple
      />

      {footerActions}
    </FormWrapper>
  );
}
