import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  max-width: 69rem;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
  background: #b1d34b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 60px 20px;

  @media (max-width: 1024px) {
    gap: 40px;
    padding: 40px 20px;
  }

  @media (max-width: 768px) {
    gap: 30px;
    padding: 30px 20px;
  }
`;

export const Text404 = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 360px;
  color: #ffffff;
  font-family: 'Unimed-Slab-Bold';
  line-height: 435.68px;

  @media (max-width: 1024px) {
    font-size: 200px;
    line-height: 200px;
  }

  @media (max-width: 768px) {
    font-size: 120px;
    line-height: 150px;
  }
`;

export const Text = styled.p`
  width: 100%;
  text-align: center;
  /* padding: 40px 0; */
  /* font-weight: bold; */
  font-family: 'Unimed-Slab-Bold';
  color: #ffffff;
  line-height: 78px;
  font-size: 72px;

  @media (max-width: 1024px) {
    font-size: 50px;
    line-height: 50px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 20px;
  }
`;

export const ButtonLogin = styled.button`
  border: 1px solid #ffffff;
  border-radius: 10px;
  background: transparent;
  padding: 15px 46px;
  color: #ffffff;
  font-size: 30px;
  font-family: 'Unimed-Sans-Book';
  font-weight: 800;

  @media (max-width: 1024px) {
    padding: 15px 36px;
    font-size: 25px;
  }

  @media (max-width: 768px) {
    padding: 15px 26px;
    font-size: 20px;
  }
`;

export const LogoUnimed = styled.div`
  width: 247px;
  img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 180px;
  }

  @media (max-width: 768px) {
    width: 150px;
  }
`;

export const DevTo = styled.div`
  a {
    font-family: 'Unimed-Sans-Book';
    font-size: 9px;
    color: #ffffff;
    text-decoration: none;
    img {
      margin-left: 5px;
    }
  }
`;
