import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { FiChevronDown } from 'react-icons/fi';
import {
  Container,
  Selected,
  SelectedInput,
  SelectedInputContainer,
  OptionsWrapper,
  Title,
  Label,
  Error,
} from './styles';

export interface OptionProps {
  value: string;
  title: string;
}
interface SelectProps {
  name: string;
  disabled?: boolean;
  options: OptionProps[];
  changeCallback?(value: string): void;
  clickCallback?(): void;
  apiSearch?(): void;
  className?: string;
  labelClassName?: string;
  formLook?: boolean;
  bolder?: boolean;
  title?: string;
  placeholder?: string;
  hidden?: boolean;
  error?: string;
  liveReload?: boolean;
  themeColor?: string;
  widthContainerDesktop?: string;
  inputMode?: boolean;
  openUp?: boolean;
  autoComplete?: 'on' | 'off';
}

export interface SelectHandles {
  value: string;
  filtered: string;
  setError(err: string): void;
  setValue(title?: string, value?: string): boolean;
  setDisabled(status: boolean): void;
}

const Select: React.ForwardRefRenderFunction<
  SelectHandles,
  React.PropsWithChildren<SelectProps>
> = (
  {
    name,
    options,
    bolder,
    disabled,
    changeCallback,
    clickCallback,
    apiSearch,
    className,
    labelClassName,
    placeholder,
    formLook,
    hidden,
    title,
    error,
    liveReload,
    themeColor,
    widthContainerDesktop,
    inputMode = true,
    children,
    openUp,
    autoComplete = 'none',
  },
  ref,
) => {
  const [open, setOpen] = useState(false);
  const [value, setActualValue] = useState(options[0]);
  const [actualHeight, setActualHeight] = useState(0);
  const optionWrapperRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState('');
  const [err, setErr] = useState('');
  const [disabledIntern, setDisabledIntern] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const setError = useCallback((errString) => {
    setErr(errString);
  }, []);

  const setValue = useCallback(
    (t = '', v = '') => {
      let concluded = false;
      options.forEach((item) => {
        if (t && item.title === t) {
          setActualValue(item);
          setError('');
          if (inputRef.current) {
            inputRef.current.value = item.title;
          }
          concluded = true;
        }
        if (v && item.value === v) {
          setActualValue(item);
          setError('');
          if (inputRef.current) {
            inputRef.current.value = item.title;
          }
          concluded = true;
        } else if (!v && !t && !item.title && !item.value) {
          setActualValue(item);
          setError('');
          if (inputRef.current) {
            inputRef.current.value = item.title;
          }
          concluded = true;
        }
      });
      return concluded;
    },
    [options, setError],
  );

  const setDisabled = useCallback((status) => {
    setDisabledIntern(status);
  }, []);

  const handleSelected = useCallback(
    (option: OptionProps) => {
      setActualValue(option);
      setError('');
      if (inputRef.current) {
        inputRef.current.value = option.title;
      }
      if (changeCallback) {
        changeCallback(option.value);
      }
      setOpen(false);
      optionWrapperRef.current?.removeAttribute('data-onhover');
    },
    [changeCallback, setError],
  );

  const optionsFiltered = useMemo(() => {
    let filtered = options;
    if (filter !== '') {
      filtered = filtered.filter((f) =>
        f.title.toLowerCase().includes(filter.toLowerCase()),
      );
    }
    return filtered.map((option) => {
      return (
        <button
          key={`${option.title}-${option.value}`}
          type="button"
          onClick={() => handleSelected(option)}
          value={option.value}
          name={option.title}
          tabIndex={-1}
          // keeps the div open when the button is in focus
          onFocus={() => {
            optionWrapperRef.current?.setAttribute('data-onhover', 'true');
          }}
          // on lost focus remove the attribute from the div that keeps it open
          onBlur={() =>
            optionWrapperRef.current?.removeAttribute('data-onhover')
          }
          // prevent scroll in the div on press arrows
          onKeyDown={(e) => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          // navigate in options
          onKeyUp={(e) => {
            if (e.key === 'ArrowDown') {
              (
                e.currentTarget.nextElementSibling as HTMLButtonElement
              )?.focus();
            } else if (e.key === 'ArrowUp') {
              (
                e.currentTarget.previousElementSibling as HTMLButtonElement
              )?.focus();
            }
          }}
        >
          {option.title}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, filter]);

  const calculateHeight = useCallback(() => {
    if (optionWrapperRef.current) {
      return optionWrapperRef.current.scrollHeight + options.length * 2;
    }
    return 0;
  }, [options.length]);

  const handleOpen = useCallback(() => {
    setOpen(() => {
      if (clickCallback) {
        clickCallback();
      }
      return true;
    });
  }, [clickCallback]);

  const handleClose = useCallback(() => {
    window.setTimeout(() => {
      setOpen(false);
    }, 100);
  }, []);

  const showOptionsRelated = useCallback((filterTerm: string) => {
    setActualValue({ title: '', value: '' });
    setFilter(filterTerm);
  }, []);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (apiSearch) {
        apiSearch();
      }
    }, 1000);
    return () => {
      window.clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useLayoutEffect(() => {
    setActualHeight(calculateHeight());
  }, [calculateHeight, open]);

  useEffect(() => {
    setFilter('');
    if (liveReload) {
      setActualValue(options[0]);
      if (inputRef.current) {
        inputRef.current.value = options[0].title;
      }
    }
  }, [liveReload, options]);

  useImperativeHandle(
    ref,
    () => {
      return {
        value: value.value,
        filtered: filter,
        setError,
        setValue,
        setDisabled,
      };
    },
    [value.value, filter, setError, setValue, setDisabled],
  );

  return (
    <Label
      className={labelClassName}
      widthContainerDesktop={widthContainerDesktop}
    >
      {title && <Title themeColor={themeColor}>{title}</Title>}
      <Container className={className}>
        {!inputMode && (
          <Selected
            hidden={hidden}
            type="button"
            value={value.value}
            onFocus={handleOpen}
            onBlur={handleClose}
            isOpen={open}
            disabled={disabled || disabledIntern}
            formLook={formLook}
            isError={!!error || !!err}
            openUp={openUp}
          >
            {value.title}
            <FiChevronDown size={24} />
            {error && (
              <Error>
                <span>{error}</span>
              </Error>
            )}
            {err && (
              <Error>
                <span>{err}</span>
              </Error>
            )}
          </Selected>
        )}

        {inputMode && (
          <SelectedInputContainer
            isOpen={open}
            isError={!!error || !!err}
            openUp={openUp}
          >
            <SelectedInput
              ref={inputRef}
              hidden={hidden}
              autoComplete={autoComplete}
              defaultValue={value.title}
              onFocus={handleOpen}
              onBlur={handleClose}
              onChange={(e) => {
                showOptionsRelated(e.currentTarget.value);
              }}
              onKeyUp={(e) => {
                if (
                  e.key === 'ArrowDown' &&
                  optionWrapperRef.current &&
                  optionWrapperRef.current.children[0]
                ) {
                  (
                    optionWrapperRef.current.children[0] as HTMLButtonElement
                  ).focus();
                }
              }}
              isOpen={open}
              disabled={disabled || disabledIntern}
              formLook={formLook}
              isError={!!error || !!err}
              isInputMode
              placeholder={placeholder}
              type="search"
            />
            <FiChevronDown size={24} style={{ pointerEvents: 'none' }} />
            {error && (
              <Error>
                <span>{error}</span>
              </Error>
            )}
            {err && (
              <Error>
                <span>{err}</span>
              </Error>
            )}
          </SelectedInputContainer>
        )}
        <OptionsWrapper
          isOpen={open}
          ref={optionWrapperRef}
          calculateHeight={actualHeight}
          formLook={formLook}
          openUp={openUp}
        >
          {optionsFiltered}
        </OptionsWrapper>
      </Container>
      {children}
    </Label>
  );
};

type State = React.Dispatch<
  React.SetStateAction<
    {
      value: string;
      title: string;
    }[]
  >
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const changeSelected = (stateCallback: State, newValue: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateCallback((prev: any) => {
    const all = prev;
    const index = all.findIndex(
      (item: { value: string }) => item.value === newValue,
    );

    if (index !== -1) {
      const option = all[index];
      const toReturn = [
        option,
        ...all.slice(0, index),
        ...all.slice(index + 1, prev.length),
      ];
      return toReturn;
    }
    return prev;
  });
};

export default forwardRef(Select);
