import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import produce from 'immer';
import PageWrapper from '../../../components/PageWrapper';
import { Column } from '../../../components/Table';
import AddField, { getTag } from '../../../utils/tableUtils';
import {
  Table,
  FormWrapperPeriodDate,
  Select,
  Container,
  ActionsButtons,
} from './styles';
import DatePicker from '../../../components/DatePicker';
import Button from '../../../components/Button';
import { dateToPTBR, ptBRToDate } from '../../../utils/formatt';
import api from '../../../services/api/api';
import { SelectHandles } from '../../../components/Select';
import Title from '../../../components/Title';
import { useContract } from '../../../hooks/contract';
import Modal2 from '../../../components/Modal2';

const RequestDuplicateCardConsultCompany: React.FC = () => {
  const { colors } = useTheme();
  const { contract, defineContract } = useContract();

  const [selectedFromDate, setSelectedFromDate] = useState<Date>(
    new Date(ptBRToDate(`01/01/${new Date().getFullYear()}`)),
  );
  const [selectedToDate, setSelectedToDate] = useState<Date>(
    new Date(ptBRToDate(`31/12/${new Date().getFullYear()}`)),
  );
  const selectRef = useRef<SelectHandles>(null);
  const [selectOptions, setSelectOptions] = useState([
    {
      title: 'Selecione...',
      value: '',
    },
  ]);
  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);

  const handleConfirmReceive = useCallback(async (nrSeqCarteira) => {
    try {
      Notiflix.Block.circle(`.notiflix-confirm-${nrSeqCarteira}`);

      const { data } = await api.put(`/beneficiary/confirm-card-receipt`, {
        cardId: nrSeqCarteira,
      });
      Notiflix.Block.remove(`.notiflix-confirm-${nrSeqCarteira}`);
      setFilteredTableData((prev) =>
        produce(prev, (draft) => {
          const row = draft.find(
            (item) => item.nrSeqCarteira === nrSeqCarteira,
          );
          row.statusDescription = 'Recebida pelo beneficiário/empresa';
        }),
      );

      Modal2.Success({
        closable: false,
        title: 'Perfeito!',
        text: data.message,
        children: (
          <>
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          </>
        ),
      });
    } catch (err) {
      Notiflix.Block.remove(`.notiflix-confirm-${nrSeqCarteira}`);
      Notiflix.Notify.failure(
        'Opss... Algo deu errado, tente novamente mais tarde.',
      );
    }
  }, []);

  const situationTemplate = useCallback(
    (statusDescription, nrSeqCarteira) => {
      switch (statusDescription) {
        case 'Em análise pela operadora':
          return getTag(
            'Status',
            statusDescription,
            'orange',
            statusDescription,
          );
        case 'cancelado':
          return getTag('Status', 'Cancelado', 'red', statusDescription);
        case 'Recebida pelo beneficiário/empresa':
          return getTag(
            'Status',
            statusDescription,
            'green',
            statusDescription,
          );
        case 'solicitado':
          return getTag('Status', 'Solicitado', 'orange', statusDescription);
        case 'Gerada a emissão da carteira de identificação':
          return getTag(
            'Status',
            statusDescription,
            'orange',
            statusDescription,
          );
        case 'Encaminhado ao beneficiário/empresa':
          return (
            <Button
              autoWidth
              sausageLook
              onClick={() => handleConfirmReceive(nrSeqCarteira)}
              className={`notiflix-confirm-${nrSeqCarteira}`}
            >
              Confirmar entrega
            </Button>
          );
        // return getTag(
        //   'Status',
        //   'Encaminhado ao beneficiário/empresa',
        //   'orange',
        //   data,
        // );
        default:
          return getTag(
            'Status',
            statusDescription,
            'orange',
            statusDescription,
          );
      }
    },
    [handleConfirmReceive],
  );

  const getSelectFilter = useCallback(async () => {
    try {
      Notiflix.Block.circle('.select-notiflix');
      const { data } = await api.get('/domain/wallet-status');

      if (data.content) {
        setSelectOptions(() => {
          const newValues = data.content.reduce(
            (
              acc: { title: string; value: string }[],
              act: { name: string; id: string },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          );
          return [{ name: 'Selecione...', title: '' }, ...newValues];
        });
      }
    } catch (error) {
      if (error?.response?.data.message) {
        Notiflix.Notify.info(error.response.data.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.select-notiflix');
    }
  }, []);

  const handleCancelDuplicateCard = useCallback(async (nrSeqCarteira) => {
    Notiflix.Block.circle(`.notiflix-cancel-${nrSeqCarteira}`);
    try {
      const { data } = await api.put(`/beneficiary/cancel-card-request`, {
        cardId: nrSeqCarteira,
        serviceProtocolNumber: null,
      });

      setFilteredTableData((prev) =>
        produce(prev, (draftState) => {
          const item = draftState.find(
            (i) => i.nrSeqCarteira === nrSeqCarteira,
          );
          item.statusDescription = 'Cancelado';
          item.allowsCancel = 'N';
        }),
      );

      Modal2.Success({
        closable: false,
        title: 'Perfeito!',
        text: data.message,
        children: (
          <>
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          </>
        ),
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Opss... Algo deu errado, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove(`.notiflix-cancel-${nrSeqCarteira}`);
    }
  }, []);

  const getFilteredTableData = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');

      const status = selectRef.current?.value;
      let route = `company/search-second-copy-card?contractId=${
        contract.contractId
      }&startDate=${dateToPTBR(selectedFromDate)}&finalDate=${dateToPTBR(
        selectedToDate,
      )}`;

      if (status) {
        route += `&status=${status}`;
      }

      const { data } = await api.get(route);
      const { content } = data;
      setFilteredTableData(content);
    } catch (error) {
      if (error?.response?.status === 400) {
        setFilteredTableData([]);
      } else {
        Notiflix.Notify.failure(
          'Não foi possível buscar os dados. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contract, selectedFromDate, selectedToDate]);

  useEffect(() => {
    if (contract.contractId) {
      getSelectFilter();
      getFilteredTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  return (
    <PageWrapper
      gridTemplateColumns="1fr 8fr 1fr"
      gridTemplateRows="auto auto 1fr"
    >
      <Container>
        <Title themeColor={colors.palet.institutional9}>
          Consultar segunda via do cartão
        </Title>

        <FormWrapperPeriodDate onSubmit={getFilteredTableData} formLook>
          <DatePicker
            name="from"
            onChange={(date: Date) => setSelectedFromDate(date)}
            selected={selectedFromDate}
            icon
            formLook
            title="Período de:"
            widthContainerDesktop="20%"
            themeColor={colors.palet.institutional9}
          />
          <DatePicker
            name="to"
            onChange={(date: Date) => setSelectedToDate(date)}
            selected={selectedToDate}
            icon
            formLook
            title="Até:"
            widthContainerDesktop="20%"
            themeColor={colors.palet.institutional9}
          />
          <Select
            name="typeuser"
            ref={selectRef}
            options={selectOptions}
            className="select-notiflix tag"
            widthContainerDesktop="30%"
            title="Status:"
            formLook
            themeColor={colors.palet.institutional9}
          />

          <Button type="submit" autoWidth grayButton formLook>
            Buscar
          </Button>
        </FormWrapperPeriodDate>

        <Table
          items={filteredTableData}
          key="duplicate-card-consult-table"
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="duplicate-card-consult-table"
        >
          <Column
            field="beneficiaryName"
            header="Nome do beneficiário"
            sortable
            body={(data) =>
              AddField(data.beneficiaryName, 'Nome do beneficiário')
            }
          />
          <Column
            field="cardNumber"
            header="Carteira"
            sortable
            body={(data) => AddField(data.cardNumber, 'Carteira')}
          />
          <Column
            field="sentDate"
            header="Data de envio"
            className="date"
            body={(data) => AddField(data.sentDate, 'Data de envio')}
          />
          <Column
            field="requestDate"
            header="Data solicitação"
            className="date"
            body={(data) =>
              AddField(data.requestDate.split('00:00:00'), 'Data solicitação')
            }
          />
          <Column
            field="statusDescription"
            header="Status"
            sortable
            className="tag"
            style={{ width: '200px' }}
            body={(data) =>
              situationTemplate(data.statusDescription, data.nrSeqCarteira)
            }
          />
          <Column
            style={{
              width: '100px',
            }}
            body={(data) => (
              <ActionsButtons>
                {data.allowsCancel !== 'N' && (
                  <Button
                    onClick={() =>
                      handleCancelDuplicateCard(data.nrSeqCarteira)
                    }
                    orangeButton
                    sausageLook
                    autoWidth
                    noChangeColor
                    className={`notiflix-cancel-${data.nrSeqCarteira}`}
                  >
                    Cancelar
                  </Button>
                )}
              </ActionsButtons>
            )}
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default RequestDuplicateCardConsultCompany;
