import styled from 'styled-components';
import FormWrapper from '../../components/FormWrapper';
import TableComponent from '../../components/Table';
import ButtonComponent from '../../components/Button';

export const Container = styled.section`
  margin-bottom: 30px;
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/4;
  }
`;

export const SerchDataToTableContainer = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    > div {
      &:nth-child(3),
      &:nth-child(4) {
        width: auto;
        flex: 1 1 0;
      }
    }
  }
`;

export const Table = styled(TableComponent)``;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 8px;

  @media (max-width: 768px) {
    width: calc(100vw - 100px);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 16px;
  }
  > button.marginRight {
    margin-right: auto;
  }
`;

export const ModalFormWrapper = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;
  button {
    & + button {
      margin-left: auto;
    }
  }
`;

export const ModalContainer = styled.div`
  h2 {
    font-family: 'Unimed-Sans-SemiBold';
  }

  display: flex;
  flex-direction: column;
  position: relative;
  width: 350px;

  @media (max-width: 768px) {
    width: 220px;
  }
`;

export const ModalPart = styled.div``;

export const FilesContainer = styled.div`
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 24px 0;

  overflow-y: auto;
  button {
    font-family: 'Unimed-Sans-SemiBold';
    border: none;
    outline: none;
    background: transparent;
    color: ${(props) => props.theme.colors.text.primary};
    transition: 300ms;
    &:hover {
      color: ${(props) => props.theme.colors.text.black};
    }
  }
`;

export const ButtonContainerModal = styled.div`
  padding-top: 15px;
`;

export const ModalButton = styled(ButtonComponent)`
  margin-top: 10px;
`;
