import styled, { keyframes, css } from 'styled-components';

import BackgroundEmpresaImg from '../../assets/images/background-login-empresa.jpg';
import Button from '../../components/Button';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 8px;
`;

interface BakcgroundProps {
  background: string;
}

export const Background = styled.div<BakcgroundProps>`
  position: relative;
  background-size: cover;
  background-blend-mode: multiply;
  position: relative;
  grid-column: 1/8;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10%;
  > div,
  > h1,
  > svg {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    width: 110%;
    left: -10%;
    height: 100%;
    background: url(${BackgroundEmpresaImg}) no-repeat center,
      linear-gradient(to top, #0a5f55, #0a5f55);
    background-size: cover;
    background-blend-mode: multiply;
    opacity: 0;
    transition: 500ms;
    ${(props) =>
      props.background === '2' &&
      css`
        opacity: 1;
      `}
  }

  > svg {
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BemVindo = styled.h1`
  font-size: 4rem;
  width: 100%;
  color: ${(props) => props.theme.colors.text.inverse};
  font-family: 'Unimed-Slab-Bold';
  @media (max-width: 1200px) {
    font-size: 3rem;
  }
`;
export const BemVindoMobile = styled.h1`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    color: ${(props) => props.theme.colors.text.inverse};
    font-family: 'Unimed-Slab-Bold';
    font-size: 2.1rem;
    text-align: center;
    margin-bottom: 24px;
    margin-top: auto;
  }
`;

const fadeToRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(-10px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ContentMobile = styled.div<BakcgroundProps>`
  > div {
    margin-top: 8px;
  }

  > svg {
    display: none;
  }
  @media (max-width: 768px) {
    background-size: cover;
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    height: 70%;
    order: 1;
    row-gap: 8px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(${BackgroundEmpresaImg}) no-repeat center,
        linear-gradient(to top, #0a5f55, #0a5f55);
      background-size: cover;
      background-blend-mode: multiply;
      opacity: 0;
      transition: 300ms;
      ${(props) =>
        props.background === '2' &&
        css`
          opacity: 1;
        `}
    }
    > svg {
      display: block;
      width: 100px;
      margin: 0 48px;
    }
    > div {
      margin: 0;
    }
    > div:last-child {
      position: absolute;
      bottom: -3px;
      width: calc(100% - 60px);
      transform: translateY(50%);
    }
    > svg,
    > h1 {
      z-index: 2;
    }
  }
`;

export const Content = styled.div`
  grid-column: 9/12;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  animation: ${fadeToRight} forwards ease-in 300ms;

  @media (max-width: 768px) {
    grid-column: 1/13;
    height: 100%;
    flex-direction: column;
    animation: none;
  }

  h2 {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 300;
    text-align: center;
    color: ${(props) => props.theme.colors.text.primary};
    font-family: 'Unimed-Sans-Book';
    margin-bottom: 38px;

    @media (max-width: 768px) {
      display: none;
    }
  }
  form {
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: calc(100% - 86px);
    }
    a {
      margin-top: 16px;
      display: block;
      width: 100%;
      font-size: 1rem;
      text-align: center;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.text.primary};
      text-decoration: none;

      @media (max-width: 768px) {
        order: 2;
        margin: 42px auto 0;
        width: calc(100% - 60px);
        text-align: right;
      }
    }
  }
`;
export const ButtonLogin = styled(Button)`
  margin-top: 15%;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0 30px;
    width: calc(100% - 60px);
    order: 3;
  }
`;

export const ContainerFooter = styled.div`
  position: absolute;
  bottom: 60px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: calc(100% - 60px);
    bottom: 0;
    flex-wrap: wrap;
  }
  > button {
    border: none;
    outline: none;
    background: transparent;
    font-family: 'Unimed-Sans-Bold';
    color: ${(props) => props.theme.colors.text.primary};
    text-decoration: underline;
    text-align: left;
    width: 100%;
    font-size: 1rem;
    @media (max-width: 320px) {
      font-size: 0.75rem;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    span {
      text-align: right;
      font-family: 'Unimed-Sans-book';
      color: ${(props) => props.theme.colors.text.primary};
      font-size: 1rem;
      @media (max-width: 767px) {
        margin-top: 16px;
      }
      @media (max-width: 320px) {
        font-size: 0.75rem;
      }
    }
    a {
      font-size: 1rem;
      text-align: right;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.text.primary};
      text-decoration: underline;
      @media (max-width: 320px) {
        font-size: 0.75rem;
      }
    }
  }
`;

export const ModalTitle = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  text-align: left;
  font-style: normal;
  font-size: 1.7rem;
  color: ${(props) => props.theme.colors.text.primary};
  @media (min-width: 769px) {
    margin-top: 11px;
    margin-left: 26px;
  }
`;

export const ModalContent = styled.p`
  font-family: 'Unimed-Sans-Book';
  word-break: break-word;
  color: ${(props) => props.theme.colors.text.primary};
  text-align: left;
  @media (min-width: 769px) {
    margin: 56px 26px;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }
  @media (max-width: 768px) {
    max-height: calc(100vh - 440px);
    overflow-y: auto;
  }
`;

export const ModalButton = styled(Button)`
  margin-left: auto;
  display: block;
  margin-top: 22px;
`;

const animationDelayBlur = (qtd: number) => {
  let styles = '';
  for (let x = 0; x < qtd; x += 1) {
    styles += `&:nth-child(${x + 1}) {transition-delay: ${x * 20}ms;}`;
  }
  return css`
    ${styles}
  `;
};

export const ContainerInfoSvg = styled.div`
  position: absolute;
  right: -30px;
  top: 15px;
  cursor: pointer;

  > div {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    right: 30px;
    top: 50%;
    transform: translate(30px, -50%);
    z-index: 99;
    transition: 300ms;
    > svg {
      path {
        &.card-header-logo,
        &.card-blur,
        &.card-svg,
        &.card-header-text-black,
        &.card-coop,
        &.card-qrcode {
          transition: 300ms;
          transition-delay: 300ms;
          opacity: 0;
          visibility: hidden;
          transform: translateX(10px);
        }
        &.card-blur {
          ${animationDelayBlur(309)}
        }
        &.card-svg {
          ${animationDelayBlur(17)}
        }
        &.card-header-text-black {
          ${animationDelayBlur(30)}
        }
      }
    }
  }
  &:hover {
    > div {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, -50%);
      > svg {
        path {
          &.card-blur,
          &.card-svg,
          &.card-header-logo,
          &.card-header-text-black,
          &.card-coop,
          &.card-qrcode {
            opacity: 1;
            visibility: visible;
            transform: translateX(0px);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    right: -26px;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const ContainerLogoW = styled.div`
  margin: 5% 0;
  a {
    font-family: 'Unimed-Sans-Book';
    text-decoration: none;
    font-size: 0.85rem;
    color: ${(props) => props.theme.colors.text.inverse};

    svg {
      margin-left: 8px;
    }
    strong {
      font-family: 'Unimed-Sans-SemiBold';
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 16px 0;
  }
  &.mobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      a {
        font-family: 'Unimed-Sans-Book';
        color: ${(props) => props.theme.colors.text.primary};
        display: flex;
        text-decoration: none;
        align-items: baseline;
        width: 100%;
        font-size: 0.75rem;
        strong {
          margin-left: 6px;
        }
        svg {
          margin-left: auto;
          path {
            fill: ${(props) => props.theme.colors.text.primary};
          }
        }
        @media (max-width: 320px) {
          font-size: 0.65rem;
        }
      }
    }
  }
`;
