/* eslint-disable no-param-reassign */
import { useField } from '@unform/core';
import Notiflix from 'notiflix';
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';

import { FiChevronDown } from 'react-icons/fi';
import { v4 } from 'uuid';
import api from '../../services/api/api';
import {
  Container,
  SelectedInput,
  SelectedInputContainer,
  OptionsWrapper,
  Title,
  Label,
  Error,
} from '../Select/styles';

export interface OptionProps {
  id: string;
  name: string;
}
interface SelectProps {
  name: string;
  disabled?: boolean;
  apiSearch?: string | null;
  className?: string;
  formLook?: boolean;
  title?: string;
  hidden?: boolean;
  themeColor?: string;
  widthContainerDesktop?: string;
  options?: OptionProps[];
  callbackOnChange?: (name: string, id: string) => void;
  callbackOnLoad?(): void;
  personalReducer?: (content: any) => OptionProps[];
  optionsId?: string;
}

const SelectUnform: React.FC<SelectProps> = ({
  name,
  apiSearch,
  className,
  formLook,
  hidden,
  title,
  themeColor,
  widthContainerDesktop,
  options: optionsSended,
  callbackOnChange,
  callbackOnLoad,
  personalReducer,
  disabled,
  optionsId,
}) => {
  const optionWrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [actualHeight, setActualHeight] = useState(0);
  const [filter, setFilter] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, error, clearError } = useField(name);

  const preventDispatchOnFirstRender = useRef(true);

  const [tempValue, setTempValue] = useState('');

  const [onLoading, setOnLoading] = useState(!!apiSearch);

  const [options, setOptions] = useState(() => {
    if (optionsSended) {
      return optionsSended;
    }
    return [] as OptionProps[];
  });
  const optionsRef = useRef(options);

  const handleSelected = useCallback(
    (option: OptionProps) => {
      if (inputRef.current) {
        inputRef.current.value = option.id;
      }
      if (inputRef2.current) {
        inputRef2.current.value = option.name;
      }
      if (callbackOnChange) {
        callbackOnChange(option.name, option.id);
      }
      setOpen(false);
      clearError();
      optionWrapperRef.current?.removeAttribute('data-onhover');
    },
    [clearError, callbackOnChange],
  );

  const optionsFiltered = useMemo(() => {
    let filtered = options;
    if (filter !== '') {
      filtered = filtered.filter((f) =>
        f.name.toLowerCase().includes(filter.toLowerCase()),
      );
    }
    return filtered.map((option) => {
      return (
        <button
          key={v4()}
          type="button"
          onClick={() => handleSelected(option)}
          value={option.id}
          data-description={option.name}
          name={name}
          tabIndex={-1}
          // keeps the div open when the button is in focus
          onFocus={() => {
            optionWrapperRef.current?.setAttribute('data-onhover', 'true');
          }}
          // on lost focus remove the attribute from the div that keeps it open
          onBlur={() =>
            optionWrapperRef.current?.removeAttribute('data-onhover')
          }
          // prevent scroll in the div on press arrows
          onKeyDown={(e) => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          // navigate in options
          onKeyUp={(e) => {
            if (e.key === 'ArrowDown') {
              (
                e.currentTarget.nextElementSibling as HTMLButtonElement
              )?.focus();
            } else if (e.key === 'ArrowUp') {
              (
                e.currentTarget.previousElementSibling as HTMLButtonElement
              )?.focus();
            }
          }}
        >
          {option.name}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, filter]);

  const calculateHeight = useCallback(() => {
    if (optionWrapperRef.current) {
      return optionWrapperRef.current.scrollHeight + options.length * 2;
    }
    return 0;
  }, [options.length]);

  const handleOpen = useCallback(() => {
    setOpen(() => {
      return true;
    });
  }, []);

  const handleClose = useCallback(() => {
    window.setTimeout(() => {
      setOpen(false);
    }, 100);
  }, []);

  const setValueAfterLoadingApi = useCallback(
    (find: string, opts: OptionProps[]) => {
      const option =
        opts.find((i) => String(i.id) === String(find)) ??
        opts.find((i) => String(i.name) === String(find));
      if (option) {
        handleSelected(option);
      }

      setTempValue('');
    },
    [handleSelected],
  );

  useLayoutEffect(() => {
    setActualHeight(calculateHeight());
  }, [calculateHeight, open, optionsFiltered]);

  useEffect(() => {
    if (!onLoading && apiSearch && tempValue && options.length > 0) {
      setValueAfterLoadingApi(tempValue, options);
    }
  }, [onLoading, apiSearch, setValueAfterLoadingApi, tempValue, options]);

  useEffect(() => {
    registerField({
      ref: inputRef,
      name: fieldName,
      getValue: (ref) => {
        return ref.current?.value;
      },
      setValue: (ref, newValue) => {
        clearError();
        if (!!onLoading || optionsRef.current.length === 0) {
          setTempValue(newValue as string);
        } else {
          const byId = optionsRef.current.find(
            (i) => String(i.id) === String(newValue),
          );
          const byName = optionsRef.current.find(
            (i) => String(i.name) === String(newValue),
          );
          handleSelected(
            byId ??
              byName ?? {
                name: '',
                id: '',
              },
          );
        }
      },
    });
  }, [clearError, fieldName, handleSelected, onLoading, registerField]);

  useEffect(() => {
    if (apiSearch) {
      setOnLoading(true);
      Notiflix.Block.circle(`.notiflix-select-unform-${name}`);
      api
        .get(apiSearch)
        .then((resp) => {
          const { content } = resp.data;
          if (content) {
            if (personalReducer) {
              setOptions(personalReducer(content));
            } else {
              setOptions(content);
            }
          }
        })
        .finally(() => {
          setOnLoading(false);
          if (callbackOnLoad) {
            callbackOnLoad();
          }
          Notiflix.Block.remove(`.notiflix-select-unform-${name}`);
        });
    } else if (callbackOnLoad) {
      callbackOnLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSearch, personalReducer, callbackOnLoad]);

  useEffect(() => {
    if (preventDispatchOnFirstRender.current) {
      preventDispatchOnFirstRender.current = false;
    } else {
      setOptions(optionsSended ?? []);
    }
  }, [optionsSended]);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  return (
    <Label
      widthContainerDesktop={widthContainerDesktop}
      className={`notiflix-select-unform-${name}`}
    >
      {title && <Title themeColor={themeColor}>{title}</Title>}
      <Container className={className}>
        <SelectedInputContainer isOpen={open} isError={!!error}>
          <SelectedInput
            ref={inputRef2}
            hidden={hidden}
            onFocus={handleOpen}
            onBlur={handleClose}
            onChange={(e) => {
              clearError();
              setFilter(e.currentTarget.value);
              if (inputRef.current) {
                inputRef.current.value = '';
              }
            }}
            onKeyUp={(e) => {
              if (callbackOnChange) {
                callbackOnChange('', '');
              }
              if (
                e.key === 'ArrowDown' &&
                optionWrapperRef.current &&
                optionWrapperRef.current.children[0]
              ) {
                (
                  optionWrapperRef.current.children[0] as HTMLButtonElement
                ).focus();
              }
            }}
            isOpen={open}
            formLook={formLook}
            isError={!!error}
            disabled={disabled}
            isInputMode
          />
          <FiChevronDown size={24} style={{ pointerEvents: 'none' }} />

          {error && (
            <Error>
              <span>{error}</span>
            </Error>
          )}
        </SelectedInputContainer>
        <OptionsWrapper
          isOpen={open}
          ref={optionWrapperRef}
          calculateHeight={actualHeight}
          formLook={formLook}
          id={optionsId}
        >
          {optionsFiltered}
        </OptionsWrapper>
        <div style={{ display: 'none' }}>
          <input type="text" ref={inputRef} hidden />
        </div>
      </Container>
    </Label>
  );
};

export default SelectUnform;
