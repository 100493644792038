/* eslint-disable max-len */
import React, { memo } from 'react';

const Sucess: React.FC = () => {
  return (
    <svg
      width="25"
      height="35"
      viewBox="0 0 25 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03681 25.9012C7.88734 25.4385 7.87417 24.082 8.03681 23.6216C10.1878 24.3773 15.126 24.4286 17.3961 23.6216C17.5587 24.082 17.5461 25.4385 17.3961 25.9012C15.256 26.6174 10.3264 26.587 8.03681 25.9012Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28317 18.0267C7.23356 17.7244 7.20372 17.4193 7.19383 17.1132C7.18238 16.786 7.17665 16.4935 7.17665 16.2358C7.17665 15.978 7.18238 15.6854 7.19383 15.3578C7.20244 15.0634 7.23229 14.77 7.28317 14.4798C7.59184 14.4335 7.92571 14.3986 8.28249 14.3749C8.63927 14.3513 8.94737 14.34 9.20965 14.34C9.47194 14.34 9.78233 14.3518 10.1368 14.3749C10.4913 14.398 10.8154 14.4313 11.1006 14.4798C11.1518 14.7699 11.1816 15.0634 11.19 15.3578C11.2018 15.6857 11.2079 15.9784 11.2083 16.2358C11.2087 16.4931 11.2026 16.7856 11.19 17.1132C11.1805 17.4193 11.1509 17.7244 11.1012 18.0267C10.816 18.0737 10.5008 18.1086 10.1557 18.1315C9.81211 18.1558 9.50744 18.167 9.24573 18.167C8.95939 18.167 8.63984 18.1558 8.28306 18.1315C7.92629 18.1073 7.59184 18.0735 7.28317 18.0267Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3322 18.0267C14.2826 17.7244 14.2529 17.4193 14.2435 17.1132C14.2309 16.7858 14.2257 16.4933 14.2257 16.2358C14.2257 15.9782 14.2309 15.6857 14.2435 15.3578C14.2516 15.0634 14.2813 14.77 14.3322 14.4798C14.6409 14.4335 14.9748 14.3986 15.331 14.3749C15.6872 14.3513 15.997 14.34 16.2587 14.34C16.5204 14.34 16.8314 14.3518 17.1865 14.3749C17.5415 14.398 17.8645 14.4313 18.1497 14.4798C18.2012 14.7699 18.2309 15.0633 18.2385 15.3578C18.2511 15.6857 18.2572 15.9784 18.2568 16.2358C18.2564 16.4931 18.2503 16.7856 18.2385 17.1132C18.2296 17.4193 18.1999 17.7245 18.1497 18.0267C17.8645 18.0737 17.5495 18.1086 17.2048 18.1315C16.8612 18.1558 16.5565 18.167 16.2948 18.167C16.0085 18.167 15.6883 18.1558 15.331 18.1315C14.9736 18.1073 14.6409 18.0735 14.3322 18.0267Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6083 14.4691C24.3192 14.4183 24.0275 14.3829 23.7344 14.3631C23.413 14.3398 23.1215 14.3284 22.86 14.3287C22.5988 14.3287 22.3068 14.34 21.9861 14.3631C21.6928 14.3829 21.4009 14.4183 21.1116 14.4691V22.7921C21.1116 24.4483 21.0543 26.1035 21.0635 27.7541C21.0755 30.2602 21.1448 30.959 18.767 30.959H6.66583C4.28865 30.959 4.35737 30.2602 4.36997 27.7541C4.37856 26.1035 4.32129 24.4483 4.32129 22.7921V10.5947C4.32129 8.25539 5.03141 8.35401 7.57867 8.33485L19.8763 8.24806C21.1093 8.24017 22.3268 7.95446 23.2866 7.1576C23.7384 6.78397 24.0969 6.28016 24.3575 5.64786C24.6181 5.01556 24.7492 4.23223 24.7492 3.29618V0H21.2559V2.52356C21.2559 4.8634 20.5458 4.76817 17.9974 4.78338L7.50308 4.8465C5.73637 4.8465 3.72342 4.76986 2.28714 5.96063C1.83664 6.33633 1.47986 6.83976 1.21681 7.47093C0.953764 8.10211 0.823003 8.88619 0.82453 9.82318V27.8285C0.82453 29.5676 0.746646 31.5479 1.95671 32.9613C2.33639 33.4048 2.84894 33.7576 3.49091 34.0145C4.13288 34.2715 4.93004 34.4 5.88069 34.4H19.551C20.5028 34.4 21.2994 34.2715 21.9408 34.0145C22.5828 33.7576 23.0953 33.4048 23.4762 32.9613C24.6851 31.5524 24.6072 29.5676 24.6072 27.8285L24.6083 14.4691Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
export default memo(Sucess);
