/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// import './datepicker-light-themestyles.css';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

import { ptBR } from 'date-fns/locale';

import { FiChevronDown } from 'react-icons/fi';
import { useField } from '@unform/core';
import {
  InputDate,
  Container,
  Error,
  SheduleSvg,
  Title,
} from '../DatePicker/styles';
import { dateToPTBR, formatDate, ptBRToDate } from '../../utils/formatt';

export interface DatePickerProps
  extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  placeholder?: string;
  icon?: boolean;
  className?: string;
  title?: string;
  formLook?: boolean;
  widthContainerDesktop?: string;
  themeColor?: string;
}

const DatePickerUnform: React.FC<DatePickerProps> = ({
  name,
  placeholder,
  icon,
  className,
  title,
  formLook,
  widthContainerDesktop,
  showTimeSelectOnly,
  themeColor,
  ...rest
}) => {
  registerLocale('pt-BR', ptBR);

  const datepickerRef = useRef<ReactDatePicker>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const [date, setDate] = useState(defaultValue || null);

  const handleKeyDown = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const data = event.target.value;
      clearError();
      if (data) {
        if (!showTimeSelectOnly) {
          // eslint-disable-next-line no-param-reassign
          event.target.value = formatDate(data);
        } else {
          // eslint-disable-next-line no-param-reassign
          event.target.value = data;
        }
        if (data.length === 10) {
          setDate(ptBRToDate(data));
        }
      }
    },
    [clearError, showTimeSelectOnly],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current?.props,
      clearValue: (ref) => {
        ref.clear();
      },
      getValue: (ref) => {
        // eslint-disable-next-line no-nested-ternary
        return ref.selected
          ? dateToPTBR(ref.selected)
          : date
          ? dateToPTBR(date)
          : '';
      },
      setValue: (ref, value) => {
        try {
          if (typeof value === 'string') {
            const temp = ptBRToDate(value);
            setDate(temp);
          } else {
            setDate(value);
          }
        } catch (err) {
          console.log(err);
          setDate(null);
        }
        clearError();
      },
    });
  }, [clearError, date, fieldName, registerField]);

  return (
    <Container
      className={className}
      isError={!!error}
      widthContainerDesktop={widthContainerDesktop}
      formLook={formLook}
    >
      {title && formLook && <Title themeColor={themeColor}>{title}</Title>}

      <InputDate
        selected={date}
        onChange={setDate}
        onChangeRaw={handleKeyDown}
        hasIcon={icon}
        showTimeSelectOnly={showTimeSelectOnly}
        formLook={formLook}
        name={name}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        locale="pt-BR"
        onError={!!error}
        showMonthDropdown
        showYearDropdown
        peekNextMonth
        dropdownMode="select"
        {...rest}
        ref={datepickerRef}
      />
      {icon && <SheduleSvg className="shedule" />}
      {formLook && (
        <FiChevronDown
          size={24}
          className={`chevron ${error ? 'onError' : ''}`}
        />
      )}
      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
};

export default DatePickerUnform;
