/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Select, { SelectHandles } from '../../components/Select';
import Table, { Column } from '../../components/Table';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';
import { getParam, ObjectToUrlParams } from '../../utils/Url';

import dependentSvg from '../../components/Svg/dependent.svg';
import ownerSvg from '../../components/Svg/owner.svg';
import { ButtonsActionsContainer } from '../../components/Table/styles';
import Modal2 from '../../components/Modal2';
import { ModalContainer, MassiveActionsContainer } from './styles';
import { useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import Checkbox from '../../components/Checkbox';
import { RowProps } from '.';

import ReportComponent from '../Report/Report';

interface DatePersonProps {
  value: Date | null;
  error: string;
}

interface Step1Props {
  defaultColor: string;
  callbackSetStep: (prev: number) => void;
  callbackSetSelecteds: (prev: any) => void;
}

export function HomeContainer({
  defaultColor,
  callbackSetStep,
  callbackSetSelecteds,
}: Step1Props): JSX.Element {
  const { user } = useAuth();
  const { contract } = useContract();
  const history = useHistory();

  const [allContracts, setAllContracts] = useState(
    [] as {
      contractId: string;
      productsLength: number;
    }[],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState([] as any[]);
  const [rowsShowed, setRowsShowed] = useState(10);

  const formFilterRef = useRef<FormHandles>(null);
  const selectMassiveRef = useRef<SelectHandles>(null);

  const [massiveActions, setMassiveActions] = useState([
    {
      title: 'Selecionar ação',
      value: '',
    },
  ]);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const [from, setFrom] = useState<DatePersonProps>({
    value: null,
    error: '',
  });
  const [to, setTo] = useState<DatePersonProps>({
    value: null,
    error: '',
  });

  const typeOfDateRef = useRef<SelectHandles>(null);
  const stipulatedBondRef = useRef<SelectHandles>(null);
  const situationRef = useRef<SelectHandles>(null);
  const laborSituationRef = useRef<SelectHandles>(null);

  const [dataTable, setDataTable] = useState<RowProps[]>([]);
  const [selectedData, setSelectedData] = useState<RowProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filters, setFilters] = useState<any>({
    dateType: 'N',
    laborSituation: 'T',
    situation: 'A',
  });
  const [stipulatedBondOptions, setStipulatedBondOptions] = useState([
    { title: '', value: '' },
  ]);

  /**
   * @description get of the API value to set in stipulate select options
   */
  const getInitialStipulatedBondOptions = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-stipulatedBond');
      const { data } = await api.get('/generic/relationship');
      const { content } = data;
      setStipulatedBondOptions([
        { title: '', value: '' },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { name: string; id: string },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      ]);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar as opções para vinculo estipulado. Por favor, tente novamente mais tarde',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-stipulatedBond');
    }
  }, []);

  /**
   * @description clear all fields to original value
   */
  const resetForm = useCallback(() => {
    formFilterRef.current?.reset();
    typeOfDateRef.current?.setValue('', 'N');
    stipulatedBondRef.current?.setValue('', '');
    situationRef.current?.setValue('', 'A');
    laborSituationRef.current?.setValue('', 'T');
    setFrom({ value: null, error: '' });
    setTo({ value: null, error: '' });
  }, []);

  /**
   * @description set variable filters to Dispatch useEffect action and get new data to table
   */
  const handleSetFilters = useCallback(
    (data) => {
      if (from.value || to.value) {
        if (!from.value) {
          setFrom((prev) => ({
            ...prev,
            error: 'É necessário informar início e fim.',
          }));
        }
        if (!to.value) {
          setTo((prev) => ({
            ...prev,
            error: 'É necessário informar início e fim',
          }));
        }
        if (!from.value || !to.value) {
          return;
        }
      }

      setFilters(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const temp = {} as any;
        typeOfDateRef.current?.value &&
          (temp.dateType = typeOfDateRef.current?.value);
        from.value && (temp.startDate = dateToPTBR(from.value));
        to.value && (temp.finalDate = dateToPTBR(to.value));
        data.beneficiaryName && (temp.beneficiaryName = data.beneficiaryName);
        data.cardNumber && (temp.cardNumber = data.cardNumber);
        situationRef.current?.value &&
          (temp.situation = situationRef.current?.value);
        stipulatedBondRef.current?.value &&
          (temp.relationshipId = stipulatedBondRef.current?.value);
        data.registration && (temp.registrationCode = data.registration);
        laborSituationRef.current?.value &&
          (temp.laborSituation = laborSituationRef.current?.value);
        return temp;
      });
    },
    [from, to],
  );

  const getAllContractsAndProducts = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-beneficiaries');
      const contracts = [];
      // if (user.groupNumber) {
      //   const { data: dataContracts } = await api.get(
      //     `/company/list-of-contracts?groupNumber=${user.groupNumber}`,
      //   );
      //   const { content: contentContracts } = dataContracts;
      //   contentContracts.forEach((item: { contractId: string }) => {
      //     contracts.push(item.contractId);
      //   });
      // } else {
      contracts.push(contract.contractId);
      // }

      const contractsAndProducts = [] as {
        contractId: string;
        productsLength: number;
      }[];
      const allProductsResp = await Promise.allSettled(
        contracts.map((ct) =>
          api.get(`/company/product-listing-for-selection?contractId=${ct}`),
        ),
      );
      const allProductsSuccess = allProductsResp.filter(
        (p) => p.status === 'fulfilled',
      );

      allProductsSuccess.forEach((productReps) => {
        if (productReps.status === 'fulfilled') {
          const { contractId } = getParam(productReps.value.config.url ?? '');
          if (contractId) {
            contractsAndProducts.push({
              contractId,
              productsLength: productReps.value.data?.content?.length ?? 0,
            });
          }
        }
      });
      setAllContracts(contractsAndProducts);
    } catch (err) {
      Notiflix.Block.remove('.notiflix-table-beneficiaries');
      Notiflix.Notify.failure(
        'Ops... Algo deu errado ao tentar obter seus contratos e produtos... Tente novamente mais tarde.',
      );
    }
  }, [contract]);

  /**
   * @description Get initial data and filtered data to table
   */
  const getDataToTable = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-beneficiaries');
      const route = `/company/list-of-beneficiaries${ObjectToUrlParams(
        filters,
      )}`;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allContents: AxiosResponse<any>[] = [];

      const allContractsResps = await Promise.allSettled(
        allContracts.map(({ contractId }) =>
          api.get(`${route}contractId=${contractId}`),
        ),
      );

      const allSuccessContractsResp = allContractsResps.filter(
        (resp) => resp.status === 'fulfilled',
      );
      allSuccessContractsResp.forEach((resp) => {
        if (resp.status === 'fulfilled') {
          allContents.push(resp.value);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allData: any[] = [];
      allContents.forEach((resp) => {
        const { contractId } = getParam(resp.config.url ?? '');
        const productsLengthOfThisContract = allContracts.find(
          (item) => item.contractId === contractId,
        );
        // console.log(contractId);
        if (contractId && resp.data.content && productsLengthOfThisContract) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resp.data.content.forEach((content: any) => {
            allData.push({
              contractId,
              productsLength: productsLengthOfThisContract.productsLength,
              ...content,
            });
          });
        }
      });

      // const { data } = await api.get(route);
      // const { content } = data;

      setDataTable(allData);
    } catch (err) {
      if (err.response?.status === 400) {
        setDataTable([]);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados no servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-beneficiaries');
    }
  }, [allContracts, filters]);

  // const handleOpenReportMultiple = useCallback(async () => {
  //   try {
  //     Notiflix.Block.circle(`.notiflix-massive-actions-submit`);
  //     console.log(selectedData);
  //     Modal2.Generic({
  //       closable: true,
  //       autoWidth: true,
  //       children: (
  //         <ModalContainer className="report">
  //           <h2>Relatórios</h2>
  //           <ReportComponent
  //             location={3}
  //             othersMapping={[
  //               {
  //                 key: 'nrSeqSegurado',
  //                 value: selectedData.map((i) => i.userUnimedId).join(),
  //               },
  //             ]}
  //           />
  //         </ModalContainer>
  //       ),
  //     });
  //   } catch (err) {
  //     Notiflix.Notify.info(
  //       'Ops... Não encontramos relatórios disponíveis para esse document.',
  //     );
  //   } finally {
  //     Notiflix.Block.remove(`.notiflix-massive-actions-submit`);
  //   }
  // }, [selectedData]);

  const handleOpenReport = useCallback(async (row: RowProps, notiflix) => {
    try {
      Notiflix.Block.circle(`.${notiflix}`);
      Modal2.Generic({
        closable: true,
        autoWidth: true,
        children: (
          <ModalContainer className="report">
            <h2>Relatórios</h2>
            <ReportComponent
              location={3}
              othersMapping={[
                { key: 'nrSeqSegurado', value: String(row.userUnimedId) },
              ]}
            />
          </ModalContainer>
        ),
      });
    } catch (err) {
      Notiflix.Notify.info(
        'Ops... Não encontramos relatórios disponíveis para esse document.',
      );
    } finally {
      Notiflix.Block.remove(`.${notiflix}`);
    }
  }, []);
  const handleOpenViewLack = useCallback(
    (row: RowProps, notiflix) => {
      history.push(`/carencia/${row.userUnimedId}/${row.beneficiaryName}`);
    },
    [history],
  );
  const handleChangeProduct = useCallback(
    (row) => {
      callbackSetSelecteds([row]);
      callbackSetStep(5);
    },
    [callbackSetSelecteds, callbackSetStep],
  );
  const handleChangeContractCNPJ = useCallback(
    (row) => {
      callbackSetSelecteds([row]);
      callbackSetStep(4);
    },
    [callbackSetSelecteds, callbackSetStep],
  );
  const handleTerminateBeneficiary = useCallback(
    (row) => {
      callbackSetSelecteds([row]);
      callbackSetStep(3);
    },
    [callbackSetSelecteds, callbackSetStep],
  );
  const handleChangeRegisterData = useCallback(
    async (row) => {
      callbackSetSelecteds([row]);
      callbackSetStep(7);
    },
    [callbackSetSelecteds, callbackSetStep],
  );
  const handleDependentsInclude = useCallback(
    async (row) => {
      callbackSetSelecteds([row]);
      callbackSetStep(6);
    },
    [callbackSetSelecteds, callbackSetStep],
  );
  const handleRequestSCopyOfCard = useCallback(
    (row) => {
      callbackSetSelecteds([row]);
      callbackSetStep(2);
    },
    [callbackSetSelecteds, callbackSetStep],
  );

  const rowExpansionTemplate = useCallback(
    (row: RowProps) => {
      const buttons = [
        {
          title: 'Relatórios',
          onClick: handleOpenReport,
        },
        {
          title: 'Visualizar carências',
          onClick: handleOpenViewLack,
        },
        // {
        //   title:
        //     row.ownership === 'Titular' &&
        //     row.productsLength > 1 &&
        //     row.situation === 'Ativo'
        //       ? 'Alterar produto'
        //       : '',
        //   onClick: handleChangeProduct,
        // },
        // {
        //   title:
        //     row.ownership === 'Titular' &&
        //     !!user.groupNumber &&
        //     row.situation === 'Ativo'
        //       ? 'Alterar contrato/CNPJ'
        //       : '',
        //   onClick: handleChangeContractCNPJ,
        // },
        {
          title: row.allowsTermination === 'S' ? 'Rescindir beneficiário' : '',
          onClick: handleTerminateBeneficiary,
        },
        {
          title: row.situation === 'Ativo' ? 'Alterar dados cadastrais' : '',
          onClick: handleChangeRegisterData,
        },
        {
          title:
            row.ownership === 'Titular' && row.situation === 'Ativo'
              ? 'Incluir dependentes'
              : '',
          onClick: handleDependentsInclude,
        },
        // {
        //   title: 'Solicitar 2ª via carteira',
        //   onClick: handleRequestSCopyOfCard,
        // },
      ].filter((i) => !!i.title);
      return (
        <ButtonsActionsContainer>
          {buttons.map((button, index) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} ${index === 0 && 'marginLeft'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(row, `notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [
      handleChangeContractCNPJ,
      handleChangeProduct,
      handleChangeRegisterData,
      handleDependentsInclude,
      handleOpenReport,
      handleOpenViewLack,
      handleRequestSCopyOfCard,
      handleTerminateBeneficiary,
      user,
    ],
  );

  const handleSubmitMassiveActions = useCallback(async () => {
    try {
      const option = selectMassiveRef.current?.value;
      let step = {} as { title: string; step: number };
      switch (option) {
        case 'request-copy-card':
          step = { title: 'Solicitar 2ª via da carteirinha', step: 2 };
          break;
        case 'terminate-beneficiary':
          step = { title: 'Rescindir beneficiário', step: 3 };
          break;
        case 'change-contract':
          step = { title: 'Alterar contrato/CNPJ', step: 4 };
          break;

        // case 'reports':
        //   step = { title: 'Imprimir relatórios', step: 99 };
        //   break;
        default:
          step = { title: '', step: 1 };
      }

      // if (step.step === 99) {
      //   handleOpenReportMultiple();
      //   return;
      // }

      if (selectedData.length === dataTable.length) {
        Modal2.Warning({
          closable: true,
          autoWidth: true,
          title: 'Importante!',
          text: `Você selecionou
                  ”${step.title}”
                  para todos os ${selectedData.length} itens.

                  Deseja seguir? `,
          children: (
            <>
              <Button modal secondary onClick={() => Modal2.Close()}>
                Cancelar
              </Button>
              <Button
                style={{ marginTop: '8px' }}
                modal
                onClick={() => {
                  Modal2.Close();
                  callbackSetSelecteds(selectedData);
                  callbackSetStep(step.step);
                }}
              >
                Ok
              </Button>
            </>
          ),
        });
      } else {
        callbackSetSelecteds(selectedData);
        callbackSetStep(step.step);
      }
    } catch (err) {
      Notiflix.Notify.failure('Ops... Algo deu errado');
    }
  }, [
    callbackSetSelecteds,
    callbackSetStep,
    dataTable.length,
    // handleOpenReportMultiple,
    selectedData,
  ]);

  /**
   * @description dispatch action to get new data for table in first time and on change filtered
   */
  useEffect(() => {
    if (allContracts.length > 0) {
      getDataToTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, allContracts]);

  /**
   * @description get initial data to stipulator select on first render
   */
  useEffect(() => {
    getInitialStipulatedBondOptions();
    if (contract.contractId) {
      getAllContractsAndProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  useEffect(() => {
    /**
     * Actions
     *  conditions->
     *   request copy of card, terminate beneficiarie, change contract/CNPJ -> {
     *    if the selecteds are from the same contract AND
     *    selected depentend + owner
     *   }
     *   change procuct ->{
     *     if the selecteds are from the same contract AND
     *     selected depentend + owner AND
     *     contract has more than one product
     *   }
     *
     *  default->
     *    imprimir relatórios
     */
    const sameContract = selectedData.every(
      (item) => item.contractId === selectedData[0].contractId,
    );
    // const ownerAndDependentSelecteds =
    //   !!selectedData.find((item) => item.ownership === 'Titular') &&
    //   !!selectedData.find((item) => item.ownership === 'Dependente');

    const owner = !!selectedData.find((item) => item.ownership === 'Titular');

    // const moreThanOneProduct =
    //   selectedData.length > 0 ? selectedData[0].productsLength > 1 : false;

    const allPossibleTerminate = selectedData.every(
      (item) => item.allowsTermination === 'S',
    );

    setMassiveActions(
      [
        {
          title: 'Selecionar ação',
          value: '',
        },
        sameContract
          ? {
              title: 'Solicitar 2ª via da carteirinha',
              value: 'request-copy-card',
            }
          : { title: '', value: '' },
        sameContract && allPossibleTerminate
          ? {
              title: 'Rescindir beneficiário',
              value: 'terminate-beneficiary',
            }
          : { title: '', value: '' },
        sameContract && owner && !!user.groupNumber
          ? // sameContract && ownerAndDependentSelecteds && !!user.groupNumber
            {
              title: 'Alterar contrato/CNPJ',
              value: 'change-contract',
            }
          : { title: '', value: '' },
      ].filter((item) => !!item.title),
    );

    // console.log({
    //   mesmoContrato: someContract,
    //   titularEDependente: ownerAndDependentSelecteds,
    //   maisDeUmProduto: moreThanOneProduct,
    // });
  }, [selectedData, user]);

  return (
    <>
      <FormWrapper
        onSubmit={handleSetFilters}
        formLook
        ref={formFilterRef}
        className="beneficiaries-home"
      >
        <Input
          name="beneficiaryName"
          title="Beneficiário:"
          formLook
          widthContainerDesktop="50%"
          themeColor={defaultColor}
        />
        <Input
          name="cardNumber"
          title="Carteirinha:"
          formLook
          widthContainerDesktop="50%"
          themeColor={defaultColor}
        />
        <Select
          name="typeOfDate"
          title="Tipo de data:"
          ref={typeOfDateRef}
          formLook
          options={[
            { title: 'Nenhuma', value: 'N' },
            { title: 'Data de adesão', value: 'A' },
            { title: 'Data de rescisão', value: 'R' },
          ]}
          widthContainerDesktop="30%"
          themeColor={defaultColor}
        />

        <DatePicker
          name="from"
          selected={from.value}
          onChange={(date: Date) => {
            setFrom({ value: date, error: '' });
            setTo((prev) => ({ ...prev, error: '' }));
          }}
          error={from.error}
          formLook
          title="Data de:"
          widthContainerDesktop="20%"
          icon
          themeColor={defaultColor}
        />
        <DatePicker
          name="to"
          selected={to.value}
          onChange={(date: Date) => {
            setTo({ value: date, error: '' });
            setFrom((prev) => ({ ...prev, error: '' }));
          }}
          error={to.error}
          formLook
          title="Data até:"
          widthContainerDesktop="20%"
          icon
          themeColor={defaultColor}
        />
        <Select
          name="stipulatedBond"
          title="Grau de parentesco:"
          options={stipulatedBondOptions}
          ref={stipulatedBondRef}
          formLook
          widthContainerDesktop="30%"
          className="notiflix-stipulatedBond"
          themeColor={defaultColor}
        />

        <Input
          name="registration"
          title="Matrícula:"
          formLook
          widthContainerDesktop="30%"
          themeColor={defaultColor}
        />

        <Select
          name="situation"
          title="Situação:"
          ref={situationRef}
          formLook
          options={[
            { title: 'Ativo', value: 'A' },
            { title: 'Inativo', value: 'I' },
            { title: 'Todos', value: 'T' },
          ]}
          widthContainerDesktop="15%"
          themeColor={defaultColor}
        />
        <Select
          name="laborSituation"
          title="Situação trabalhista:"
          ref={laborSituationRef}
          formLook
          options={[
            { title: 'Todos', value: 'T' },
            { title: 'Somente demitidos e Aposentados', value: 'C' },
            { title: 'Somente funcionários ativos', value: 'S' },
          ]}
          widthContainerDesktop="20%"
          themeColor={defaultColor}
        />
        <Button onClick={resetForm} formLook secondary autoWidth>
          Limpar
        </Button>
        <Button type="submit" formLook greenButton autoWidth>
          Consultar
        </Button>
      </FormWrapper>

      <Table
        items={dataTable}
        className="notiflix-table-beneficiaries table-wrapper-beneficiaries"
        selection={selectedData}
        onSelectionChange={(e) => {
          if (e.originalEvent.currentTarget.classList.contains('p-checkbox')) {
            const rows =
              dataTable.length < rowsShowed ? dataTable.length : rowsShowed;
            const maxSelecteds: RowProps[] = e.value.slice(0, rows);
            setSelectedData(maxSelecteds);
            setExpandedRows(maxSelecteds);
            if (maxSelecteds.length >= 2) {
              setExpandedRows([]);
            }
          }
        }}
        callbackShowMore={setRowsShowed}
        scrollable={window.innerWidth > 768}
        scrollHeight="550px"
        expandedRows={expandedRows}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        onRowExpand={(e) =>
          window.innerWidth <= 768 && setPositionOfRowExpanded(e.originalEvent)
        }
        rowExpansionTemplate={rowExpansionTemplate}
        onRowClick={(e) => {
          if (e.data && selectedData.length <= 1) {
            setExpandedRows([e.data]);
          } else {
            setExpandedRows([]);
          }
        }}
      >
        {/* {window.innerWidth <= 768 && ( */}
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '50px' }}
          header="Selecionar todos"
        />
        {/* )} */}
        {/* {window.innerWidth > 768 && (
          <Column
            selectionMode="multiple"
            header="Selecionar todos"
            frozen
            headerClassName="frozen"
            bodyClassName="frozen"
          />
        )} */}
        <Column
          field="beneficiaryName"
          header="Beneficiário"
          body={(row) => AddField(row.beneficiaryName, 'Beneficiário')}
          headerClassName="no-rounded"
          sortable
          style={{
            minWidth: '200px',
            overflow: 'initial',
            textOverflow: 'initial',
            whiteSpace: 'pre-wrap',
          }}
        />
        <Column
          field="cardNumber"
          header="Carteira"
          sortable
          style={{ width: '175px' }}
          body={(row) => AddField(row.cardNumber, 'Carteira')}
        />
        <Column
          field="hiringDate"
          header="Data Contratação"
          className="date"
          body={(row) => AddField(row.hiringDate, 'Data Contratação')}
        />
        <Column
          field="expectedTerminationDate"
          header="Data Prev. Rescisão"
          className="date"
          body={(row) =>
            AddField(row.expectedTerminationDate, 'Data Prev. Rescisão')
          }
        />
        <Column
          field="terminationDate"
          header="Data Rescisão"
          className="date"
          body={(row) => AddField(row.terminationDate, 'Data Rescisão')}
        />
        <Column
          field="holderName"
          header="Titular"
          sortable
          body={(row) => AddField(row.holderName, 'Titular')}
          style={{
            minWidth: '200px',
            overflow: 'initial',
            textOverflow: 'initial',
            whiteSpace: 'pre-wrap',
          }}
        />
        <Column
          field="ownership"
          header="Titularidade"
          sortable
          body={(row) => AddField(row.ownership, 'Titularidade')}
          style={{ width: '110px' }}
        />
        <Column
          field="situation"
          header="Situação"
          className="tag"
          sortable
          style={{ width: '100px' }}
          body={(row) => {
            switch (row.situation) {
              case 'Ativo':
              case 'ativo':
                return getTag('Situação', 'Ativo', 'green');
              case 'Inativo':
              case 'inativo':
                return getTag('Situação', 'Inativo', 'red');
              default:
                return <></>;
            }
          }}
        />
        <Column
          field="birthDate"
          header="Nascimento"
          className="date"
          body={(row) => AddField(row.birthDate, 'Nascimento')}
        />
        <Column
          header=""
          style={{ width: '60px' }}
          bodyClassName="centered"
          body={(row) => (
            <>
              {row.ownership === 'Dependente' && (
                <label data-hover="Dependente">
                  <img src={dependentSvg} alt="Dependente" />
                </label>
              )}
              {row.ownership === 'Titular' && (
                <label data-hover="Titular">
                  <img src={ownerSvg} alt="Titular" />
                </label>
              )}
            </>
          )}
        />
        <Column
          header=""
          expander
          headerStyle={{ width: '60px' }}
          className="only-mobile"
        />
      </Table>

      <MassiveActionsContainer
        onSubmit={handleSubmitMassiveActions}
        formLook
        className={selectedData.length > 1 ? 'show' : 'hide'}
      >
        <Select
          name="actions"
          options={massiveActions}
          ref={selectMassiveRef}
          formLook
          openUp
          changeCallback={(option) => setButtonEnabled(!!option)}
        />
        <Button
          type="submit"
          autoWidth
          formLook
          disabled={!buttonEnabled}
          greenButton
          className="notiflix-massive-actions-submit"
        >
          Aplicar
        </Button>

        <span>{`Selecionados: ${selectedData.length}/${dataTable.length}`}</span>

        <label>
          Deseja aplicar para todos?
          <span>
            Marque aqui
            <br />
            se deseja aplicar
            <br />
            a ação desejada
            <br />
            em <strong>todos os itens</strong>
            <br />
            dessa lista.
          </span>
          <Checkbox
            name="apllyForAll"
            value="applyForAll"
            noShowValue
            onChange={(e) =>
              setSelectedData(e.currentTarget.checked ? dataTable : [])
            }
          />
        </label>
      </MassiveActionsContainer>
    </>
  );
}
