import styled, { keyframes } from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 8px;
`;
export const Background = styled.div`
  background: #b1d34b;
  background-size: cover;

  background-blend-mode: multiply;
  position: relative;
  grid-column: 1/8;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10%;
  > svg {
    margin-top: 112px;
  }
  > a {
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.inverse};
    text-decoration: none;
    margin: 5% 0;
    font-size: 0.85rem;
    strong {
      font-family: 'Unimed-Sans-SemiBold';
    }
    svg {
      margin-left: 8px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BemVindo = styled.h1`
  font-size: 4rem;
  width: 100%;
  color: ${(props) => props.theme.colors.text.inverse};
  font-family: 'Unimed-Slab-Bold';
  @media (max-width: 1200px) {
    font-size: 3rem;
  }
`;
export const BemVindoMobile = styled.h1`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    color: ${(props) => props.theme.colors.text.inverse};
    font-family: 'Unimed-Slab-Bold';
    font-size: 2.1rem;
    text-align: center;
    margin-bottom: 68px;

    @media (max-width: 320px) {
      margin-bottom: 30px;
    }
  }
`;

const fadeToRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(-10px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ContentMobile = styled.div`
  > svg {
    display: none;
  }
  > div {
    margin-top: 8px;
  }
  @media (max-width: 768px) {
    background-color: #b1d34b;
    background-size: cover;
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;

    order: 1;
    > svg {
      display: block;
      width: 100px;
      margin: 4px 48px;
      margin-bottom: 56px;

      @media (max-width: 320px) {
        margin-bottom: 0px;
      }
    }
    > div {
      margin: 0;
    }
    > div:last-child {
      position: absolute;
      top: calc(100% - 22px);
      width: calc(100% - 60px);
    }
  }
`;

export const Content = styled.div`
  grid-column: 9/12;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  animation: ${fadeToRight} forwards ease-in 300ms;

  @media (max-width: 768px) {
    grid-column: 1/13;
    height: 100%;
    flex-direction: column;
    animation: none;
  }

  h2 {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 300;
    text-align: center;
    color: ${(props) => props.theme.colors.text.primary};
    font-family: 'Unimed-Sans-Book';
    margin-bottom: 38px;

    @media (max-width: 768px) {
      display: none;
    }
  }
  form {
    width: 100%;

    a {
      margin-top: 16px;
      display: block;
      width: 100%;
      font-size: 1rem;
      text-align: center;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.text.primary};
      text-decoration: none;

      @media (max-width: 768px) {
        order: 2;
        margin: 42px auto 0;
        width: calc(100% - 60px);
        text-align: right;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;

export const ButtonGoBack = styled(Button)`
  margin-top: 15%;
  background-color: #eaedef;
  border: 0;
  font-size: 1em;
  color: ${(props) => props.theme.colors.palet.institutional11};
  @media (max-width: 768px) {
    margin: auto 30px 0;
    width: calc(100% - 60px);
    order: 3;
  }
  @media (max-width: 320px) {
    margin-top: 40px;
  }
`;

export const ButtonSubmit = styled(Button)`
  margin-top: 8px;
  @media (max-width: 768px) {
    margin: 8px 30px auto;
    width: calc(100% - 60px);
    order: 3;
  }
`;

export const ContainerLogoW = styled.div`
  margin: 5% 0;
  a {
    font-family: 'Unimed-Sans-Book';
    text-decoration: none;
    font-size: 0.85rem;
    color: ${(props) => props.theme.colors.text.inverse};

    svg {
      margin-left: 8px;
    }
    strong {
      font-family: 'Unimed-Sans-SemiBold';
    }
  }
  @media (max-width: 768px) {
    width: calc(100% - 60px);
    display: block;
    margin: 16px 0;
  }
  &.mobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      a {
        font-family: 'Unimed-Sans-Book';
        color: ${(props) => props.theme.colors.text.primary};
        display: flex;
        text-decoration: none;
        align-items: baseline;
        width: 100%;
        font-size: 0.75rem;
        strong {
          margin-left: 6px;
        }
        svg {
          margin-left: auto;
          path {
            fill: ${(props) => props.theme.colors.text.primary};
          }
        }
        @media (max-width: 320px) {
          font-size: 0.65rem;
        }
      }
    }
  }
`;
