import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import FormWrapper from '../../components/FormWrapper';

export const Container = styled.div`
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
  }

  /* @media (min-width: 769px) {
    overflow-y: hidden;
  } */

  @media (min-width: 769px) and (max-width: 2500px) {
    grid-column: 1/4;
    padding: 0 0px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    > button {
      text-transform: uppercase;

      &.marginRight {
        margin-right: auto;
      }
      &.marginLeft {
        margin-left: auto;
      }
    }
    &.request-copy-card,
    &.change-contract {
      margin-bottom: 30px;
    }
    &.form-termination-beneficiary {
      > button {
        margin: 0;
        &:nth-of-type(1) {
          margin-right: auto;
        }
      }
    }
    &.form-change-product-details {
      margin-bottom: 24px;
      & + form {
        margin-bottom: 30px;
      }
    }
    &.form-change-request-datasheet {
      margin-bottom: 24px;
    }
    &.actions-include-dependent {
      margin: 24px 0 30px;
    }
    &.depentent-include {
      /* .select-city button:first-child,
      .select-state button:first-child,
      .select-genre button:first-child,
      .select-uf button:first-child,
      .select-marital-status button:first-child {
        display: none;
      } */

      @media (max-width: 768px) {
        > div {
          &:nth-child(5),
          &:nth-child(6) {
            width: auto;
            flex: 1 1;
          }
          &:nth-child(14),
          &:nth-child(15) {
            width: auto;
            min-width: 45%;
            flex: 1 1;
          }
          &:nth-child(16),
          &:nth-child(17) {
            width: auto;
            min-width: 45%;
            flex: 1 1;
          }
        }
      }
    }
    &.beneficiaries-home {
      @media (max-width: 768px) {
        > div {
          &:nth-child(4),
          &:nth-child(5) {
            width: auto;
            flex: 1 1;
          }
        }
      }
    }
  }

  div.table-wrapper-beneficiaries {
    margin-top: 24px;
    div.p-datatable-scrollable-view.p-datatable-frozen-view {
      div.p-datatable-scrollable-header {
        background: transparent;
      }
      table {
        tbody {
          tr.p-row-expanded {
            opacity: 0;
            pointer-events: none;
          }
          tr.p-highlight.p-selectable-row {
            border-left-width: 2px !important;
          }
        }
      }
    }
    div.p-datatable-scrollable-view.p-datatable-unfrozen-view {
      div.p-datatable-scrollable-header {
        background: transparent;
      }
      table {
        tbody {
          tr.p-highlight.p-selectable-row {
            border-left: none !important;
          }
        }
      }
    }
    table {
      th {
        /* word-break: break-word; */
        height: 50px;
        span {
          display: flex;
          height: 1rem;
          align-items: center;
        }
        &.frozen {
          border-radius: 10px 0 0 0 !important;
        }
        &.no-rounded {
          border-radius: 0 0 0 0 !important;
        }
      }
      tr.p-highlight {
        td.centered {
          > label {
            > img {
              ${(props) =>
                props.theme.title === 'dark' &&
                css`
                  filter: grayscale(1) brightness(1.4);
                `}
            }
          }
        }
      }
      td {
        white-space: nowrap;
        &:not(.centered) {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.centered {
          overflow: visible !important;

          > label {
            position: relative;
            > img {
              display: inline;
              transform: translateY(1px);

              filter: grayscale(1) brightness(0.4);

              transition: 300ms;
            }
            &::before {
              content: attr(data-hover);
              position: absolute;
              background: ${(props) => props.theme.colors.palet.institutional4};
              padding: 4px 10px;
              font-family: 'Unimed-Sans-Bold';
              color: ${(props) => props.theme.colors.palet.institutional2};
              top: calc(-100% - 16px);
              right: 50%;
              border-radius: 5px 5px 0 5px;
              font-size: 0.65rem;

              opacity: 0;
              visibility: hidden;
              transform: translateY(10px);
              transition: 300ms;
            }
            &::after {
              content: '';
              position: absolute;
              top: -100%;
              right: 50%;
              border: 5px solid
                ${(props) => props.theme.colors.palet.institutional4};
              border-left-color: transparent;
              border-bottom-color: transparent;

              opacity: 0;
              visibility: hidden;
              transform: translateY(10px);
              transition: 300ms;
            }
            &:hover {
              > img {
                filter: grayscale(0) !important;
              }
              &::after,
              &::before {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
              }
            }
            @media (max-width: 768px) {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50px);
              z-index: 1;

              > img {
                width: 20px;
                filter: grayscale(1) invert(1) brightness(0) invert(1) sepia(1);
              }
            }
          }
        }
      }
      tr:nth-child(1) {
        td.centered {
          label {
            &::before {
              top: calc(100% + 7px);
              z-index: 1;
            }
            &::after {
              top: 100%;
              border: 5px solid
                ${(props) => props.theme.colors.palet.institutional4};
              border-left-color: transparent;
              border-top-color: transparent;
            }
          }
        }
      }
    }
  }
`;

export const ErrorWrapper = styled.div`
  > p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text.primary};
    font-family: 'Unimed-Sans-Book';
    margin-bottom: 4px;
  }
  p:last-child {
    margin-bottom: 48px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
  button {
  }
`;

export const ModalContainer = styled.div`
  h2 {
    font-family: 'Unimed-Sans-SemiBold';
    color: ${(props) => props.theme.colors.text.primary};
  }

  display: flex;
  flex-direction: column;
  position: relative;

  &.report {
    width: 320px;
    h2 {
      margin-bottom: 24px;
    }
    form {
      & + form {
        margin-top: 24px;
      }
      > button {
        margin-top: 24px;
      }
    }
  }
`;

export const MassiveActionsContainer = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 20px !important;
  transition: 300ms;
  &.hide {
    /* height: 0; */
    visibility: hidden;
    opacity: 0;
  }
  &.show {
    /* height: auto; */
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 768px) {
    row-gap: 8px !important;
    margin: 24px 0;
    position: sticky;
    bottom: 0;
    z-index: 1;
    box-shadow: 0px 5px 20px
      ${(props) =>
        transparentize(0.2, props.theme.colors.text.lightGrayToBlack)};
  }

  > div:nth-child(1) {
    width: 30%;
    flex: none;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  > button {
    &[disabled] {
      background: ${(props) => props.theme.colors.palet.institutional12};
      border-color: ${(props) => props.theme.colors.palet.institutional12};
      color: ${(props) => props.theme.colors.text.inverse};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  > span {
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    margin: auto 16px;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }
  > label {
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text.grayishToWhite};
    margin: auto 0 auto auto;
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    > label {
      margin-left: 12px;
    }
    > span {
      position: absolute;
      bottom: calc(100% + 24px);
      right: 10px;
      background: ${(props) => props.theme.colors.palet.institutional4};
      font-family: 'Unimed-Sans-Book';
      font-size: 1rem;
      padding: 22px 16px 16px;
      border-radius: 20px 20px 0 20px;
      box-shadow: 0px 0px 20px rgba(177, 211, 75, 0.5);
      text-align: right;
      color: ${(props) => props.theme.colors.palet.institutional2};
      strong {
        font-family: 'Unimed-Sans-SemiBold';
      }
      &::after {
        content: '';
        border: 16px solid ${(props) => props.theme.colors.palet.institutional4};
        position: absolute;
        right: 0;
        bottom: calc(-16px);
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
      transition: 300ms;
      @media (max-width: 768px) {
        right: calc(50% - 90px);
      }
    }
    &:hover {
      > span {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
`;

export const Term = styled.div`
  background: ${(props) => props.theme.colors.background};
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 24px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Unimed-Sans-Bold';
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }
  p,
  span {
    font-size: 1rem;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.grayishToWhite};
  }
`;

export const OptionalParamsTerminateBeneficiaryContainer = styled.div`
  display: none;
  width: 100%;

  &.visible {
    display: flex;
    flex-wrap: wrap;
    row-gap: 27px;
    column-gap: 8px;
    @media (max-width: 768px) {
      row-gap: 24px;
      column-gap: 8px;
    }

    .contentNotVisible {
      display: none;
    }
  }
`;
