import Notiflix from 'notiflix';
import React, {
  useCallback,
  useRef,
  useState,
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import { FiFile, FiTrash } from 'react-icons/fi';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';

import {
  Container,
  Content,
  FileContainer,
  FileTitle,
  Placeholder,
  FiPlusCircle,
  Error,
  PlaceholderExternal,
} from './styles';

interface InputFileProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  className?: string;
  svgFileSize?: number;
  svgTrashSize?: number;
  callbackHasChanged?(): void;
  themeColor?: string;
}

export interface InputFileHandles {
  files: File[] | null;
  setFiles: (files: File[] | null) => void;
  setError: (text: string | null) => void;
  containerRef: HTMLDivElement | null;
}

const InputFile: React.ForwardRefRenderFunction<
  InputFileHandles,
  InputFileProps
> = (
  {
    className,
    placeholder,
    multiple,
    svgFileSize = 36,
    svgTrashSize = 14,
    callbackHasChanged,
    themeColor,
    accept,
    ...rest
  },
  ref,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const verifyTypes = useCallback(
    (fileArr: FileList | null) => {
      let valid = true;
      if (fileArr && accept) {
        const allFiles = Array.from(fileArr);
        allFiles.forEach((file) => {
          if (!accept.includes(file.type)) {
            valid = false;
            setError('O tipo de arquivo selecionado não é permitido.');
          }
        });
      }
      return valid;
    },
    [accept],
  );

  const handleAddFiles = useCallback(
    (filesAdded: FileList | null) => {
      if (!verifyTypes(filesAdded)) return;
      if (filesAdded) {
        const fileArray = Array.from(filesAdded);
        let overflowSize = false;
        if (fileArray) {
          fileArray.forEach((f) => {
            if (f.size / 1024000 > 5) {
              Notiflix.Notify.info(
                'Permitido apenas arquivos com tamanho máximo de 5MB',
              );
              overflowSize = true;
            }
          });
        }
        if (overflowSize) {
          return;
        }
        if (multiple) {
          setFiles((prev) => {
            if (!prev) {
              return fileArray;
            }
            return [...prev, ...fileArray];
          });
        } else {
          setFiles(fileArray);
        }

        setError(null);
      }
    },
    [multiple, verifyTypes],
  );

  const handleRemoveFiles = useCallback((file: File) => {
    setFiles((prev) => {
      if (!prev) return null;
      const all = prev.filter((f) => f !== file);
      return all.length === 0 ? null : all;
    });
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        files,
        setFiles: (f) => {
          setFiles(f);
        },
        setError,
        containerRef: containerRef.current,
      };
    },
    [files, setFiles, setError],
  );

  useEffect(() => {
    if (callbackHasChanged) {
      callbackHasChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <Container className={className} error={!!error} ref={containerRef}>
      {placeholder && (
        <PlaceholderExternal themeColor={themeColor}>
          {placeholder}:
        </PlaceholderExternal>
      )}
      {/* <Content className="notiflix-content-file">{filesRepresent}</Content> */}
      <Content className="notiflix-content-file">
        {(!files || files.length === 0) && (
          <Placeholder onClick={() => inputRef.current?.click()}>
            {placeholder}
          </Placeholder>
        )}
        {files &&
          files.length > 0 &&
          files.map((file) => (
            <FileContainer key={`${file.name}-${file.size}`}>
              <FiFile size={svgFileSize} />
              <FileTitle
                onClick={() => {
                  const blob = new Blob([file], {
                    type: file.name.split('.')[1],
                  });
                  downloadFileOfBlob(blob);
                }}
                aria-valuetext={file.name}
              >
                {file.name.substr(0, 15)}
              </FileTitle>
              <FiTrash
                size={svgTrashSize}
                onClick={() => handleRemoveFiles(file)}
                className="trash"
              />
            </FileContainer>
          ))}
      </Content>
      <FiPlusCircle
        size={24}
        onClick={() => inputRef.current?.click()}
        className=""
        data-error={error}
      />
      {!!error && <Error>{error}</Error>}
      <input
        type="file"
        ref={inputRef}
        multiple={multiple}
        onChange={(e) => {
          handleAddFiles(e.currentTarget.files);
          e.currentTarget.value = '';
          e.currentTarget.files = null;
        }}
        {...rest}
      />
    </Container>
  );
};

export default forwardRef(InputFile);
