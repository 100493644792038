/* eslint-disable max-len */
import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  isErrored: boolean;
  isValid?: boolean;
  dataSheet?: boolean;
  formLook?: boolean;
  disabled?: boolean;
  textArea?: boolean;
}

const animValid = keyframes`
  from{
    transform: translateY(20px);
    opacity: 0;
  }to{
    transform: translateY(0);
    opacity: 1;
  }
`;

interface LabelProps {
  widthContainerDesktop?: string;
  formLook?: boolean;
}
const reducedOnePercent = (width: string) => {
  return `calc(${width} - 1%)`;
};
export const Label = styled.div<LabelProps>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 8px;

  ${(props) =>
    props.widthContainerDesktop &&
    !props.formLook &&
    css`
      @media (min-width: 769px) {
        width: ${props.widthContainerDesktop};
      }
    `}
  ${(props) =>
    props.widthContainerDesktop &&
    props.formLook &&
    css`
      @media (min-width: 769px) {
        width: auto;
        flex: 1 1 ${reducedOnePercent(props.widthContainerDesktop)};
        min-width: 10px;
      }
    `}
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const Container = styled.div<ContainerProps>`
  border: 1px solid ${(props) => props.theme.colors.text.secondary};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  flex: 1;
  padding: 15px 20px;
  position: relative;
  display: flex;
  background: white;
  &.textUppercase {
    input {
      text-transform: uppercase;
    }
  }
  input,
  span,
  textarea {
    border: none;
    background: transparent;

    outline: none;
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
    flex: 1;
    overflow: hidden;

    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      opacity: 0.5;
    }
    ${(props) =>
      props.dataSheet &&
      css`
        color: ${props.theme.title === 'light'
          ? props.theme.colors.palet.institutional11
          : props.theme.colors.text.white};
        flex: 1 0 100%;
        pointer-events: none;
      `}

    ${(props) =>
      props.formLook &&
      css`
        font-family: 'Unimed-Sans-Book';
        color: ${props.theme.colors.palet.institutional11};
      `}
  }

  ${(props) =>
    props.formLook &&
    css`
      padding: 8px 10px;
      border-radius: 5px;
      background: #fff;
      border-color: ${props.theme.colors.palet.institutional12};
      ${!props.textArea && 'max-height: 36px;'}
    `}

  ${(props) =>
    props.dataSheet &&
    css`
      background: ${props.theme.colors.palet.institutional13} !important;
      border-radius: 3px;
      min-height: 50px;
      display: flex;
      padding: 8px 8px 8px;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        /* min-height: 40px; */
        /* padding: 18px 8px 2px; */
        max-width: 100%;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: ${shade(0.05, props.theme.colors.text.grayToGrayish)};
      cursor: not-allowed !important;
      & input {
        cursor: not-allowed !important;
      }
    `}
  ${(props) =>
    props.disabled &&
    props.formLook &&
    css`
      background: ${props.theme.colors.palet.institutional14};
      border-color: ${props.theme.colors.palet.institutional14};
      cursor: default !important;
      & input {
        cursor: default !important;
      }
    `}
  ${(props) =>
    // props.disabled &&
    props.dataSheet &&
    css`
      background: ${props.theme.colors.text.grayToHeavyGrayish} !important;
      border-color: ${props.theme.colors.palet.institutional12} !important;
      cursor: initial !important;
    `}

  ${(props) =>
    props.isValid &&
    !props.isErrored &&
    css`
      border: 1px solid ${props.theme.colors.palet.institutional4};
      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L6 12L1 7' stroke='%23B1D34B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        animation: ${animValid} forwards 300ms;
      }
    `}

  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid ${props.theme.colors.palet.institutional7};
    `}
`;

interface InsideInputTitleProps {
  themeColor?: string;
}

export const InsideInputTitle = styled.label<InsideInputTitleProps>`
  color: ${(props) => props.theme.colors.palet.institutional3};
  /* position: absolute; */
  /* top: 8px; */
  /* left: 8px; */
  font-family: 'Unimed-Sans-Semibold';
  font-size: 0.65rem;
  text-transform: uppercase;
  line-height: 0.65rem;

  ${(props) =>
    props.themeColor &&
    css`
      color: ${props.themeColor};
    `}
`;

interface TitleProps {
  themeColor?: string;
}

export const Title = styled.span<TitleProps>`
  text-align: left;
  font-family: 'Unimed-Sans-Bold';
  font-size: 0.65rem;
  color: ${(props) => props.theme.colors.text.grayishToWhite};

  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 6px));
  ${(props) =>
    props.themeColor &&
    props.theme.title === 'light' &&
    css`
      color: ${props.themeColor};
    `}
  ${(props) =>
    props.theme.title === 'dark' &&
    css`
      color: ${props.theme.colors.text.grayishToWhite};
      /* color: ${props.themeColor}; */
    `}
`;

export const ContainerLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1rem;
  position: relative;
  > div {
    margin-right: 8px;
  }
`;

export const ContainerCheck = styled.div`
  border: 1px solid #c4cbcf;
  box-sizing: border-box;
  border-radius: 50%;
  min-width: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  input {
    border: none;
    outline: none;
    appearance: none;
    position: relative;

    width: 100%;
    height: 100%;
    transition: background 300ms;
    &:checked {
      background: ${(props) => props.theme.colors.palet.institutional4};
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }
  & + div {
    margin-top: 8px;
  }
`;

export const Error = styled.div`
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.36333 1.9188L1.25652 15.4525C1.08903 15.7396 1.00097 16.0746 1.00001 16.4C0.999051 16.735 1.08519 17.07 1.25077 17.3572C1.41635 17.6443 1.65563 17.8931 1.94372 18.0558C2.23181 18.2281 2.55915 18.3239 2.89319 18.3239H19.1068C19.4408 18.3239 19.7682 18.2281 20.0563 18.0558C20.3444 17.8931 20.5836 17.6443 20.7492 17.3572C20.9148 17.07 21.0009 16.735 21 16.4C20.999 16.0746 20.911 15.7396 20.7435 15.4525L12.6367 1.9188C12.4663 1.64123 12.2261 1.40199 11.9389 1.23928C11.6527 1.08614 11.3292 1 11 1C10.6708 1 10.3473 1.08614 10.0611 1.23928C9.77394 1.40199 9.5337 1.64123 9.36333 1.9188Z' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 6.83853V10.667' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 14.4955H11.01' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;

  span {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: 300ms !important;
    position: absolute;
    white-space: nowrap;
    right: -20px;
    top: -54px;
    background: ${(props) => props.theme.colors.palet.institutional7};
    color: ${(props) => props.theme.colors.text.inverse};
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
    padding: 6px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(255, 15, 100, 0.3);

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 20px;
      border: 5px solid ${(props) => props.theme.colors.palet.institutional7};
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &:hover {
    span {
      transition: 300ms;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
