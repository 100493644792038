// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, {
  MouseEvent,
  useCallback,
  useState,
  useRef,
  memo,
  useEffect,
} from 'react';

import { FiChevronDown } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { Container, Options, Title } from './styles';

interface Props {
  title: string;
  subtitle: string;
  url: string;
  options: { url: string; title: string }[];
  colorBase?: string;
  className?: string;
  detail?: boolean;
  closeParentsOnDocument?: boolean;
}

const ButtonWithOptionsBeneficiary: React.FC<Props> = ({
  title,
  subtitle,
  url,
  options,
  colorBase: color,
  className,
  detail,
  closeParentsOnDocument,
}) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const history = useHistory();

  const handleOpenOption = useCallback(
    (event: MouseEvent<HTMLHeadingElement>) => {
      const allInFather = !closeParentsOnDocument
        ? event.currentTarget.parentElement?.parentElement?.querySelectorAll(
            '[data-butttonwithoptions]',
          )
        : document.querySelectorAll('[data-butttonwithoptions]');
      allInFather?.forEach((element) => {
        if (element !== event.currentTarget) {
          element.classList.remove('rotateSvg');
          element.nextElementSibling?.classList.remove('open');
        }
      });
      if (event.currentTarget.nextElementSibling?.classList.contains('open')) {
        event.currentTarget.nextElementSibling.classList.remove('open');
        event.currentTarget.classList.remove('rotateSvg');
      } else {
        event.currentTarget.nextElementSibling?.classList.add('open');
        event.currentTarget.classList.add('rotateSvg');
      }
    },
    [closeParentsOnDocument],
  );

  useEffect(() => {
    if (optionsRef.current) {
      setCalculatedHeight(optionsRef.current.scrollHeight);
    }
  }, []);

  return (
    <Container className={className}>
      <Title
        style={{ color, cursor: url ? 'pointer' : 'default' }}
        onClick={handleOpenOption}
        data-butttonwithoptions
      >
        {title}
        {options.length > 0 && <FiChevronDown size={14} color={color} />}
      </Title>

      <Options
        colorHover={color}
        ref={optionsRef}
        calculatedHeight={calculatedHeight}
      >
        <Link to={url} title={subtitle} className="full-line">
          {subtitle}
        </Link>
        {options.map((option) => (
          <p key={`${option.url}-${option.title}`}>{option.title}</p>
        ))}
      </Options>
    </Container>
  );
};

export default memo(ButtonWithOptionsBeneficiary);
