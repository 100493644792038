import Notiflix from 'notiflix';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api/api';
import { useAuth } from './auth';

interface ContractProps {
  contractId: string;
  stipulator: string;
  companyCode: string;
  contractDate: string;
  cnpj: string;
}

interface ContractContextProps {
  contract: ContractProps;
  defineContract(data: ContractProps): void;
}

const ContractContext = createContext<ContractContextProps>(
  {} as ContractContextProps,
);

export const ContractProvider: React.FC = ({ children }) => {
  const [contract, setContract] = useState<ContractProps>(() => {
    const saved = localStorage.getItem('@unimedLitoralContract');
    if (saved) {
      const json = JSON.parse(saved);
      api.defaults.headers.common.Contract = json.contractId;
      return json;
    }
    return {} as ContractProps;
  });

  const { user } = useAuth();

  const defineContract = useCallback((data: ContractProps) => {
    api.defaults.headers.common.Contract = data.contractId;
    setContract(data);
    localStorage.setItem('@UnimedLitoralContract', JSON.stringify(data));
  }, []);

  const getDetailsOfDefaultContract = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/company/my-contract-data?contractId=${user.contractId}`,
      );
      const { content: contentMyContract } = data;

      if (contentMyContract[0]) {
        // set default contract to new requests
        defineContract({
          contractId: contentMyContract[0].contractId,
          companyCode: contentMyContract[0].companyCode,
          cnpj: contentMyContract[0].cnpj,
          stipulator: contentMyContract[0].stipulatorName,
          contractDate: contentMyContract[0].contractDate,
        });
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos carregar as informações do contrato atual. Por favor, tente novamente mais tarde.',
      );
    }
  }, [defineContract, user.contractId]);

  useEffect(() => {
    if (user.profile === 2 && !contract.contractId) {
      getDetailsOfDefaultContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profile]);

  return (
    <ContractContext.Provider value={{ contract, defineContract }}>
      {children}
    </ContractContext.Provider>
  );
};

export function useContract(): ContractContextProps {
  const context = useContext(ContractContext);

  if (!context) {
    throw new Error('useContract must be used within an ContractProvider');
  }

  return context;
}
