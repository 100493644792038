import styled, { css } from 'styled-components';
import FooterComponent from '../Footer';
import HeaderComponent from '../Header';
import NavigationAsideComponent from '../NavigationAside';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.text.grayToGrayish};
  display: grid;
  grid-template-areas:
    'aside header'
    'aside content'
    'aside footer';
  grid-template-columns: 2fr 10fr;
  grid-template-rows: 60px 1fr 60px;
  column-gap: 20px;
  /* row-gap: 20px; */
  min-height: 100%;
  position: relative;
  @media (max-width: 768px) {
    grid-template-areas:
      'header'
      'content'
      'footer';
    grid-template-columns: 1fr;
    grid-template-rows: 73px 1fr 200px;
    row-gap: 0px;
  }
`;

export const Header = styled(HeaderComponent)`
  grid-area: header;
`;

export const NavigationAside = styled(NavigationAsideComponent)`
  grid-area: aside;
  z-index: 9999;
`;

interface ContainerWrapper {
  gridTemplateAreas?: string[];
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
}

export const ContainerWrapper = styled.main<ContainerWrapper>`
  grid-area: content;
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(10, 1fr);
  margin-right: 16px;

  @media (max-width: 768px) {
    column-gap: 0;
    margin-right: 0px;
    grid-template-columns: 1fr;
  }
  @media (min-width: 769px) {
    overflow-x: auto;
    ${(props) =>
      props.gridTemplateAreas &&
      css`
        grid-template-areas: '${props.gridTemplateAreas
          .toString()
          .replaceAll(',', "' '")}';
      `};

    ${(props) =>
      props.gridTemplateColumns &&
      css`
        grid-template-columns: ${props.gridTemplateColumns};
      `}
    ${(props) =>
      props.gridTemplateRows &&
      css`
        grid-template-rows: ${props.gridTemplateRows};
      `}
  }
`;

export const Footer = styled(FooterComponent)`
  grid-area: footer;

  margin-right: 16px;
  @media (max-width: 768px) {
    overflow: hidden;
    margin-left: 0;
    margin-right: 0px;
  }
`;
