/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
// import Button from '../components/Button';
// import { AddSvg } from '../components/Svg';
import { HeaderMobile, Tag } from '../components/Table/styles';

export function formatStringToBRL(value: string, pointer = ','): string {
  if (value.includes(',')) {
    return `R$ ${value}`;
  }

  const numberValue = parseFloat(value.replaceAll(pointer, '.'));
  // const numberValue = parseFloat(value);
  if (Number.isNaN(numberValue)) {
    return 'NaN';
  }

  const formatted = numberValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  });
  return `${formatted}`;
}

export function AddField(
  value: string,
  headerMobile: string,
  BRL = false,
  pointer = ',',
  link?: string,
  noLink?: string,
): JSX.Element {
  return (
    <>
      <HeaderMobile>{headerMobile}</HeaderMobile>
      {!BRL && value}
      {BRL && !link && !noLink && formatStringToBRL(String(value), pointer)}
      {value && link && (
        <a className="link" href={value}>
          {link}
        </a>
      )}
      {!value && noLink && <span className="linkNo">{noLink}</span>}
    </>
  );
}

// function toggleCallback(element: HTMLDivElement) {
//   const tr = element.parentElement?.parentElement;
//   const allTr =
//     tr?.parentElement?.parentElement?.querySelectorAll('tbody > tr');
//   if (tr) {
//     if (!tr.getAttribute('expand')) {
//       tr.setAttribute('expand', 'expand');
//       element.children[0].classList.add('open');
//       element.children[1].classList.add('open');
//     } else {
//       tr.removeAttribute('expand');
//       element.children[0].classList.remove('open');
//       element.children[1].classList.remove('open');
//     }
//   }
//   if (allTr) {
//     allTr.forEach((t) => {
//       if (t !== tr) {
//         t.removeAttribute('expand');
//         const childrens = t.querySelectorAll('td.options .open');
//         childrens.forEach((c) => c.classList.remove('open'));
//       }
//     });
//   }
// }

export function getTag(
  header: string,
  field: string,
  color: 'green' | 'red' | 'orange' | 'gray',
  fullDescription?: string,
): JSX.Element {
  return (
    <>
      <HeaderMobile>{header}</HeaderMobile>
      <Tag borderColor={color} title={fullDescription ?? field}>
        {field}
      </Tag>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setPositionOfRowExpanded(data: any): void {
  const toggleButton = data.originalEvent.target;
  const trContainer = toggleButton.parentElement?.parentElement
    ?.parentElement as HTMLTableRowElement | null;
  window.setTimeout(() => {
    const trExpanded =
      trContainer?.nextElementSibling as HTMLTableRowElement | null;

    if (trExpanded && trContainer) {
      trExpanded.style.display = 'flex';
      trExpanded.style.position = 'absolute';
      trExpanded.style.zIndex = '1';
      trExpanded.style.top = `${String(trContainer.offsetTop + 1)}px`;
      trExpanded.style.left = `0px`;
      trExpanded.style.height = `${String(trContainer.clientHeight)}px`;
      trExpanded.style.width = `calc(100% - 40px)`;
      trExpanded.style.setProperty('border', 'none', 'important');
      trExpanded.style.borderRadius = '20px 0 0 20px';
    }
  }, 100);
}

export default AddField;
