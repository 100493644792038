import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import Input from '../../components/Input';
import PageWrapper from '../../components/PageWrapper';
import { Column } from '../../components/Table';
import api from '../../services/api/api';
import getValidationErrors from '../../utils/getValidationErrors';
import AddField from '../../utils/tableUtils';
import {
  Title,
  Table,
  Container,
  SearchBarContainer,
  SearchButton,
} from './styles';

const ReferenceUnimedTable: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consultDatas, setConsultDatas] = useState<any>([]);
  const formRef = useRef<FormHandles>(null);

  const handleFilter = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});
      if (!data.procedureCode && !data.procedureDescription) {
        formRef.current?.setErrors({
          procedureCode: 'Informe um dos campos',
          procedureDescription: 'Informe um dos campos',
        });
        return;
      }

      Notiflix.Block.circle('.notiflix-table-loading');
      const { data: dataApi } = await api.get(
        `/unimed-reference-table/list?procedureCode=${data.procedureCode}&procedureDescription=${data.procedureDescription}`,
      );
      Notiflix.Block.remove('.notiflix-table-loading');
      const { content } = dataApi;
      setConsultDatas(content);
    } catch (error) {
      Notiflix.Block.remove('.notiflix-table-loading');
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      } else if (error.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Não foi possível buscar os dados da tabela de Referência Unimed. Tente novamente mais tarde.',
        );
      }
    }
  }, []);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10,1fr)"
      gridTemplateRows="auto auto 1fr"
    >
      <Title>Tabela Referência Unimed</Title>

      <Container>
        <SearchBarContainer formLook ref={formRef} onSubmit={handleFilter}>
          <Input
            name="procedureCode"
            title="Procure por um código de referência:"
            formLook
            widthContainerDesktop="30%"
          />
          <Input
            name="procedureDescription"
            formLook
            title="Procure por um nome de referência:"
            widthContainerDesktop="30%"
          />
          <SearchButton type="submit" formLook grayButton autoWidth>
            Procurar
          </SearchButton>
        </SearchBarContainer>
        <Table
          items={consultDatas}
          key="consulta-recisao-beneficiario-table"
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="consulta-recisao-beneficiario-table"
        >
          <Column
            field="validityStartDate"
            header="Vigência"
            className="date"
            body={(data) => AddField(data.validityStartDate, 'Vigência')}
          />
          <Column
            field="validityEndDate"
            header="Fim Vigência"
            className="date"
            body={(data) => AddField(data.validityEndDate, 'Fim Vigência')}
          />

          <Column
            field="procedureDescription"
            header="Procedimento"
            sortable
            body={(data) => AddField(data.procedureDescription, 'Procedimento')}
          />
          <Column
            field="value"
            header="Valor"
            sortable
            className="currency"
            body={(data) => {
              return AddField(data.value.toString(), 'Valor', true);
            }}
          />
        </Table>
      </Container>
    </PageWrapper>
  );
};

export default ReferenceUnimedTable;
