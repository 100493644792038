import React, { useCallback, useEffect, useState } from 'react';
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import { ObjectGetValueOfKey } from '../../utils/formatt';

import { LauraSvg, WhatsappSvg, PhoneSvg, GrupowSvg } from '../Svg';
import {
  ContainerDesktop,
  Label,
  Address,
  ANSContainer,
  ContainerMobile,
  Row,
  LabelMobile,
} from './styles';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const { isAuthendicated } = useAuth();
  const [lauraLink, setLauraLink] = useState('');
  const getLauraLink = useCallback(async () => {
    const { data } = await api.get('/gw/translation?key=fale-com-a-laura');
    setLauraLink(ObjectGetValueOfKey(data.content, 'fale-com-a-laura'));
  }, []);

  useEffect(() => {
    if (isAuthendicated) {
      getLauraLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerDesktop className={className}>
        <Label>
          Fale Conosco:
          <a
            href="https://site.unimedlitoral.com.br/faleconosco/"
            target="_blank"
            rel="noreferrer"
          >
            0800 047 2100
          </a>
        </Label>
        <Label>
          Whatsapp:
          <a href="https://wa.me/554733414444" target="_blank" rel="noreferrer">
            +554733414444
          </a>
        </Label>
        <Address>
          <p>Av. do Estado Dalmo Vieira, 1550 • Ariribá</p>
          <p>CEP: 88338-640 • Balneário Camboriú </p>
          <div>
            <a
              href="https://www.facebook.com/unimedlitoral"
              target="_blank"
              rel="noreferrer"
            >
              <FiFacebook size={10} /> Unimed Litoral
            </a>
            <a
              href="https://www.instagram.com/unimedlitoral/"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={10} /> @unimedlitoral
            </a>
            <a
              href="https://www.linkedin.com/company/unimed-litoral/"
              target="_blank"
              rel="noreferrer"
            >
              <FiLinkedin size={10} /> Unimed Litoral
            </a>
          </div>
        </Address>
        <ANSContainer>
          <p>ANS - Nº 303356</p>
        </ANSContainer>
      </ContainerDesktop>

      <ContainerMobile>
        <Row>
          <LabelMobile>
            <PhoneSvg />
            <div>
              <p>Fale Conosco:</p>
              <a
                href="https://site.unimedlitoral.com.br/faleconosco/"
                target="_blank"
                rel="noreferrer"
              >
                0800 047 2100
              </a>
            </div>
          </LabelMobile>
          <LabelMobile>
            <WhatsappSvg />
            <div>
              <p>WhatsApp:</p>
              <a
                href="https://wa.me/554733414444"
                target="_blank"
                rel="noreferrer"
              >
                +554733414444
              </a>
            </div>
          </LabelMobile>
        </Row>
        <Row>
          <LabelMobile className="laura">
            <LauraSvg />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://triagemvirtual.laura-br.com/unimedlitoral"
            >
              Fale com Laura
            </a>
          </LabelMobile>
          {/* )} */}
          <ANSContainer>
            <p>ANS - nº 303356</p>
          </ANSContainer>
        </Row>
        <Row className="grupow">
          <a href="https://www.grupow.com.br" target="_blank" rel="noreferrer">
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>{' '}
            <GrupowSvg />{' '}
          </a>
        </Row>
      </ContainerMobile>
    </>
  );
};

export default Footer;
