/* eslint-disable no-nested-ternary */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import DatePickerUnform from '../../components/DatePickerUnform';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import Select from '../../components/Select';
import SelectUnform from '../../components/SelectUnform';
import { useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { ObjectGetValueOfKey } from '../../utils/formatt';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import getValidationErrors from '../../utils/getValidationErrors';

interface Params {
  cdParametro: string;
  ieCampo: string;
  dsParametro: string | null;
  dsValorParametro: { id: string; nome: string }[] | null;
  ieTipoAtributo: string;
  ieTipoParametro: string;
  ieObrigatorio: 'S' | 'N';
  API: string | null;
  ieFormaApresentacao: 'I' | 'E';
  nrSeqApresentacao: number;
}
interface ReportProps {
  nrSeqRelatorio: string;
  dsRelatorio: string;
  Parametros: Params[];
}

interface ComponentParams {
  location: number;
  othersMapping?: { key: string; value: string }[];
}

const Report: React.FC<ComponentParams> = ({ location, othersMapping }) => {
  const { user } = useAuth();
  const { contract } = useContract();

  const relationBeneficiary = useMemo(() => {
    return [
      {
        pt: 'nmSegurado',
        en: '',
      },
      {
        pt: 'nmLogin',
        en: 'name',
      },
      {
        pt: 'dsEmail',
        en: 'email',
      },
      {
        pt: 'nrCartao',
        en: 'card',
      },
      {
        pt: 'ieSituacao',
        en: '',
      },
      {
        pt: 'nrSeqSegurado',
        en: 'userUnimedId',
      },
      {
        pt: 'dsPlano',
        en: '',
      },
      {
        pt: 'cdPessoaFisica',
        en: 'physicalPersonalCode',
      },
      {
        pt: 'cdEstabelecimento',
        en: '',
      },
      {
        pt: 'dtAdesao',
        en: '',
      },
      {
        pt: 'dtRescisao',
        en: '',
      },
      {
        pt: 'dtValidadeCartao',
        en: '',
      },
      {
        pt: 'nrSeqTitular',
        en: '',
      },
      {
        pt: 'nrSeqContrato',
        en: 'contractId',
      },
      {
        pt: 'nrSeqPagador',
        en: 'payerNumber',
      },
      {
        pt: 'iePagador',
        en: 'isPayer',
      },
      {
        pt: 'ieTitularidade',
        en: 'isOwner',
      },
      {
        pt: 'nrSeqCarteira',
        en: '',
      },
      {
        pt: 'qtMaxCaracCart',
        en: '',
      },
      {
        pt: 'ieValCart',
        en: '',
      },
      {
        pt: 'nrSeqSeguradoWeb',
        en: '',
      },
      {
        pt: 'dsTec',
        en: '',
      },
      {
        pt: 'dtNascimento',
        en: 'birthDate',
      },
      {
        pt: 'cdVersaoTiss',
        en: '',
      },
      {
        pt: 'dtUltimoAcesso',
        en: 'lastAccessDateTime',
      },
      {
        pt: 'nrProtocoloAtend',
        en: 'serviceProtocolNumber',
      },
      {
        pt: 'nrProtocolo',
        en: 'protocolNumber',
      },
      {
        pt: 'ieAlterouFoto',
        en: '',
      },
    ];
  }, []);
  const relationCompany = useMemo(() => {
    return [
      {
        pt: 'nmUsuarioWeb',
        en: 'webUsername',
      },
      {
        pt: 'nmEstipulante',
        en: 'name',
      },
      {
        pt: 'cdCNPJ',
        en: 'cnpj',
      },
      {
        pt: 'nrSeqWeb',
        en: 'webUserId',
      },
      {
        pt: 'nrSeqContrato',
        en: 'contractId',
      },
      {
        pt: 'nrSeqPagador',
        en: 'payerNumber',
      },
      {
        pt: 'nrSeqGrupo',
        en: 'groupNumber',
      },
      {
        pt: 'nrSeqPerfilWeb',
        en: 'webUserId',
      },
      {
        pt: 'dtUltimoAcesso',
        en: 'lastAccessDateTime',
      },
    ];
  }, []);

  const getInternalValue = useCallback(
    (internal: string) => {
      const relation = relationCompany;
      const relative = relation.find((i) => i.pt === internal);
      if (relative) {
        if (relative.en === 'contractId') return contract.contractId;
        return ObjectGetValueOfKey(user, relative.en);
      }
      const otherMapping = othersMapping?.find((i) => i.key === internal);
      if (otherMapping) {
        return otherMapping.value;
      }
      return '';
    },
    [relationCompany, user, othersMapping, contract],
  );

  const [reportOptions, setReportOptions] = useState({
    options: [{ title: '', value: '' }],
    all: [] as ReportProps[],
  });

  const getOptionsForBeneficiary = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/report/list-of-beneficiary-reports?exhibitionLocation=${location}`,
      );
      const { content } = data;
      setReportOptions({
        options: content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { dsRelatorio: string; nrSeqRelatorio: string },
          ) => {
            acc.push({
              title: act.dsRelatorio,
              value: act.nrSeqRelatorio,
            });
            return acc;
          },
          [],
        ),
        all: content,
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os relatórios disponíveis no momento. Por favor, tente novamente mais tarde.',
      );
    }
  }, [location]);

  const getOptionsForCompany = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/report/list-of-company-portal-reports?contractId=${contract.contractId}&exhibitionLocation=${location}`,
      );
      const { content } = data;
      setReportOptions({
        options: content.reduce(
          (
            acc: { title: string; value: string }[],
            act: { dsRelatorio: string; nrSeqRelatorio: string },
          ) => {
            acc.push({
              title: act.dsRelatorio,
              value: act.nrSeqRelatorio,
            });
            return acc;
          },
          [],
        ),
        all: content,
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os relatórios disponíveis no momento. Por favor, tente novamente mais tarde.',
      );
    }
  }, [contract, location]);

  useEffect(() => {
    if (user?.profile === 1) {
      getOptionsForBeneficiary();
    } else if (user?.profile === 2 && contract?.contractId) {
      getOptionsForCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, contract]);

  const [actualOption, setActualOption] = useState('');
  const formRef = useRef<FormHandles>(null);

  const formToShow = useMemo(() => {
    const params = reportOptions.all.find(
      (item) => item.nrSeqRelatorio === actualOption,
    );
    formRef.current?.setErrors({});

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submitForm = async (formData: any) => {
      try {
        Notiflix.Block.circle(`.notiflix-generate-report-${actualOption}`);
        formRef.current?.setErrors({});
        if (!params) return;
        const shape = params.Parametros.reduce(
          (
            acc: {
              [x: string]: RequiredStringSchema<
                string | null | undefined,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Record<string, any>
              >;
            },
            act: { ieObrigatorio: string; cdParametro: string },
          ) => {
            if (act.ieObrigatorio === 'S') {
              acc[act.cdParametro] = Yup.string().required('Campo obrigatório');
            }
            return acc;
          },
          {},
        );
        const schema = Yup.object().shape(shape);
        await schema.validate(formData, { abortEarly: false });

        const params2 = Object.entries(formData).reduce((acc, act) => {
          const value =
            typeof act[1] === 'boolean' ? (act[1] ? 'S' : 'N') : act[1];

          if (params.Parametros.find((i) => i.cdParametro === act[0])) {
            acc.push({
              id: act[0],
              valor: value as string,
            });
          }
          return acc;
        }, [] as { id: string; valor: string }[]);

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          {
            nrSeqRelatorio: params.nrSeqRelatorio,
            Parametros: params2,
          },
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      } finally {
        Notiflix.Block.remove(`.notiflix-generate-report-${actualOption}`);
      }
    };

    if (!params) {
      return <></>;
    }

    return (
      <FormWrapper
        onSubmit={(e) => submitForm(e)}
        formLook
        ref={formRef}
        className="form-variable"
      >
        {params?.Parametros.sort((a, b) => {
          if (a.nrSeqApresentacao < b.nrSeqApresentacao) {
            return -1;
          }
          if (a.nrSeqApresentacao > b.nrSeqApresentacao) {
            return 1;
          }
          return 0;
        }).map((param) => {
          if (param.ieFormaApresentacao === 'I') {
            return (
              <Input
                key={v4()}
                name={param.cdParametro}
                hidden
                value={getInternalValue(param.ieCampo)}
              />
            );
          }

          if (param.API || param.ieTipoParametro === 'select') {
            const options = [] as { id: string; name: string }[];
            if (param.dsValorParametro) {
              options.push(
                ...param.dsValorParametro.map((i) => ({
                  id: i.id,
                  name: i.nome,
                })),
              );
            }
            return (
              <SelectUnform
                key={v4()}
                name={param.cdParametro}
                title={param.dsParametro ?? ''}
                formLook
                options={options}
                apiSearch={param.API?.replace('http://', 'https://')}
              />
            );
          }
          if (param.ieTipoAtributo === 'NUMBER') {
            return (
              <Input
                key={v4()}
                name={param.cdParametro}
                title={param.dsParametro ?? ''}
                type="number"
                formLook
              />
            );
          }
          if (
            param.ieTipoAtributo === 'VARCHAR2' &&
            param.ieTipoParametro === 'CHECK BOX'
          ) {
            return (
              <Checkbox
                key={v4()}
                name={param.cdParametro}
                title={param.dsParametro ?? ''}
                value={param.cdParametro}
              />
            );
          }
          if (param.ieTipoAtributo === 'VARCHAR2') {
            return (
              <Input
                key={v4()}
                name={param.cdParametro}
                title={param.dsParametro ?? ''}
                formLook
              />
            );
          }
          if (param.ieTipoAtributo === 'DATE') {
            return (
              <DatePickerUnform
                key={v4()}
                name={param.cdParametro}
                title={param.dsParametro ?? ''}
                formLook
              />
            );
          }
          return <></>;
        })}
        <Button
          type="submit"
          autoWidth
          greenPrimary
          formLook
          className={`notiflix-generate-report-${actualOption}`}
        >
          Gerar Relatório
        </Button>
      </FormWrapper>
    );
  }, [actualOption, getInternalValue, reportOptions]);

  return (
    <>
      <FormWrapper
        onSubmit={() => {
          //
        }}
      >
        <Select
          name="reportOptions"
          options={reportOptions.options}
          formLook
          changeCallback={(option) => setActualOption(option)}
        />
      </FormWrapper>
      {formToShow}
    </>
  );
};

export default Report;
