import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '../../components/Button';
import Modal2 from '../../components/Modal2';
import PageWrapper from '../../components/PageWrapper';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import Form from './Form';
import { Container } from './styles';
import { RowProps } from '../Beneficiaries';

const CallEditBeneficiaryInclude: React.FC = () => {
  const { contract } = useContract();
  const history = useHistory();

  const { requestId: requestIdParam, params } =
    useParams<{ requestId: string; params: string }>();
  const [requestId, setRequestId] = useState(requestIdParam);

  const [isTitular, setIsTitular] = useState<boolean>(
    params.split('/')[1] === 'titular',
  );

  const [showDependentIncludeButton, setShowDependentIncludeButton] =
    useState(false);
  const [requestHolderId, setRequestHolderId] = useState('');

  const [simulatedRows, setSimulatedRows] = useState<RowProps>({} as RowProps);

  const handleSubmitRejected = useCallback(
    async (data, files) => {
      const NOT_MAPPED = '';
      try {
        Notiflix.Block.circle('.notiflix-save-beneficiary');
        const params2 = {
          voterTitleNumber: NOT_MAPPED,
          adoptionDate: NOT_MAPPED,
          brazilianstatesWhichIssuedCtps: NOT_MAPPED,
          weddingDate: NOT_MAPPED,
          shortName: NOT_MAPPED,
          marriageCertificateNumber: NOT_MAPPED,
          numberPisPasep: NOT_MAPPED,
          portabilityProtocolNumber: NOT_MAPPED,
          numberCtps: NOT_MAPPED,
          numberSeriesCtps: NOT_MAPPED,

          requestHolderId: data.requestHolderId,
          nameRequestHolder: data.nameRequestHolder,
          previousRequestId: data.previousRequestId,

          contractId: contract.contractId,
          beneficiaryName: data.beneficiaryName,
          email: data.email,
          birthDate: data.birthDate,
          hiringDate: data.hiringDate,
          ddiPhone: data.DDIPhone,
          dddPhone: data.phone?.slice(0, 2),
          phone: data.phone?.slice(2),
          birthCityCode: data.cityOfBirth,
          initialsBirthState: data.stateOfBirth,
          cpf: data.cpf,
          rg: data.rg,
          issuingBody: data.rgAgencyIssuer,
          brazilianstatesWhichIssuedRg: data.rgIssuerState,
          emissionDate: data.rgIssuerDate,
          issuingAgencyCountry: data.rgIssuerCountry,
          maritalStatus: data.maritalStatus,
          physicalSex: data.phisicalSex,
          motherName: data.motherName,
          fatherName: data.fatherName,
          motiveIncludeId: data.includeReason,
          initialsState: data.state,
          birthCertificateCodeAlive: data.declarationOfLive,
          neighborhood: data.neighborhood,
          ddiNumberCellphone: data.DDICellphone,
          dddCellPhone: data.cellphone?.slice(0, 2),
          cellphone: data.cellphone?.slice(2),
          zipCode: data.zipCode,
          nationalityCode: data.nationality,
          address: data.address,
          complement: data.complement,
          addressNumber: data.addressNumber,
          nationalSusCardNumber: data.susCard,
          socialName: data.socialName,
          admissionDate: data.holderHiringDate,
          addressTypePublicPlace: data.placeType,
          ibgeCityCode: data.city,
          planId: data.product,
          observation: data.observation,
          descriptionCountyBirthForeign: data.foreignBirthCity,
          foreignRgId: data.rgForeign,
          registrationCode: data.registrationCode,
          datePlanAgreementOrigin: data.contractDateOfOriginPlan,
          sourcePlanPriceValue: data.priceOfOriginPlan,
          ansGuideProtocolNumber: data.ANSGuideProtocolNumber,
          protocolIssueDate: data.issueProtocolDate,
          descriptionOperatorOrigin: data.originOperator,
          codeCoveragePlanOrigin: data.coverageGeographicalOriginalPlan,
          sourcePlanRegistrationCode: data.registerOfOriginalProduct,
          typePlanContractOrigin: data.typeOfContract,
          // relationshipCode: data.relationship,
        };
        const formData = new FormData();
        Object.entries(params2).forEach((ar) => {
          formData.append(ar[0], ar[1]);
        });
        Object.keys(files).forEach((key, index) => {
          if (files[key]) {
            formData.append(
              `beneficiaryFile[${key}]`,
              files[index].file,
              files[index].name,
            );
          }
        });
        const { data: dataApi } = await api.post(
          `/beneficiary-inclusion/include-beneficiary`,
          formData,
        );
        const { message, data: data2 } = dataApi;
        Notiflix.Notify.success(message);
        // Modal2.Success({
        //   closable: true,
        //   autoWidth: true,
        //   title: 'Perfeito!',
        //   text: message,
        //   children: (
        //     <Button
        //       modal
        //       onClick={() => {
        //         Modal2.Close();
        //         history.goBack();
        //       }}
        //     >
        //       Ok
        //     </Button>
        //   ),
        // });
        setShowDependentIncludeButton(true);
        setRequestHolderId(data2[0].requestId);
        setRequestId(data2[0].requestId);
        return data2[0].requestId;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
          );
        }
        return null;
      } finally {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
      }
    },
    [contract],
  );

  const handleSubmitEdit = useCallback(
    async (data, files) => {
      try {
        Notiflix.Block.circle('.notiflix-save-beneficiary');
        const NOT_MAPPED = '';
        const params2 = {
          voterTitleNumber: NOT_MAPPED,
          adoptionDate: NOT_MAPPED,
          brazilianstatesWhichIssuedCtps: NOT_MAPPED,
          weddingDate: NOT_MAPPED,
          shortName: NOT_MAPPED,
          marriageCertificateNumber: NOT_MAPPED,
          numberPisPasep: NOT_MAPPED,
          portabilityProtocolNumber: NOT_MAPPED,
          numberCtps: NOT_MAPPED,
          numberSeriesCtps: NOT_MAPPED,
          requestId,

          requestHolderId: data?.requestHolderId,
          nameRequestHolder: data?.nameRequestHolder,
          previousRequestId: data?.previousRequestId,

          contractId: contract.contractId,
          beneficiaryName: data.beneficiaryName,
          email: data.email,
          birthDate: data.birthDate,
          hiringDate: data.hiringDate,
          ddiPhone: data.DDIPhone,
          dddPhone: data.phone?.slice(0, 2),
          phone: data.phone?.slice(2),
          birthCityCode: data.cityOfBirth,
          initialsBirthState: data.stateOfBirth,
          cpf: data.cpf,
          rg: data.rg,
          issuingBody: data.rgAgencyIssuer,
          brazilianstatesWhichIssuedRg: data.rgIssuerState,
          emissionDate: data.rgIssuerDate,
          issuingAgencyCountry: data.rgIssuerCountry,
          maritalStatus: data.maritalStatus,
          physicalSex: data.phisicalSex,
          motherName: data.motherName,
          fatherName: data.fatherName,
          motiveIncludeId: data.includeReason,
          initialsState: data.state,
          birthCertificateCodeAlive: data.declarationOfLive,
          neighborhood: data.neighborhood,
          ddiNumberCellphone: data.DDICellphone,
          dddCellPhone: data.cellphone?.slice(0, 2),
          cellphone: data.cellphone?.slice(2),
          zipCode: data.zipCode,
          nationalityCode: data.nationality,
          address: data.address,
          complement: data.complement,
          addressNumber: data.addressNumber,
          nationalSusCardNumber: data.susCard,
          socialName: data.socialName,
          admissionDate: data.holderHiringDate,
          addressTypePublicPlace: data.placeType,
          ibgeCityCode: data.city,
          planId: data.product,
          observation: data.observation,
          descriptionCountyBirthForeign: data.foreignBirthCity,
          foreignRgId: data.rgForeign,
          registrationCode: data.registrationCode,
          datePlanAgreementOrigin: data.contractDateOfOriginPlan,
          sourcePlanPriceValue: data.priceOfOriginPlan,
          ansGuideProtocolNumber: data.ANSGuideProtocolNumber,
          protocolIssueDate: data.issueProtocolDate,
          descriptionOperatorOrigin: data.originOperator,
          codeCoveragePlanOrigin: data.coverageGeographicalOriginalPlan,
          sourcePlanRegistrationCode: data.registerOfOriginalProduct,
          typePlanContractOrigin: data.typeOfContract,
          // relationshipCode: data.relationship,
        };
        const formData = new FormData();
        Object.entries(params2).forEach((ar) => {
          formData.append(ar[0], ar[1]);
        });

        files.forEach((key: any, index: number) => {
          formData.append(
            `detailsFile[${index}]`,
            files[index].file,
            files[index].name,
          );
        });

        const { data: dataApi } = await api.post(
          `/beneficiary-inclusion/save-details-changes`,
          formData,
        );
        const { message } = dataApi;
        Notiflix.Notify.success(message);
        return requestId;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos salvar sua requisição no servidor. Por favor, tente novamente mais tarde.',
          );
        }
        return null;
      } finally {
        Notiflix.Block.remove('.notiflix-save-beneficiary');
      }
    },
    [requestId, contract],
  );

  useEffect(() => {
    if (contract.contractId) {
      setSimulatedRows((prev) => ({
        ...prev,
        contractId: contract.contractId,
        requestId,
        beneficiaryName: params.split('/')[2],
      }));
    }
  }, [contract, requestId, params]);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr" gridTemplateRows="auto 1fr">
      <Container>
        {simulatedRows.requestId && (
          <>
            {params.split('/')[0] === 'rejeitado' && (
              <Form
                allDisabled
                showNewButton
                callbackSubmit={
                  !requestHolderId ? handleSubmitRejected : handleSubmitEdit
                }
                contractId={contract.contractId}
                showDependentIncludeButton={showDependentIncludeButton}
                isOwner={isTitular}
                preloadOfRequestId={requestId}
                callbackButtonGoBack={() => {
                  setShowDependentIncludeButton(false);
                  history.goBack();
                }}
                requestHolderId={requestHolderId}
                dependentParams={simulatedRows}
              />
            )}
            {params.split('/')[0] !== 'rejeitado' &&
              params.split('/')[0] !== 'freeLot' && (
                <Form
                  callbackSubmit={handleSubmitEdit}
                  contractId={contract.contractId}
                  showDependentIncludeButton={isTitular}
                  isOwner={isTitular}
                  preloadOfRequestId={requestId}
                  callbackButtonGoBack={() => {
                    history.goBack();
                  }}
                  requestHolderId={requestId}
                  dependentParams={simulatedRows}
                />
              )}
            {params.split('/')[0] === 'freeLot' && (
              <Form
                allDisabled
                showNewButton={false}
                noActions
                callbackSubmit={
                  !requestHolderId ? handleSubmitRejected : handleSubmitEdit
                }
                contractId={contract.contractId}
                showDependentIncludeButton={showDependentIncludeButton}
                isOwner={isTitular}
                preloadOfRequestId={requestId}
                callbackButtonGoBack={() => {
                  setShowDependentIncludeButton(false);
                  history.goBack();
                }}
                requestHolderId={requestHolderId}
                dependentParams={simulatedRows}
              />
            )}
          </>
        )}
      </Container>
    </PageWrapper>
  );
};

export default CallEditBeneficiaryInclude;
