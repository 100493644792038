import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

import Hooks from './hooks';

import GlobalStyle from './global/styles';

// declare global {
//   interface Window {
//     gtag: any;
//   }
// }

const App: React.FC = () => {
  // useEffect(() => {
  //   /**
  //    * Google Analytics
  //    */
  //   let key = '';
  //   switch (process.env.REACT_APP_LOCAL) {
  //     case 'QAS':
  //       key = 'G-VZPLNTC177';
  //       break;
  //     case 'PRD':
  //       key = 'G-PB5QHY39ZF';
  //       break;
  //     default:
  //       key = '';
  //   }
  //   if (key) {
  //     const scriptPreload = document.createElement('script');
  //     scriptPreload.type = 'text/javascript';
  //     scriptPreload.async = true;
  //     scriptPreload.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  //     document.head.appendChild(scriptPreload);

  //     window.gtag('js', new Date());

  //     window.gtag('config', key);
  //   }
  // }, []);

  return (
    <>
      <Hooks>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <GlobalStyle />
      </Hooks>
    </>
  );
};

export default App;
