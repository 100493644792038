/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from 'react';
import Notiflix from 'notiflix';
import { v4 } from 'uuid';
import { FormHandles } from '@unform/core';
import ClipboardJS from 'clipboard';
import { useTheme } from 'styled-components';
import Button from '../../../components/Button';
import Modal2 from '../../../components/Modal2';
import { ModalContainer } from '../../Beneficiaries/styles';
import ReportComponent from '../../Report/Report';
import PageWrapper from '../../../components/PageWrapper';
import Table, { Column } from '../../../components/Table';
import { ButtonsActionsContainer } from '../../../components/Table/styles';
import api from '../../../services/api/api';
import {
  AddField as addField,
  getTag,
  setPositionOfRowExpanded,
} from '../../../utils/tableUtils';

import Title, { TitleIntern } from '../../../components/Title';

import {
  TableContainer,
  Steps,
  FormWrapper2,
  Step1,
  Step2,
  ButtonElement,
  ButtonWrapper,
  ButtonsWrapper,
  ModalWrapper,
  ModalDetailValue,
  ModalObservation,
  ModalObservationContent,
  ModalObservationTitle,
  ContainerTable,
} from './styles';

import { downloadFileOfBlob } from '../../../utils/geterateFileURL';
import FormWrapper from '../../../components/FormWrapper';
import Input from '../../../components/Input';
import { useAuth } from '../../../hooks/auth';
import Select, { SelectHandles } from '../../../components/Select';
import { useContract } from '../../../hooks/contract';

interface dataProps {
  amountOfDaysDelay: number;
  competitionDate: string;
  coparcenaryValue: string;
  expirationDate: string;
  liquidationDate: string;
  payerName: string;
  payerNumber: number;
  situation: string;
  titleNumber: number;
  tuitionNumber: number;
  tuitionValue: string;
  nfe: string;
}

interface internDataProps {
  cardNumber: string;
  insuredName: string;
  holderName: string;
  tuitionValue: string;
  coparcenaryValue: string;
  additionalValue: string;
}

const CompanyDemonstrativeAndDuplicate: React.FC = () => {
  const { user } = useAuth();
  const { contract } = useContract();
  const { colors } = useTheme();
  const defaultColor = colors.palet.institutional;

  const clipboard = new ClipboardJS('.btn');
  const [tableData, setTableData] = useState<dataProps[]>([]);
  const [internTableData, setInternTableData] = useState<internDataProps[]>([]);
  const step2formRef = useRef<FormHandles>(null);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const generateRef = useRef<SelectHandles>(null);
  const [actualStep, setActualStep] = useState(1);
  const [choosenRow, setChoosenRow] = useState('');
  const [heightStep, setHeightStep] = useState(['']);
  const [optionsDuplicate, setOptionsDuplicate] = useState([
    {
      title: 'Selecione uma opção',
      value: '',
    },
  ]);

  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      case 3:
        return heightStep[2];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);

  const calculateHeightOfSteps = useCallback(() => {
    setHeightStep([
      `${(step1Ref.current?.scrollHeight ?? 0) + 50}px`,
      `${step2Ref.current?.scrollHeight}px`,
    ]);
  }, []);

  const getFooterButton = useCallback(() => {
    const footerButton = document.querySelector(
      '#demonstrativo-e-segunda-via-do-boleto',
    );
    if (footerButton) {
      const footer = footerButton.querySelector('.p-datatable-footer');
      if (footer) {
        const button = footer.querySelector('div > button');
        if (button) {
          button.addEventListener('click', () => {
            setTimeout(() => {
              calculateHeightOfSteps();
            }, 100);
          });
        }
      }
    }
  }, [calculateHeightOfSteps]);

  useLayoutEffect(() => {
    calculateHeightOfSteps();
    getFooterButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, internTableData]);

  const handleOpenDetail = useCallback(async (rowData) => {
    try {
      setChoosenRow(rowData.tuitionNumber);
      const { data } = await api.get(
        `company-financial/registration-details?tuitionNumber=${rowData.tuitionNumber}`,
      );
      const { content } = data;
      setInternTableData(content);
      setActualStep(2);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleOpenObservation = useCallback(async (data, notiflix) => {
    try {
      const { tuitionNumber } = data;
      Notiflix.Block.circle(notiflix);
      const { data: dataApi } = await api.get(
        `/financial/beneficiary-tuition-observation?tuitionNumber=${tuitionNumber}`,
      );
      const { content } = dataApi;

      Modal2.Generic({
        closable: false,
        autoWidth: true,
        children: (
          <ModalObservation>
            <ModalWrapper>
              {content[0].dischargeMessage && (
                <>
                  <ModalObservationTitle>
                    Mensagem quitação anual de débitos
                  </ModalObservationTitle>
                  <ModalObservationContent
                    dangerouslySetInnerHTML={{
                      __html: content[0].dischargeMessage,
                    }}
                  />
                </>
              )}
              {content[0].observation && (
                <>
                  <ModalObservationTitle>Observações</ModalObservationTitle>
                  <ModalObservationContent
                    dangerouslySetInnerHTML={{
                      __html: content[0].observation,
                    }}
                  />
                </>
              )}
              <Button
                outlined
                modal
                autoWidth
                float="right"
                onClick={(e) => {
                  e.stopPropagation();
                  Modal2.Close();
                }}
              >
                Ok
              </Button>
            </ModalWrapper>
          </ModalObservation>
        ),
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error?.response?.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar as observações. Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(notiflix);
    }
  }, []);

  const handleCopyToClipboard = useCallback(
    async (rowData, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/company-financial/copy-barcode?titleNumber=${rowData.titleNumber}&webUsername=${user.webUsername}`,
        );

        Modal2.Generic({
          closable: true,
          autoWidth: true,
          children: (
            <ModalWrapper>
              <FormWrapper2
                formLook
                onSubmit={() => {
                  try {
                    Notiflix.Block.circle('.btn');
                    clipboard
                      .on('success', () => {
                        Notiflix.Notify.success('Copiado com Sucesso!');
                        Modal2.Close();
                      })
                      .on('error', () => {
                        Notiflix.Notify.failure(
                          'Ops...Não conseguimos copiar o código de barras automaticamente.',
                        );
                      });
                  } catch (error) {
                    Notiflix.Notify.failure(
                      'Ops...Não conseguimos copiar o código de barras automaticamente.',
                    );
                  } finally {
                    Notiflix.Block.circle('.btn');
                    Notiflix.Block.remove('.btn');
                  }
                }}
              >
                <Input
                  id="bar"
                  name="barcodeNumber"
                  formLook
                  title="Código de Barras"
                  value={data.content[0].barcodeNumber}
                  autoFocus
                />

                <Button
                  type="submit"
                  className="btn"
                  data-clipboard-action="cut"
                  data-clipboard-target="#bar"
                >
                  Copiar
                </Button>
              </FormWrapper2>
            </ModalWrapper>
          ),
        });
      } catch (error) {
        console.log(error);
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [clipboard],
  );

  const handleSendOnEmail = useCallback(
    async (data, notiflix) => {
      const { titleNumber } = data;
      const { webUsername } = user;
      const params = `titleNumber=${titleNumber}&webUsername=${webUsername}`;
      try {
        Notiflix.Block.circle(notiflix);
        const { data: dataApi } = await api.get(
          `/company-financial/send-bank-slip-by-email?${params}`,
        );
        Modal2.Success({
          autoWidth: true,
          closable: true,
          title: 'Sucesso!',
          children: (
            <>
              <ModalDetailValue>{dataApi.message}</ModalDetailValue>
              <Button
                style={{ marginTop: '8px' }}
                modal
                onClick={() => Modal2.Close()}
              >
                Fechar
              </Button>
            </>
          ),
        });
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Opss... Algo deu errado, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [user],
  );

  const situationTemplate = useCallback((status) => {
    switch (status) {
      case 'Aberto':
        return getTag('Aberto', 'Aberto', 'orange', 'Em aberto');
      case 'Pago':
        return getTag('Pago', 'Pago', 'green', 'Pago');
      case 'Liquidado':
        return getTag('Liquidado', 'Liquidado', 'green', 'Liquidado');
      default:
        return status;
    }
  }, []);

  const generateDoc = useCallback(
    async (data, exhibitionLocation) => {
      Notiflix.Block.circle('.notiflix-generate-report');
      if (generateRef?.current?.value === '') {
        generateRef.current.setError('Selecione uma opção');
        return;
      }
      generateRef?.current?.setError('');
      const option = generateRef?.current?.value;
      const { titleNumber } = data;
      try {
        const { data: dataApi } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${user.id}&exhibitionLocation=${exhibitionLocation}`,
        );
        const { nrSeqRelatorio, Parametros: P } = dataApi.content[0];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const params = [titleNumber, option];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            ...P.map((p: { cdParametro: string }, index: number) => {
              return {
                id: p.cdParametro,
                valor: params[index],
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (error) {
        if (error.response?.data?.message) {
          Notiflix.Notify.failure(error.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos gerar a 2º via. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-generate-report');
        Modal2.Close();
      }
    },
    [user.id],
  );

  const handleOpenLayout = useCallback(
    (data) => {
      Modal2.Generic({
        closable: true,
        autoWidth: true,
        title: 'Download TXT',
        children: (
          <ModalWrapper>
            <Select
              options={optionsDuplicate}
              ref={generateRef}
              name="optionsDuplicate"
            />
            <ButtonWrapper>
              <Button modal secondary onClick={() => Modal2.Close()}>
                Fechar
              </Button>
              <Button
                modal
                className="notiflix-generate-report"
                onClick={() => {
                  generateDoc(data, 6);
                  // Modal2.Close();
                }}
              >
                Gerar
              </Button>
            </ButtonWrapper>
          </ModalWrapper>
        ),
      });
    },
    [generateDoc, optionsDuplicate],
  );

  const handleDuplicateSelect = useCallback(async () => {
    try {
      const { data } = await api.get('/report/list-layout-files');
      const { content } = data;
      setOptionsDuplicate(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: {
              name: string;
              id: string;
            },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (error) {
      if (error?.response.status === 400) {
        Notiflix.Notify.info(error.response.data?.message);
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os modelos de arquivo do servidor. Tente novamente mais tarde.',
        );
        console.log(error);
      }
    }
  }, []);

  const handleOpenReport = useCallback(async (row, notiflix) => {
    try {
      // Notiflix.Block.circle(`.${notiflix}`);
      Modal2.Generic({
        closable: true,
        autoWidth: true,
        children: (
          <ModalContainer className="report">
            <h2>Relatórios</h2>
            <ReportComponent
              location={9}
              othersMapping={[
                { key: 'nrTitulo', value: String(row.titleNumber) },
              ]}
            />
          </ModalContainer>
        ),
      });
    } catch (err) {
      Notiflix.Notify.info(
        'Ops... Não encontramos relatórios disponíveis para esse documento.',
      );
    }
    //  finally {
    //   Notiflix.Block.remove(`.${notiflix}`);
    // }
  }, []);

  const handleRequestDuplicate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (row: any, notiflix) => {
      const { titleNumber } = row;
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${user.id}&exhibitionLocation=7`,
        );
        const { nrSeqRelatorio, Parametros } = data.content[0];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            {
              id: Parametros[0].cdParametro,
              valor: titleNumber,
            },
            {
              id: Parametros[1].cdParametro,
              valor: user.webUsername,
            },
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        console.log(err);
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [user],
  );

  const rowExpansionTemplate = useCallback(
    (rowData) => {
      const buttons = [
        {
          title: 'Detalhes',
          onClick: handleOpenDetail,
        },
        {
          title: 'Observações',
          float: 'left',
          onClick: handleOpenObservation,
          last: true,
        },
        {
          title: 'Relatório',
          onClick: handleOpenReport,
        },
        {
          title: 'Download TXT',
          onClick: handleOpenLayout,
        },
        {
          title: 'Enviar boleto por e-mail',
          onClick: handleSendOnEmail,
        },
        {
          title: 'Copiar código de barras',
          onClick: handleCopyToClipboard,
        },
        {
          title: '2ª via do boleto',
          onClick: handleRequestDuplicate,
        },
      ];
      return (
        <ButtonsActionsContainer>
          {buttons.map((button, index) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} ${index === 1 && 'marginRight'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(rowData, `.notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [
      handleOpenDetail,
      handleOpenReport,
      handleOpenLayout,
      handleSendOnEmail,
      handleCopyToClipboard,
      handleRequestDuplicate,
      handleOpenObservation,
    ],
  );

  const getData = useCallback(async () => {
    try {
      const { webUsername } = user;
      const { contractId } = contract;
      const params = `contractId=${contractId}&Usernameweb=${webUsername}`;
      Notiflix.Block.circle('.notiflix-table-loading');
      const { data } = await api.get(
        `/company-financial/listing-of-demonstrative?${params}`,
      );
      const { content } = data;
      setTableData(content);
    } catch (err) {
      console.log(err);
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os dados, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [user, contract]);

  const handleFilter = useCallback(async () => {
    try {
      Notiflix.Block.circle('.intern-table');
      if (step2formRef?.current) {
        const cardCode = step2formRef.current.getFieldValue('cardCode');
        const familyCode = step2formRef.current.getFieldValue('familyCode');
        const { data } = await api.get(
          `company-financial/registration-details?tuitionNumber=${choosenRow}&matriculationNumber=${familyCode}&cardNumber=${cardCode}`,
        );
        const { content } = data;
        setInternTableData(content);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        Notiflix.Notify.info(error.response?.data?.message);
        setInternTableData([]);
      } else {
        setInternTableData([]);
      }
    } finally {
      Notiflix.Block.remove('.intern-table');
    }
  }, [choosenRow]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState([] as any[]);
  const table = useMemo(() => {
    return (
      <ContainerTable>
        <Table
          items={tableData}
          key="demonstrativo-e-segunda-via-do-boleto"
          className="notiflix-table-loading"
          columnResizeMode="fit"
          id="demonstrativo-e-segunda-via-do-boleto"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows([e.data[e.data.length - 1]])}
          onRowClick={(e) => {
            if (
              expandedRows.length > 0 &&
              expandedRows[0]?.titleNumber !== e.data?.titleNumber
            ) {
              setExpandedRows([]);
              return setExpandedRows([e.data]);
            }
            return expandedRows.length === 0
              ? setExpandedRows([e.data])
              : setExpandedRows([]);
          }}
          rowExpansionTemplate={rowExpansionTemplate}
          onRowExpand={(e) => {
            if (window.innerWidth <= 768) {
              setPositionOfRowExpanded(e.originalEvent);
            }
          }}
          scrollable={window.innerWidth > 768}
        >
          <Column
            field="payerName"
            header="Pagador"
            sortable
            style={{ width: '200px' }}
            body={(data) => addField(data.payerName, 'Pagador')}
          />
          <Column
            field="competitionDate"
            header="Competência"
            className="date"
            body={(data) => addField(data.competitionDate, 'Competência')}
          />
          <Column
            field="expirationDate"
            header="Vencimento"
            className="date"
            body={(data) => addField(data.expirationDate, 'Data de Vencimento')}
          />
          <Column
            field="liquidationDate"
            header="Liquidação"
            className="date"
            body={(data) =>
              addField(data.liquidationDate, 'Data de Liquidação')
            }
          />
          <Column
            field="amountOfDaysDelay"
            header="Dias de atraso"
            style={{ width: '100px' }}
            className="date"
            body={(data) => addField(data.amountOfDaysDelay, 'Dias de atraso')}
          />
          <Column
            field="titleNumber"
            header="Título"
            sortable
            style={{ width: '100px' }}
            body={(data) => addField(data.titleNumber, 'Título')}
          />
          <Column
            field="tuitionValue"
            header="Valor do Título"
            className="currency"
            style={{ width: '150px' }}
            sortable
            body={(data) =>
              addField(data.tuitionValue, 'Valor do Título', true)
            }
          />
          <Column
            field="coparcenaryValue"
            header="Valor de Coparticipação"
            className="currency"
            sortable
            body={(data) =>
              addField(data.coparcenaryValue, 'Valor de Coparticipação', true)
            }
          />
          <Column
            field="invoive"
            header="Nota fiscal"
            className="invoice"
            sortable
            style={{ width: '90px' }}
            body={(data) =>
              addField(
                data.NFE,
                'Nota Fiscal',
                true,
                '',
                data.NFE ? 'Disponível' : '',
                !data.NFE ? 'Processando' : '',
              )
            }
          />
          <Column
            field="ieOpen"
            header="Situação"
            className="tag"
            style={{ width: '100px' }}
            body={(data) => situationTemplate(data.situation)}
          />
          <Column header="" expander style={{ width: '40px' }} />
        </Table>
      </ContainerTable>
    );
  }, [expandedRows, rowExpansionTemplate, situationTemplate, tableData]);

  const internTable = useMemo(() => {
    return (
      <Table
        items={internTableData}
        key="internTable"
        className="intern-table"
        columnResizeMode="fit"
        id="internTable"
        scrollable={window.innerWidth > 768}
      >
        <Column
          field="insuredName"
          header="Segurado"
          sortable
          body={(data) => addField(data.insuredName, 'Segurado')}
        />
        <Column
          field="holderName"
          header="Titular"
          sortable
          body={(data) => addField(data.holderName, 'Titular')}
        />
        <Column
          field="tuitionValue"
          header="Valor do Título"
          sortable
          className="currency"
          body={(data) => addField(data.tuitionValue, 'Valor do Título', true)}
        />
        <Column
          field="additionalValue"
          header="Valores adicionais"
          sortable
          className="currency"
          body={(data) =>
            addField(data.additionalValue, 'Valores adicionais', true)
          }
        />
        <Column
          field="coparcenaryValue"
          header="Valores de Coparticipação"
          className="currency"
          sortable
          body={(data) =>
            addField(data.coparcenaryValue, 'Valores de Coparticipação', true)
          }
        />
      </Table>
    );
  }, [internTableData]);

  useEffect(() => {
    getData();
    handleDuplicateSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateColumns="1fr 8fr 1fr">
      <Steps defineHeight={defineHeightOfSteps}>
        <Step1 show={actualStep === 1} ref={step1Ref}>
          <Title themeColor={defaultColor}>
            Demonstrativo e segunda via do boleto
          </Title>
          <TableContainer>{table}</TableContainer>
        </Step1>
        <Step2 show={actualStep === 2} ref={step2Ref}>
          <Title themeColor={defaultColor}>
            Demonstrativo e segunda via do boleto
          </Title>
          <TitleIntern themeColor={defaultColor}>
            Detalhe do demonstrativo
          </TitleIntern>
          <FormWrapper
            ref={step2formRef}
            formLook
            onSubmit={() => console.log('N/A')}
          >
            <Input
              widthContainerDesktop="50%"
              formLook
              title="Beneficiário (carteirinha)"
              name="cardCode"
              themeColor={defaultColor}
            />
            <Input
              widthContainerDesktop="50%"
              formLook
              title="Código família"
              name="familyCode"
              themeColor={defaultColor}
            />
            <ButtonsWrapper>
              <ButtonElement
                formLook
                secondary
                onClick={() => setActualStep(1)}
              >
                Voltar
              </ButtonElement>
              <ButtonElement greenPrimary formLook onClick={handleFilter}>
                Consultar
              </ButtonElement>
            </ButtonsWrapper>
          </FormWrapper>
          {internTable}
        </Step2>
      </Steps>
    </PageWrapper>
  );
};

export default CompanyDemonstrativeAndDuplicate;
