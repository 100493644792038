import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { ContainerCheck, ContainerLabel, Error } from './styles';

interface checkboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  name: string;
  value?: string;
  noShowValue?: boolean;
  themeColor?: string;
}

const Checkbox: React.FC<checkboxProps> = ({
  className,
  value,
  name,
  noShowValue,
  title,
  themeColor,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  const defaultChecked = defaultValue === value;
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked;
      },
      clearValue: (ref) => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        // eslint-disable-next-line no-param-reassign
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, v) => {
        clearError();
        // eslint-disable-next-line no-param-reassign
        ref.current.checked = v;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked, clearError]);

  return (
    <ContainerLabel className={className}>
      <ContainerCheck themeColor={themeColor}>
        <input
          defaultChecked={defaultChecked}
          ref={inputRef}
          value={value}
          type="checkbox"
          id={fieldName}
          {...rest}
        />
      </ContainerCheck>
      {!noShowValue && (title || value)}
      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </ContainerLabel>
  );
};

export default Checkbox;
