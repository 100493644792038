import styled from 'styled-components';
import TableComponent, { Column } from '../../components/Table';
import FormWrapper from '../../components/FormWrapper';
import Button from '../../components/Button';

export const Table = styled(TableComponent)`
  @media (max-width: 768px) {
    grid-column: 1;
    margin-bottom: 30px;
  }
`;

export const SearchBarContainer = styled(FormWrapper)`
  display: flex;
  flex-wrap: wrap;

  > button {
    min-width: 150px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  margin-bottom: 24px;
`;

export const SearchButton = styled(Button)``;

export const Container = styled.div`
  grid-column: 2/10;
  display: grid;
  row-gap: 8px;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Title = styled.h1`
  align-self: center;
  grid-column: 2/10;
  text-transform: uppercase;
  font-size: 2.55rem;
  line-height: 2.4rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.palet.institutional};
  text-align: center;
  padding: 42px;

  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 32px;
  }
`;

export const PaymentPreviewColumn = styled(Column)``;

export const Tag = styled.span`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.7rem;
  position: relative;
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.cancelado {
    color: ${(props) => props.theme.colors.palet.institutional7};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional7};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional7};
      }
    }
  }

  &.solicitado {
    color: ${(props) => props.theme.colors.palet.institutional6};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional6};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional6};
      }
    }
  }
  &.confirmado {
    color: ${(props) => props.theme.colors.palet.institutional};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional};
      }
    }
  }
`;
