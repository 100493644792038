import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Notiflix from 'notiflix';
import Input from '../../components/Input';

import {
  Container,
  Background,
  Content,
  ContainerFooter,
  BemVindo,
  BemVindoMobile,
  ContentMobile,
  ModalContent,
  ModalButton,
  ButtonLogin,
  ContainerLogoW,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { SelectHandles } from '../../components/Select';
import { GrupowSvg, LogoUnimedSvg } from '../../components/Svg';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api/api';
import Modal2 from '../../components/Modal2';
import { ObjectGetValueOfKey } from '../../utils/formatt';
import { getParams } from '../../utils/Url';

const Login: React.FC = () => {
  const { login, hasPrimaryToken } = useAuth();
  const history = useHistory();
  const typeUserRef = useRef<SelectHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const [typeUser, setTypeUser] = useState('2');

  const handleToogleModal = useCallback(async () => {
    try {
      Notiflix.Loading.circle();
      const { data } = await api.get(
        '/gw/translation?key=termo-de-cookies&language=PT',
      );
      const { content } = data;
      const text = `${ObjectGetValueOfKey(content, 'termo-de-cookies')}`;

      Modal2.Generic({
        closable: false,
        children: (
          <>
            {/* <ModalTitle>Aviso de Cookies</ModalTitle> */}
            <ModalContent dangerouslySetInnerHTML={{ __html: text }} />
            <ModalButton
              onClick={() => Modal2.Close()}
              modal
              outlined
              autoWidth
            >
              Fechar
            </ModalButton>
          </>
        ),
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Estamos com algum erro no servidor... tente mais tarde...',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, []);

  const handleFormSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          document: Yup.string().required('Informe seu usuário'),
          password: Yup.string().required('Informe sua senha'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        Notiflix.Loading.circle();
        const toSend = {
          user: data.document,
          password: data.password,
          typeUser,
        };
        const success = await login(toSend);
        if (success) {
          history.push('/');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (
          err.response?.data?.message &&
          err.response?.status !== 503
        ) {
          formRef.current?.setFieldError(
            'document',
            'Usuario e/ou senha inválidos',
          );
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos nos conectar ao servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Loading.remove();
      }
    },
    [typeUser, login, history],
  );

  const verifyHomologation = useCallback(async () => {
    const { hash } = getParams();
    if (hash) {
      try {
        Notiflix.Loading.circle('Autenticando...');
        await api.get(`/user/activation-by-clicking-on-email?uid=${hash}`);

        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          text: 'Você foi homologado com sucesso!',
        });
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            subtitle: 'Ops... algo deu errado.',
            text: err.response.data.message,
          });
        } else {
          Modal2.Failure({
            closable: true,
            subtitle: 'Ops... algo deu errado.',
            text: 'Algo aconteceu com seu Link de Homologação. Não conseguimos prosseguir.',
          });
        }
      } finally {
        Notiflix.Loading.remove();
        window.location.search = '';
      }
    }
  }, []);

  useEffect(() => {
    if (hasPrimaryToken) {
      verifyHomologation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPrimaryToken]);

  useEffect(() => {
    const userType = localStorage.getItem('@userType');
    if (!userType) {
      return;
    }

    typeUserRef.current?.setValue('Empresa', userType);

    setTypeUser(userType);
  }, []);

  const bemVindoDesktop = useMemo(() => {
    if (typeUser === '2') {
      return <BemVindo>Portal das Empresas</BemVindo>;
    }
    return <></>;
  }, [typeUser]);

  const bemVindoMobile = useMemo(() => {
    if (typeUser === '2') {
      return <BemVindoMobile>Portal das Empresas</BemVindoMobile>;
    }
    return <></>;
  }, [typeUser]);

  return (
    <Container>
      <Background background={typeUser}>
        {bemVindoDesktop}
        <LogoUnimedSvg />
        <ContainerLogoW>
          <a
            href="https://www.grupow.com.br/"
            title="GrupoW Softwares para Internet"
            target="_blanck"
          >
            Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
            <GrupowSvg />
          </a>
        </ContainerLogoW>
      </Background>
      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <ContentMobile background={typeUser}>
            <h2>Digite seus dados</h2>
            <LogoUnimedSvg />
            {bemVindoMobile}

            <Input name="document" placeholder="Usuário" />

            <Input name="password" type="password" placeholder="Senha" />
          </ContentMobile>

          <ButtonLogin type="submit">Entrar</ButtonLogin>
          <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
        </Form>

        <ContainerFooter>
          <button type="button" onClick={handleToogleModal}>
            Aviso de Cookies
          </button>
          <ContainerLogoW className="mobile">
            <a
              href="https://www.grupow.com.br/"
              title="GrupoW Softwares para Internet"
              target="_blanck"
            >
              Desenvolvido por <strong>GrupoW Softwares para Internet</strong>
              <GrupowSvg />
            </a>
          </ContainerLogoW>
        </ContainerFooter>
      </Content>
    </Container>
  );
};

export default Login;
