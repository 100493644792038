import styled from 'styled-components';
import FormWrapper from '../../../components/FormWrapper';
import InputFileElement from '../../../components/InputFile';

export const InputFile = styled(InputFileElement)`
  grid-column: 1/3;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;
export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;
  grid-column: 2/3;

  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional6};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    grid-column: 1;
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const TitleIntern = styled.h2`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1.6rem;
  line-height: 1.6rem;

  text-align: left;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  margin-bottom: 8px;
  grid-column: 2/3;
  @media (max-width: 768px) {
    grid-column: 1;
    font-family: 'Unimed-Sans-Bold';
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
  }
`;

export const Form = styled(FormWrapper)`
  grid-column: 2/3;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  > button {
    width: 150px;
    text-transform: uppercase;

    & + button {
      margin-left: auto;
    }
  }

  @media (max-width: 768px) {
    grid-column: 1;
  }
`;
