/* eslint-disable max-len */
import styled, { css } from 'styled-components';

export const ContainerLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.text.grayishToWhite};
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 1rem;
  position: relative;
  > div {
    margin-right: 8px;
  }
`;

interface ContainerCheckProps {
  themeColor?: string;
}

export const ContainerCheck = styled.div<ContainerCheckProps>`
  border: 1px solid #c4cbcf;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  input {
    border: none;
    outline: none;
    appearance: none;
    position: relative;

    width: 100%;
    height: 100%;
    transition: background 300ms;
    &:checked {
      background: ${(props) => props.theme.colors.palet.institutional3};
      ${(props) =>
        props.themeColor &&
        css`
          background: ${props.themeColor};
        `}
    }

    &:checked::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.293056 5.72123C-0.600029 4.68471 0.750382 3.09982 1.64331 4.13634L4.3843 7.30374L10.3502 0.340876C11.2433 -0.701482 12.601 0.883217 11.7079 1.92558L5.06654 9.67267C4.69361 10.1081 4.08874 10.1094 3.71441 9.67485L0.293056 5.72123Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  & + div {
    margin-top: 8px;
  }
`;

export const Error = styled.div`
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.36333 1.9188L1.25652 15.4525C1.08903 15.7396 1.00097 16.0746 1.00001 16.4C0.999051 16.735 1.08519 17.07 1.25077 17.3572C1.41635 17.6443 1.65563 17.8931 1.94372 18.0558C2.23181 18.2281 2.55915 18.3239 2.89319 18.3239H19.1068C19.4408 18.3239 19.7682 18.2281 20.0563 18.0558C20.3444 17.8931 20.5836 17.6443 20.7492 17.3572C20.9148 17.07 21.0009 16.735 21 16.4C20.999 16.0746 20.911 15.7396 20.7435 15.4525L12.6367 1.9188C12.4663 1.64123 12.2261 1.40199 11.9389 1.23928C11.6527 1.08614 11.3292 1 11 1C10.6708 1 10.3473 1.08614 10.0611 1.23928C9.77394 1.40199 9.5337 1.64123 9.36333 1.9188Z' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 6.83853V10.667' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 14.4955H11.01' stroke='%23ED1651' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;

  span {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: 300ms !important;
    position: absolute;
    white-space: nowrap;
    right: -20px;
    top: -54px;
    background: ${(props) => props.theme.colors.palet.institutional7};
    color: ${(props) => props.theme.colors.text.inverse};
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
    padding: 6px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(255, 15, 100, 0.3);

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 20px;
      border: 5px solid ${(props) => props.theme.colors.palet.institutional7};
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &:hover {
    span {
      transition: 300ms;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
