import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Content,
  Wrapper,
  Text404,
  Text,
  ButtonLogin,
  LogoUnimed,
  DevTo,
} from './styles';
import ImgUnimed from '../../assets/images/logoUnimedLit.png';
import logoW from '../../assets/images/logoGrupoW.png';

const Error404: React.FC = () => {
  const history = useHistory();

  return (
    <Content>
      <Wrapper>
        <Text404>404</Text404>
        <Text>Página não encontrada.</Text>
      </Wrapper>
      <ButtonLogin
        onClick={() => {
          history.push('/login');
        }}
      >
        Voltar para a tela de login
      </ButtonLogin>
      <LogoUnimed>
        <img src={ImgUnimed} alt="Erro" />
      </LogoUnimed>
      <DevTo>
        <a href="https://grupow.com.br">
          Desenvolvido por <strong>GrupoW Softwares para Internet</strong>{' '}
          <img src={logoW} alt="" />
        </a>
      </DevTo>
    </Content>
  );
};

export default Error404;
