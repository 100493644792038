import styled from 'styled-components';

export const ContainerDesktop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${(props) => props.theme.colors.text.whiteToGrayish};
  position: relative;

  @media (min-width: 769px) {
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
      left: -20px;
      bottom: 0;
      background: ${(props) => props.theme.colors.text.whiteToGrayish};
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Label = styled.label`
  font-family: 'Unimed-Sans-Bold';
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text.grayishToLightGray};
  > a {
    color: ${(props) => props.theme.colors.text.grayishToLightGray};
    font-family: 'Unimed-Sans-Book';
    text-decoration: none;
    margin-left: 4px;
  }
  &.laura {
    > a {
      display: flex;
      align-items: center;
      font-family: 'Unimed-Sans-Bold';
      > svg {
        width: 15px;
        margin-right: 6px;
        > path {
          fill: ${(props) => props.theme.colors.text.grayishToLightGray};
        }
      }
    }
  }

  & + label {
    margin-left: 16px;
  }
  @media (max-width: 1110px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
  }
`;

export const Address = styled.div`
  font-size: 0.65rem;
  font-family: 'Unimed-Sans-Book';
  margin-left: 80px;
  p,
  a {
    color: ${(props) => props.theme.colors.text.grayishToLightGray};
    font-family: 'Unimed-Sans-Book';
    font-size: 0.65rem;
    text-decoration: none;
    line-height: 0.8rem;
  }
  a {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 2px;
    }
  }
  > div {
    display: flex;
    column-gap: 4px;
  }
  @media (max-width: 1220px) {
    margin-left: auto;
  }
`;

export const ANSContainer = styled.div`
  background: #18181a;
  border-radius: 0px;
  display: flex;
  padding: 5px;
  margin-left: auto;
  margin-right: 16px;
  p {
    font-family: 'Unimed-Sans-SemiBold';
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text.white};
    text-decoration: none;
    padding: 4px 12px;
    border: 2px solid #fff;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 769px) and (max-width: 900px) {
    > p {
      font-size: 0.7rem;
    }
  }
`;

export const ContainerMobile = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  padding: 0 30px;
  justify-content: space-between;

  &.grupow {
    height: 40px;
    background: #fff;
    justify-content: center;
    > a {
      font-family: 'Unimed-Sans-Book';
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 0.65rem;
      color: ${(props) => props.theme.colors.palet.institutional11};
      column-gap: 5px;
      > svg {
        transform: translateY(-4px);
        path {
          stroke: ${(props) => props.theme.colors.palet.institutional11};
        }
      }
      > strong {
        font-family: 'Unimed-Sans-SemiBold';
      }
    }
  }
  &:nth-child(1) {
    background: ${(props) => props.theme.colors.palet.institutional2};
  }
  &:nth-child(2) {
    background: ${(props) => props.theme.colors.palet.institutional13};
  }
`;

export const LabelMobile = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    path {
      stroke: ${(props) => props.theme.colors.text.white};
    }
  }
  > div {
    > p {
      font-size: 0.65rem;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.text.white};
    }
    > a {
      font-size: 1rem;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.palet.institutional4};
      text-decoration: none;
    }
  }

  &.laura {
    > svg {
      width: 23px;
      height: 33px;
      path {
        fill: ${(props) => props.theme.colors.palet.institutional6};
        stroke: ${(props) => props.theme.colors.palet.institutional6};
      }
    }
    > a {
      font-size: 1rem;
      font-family: 'Unimed-Sans-Bold';
      color: ${(props) => props.theme.colors.palet.institutional6};
      text-decoration: none;
      text-transform: uppercase;
    }
  }
`;
