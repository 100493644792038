/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { ptBR } from 'date-fns/locale';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Notiflix from 'notiflix';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';
import PageWrapper from '../../components/PageWrapper';

import Title from '../../components/Title';

import {
  Container,
  FormWrapper,
  Steps,
  Step1,
  Step2,
  CheckboxGroup,
  TitleIntern,
  Step3,
} from './styles';
import Input from '../../components/Input';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import Select, { SelectHandles } from '../../components/Select';
import getValidationErrors from '../../utils/getValidationErrors';
import { dateToPTBR } from '../../utils/formatt';
import Modal2 from '../../components/Modal2';
import Translation from '../../services/api/Translation';

const Ombudsman: React.FC = () => {
  const { colors } = useTheme();

  const [heightStep, setHeightStep] = useState(['']);
  const [actualStep, setActualStep] = useState(1);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);

  const formStep2Ref = useRef<FormHandles>(null);
  const manifestationRef = useRef<SelectHandles>(null);
  const subjectRef = useRef<SelectHandles>(null);

  const formStep3Ref = useRef<FormHandles>(null);
  const [initialRegisterDataStep3, setInitialRegisterDataStep3] = useState<any>(
    {},
  );
  const [initialFactOccurrenceDataStep3, setInitialFactOccurrenceDataStep3] =
    useState<any>({});
  const [initialFactOccurrenceData2Step3, setInitialFactOccurrenceData2Step3] =
    useState<any>({});

  const [dateStep2, setDateStep2] = useState(new Date());
  const [dateStep2Error, setDateStep2Error] = useState('');
  const [timeStep2, setTimeStep2] = useState(new Date());
  const [timeStep2Error, setTimeStep2Error] = useState('');
  const attachmentRef = useRef<InputFileHandles>(null);

  const defineHeightOfSteps = useMemo(() => {
    switch (actualStep) {
      case 1:
        return heightStep[0];
      case 2:
        return heightStep[1];
      case 3:
        return heightStep[2];
      default:
        return '0px';
    }
  }, [actualStep, heightStep]);

  const calculateHeightOfSteps = useCallback(() => {
    setHeightStep([
      `${step1Ref.current?.scrollHeight}px`,
      `${step2Ref.current?.scrollHeight}px`,
      `${step3Ref.current?.scrollHeight}px`,
    ]);
  }, []);

  useLayoutEffect(() => {
    calculateHeightOfSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitStep2 = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-step2-ombudsman');
        formStep2Ref.current?.setErrors({});
        setDateStep2Error('');
        setTimeStep2Error('');

        const schema = Yup.object().shape({
          involved: Yup.string().required('Informe os envolvidos'),
          description: Yup.string().required('informe uma descrição'),
          name: Yup.string().required('Nome obrigatório'),
          CPF: Yup.number()
            .typeError('CPF obrigatório')
            .min(100000000, 'Informe um CPF válido'),
          email: Yup.string()
            .required('Informe um email')
            .email('Informe um email válido'),
          tel: Yup.number()
            .typeError('Informe um telefone')
            .min(1000000, 'Informe um telefone válido'),
        });
        if (!dateStep2 || !timeStep2) {
          setDateStep2Error(!dateStep2 ? 'Data obrigatória' : '');
          setTimeStep2Error(!timeStep2 ? 'Hora obrigatória' : '');
        }
        await schema.validate(data, {
          abortEarly: false,
        });
        if (!dateStep2 || !timeStep2) {
          return;
        }

        const attachments = attachmentRef.current?.files;
        const date = dateToPTBR(dateStep2);
        const time = `${timeStep2
          .getHours()
          .toString()
          .padStart(2, '0')}:${timeStep2
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;
        const manifestation = manifestationRef.current?.value;
        const subject = subjectRef.current?.value;

        // const { data: dataApi } = await api.put('/');
        const protocol = '123123123123123';
        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          subtitle: `Protocolo gerado: ${protocol}`,
          text: 'Enviamos algumas informaçõs para você via email.',
          children: (
            <>
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            </>
          ),
        });
        setActualStep(1);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formStep2Ref.current?.setErrors(errors);
        } else {
          Notiflix.Notify.failure(
            'Ops, algo deu errado. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-step2-ombudsman');
      }
    },
    [dateStep2, timeStep2],
  );

  const handleSubmitStep3 = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-data-step3');

        // const { data: dataApi } = await api.get(`/ouvidoria/consulta?manifestationCode=${data.manifestationCode}&protocolService=${data.protocolService}`)

        setInitialRegisterDataStep3({
          protester: `manifestante ${new Date().getSeconds()}`,
          date: `30/12/2020 ${new Date().getSeconds()}`,
          protocolService: `12312312312312 ${new Date().getSeconds()}`,
          hour: `12:12 ${new Date().getSeconds()}`,
          code: `12312 ${new Date().getSeconds()}`,
          identification: `Jonathan CR ${new Date().getSeconds()}`,
          classification: `Dev Front ${new Date().getSeconds()}`,
          entry: `entrada ${new Date().getSeconds()}`,
          response: `responsta ${new Date().getSeconds()}`,
          manifestationType: `Pacífico ${new Date().getSeconds()}`,
          subject: `Trabalho ${new Date().getSeconds()}`,
          subSubject: `Sem mais.. ${new Date().getSeconds()}`,
        });
        setInitialFactOccurrenceDataStep3({
          unit: `Balneário Camboriú ${new Date().getSeconds()}`,
          date: `10/12/2021 ${new Date().getSeconds()}`,
          hour: `13:30 ${new Date().getSeconds()}`,
          involved: `Toda a equipe do frontend ${new Date().getSeconds()}`,
          description: `Não fizeram café e entraram em atrito ${new Date().getSeconds()}`,
        });
        setInitialFactOccurrenceData2Step3({
          receivingDate: `31/12/2021 ${new Date().getSeconds()}`,
          date: `30/11/2020 ${new Date().getSeconds()}`,
          situation: `Em aberto ${new Date().getSeconds()}`,
          deadline: `30 dias úteis ${new Date().getSeconds()}`,
          responsibleUnit: `Balneário Camboriú ${new Date().getSeconds()}`,
        });

        calculateHeightOfSteps();
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops. Não conseguimos buscar os dados do servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.circle('.notiflix-data-step3');
        Notiflix.Block.remove('.notiflix-data-step3');
      }
    },
    [calculateHeightOfSteps],
  );

  const getDataForStep3 = useCallback(async () => {
    setInitialRegisterDataStep3({
      protester: 'manifestante',
      date: '30/12/2020',
      protocolService: '12312312312312',
      hour: '12:12',
      code: '12312',
      identification: 'Jonathan CR',
      classification: 'Dev Front',
      entry: 'entrada',
      response: 'responsta',
      manifestationType: 'Pacífico',
      subject: 'Trabalho',
      subSubject: 'Sem mais..',
    });
    setInitialFactOccurrenceDataStep3({
      unit: 'Balneário Camboriú',
      date: '10/12/2021',
      hour: '13:30',
      involved: 'Toda a equipe do frontend',
      description: 'Não fizeram café e entraram em atrito',
    });
    setInitialFactOccurrenceData2Step3({
      receivingDate: '31/12/2021',
      date: '30/11/2020',
      situation: 'Em aberto',
      deadline: '30 dias úteis',
      responsibleUnit: 'Balneário Camboriú',
    });
  }, []);

  useEffect(() => {
    if (actualStep === 3) {
      getDataForStep3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualStep]);

  useEffect(() => {
    window.addEventListener('resize', calculateHeightOfSteps);
    return () => {
      window.removeEventListener('resize', calculateHeightOfSteps);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <Container>
        <Title themeColor={colors.palet.institutional}>Ouvidoria</Title>
        <FormWrapper
          formLook
          onSubmit={() => {
            'N/A';
          }}
        >
          <h2>Bem-vindo(a) à Ouvidoria Unimed Litoral</h2>
          <Translation
            tag="beneficiario-ouvidoria"
            className="normalize-text"
          />
          <Button
            formLook
            // grayButton
            greenPrimary
            autoWidth
            type="button"
            onClick={() =>
              window.open(
                'https://www.unimed.coop.br/documents/1001528/6195999/Manual-da-Ouvidoria-2018.pdf/83745a9f-ac23-42b7-9189-587057641b67',
                '_blank',
              )
            }
          >
            Manual da Ouvidoria
          </Button>
          <Button
            formLook
            grayButton
            autoWidth
            type="button"
            onClick={() =>
              window.open(
                'http://www.unimedlitoral.com.br/ouvidoria/novamanifestacao.html',
              )
            }
          >
            Registrar Manifestação
          </Button>
          <Button
            formLook
            grayButton
            autoWidth
            type="button"
            onClick={() =>
              window.open(
                'http://www.unimedlitoral.com.br/ouvidoria/consultaandamento.html',
              )
            }
          >
            Consultar Manifestação
          </Button>
        </FormWrapper>

        {/* <Steps defineHeight={defineHeightOfSteps}>
          <Step1 show={actualStep === 1} ref={step1Ref}>
            <FormWrapper
              formLook
              onSubmit={() => {
                'N/A';
              }}
            >
              <h2>Bem-vindo(a) à Ouvidoria Unimed Litoral</h2>
              <Translation
                tag="beneficiario-ouvidoria"
                className="normalize-text"
              />
              <Button
                formLook
                // grayButton
                greenPrimary
                autoWidth
                type="button"
                onClick={() =>
                  window.open(
                    'https://www.unimed.coop.br/documents/1001528/6195999/Manual-da-Ouvidoria-2018.pdf/83745a9f-ac23-42b7-9189-587057641b67',
                    '_blank',
                  )
                }
              >
                Manual da Ouvidoria
              </Button>
              <Button
                formLook
                grayButton
                autoWidth
                type="button"
                onClick={() =>
                  window.open(
                    'http://www.unimedLitoral.com.br/ouvidoria/novamanifestacao.html',
                  )
                }
              >
                Registrar Manifestação
              </Button>
              <Button
                formLook
                grayButton
                autoWidth
                type="button"
                onClick={() =>
                  window.open(
                    'http://www.unimedLitoral.com.br/ouvidoria/consultaandamento.html',
                  )
                }
              >
                Consultar Manifestação
              </Button>
            </FormWrapper>
          </Step1>
          <Step2 show={actualStep === 2} ref={step2Ref}>
            <TitleIntern>Registrar Manifestação</TitleIntern>
            <FormWrapper
              onSubmit={handleSubmitStep2}
              formLook
              ref={formStep2Ref}
            >
              <CheckboxGroup>
                <Checkbox name="reanalyze" value="Reanalise" />
              </CheckboxGroup>
              <DatePicker
                name="date"
                selected={dateStep2}
                error={dateStep2Error}
                onChange={(date: Date) => setDateStep2(date)}
                formLook
                title="Data:"
                widthContainerDesktop="20%"
              />
              <DatePicker
                name="time"
                selected={timeStep2}
                onChange={(data: Date) => setTimeStep2(data)}
                error={timeStep2Error}
                locale={ptBR}
                showTimeSelect
                showTimeSelectOnly
                timeFormat="p"
                timeIntervals={15}
                dateFormat="HH:mm"
                timeCaption="Hora"
                formLook
                title="Hora:"
                widthContainerDesktop="20%"
              />
              <Input
                formLook
                name="involved"
                title="Envolvidos:"
                widthContainerDesktop="30%"
              />
              <Input
                formLook
                name="description"
                title="Descrição:"
                widthContainerDesktop="100%"
              />
              <InputFile
                ref={attachmentRef}
                className="input-file"
                placeholder="Anexo"
              />
              <Select
                formLook
                name="manifestation"
                title="Manifestação:"
                widthContainerDesktop="50%"
                ref={manifestationRef}
                options={[
                  {
                    title: 'Denúncia',
                    value: 'denunciation',
                  },
                  {
                    title: 'Elogio',
                    value: 'praise',
                  },
                  {
                    title: 'Informação',
                    value: 'information',
                  },
                  {
                    title: 'Sugestão',
                    value: 'suggestion',
                  },
                  {
                    title: 'Reclamação',
                    value: 'complaint',
                  },
                ]}
              />
              <Select
                formLook
                name="subject"
                title="Assunto:"
                widthContainerDesktop="50%"
                ref={subjectRef}
                options={[
                  {
                    title: 'Administrativo',
                    value: 'administrative',
                  },
                  {
                    title: 'Cobertura assistencial',
                    value: 'assistanceCoverage',
                  },
                  {
                    title: 'Financeiro',
                    value: 'financial',
                  },
                  {
                    title: 'Rede Credenciada/Referenciada',
                    value: 'accredited/referencedNetwork',
                  },
                  {
                    title: 'SAQ',
                    value: 'SAQ',
                  },
                  {
                    title: 'Outro',
                    value: 'other',
                  },
                ]}
              />
              <Input
                formLook
                name="firstInstanceProtocolCode"
                title="Protocolo 1ª Instância - Código:"
                widthContainerDesktop="30%"
              />
              <Input
                formLook
                name="previousManifestationCode"
                title="Manifestação anterior - Código:"
                widthContainerDesktop="30%"
              />
              <Input
                formLook
                name="name"
                title="Nome:"
                widthContainerDesktop="40%"
              />
              <Input
                formLook
                name="CPF"
                title="CPF:"
                widthContainerDesktop="30%"
                masks={['999.999.999-99']}
              />
              <Input
                formLook
                name="email"
                title="E-mail:"
                widthContainerDesktop="70%"
              />
              <Input
                formLook
                name="tel"
                title="Telefone 1:"
                widthContainerDesktop="50%"
                masks={[
                  '(99) 9999-9999',
                  '(99) 9 9999-9999',
                  '(+99) 99 9 9999-9999',
                ]}
              />
              <Input
                formLook
                name="tel2"
                title="Telefone 2:"
                widthContainerDesktop="50%"
                masks={[
                  '(99) 9999-9999',
                  '(99) 9 9999-9999',
                  '(+99) 99 9 9999-9999',
                ]}
              />
              <Button
                formLook
                secondary
                autoWidth
                type="button"
                onClick={() => setActualStep(1)}
              >
                Voltar
              </Button>
              <Button
                className="notiflix-step2-ombudsman"
                formLook
                grayButton
                autoWidth
                type="submit"
              >
                Enviar
              </Button>
            </FormWrapper>
          </Step2>
          <Step3 show={actualStep === 3} ref={step3Ref}>
            <TitleIntern>Consultar Manifestação</TitleIntern>
            <FormWrapper
              onSubmit={handleSubmitStep3}
              formLook
              ref={formStep3Ref}
            >
              <Input
                name="manifestationCode"
                formLook
                title="Código da Manifestação:"
                widthContainerDesktop="50%"
              />
              <Input
                name="protocolService"
                formLook
                title="Protocolo de Atendimento:"
                widthContainerDesktop="50%"
              />
              <Button
                formLook
                secondary
                autoWidth
                type="button"
                onClick={() => setActualStep(1)}
              >
                Voltar
              </Button>
              <Button
                className="notiflix-step2-ombudsman"
                formLook
                grayButton
                autoWidth
                type="submit"
              >
                Buscar
              </Button>
            </FormWrapper>

            <TitleIntern>Dados do cadastro</TitleIntern>
            <FormWrapper
              onSubmit={() => console.log('N/A')}
              initialData={initialRegisterDataStep3}
              className="notiflix-data-step3"
            >
              <Input
                name="protester"
                insideInputTitle="Manifestante"
                widthContainerDesktop="40%"
              />
              <Input
                name="date"
                insideInputTitle="Data"
                widthContainerDesktop="20%"
              />
              <Input
                name="protocolService"
                insideInputTitle="Protocolo de atendimento"
                widthContainerDesktop="40%"
              />
              <Input
                name="hour"
                insideInputTitle="Hora"
                widthContainerDesktop="20%"
              />
              <Input
                name="code"
                insideInputTitle="Código"
                widthContainerDesktop="30%"
              />
              <Input
                name="identification"
                insideInputTitle="Identificação"
                widthContainerDesktop="50%"
              />
              <Input
                name="classification"
                insideInputTitle="Classificação"
                widthContainerDesktop="60%"
              />
              <Input
                name="entry"
                insideInputTitle="Entrada"
                widthContainerDesktop="40%"
              />
              <Input
                name="response"
                insideInputTitle="Resposta"
                widthContainerDesktop="40%"
              />
              <Input
                name="manifestationType"
                insideInputTitle="Tipo de manifestante"
                widthContainerDesktop="60%"
              />
              <Input
                name="subject"
                insideInputTitle="Assunto"
                widthContainerDesktop="50%"
              />
              <Input
                name="subSubject"
                insideInputTitle="Sub-assunto"
                widthContainerDesktop="50%"
              />
            </FormWrapper>

            <TitleIntern>Dados da ocorrência do fato</TitleIntern>
            <FormWrapper
              onSubmit={() => console.log('N/A')}
              initialData={initialFactOccurrenceDataStep3}
              className="notiflix-data-step3"
            >
              <Input
                name="unit"
                insideInputTitle="Unidate"
                widthContainerDesktop="60%"
              />
              <Input
                name="date"
                insideInputTitle="Data"
                widthContainerDesktop="20%"
              />
              <Input
                name="hour"
                insideInputTitle="Hora"
                widthContainerDesktop="20%"
              />
              <Input
                name="involved"
                insideInputTitle="Envolvidos"
                widthContainerDesktop="40%"
              />
              <Input
                name="description"
                insideInputTitle="Descrição"
                widthContainerDesktop="60%"
              />
            </FormWrapper>
            <FormWrapper
              onSubmit={() => console.log('N/A')}
              initialData={initialFactOccurrenceData2Step3}
              className="notiflix-data-step3"
            >
              <Input
                name="receivingDate"
                insideInputTitle="Data recebimento"
                widthContainerDesktop="20%"
              />
              <Input
                name="date"
                insideInputTitle="Data"
                widthContainerDesktop="20%"
              />
              <Input
                name="situation"
                insideInputTitle="Situação"
                widthContainerDesktop="30%"
              />
              <Input
                name="deadline"
                insideInputTitle="Prazo"
                widthContainerDesktop="30%"
              />
              <Input
                name="responsibleUnit"
                insideInputTitle="Unidade responsável"
                widthContainerDesktop="100%"
              />
            </FormWrapper>
          </Step3>
        </Steps> */}
      </Container>
    </PageWrapper>
  );
};

export default Ombudsman;
