import Notiflix from 'notiflix';
import { title } from 'process';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import FormWrapper from '../../components/FormWrapper';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import Modal2 from '../../components/Modal2';
import Select, { SelectHandles } from '../../components/Select';
import { useAuth } from '../../hooks/auth';
import { useContract } from '../../hooks/contract';
import api from '../../services/api/api';
import { dateToPTBR } from '../../utils/formatt';

interface ChangeContractProps {
  defaultColor: string;
  callbackSetStep: (prev: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowsSelecteds: any[];
}

interface DateProps {
  value: Date | null;
  error: string;
}

export function ChangeContract({
  defaultColor,
  callbackSetStep,
  rowsSelecteds,
}: ChangeContractProps): JSX.Element {
  const { user } = useAuth();
  const { contract } = useContract();

  const [migrationDate, setMigrationDate] = useState<DateProps>({
    value: new Date(),
    error: '',
  });
  const contractRef = useRef<SelectHandles>(null);
  const payerRef = useRef<SelectHandles>(null);
  const productRef = useRef<SelectHandles>(null);
  const priceTableRef = useRef<SelectHandles>(null);
  const attachmentRef = useRef<InputFileHandles>(null);

  const [contractOptions, setContractOptions] = useState([
    { title: 'Contratos', value: '' },
  ]);
  const [payerOptions, setPayerOptions] = useState([
    { title: 'Pagador', value: '' },
  ]);
  const [productOptions, setProductOptions] = useState([
    { title: 'Produto', value: '' },
  ]);
  const [priceTableOptions, setPriceTableOptions] = useState([
    { title: 'Tabela de preços', value: '' },
  ]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-send-form');
        contractRef.current?.setError(
          !contractRef.current?.value
            ? 'É necessário informar um contrato.'
            : '',
        );
        payerRef.current?.setError(
          !payerRef.current?.value ? 'É necessário informar um pagador.' : '',
        );
        productRef.current?.setError(
          !productRef.current?.value ? 'É necessário informar um produto.' : '',
        );
        priceTableRef.current?.setError(
          !priceTableRef.current?.value
            ? 'É necessário informar uma Tabela de Preço.'
            : '',
        );
        if (!migrationDate.value) {
          setMigrationDate((prev) => ({
            ...prev,
            error: 'É necessário informar uma data',
          }));
        }
        const owners = rowsSelecteds.filter(
          (item) => item.ownership === 'Titular',
        );
        if (
          !contractRef.current?.value ||
          !payerRef.current?.value ||
          !productRef.current?.value ||
          !priceTableRef.current?.value ||
          !migrationDate.value ||
          owners.length === 0
        ) {
          return;
        }
        const migrationPT = dateToPTBR(migrationDate.value);
        const limitDateToUse = dateToPTBR(
          new Date(
            migrationDate.value.getFullYear(),
            migrationDate.value.getMonth(),
            migrationDate.value.getDate() - 1,
          ),
        );
        const contractIdMigration = contractRef.current.value;
        const payerNumber = payerRef.current.value;
        const planId = productRef.current.value;
        const priceListId = priceTableRef.current.value;
        const { webUsername } = user;
        const allResp = await Promise.allSettled(
          owners.map(async (owner) => {
            const params = new FormData();
            params.append('userUnimedId', owner.userUnimedId);
            params.append('contractId', contract.contractId);
            params.append('migrationDate', migrationPT);
            params.append('limitDateToUse', limitDateToUse);
            attachmentRef.current?.files?.forEach((f, index) => {
              params.append(`contractFiles[${index}]`, f);
            });
            params.append('contractIdMigration', contractIdMigration);
            params.append('payerNumber', payerNumber);
            params.append('planId', planId);
            params.append('priceListId', priceListId);
            params.append('webUsername', webUsername);
            return api.post(`/company/send-contract-amendment`, params);
          }),
        );

        let errors = false;
        allResp.forEach((resp) => {
          if (resp.status === 'rejected') {
            console.log(resp.reason.response.data.message);
            Notiflix.Notify.failure(resp.reason.response.data.message);
            errors = true;
          }
        });
        if (errors) {
          return;
        }
        const message = 'Solicitação realizada com sucesso.';
        Modal2.Success({
          closable: false,
          autoWidth: true,
          title: 'Perfeito!',
          text: message,
          children: (
            <Button
              modal
              onClick={() => {
                Modal2.Close();
                callbackSetStep(1);
              }}
            >
              Ok
            </Button>
          ),
        });
        callbackSetStep(1);
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: false,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar enviar sua alteração para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-send-form');
      }
    },
    [callbackSetStep, contract, migrationDate, rowsSelecteds, user],
  );
  const getPriceTableOptions = useCallback(
    async (actualContract: string, productId: string) => {
      try {
        Notiflix.Block.circle('.notiflix-price-options');
        const { data } = await api.get(
          `/company/list-of-contract-tables-and-selected-products?contractId=${actualContract}&planId=${productId}`,
        );
        const { content } = data;
        setPriceTableOptions([
          { title: '', value: '' },
          ...content.reduce(
            (
              acc: { title: string; value: string }[],
              act: {
                name: string;
                id: string;
              },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        ]);
      } catch (err) {
        setPriceTableOptions([{ title: '', value: '' }]);
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar a tabela de preços do contrato selecionado para seleção.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-price-options');
      }
    },
    [],
  );
  const getProductsOptions = useCallback(
    async (actualContract: string) => {
      try {
        Notiflix.Block.circle('.notiflix-products-options');
        const { data } = await api.get(
          `/company/product-listing-for-selection?contractId=${actualContract}`,
        );
        const { content } = data;
        setProductOptions([
          { title: '', value: '' },
          ...content.reduce(
            (
              acc: { title: string; value: string }[],
              act: {
                name: string;
                id: string;
              },
            ) => {
              acc.push({
                title: act.name,
                value: act.id,
              });
              return acc;
            },
            [],
          ),
        ]);
        getPriceTableOptions(actualContract, content[0].id);
      } catch (err) {
        setProductOptions([{ title: '', value: '' }]);
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os produtos do contrato selecionado para seleção.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-products-options');
      }
    },
    [getPriceTableOptions],
  );
  const getPayerOptions = useCallback(async (actualContract: string) => {
    try {
      Notiflix.Block.circle('.notiflix-payer-options');
      const { data } = await api.get(
        `/company/finds-payer-selected-contract?contractId=${actualContract}`,
      );
      const { content } = data;
      setPayerOptions(
        content.reduce(
          (
            acc: { title: string; value: string }[],
            act: {
              name: string;
              id: string;
            },
          ) => {
            acc.push({
              title: act.name,
              value: act.id,
            });
            return acc;
          },
          [],
        ),
      );
    } catch (err) {
      setPayerOptions([{ title: '', value: '' }]);
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os pagadores do contrato selecionado para seleção.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-payer-options');
    }
  }, []);

  // const showStringIfExists = (act: any) => `${act ? `${act} |` : ''}`;

  const getContractOptions = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-contract-options');
      const { data } = await api.get(
        `/company/list-of-contracts-to-edit?groupNumber=${user.groupNumber}`,
      );
      const { content } = data;
      setContractOptions([
        { title: '', value: '' },
        ...content.reduce(
          (
            acc: { title: string; value: string }[],
            act: {
              unit: string;
              contractId: string;
              stipulatorName: string;
              compstringCode: string;
              companyCode: string;
              cnpj: string;
            },
          ) => {
            acc.push({
              title: `${act.unit ? `${act.unit} | ` : ''}  ${
                act.contractId
              } | ${act.stipulatorName} | ${act.companyCode} | ${act.cnpj}`,
              value: act.contractId,
            });
            return acc;
          },
          [],
        ),
      ]);
      getPayerOptions(content[0].contractId);
      getProductsOptions(content[0].contractId);
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os contratos para seleção.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-contract-options');
    }
  }, [getPayerOptions, getProductsOptions, user.groupNumber]);

  useEffect(() => {
    Modal2.Warning({
      closable: false,
      autoWidth: true,
      title: 'Atenção!',
      text: 'Informamos que a ação será realizada para os dependentes dos titulares selecionados',
      children: (
        <Button modal onClick={() => Modal2.Close()}>
          Ok
        </Button>
      ),
    });
    getContractOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormWrapper onSubmit={handleSubmit} formLook className="change-contract">
      <DatePicker
        name="migrationDate"
        title="Data migração:"
        minDate={new Date()}
        selected={migrationDate.value}
        onChange={(date: Date) => setMigrationDate({ value: date, error: '' })}
        error={migrationDate.error}
        formLook
        themeColor={defaultColor}
        widthContainerDesktop="20%"
        icon
      />
      <Select
        name="contract"
        title="Contrato:"
        ref={contractRef}
        options={contractOptions}
        className="notiflix-contract-options"
        formLook
        liveReload
        inputMode
        themeColor={defaultColor}
        changeCallback={(option) => {
          getPayerOptions(option);
          getProductsOptions(option);
        }}
        widthContainerDesktop="80%"
      />
      <Select
        name="payer"
        title="Pagador:"
        ref={payerRef}
        options={payerOptions}
        className="notiflix-payer-options"
        formLook
        liveReload
        inputMode
        themeColor={defaultColor}
        widthContainerDesktop="50%"
      />
      <Select
        name="product"
        title="Produto:"
        ref={productRef}
        options={productOptions}
        changeCallback={(option) => {
          getPriceTableOptions(contractRef.current?.value || '', option);
        }}
        className="notiflix-products-options"
        formLook
        liveReload
        inputMode
        themeColor={defaultColor}
        widthContainerDesktop="50%"
      />
      <Select
        name="priceTable"
        title="Tabela preço:"
        ref={priceTableRef}
        options={priceTableOptions}
        className="notiflix-price-options"
        formLook
        liveReload
        inputMode
        themeColor={defaultColor}
      />

      <InputFile
        name="attachments"
        title="Anexos:"
        placeholder="Anexos"
        ref={attachmentRef}
        multiple
        themeColor={defaultColor}
      />

      <Button secondary autoWidth formLook onClick={() => callbackSetStep(1)}>
        Voltar
      </Button>
      <Button
        type="submit"
        autoWidth
        greenButton
        formLook
        className="notiflix-send-form marginLeft"
      >
        Enviar
      </Button>
    </FormWrapper>
  );
}
