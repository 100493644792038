import React from 'react';
import {
  Route as RouteDom,
  RouteProps as RoutePropsDom,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends RoutePropsDom {
  isPrivate?: boolean;
  component: React.ComponentType;
}
const Route: React.FC<RouteProps> = ({
  isPrivate,
  component: Component,
  path,
  ...rest
}) => {
  const { isAuthendicated } = useAuth();

  if (path === '/login' && isAuthendicated) {
    return (
      <RouteDom path={path} {...rest}>
        <Redirect to="/" />
      </RouteDom>
    );
  }

  return (
    <RouteDom path={path} {...rest}>
      {isPrivate && !isAuthendicated ? <Redirect to="/login" /> : <Component />}
    </RouteDom>
  );
};

export default Route;
