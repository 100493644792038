/* eslint-disable react/no-danger */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Notiflix from 'notiflix';
import styled from 'styled-components';
import axios from 'axios';
import api from '../services/api/api';
import { useAuth } from './auth';

import Modal2 from '../components/Modal2';
import Button from '../components/Button';

const ModalContainer = styled.div`
  h1 {
    font-size: 1.7rem;
    font-family: 'Unimed-Slab-Bold';
    color: ${(props) => props.theme.colors.text.primary};
    margin-bottom: 56px;
    text-align: left;
    padding: 0 20px;
    @media (max-width: 768px) {
      font-size: 1.3rem;
      margin-bottom: 32px;
    }
  }
  > div {
    max-height: calc(100vh - 320px);
    overflow-y: auto;
    @media (max-width: 768px) {
      max-height: calc(100vh - 350px);
    }
    font-size: 1rem;
    padding: 0 20px;
    margin-bottom: 30px;
    text-align: left;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.primary};
  }
  p {
    font-size: 1rem;
    text-align: left;
    font-family: 'Unimed-Sans-Book';
    color: ${(props) => props.theme.colors.text.primary};
  }
`;

export interface ReleaseProps {
  emit: string;
  id: number;
  read: boolean;
  // eslint-disable-next-line camelcase
  reading_date: string | null;
  requiredReading: boolean;
  text: string;
  title: string;
}

interface ReleasesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: ReleaseProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  importants: any[];
}

export interface Term {
  acceptanceTerm: string;
  acceptanceTermId: number;
  acceptanceTermTitle: string;
  changeable: string;
  confirmationDate: string | null;
  ieExibirLogin: string;
  initialsSituation: string;
  insuredName: string;
  registerAcceptanceTermId: string | null;
  required: string;
  revogationDate: string | null;
  termUserUnimedId: number;
}
interface TermsProps {
  items: Term[];
  importants: {
    acceptanceTermId: number;
    registerAcceptanceTermId: string | null;
    termUserUnimedId: number;
  }[];
}

interface ContextProps {
  releases: ReleasesProps;
  checkRead(id: number, notiflixId?: string): Promise<void>;
  decrementMemo(id: number): void;
}

const releasesContext = createContext<ContextProps>({} as ContextProps);

export const ReleasesProvider: React.FC = ({ children }) => {
  const { user, isAuthendicated } = useAuth();
  const [releases, setReleases] = useState({} as ReleasesProps);
  const [terms, setTerms] = useState({} as TermsProps);

  const decrementMemo = useCallback((id: number) => {
    setReleases((prev) => {
      const newValues = {
        items: [
          ...prev.items.map((i) => {
            if (i.id === id) {
              // eslint-disable-next-line no-param-reassign
              i.read = true;
            }
            return i;
          }),
        ],
        importants: prev.importants.filter((i) => i !== id),
      };
      return newValues;
    });
  }, []);

  const checkReleaseRead = useCallback(
    async (id: number, notiflixId = '') => {
      const itemChange = releases.items.find((i) => i.id === id);
      if (itemChange && itemChange.read) {
        return;
      }

      try {
        if (notiflixId) {
          Notiflix.Block.circle(`.${notiflixId}`);
        }
        const { data: dataMyIp } = await axios.get(
          'https://api.ipify.org/?format=json',
        );

        await api.put(`/memo/set-read`, {
          memo_id: id,
          ip: dataMyIp.ip,
        });

        setReleases((prev) => {
          const newValues = {
            items: [
              ...prev.items.map((i) => {
                if (i.id === id) {
                  // eslint-disable-next-line no-param-reassign
                  i.read = true;
                }
                return i;
              }),
            ],
            importants: prev.importants.filter((i) => i !== id),
          };
          return newValues;
        });
      } catch (err) {
        Notiflix.Notify.failure(
          'Não conseguimos informar ao servidor que esse documento foi lido.',
        );
      } finally {
        if (notiflixId) {
          Notiflix.Block.remove(`.${notiflixId}`);
        }
      }
    },
    [releases],
  );

  const checkTermRead = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      try {
        const status = 'C'; // "C" (accepted) | "R" (revogued)
        await api.put(`/beneficiary/agreement-acceptance-terms`, {
          termUserUnimedId: row.termUserUnimedId,
          acceptanceTermId: row.acceptanceTermId,
          registerAcceptanceTermId: row.registerAcceptanceTermId ?? '',
          status,
        });

        setTerms((prev) => ({
          ...prev,
          importants: [
            ...prev.importants.filter((item) => {
              if (
                item.acceptanceTermId === row.acceptanceTermId &&
                item.registerAcceptanceTermId ===
                  row.registerAcceptanceTermId &&
                item.termUserUnimedId === row.termUserUnimedId
              ) {
                return false;
              }
              return true;
            }),
          ],
        }));
      } catch (err) {
        Notiflix.Notify.failure(
          'Não conseguimos informar ao servidor que esse documento foi lido.',
        );
      }
    },
    [],
  );

  const closeModalRelease = useCallback(
    async (id: number) => {
      // console.log('fechando modal id=>', id);
      Modal2.Close();
      await checkReleaseRead(id);
    },
    [checkReleaseRead],
  );

  const closeModalTerm = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      // console.log('fechando modal id=>', id);
      Modal2.Close();
      await checkTermRead(row);
    },
    [checkTermRead],
  );

  const openImportantRelease = useCallback(
    (id: number) => {
      if (!user.name) return;
      const release = releases.items.find((item) => item.id === id);
      if (release) {
        Modal2.Generic({
          closable: false,
          children: (
            <ModalContainer>
              <h1>{release.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: release.text }} />
              <Button
                onClick={() => closeModalRelease(id)}
                modal
                autoWidth
                float="right"
              >
                Lido
              </Button>
            </ModalContainer>
          ),
        });
      } else {
        closeModalRelease(id);
      }
    },
    [releases, closeModalRelease, user],
  );

  const getReleasesAndTerms = useCallback(async () => {
    try {
      const listMemo = await api.get('/memo/list-memo');
      const listTerms = { data: { content: [] } };

      const allMemos = listMemo.data.content as ReleaseProps[];
      const allTerms = listTerms.data.content as Term[];

      const importantsMemoNotReaded =
        allMemos
          ?.filter((memo) => memo.requiredReading && !memo.read)
          .map((memo) => memo.id) || [];

      setReleases({ items: allMemos, importants: importantsMemoNotReaded });

      setTerms({
        items: allTerms,
        importants: allTerms.reduce((acc, act: Term) => {
          if (act.ieExibirLogin === 'S') {
            acc.push({
              acceptanceTermId: act.acceptanceTermId,
              registerAcceptanceTermId: act.registerAcceptanceTermId,
              termUserUnimedId: act.termUserUnimedId,
            });
          }
          return acc;
        }, [] as { acceptanceTermId: number; registerAcceptanceTermId: string | null; termUserUnimedId: number }[]),
      });
    } catch (err) {
      // console.log(err);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthendicated) {
      getReleasesAndTerms();
    }
  }, [getReleasesAndTerms, isAuthendicated]);

  const getTermContent = useCallback(
    async (row: {
      acceptanceTermId: number;
      registerAcceptanceTermId: string | null;
      termUserUnimedId: number;
    }) => {
      try {
        const termFinded = terms.items.find(
          (item) =>
            item.acceptanceTermId === row.acceptanceTermId &&
            item.registerAcceptanceTermId === row.registerAcceptanceTermId &&
            item.termUserUnimedId === row.termUserUnimedId,
        );
        Modal2.Generic({
          closable: false,
          children: (
            <ModalContainer>
              <h1
                dangerouslySetInnerHTML={{
                  __html: termFinded?.acceptanceTermTitle ?? '<></>',
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: termFinded?.acceptanceTerm ?? '<p></p>',
                }}
              />
              {termFinded?.required !== 'S' && (
                <Button
                  onClick={() => {
                    setTerms((prev) => ({
                      ...prev,
                      importants: [
                        ...prev.importants.filter((item) => {
                          if (
                            item.acceptanceTermId === row.acceptanceTermId &&
                            item.registerAcceptanceTermId ===
                              row.registerAcceptanceTermId &&
                            item.termUserUnimedId === row.termUserUnimedId
                          ) {
                            return false;
                          }
                          return true;
                        }),
                      ],
                    }));
                    Modal2.Close();
                  }}
                  modal
                  outlined
                  autoWidth
                  float="left"
                >
                  Ver depois
                </Button>
              )}
              <Button
                onClick={() => closeModalTerm(row)}
                modal
                autoWidth
                float="right"
              >
                Aceito
              </Button>
            </ModalContainer>
          ),
        });
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops..Não conseguimos buscar um termo importante para você. Tente novamente mais tarde',
        );
      }
    },
    [closeModalTerm, terms.items],
  );

  useEffect(() => {
    if (terms?.importants?.length > 0) {
      getTermContent(terms.importants[0]);
    }
    // first open terms
    else if (releases?.importants?.length > 0) {
      const id = releases.importants[0];
      openImportantRelease(id);
    }
  }, [releases, terms, openImportantRelease, getTermContent]);

  return (
    <releasesContext.Provider
      value={{ releases, checkRead: checkReleaseRead, decrementMemo }}
    >
      {children}
    </releasesContext.Provider>
  );
};

export function useReleases(): ContextProps {
  const context = useContext(releasesContext);

  if (!context) {
    throw new Error('useReleases must be used within an ReleasesProvider');
  }

  return context;
}
