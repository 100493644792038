import styled, { css } from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { shade } from 'polished';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: ${(props) => props.theme.colors.palet.institutional11};
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    position: absolute;
    transition: transform 300ms;
    padding: 0 0 30px;
    border-radius: 0 20px 20px 0;
    width: 80%;
    max-width: 400px;

    > svg {
      display: none;
    }
    ${(props) =>
      props.collapsed &&
      css`
        transform: translateX(-100%);
        overflow: hidden;
        @media (min-width: 769px) {
          height: 100%;
        }
      `}
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  > svg {
    width: calc(100% - 60px);
    height: auto;
    margin: 30px auto;
  }
  @media (max-width: 768px) {
    > svg {
      display: none;
    }
  }
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 30px 30px 22px;
  svg {
    color: ${(props) => props.theme.colors.text.white};
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  > a {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  a:first-child {
    margin-bottom: 0;
  }
  @media (min-width: 769px) {
    height: 100%;
  }
`;

export const NavDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 10px;
  }

  &:hover,
  &.active {
    > button {
      color: ${(props) => shade(0.2, props.theme.colors.text.secondary)};
      &::before {
        height: 100%;
      }
    }
  }
  > button {
    width: calc(100% - 60px);
    border: none;
    background: transparent;
    color: ${(props) => props.theme.colors.text.white};
    outline: none;
    text-align: left;
    font-size: 1rem;
    font-family: 'Unimed-Sans-SemiBold';
    margin: 0 30px;
    line-height: 1.1rem;
    padding: 8px 0;
    transition: 300ms;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 3px;
      height: 0;
      background: ${(props) => props.theme.colors.palet.institutional4};
      left: -10px;
      bottom: 0;
      transition: 300ms;
    }
  }
`;

interface NavCollapseProps {
  height: number;
}

export const NavCollapse = styled.nav<NavCollapseProps>`
  display: flex;
  flex-direction: column;
  padding-left: 8px;

  > a {
    color: ${(props) => props.theme.colors.text.white};
    text-decoration: none;
    font-size: 1rem;
    font-family: 'Unimed-Sans-Book';
    margin: 0 30px;
    line-height: 1.1rem;
    padding: 8px 0;
    transition: 300ms;
    &:hover,
    &.active {
      color: ${(props) => props.theme.colors.palet.institutional4};
    }
  }
  height: 0;
  overflow: hidden;
  transition: 300ms;
  &.open {
    ${(props) =>
      css`
        height: ${props.height}px;
      `}
  }
`;

export const IconDrop = styled.div`
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.colors.text.white};
  border-radius: 50%;
  &::before {
    content: '';
    width: 60%;
    height: 2px;
    background: ${(props) => props.theme.colors.text.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    content: '';
    width: 60%;
    height: 2px;
    background: ${(props) => props.theme.colors.text.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: center;
    transition: 300ms;
  }
  &.open::after {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  @media (max-width: 768px) {
    right: 0;
  }
`;

export const Link = styled(LinkRouter)`
  color: ${(props) => props.theme.colors.text.white};
  text-decoration: none;
  font-size: 1rem;
  font-family: 'Unimed-Sans-SemiBold';
  margin: 0 30px;
  line-height: 1.1rem;
  padding: 8px 0;
  transition: 300ms;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 0;
    background: ${(props) => props.theme.colors.palet.institutional4};
    left: -10px;
    bottom: 0;
    transition: 300ms;
  }
  &:hover,
  &.active {
    color: ${(props) => shade(0.2, props.theme.colors.text.secondary)};
    &::before {
      height: 100%;
    }
  }
`;

export const Logoff = styled.button`
  width: calc(100% - 60px);
  border: none;
  background: transparent;
  color: ${(props) => props.theme.colors.text.white};
  outline: none;
  text-align: left;

  font-size: 1rem;
  font-family: 'Unimed-Sans-SemiBold';
  margin: 0 30px;
  line-height: 1.1rem;
  padding: 8px 0;
  transition: 300ms;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 0;
    background: ${(props) => props.theme.colors.palet.institutional4};
    left: -10px;
    bottom: 0;
    transition: 300ms;
  }
  &:hover {
    color: ${(props) => shade(0.2, props.theme.colors.text.secondary)};
    &::before {
      height: 100%;
    }
  }
`;

export const ContainerLogoW = styled.div`
  margin: auto 30px 0;
  border-top: 1px solid ${(props) => props.theme.colors.text.white};
  padding-top: 16px;
  a {
    font-family: 'Unimed-Sans-Book';
    text-decoration: none;
    font-size: 0.85rem;
    color: ${(props) => props.theme.colors.text.white};

    svg {
      margin-left: 8px;
    }
    strong {
      font-family: 'Unimed-Sans-SemiBold';
      @media (min-width: 769px) {
        white-space: nowrap;
      }
    }
    @media (min-width: 769px) and (max-width: 1400px) {
      font-size: 0.65rem;
    }
  }
  @media (max-width: 768px) {
    display: block;
    margin: 16px 30px;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    margin: auto 0 0 30px;
  }
  &.mobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      a {
        font-family: 'Unimed-Sans-Book';
        color: ${(props) => props.theme.colors.text.primary};
        display: flex;
        text-decoration: none;
        align-items: baseline;
        width: 100%;
        font-size: 0.75rem;
        strong {
          margin-left: 6px;
        }
        svg {
          margin-left: auto;
          path {
            fill: ${(props) => props.theme.colors.text.primary};
          }
        }
        @media (max-width: 320px) {
          font-size: 0.65rem;
        }
      }
    }
  }
`;
