/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import Notiflix from 'notiflix';
import { v4 } from 'uuid';
import produce from 'immer';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../../components/PageWrapper';
import {
  FormWrapperPeriodDate,
  ButtonContainerModal,
  ModalContainer,
  FilesContainer,
  PeriodButton,
  DatePicker,
  ModalButton,
  Select,
  Title,
  Table,
} from './styles';
import { Column } from '../../../components/Table';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../../utils/tableUtils';
import { ButtonsActionsContainer } from '../../../components/Table/styles';
import Modal2 from '../../../components/Modal2';
import InputFile, { InputFileHandles } from '../../../components/InputFile';
import api from '../../../services/api/api';
import { downloadFileOfBlob } from '../../../utils/geterateFileURL';
import Button from '../../../components/Button';
import { SelectHandles } from '../../../components/Select';
import { ObjectToUrlParams } from '../../../utils/Url';
import { useContract } from '../../../hooks/contract';
import {
  dateInFirstDayOfYear,
  dateInLastDayOfYear,
  dateToPTBR,
} from '../../../utils/formatt';

interface DateProps {
  value: Date | null;
  error: string;
}

interface Row {
  attachment: string;
  beneficiaryName: string;
  cancel: string;
  holderName: string | null;
  reincludeRetiredFired: string;
  release: string;
  releaseDate: string | null;
  requestDate: string;
  requestId: number;
  statusDescription: string;
  terminationDate: string | null;
  userUnimedId: number;
  iePermitirDesfazerLib: string;
}

const CompanyTerminationConsult: React.FC = () => {
  const { contract } = useContract();
  const history = useHistory();
  const [selectedToDate, setSelectedToDate] = useState<DateProps>({
    value: dateInLastDayOfYear(),
    error: '',
  });
  const [selectedFromDate, setSelectedFromDate] = useState<DateProps>({
    value: dateInFirstDayOfYear(),
    error: '',
  });
  const statusRef = useRef<SelectHandles>(null);
  // const terminateRef = useRef<SelectHandles>(null);

  const [filter, setFilter] = useState({
    initialDate: dateToPTBR(dateInFirstDayOfYear()),
    finalDate: dateToPTBR(dateInLastDayOfYear()),
    status: '1',
    // reinclusion: '1',
  });

  const [tableData, setTableData] = useState([] as Row[]);
  const [expandedRows, setExpandedRows] = useState([] as Row[]);
  const [dataTable, setDataTable] = useState([] as Row[]);

  const inputFilesRef = useRef<InputFileHandles>(null);

  const getInitialTableDatas = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-table-loading');
      const params = ObjectToUrlParams(filter);
      const { data, status } = await api.get(
        `/company/list-beneficiary-termination${params}contractId=${contract.contractId}`,
      );
      const { content } = data;
      setTableData(content);
    } catch (error) {
      setTableData([]);
      if (error.response?.status !== 400) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove('.notiflix-table-loading');
    }
  }, [contract.contractId, filter]);

  const handleSearchDatas = useCallback(() => {
    if (!selectedToDate.value) {
      setSelectedToDate((prev) => ({ ...prev, error: 'Informe uma data' }));
    }
    if (!selectedFromDate.value) {
      setSelectedFromDate((prev) => ({ ...prev, error: 'Informe uma data' }));
    }

    // if (!terminateRef.current?.value) {
    //   terminateRef.current?.setError('Selecione uma opção');
    // }
    if (
      !selectedToDate.value ||
      !selectedFromDate.value
      // !terminateRef.current?.value
    ) {
      return;
    }
    setFilter({
      initialDate: dateToPTBR(selectedFromDate.value),
      finalDate: dateToPTBR(selectedToDate.value),
      status: statusRef.current?.value ?? '',
      // reinclusion: terminateRef.current.value,
    });
  }, [selectedFromDate, selectedToDate]);

  const handleOpenAttachment = useCallback(
    async (archive, requestId, userUnimedId, notiflix) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/company/files-from-termination-request-to-edit?userUnimedId=${userUnimedId}&requestId=${requestId}&fileId=${archive.id}&fileName=${archive.name}`,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(data);
      } catch (err) {
        if (err.response?.data?.message) {
          Notiflix.Notify.failure(err.response.data.message);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos buscar seu documento. Por favor, tente novamente mais tarde',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [],
  );

  const handleViewAndAttachLineOption = useCallback(
    async (row: Row, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        let attachments = [] as { id: number; name: string }[];
        try {
          const { data } = await api.get(
            `/company/get-termination-request-to-edit?requestId=${row.requestId}&userUnimedId=${row.userUnimedId}`,
          );
          const { content } = data;
          attachments = content[0].attachments;
        } catch (e) {
          Notiflix.Notify.info('Ainda não há anexos.');
        }

        const addAttachment = async () => {
          if (!inputFilesRef.current?.files) return;
          try {
            Notiflix.Block.circle('.notiflix-send-attachment');
            const params = new FormData();
            params.append('requestId', row.requestId.toString());
            params.append('userUnimedId', row.userUnimedId.toString());
            params.append('terminationFile', inputFilesRef.current.files[0]);
            const { data: data2 } = await api.post(
              `/company/send-termination-attachment`,
              params,
            );
            const { message } = data2;
            Notiflix.Notify.success(message);
            Modal2.Close();
            handleViewAndAttachLineOption(row, notiflix);
          } catch (err) {
            if (err.response?.data?.message) {
              Notiflix.Notify.failure(err.response.data.message);
            } else {
              Notiflix.Notify.failure(
                'Ops... Não conseguimos enviar seu arquivo para o servidor. Por favor, tente novamente mais tarde.',
              );
            }
          } finally {
            Notiflix.Block.remove('.notiflix-send-attachment');
          }
        };

        Modal2.Generic({
          children: (
            <ModalContainer>
              <h2>Informações dos arquivos</h2>
              <FilesContainer>
                {attachments.map((archive) => (
                  <button
                    type="button"
                    key={archive.id}
                    onClick={() =>
                      handleOpenAttachment(
                        archive,
                        row.requestId,
                        row.userUnimedId,
                        `.notiflix-archive-${archive.id}`,
                      )
                    }
                    className={`notiflix-archive-${archive.id}`}
                  >
                    {archive.name}
                  </button>
                ))}
              </FilesContainer>

              <ButtonContainerModal>
                <InputFile placeholder="Anexar arquivo" ref={inputFilesRef} />
                <ModalButton
                  modal
                  onClick={addAttachment}
                  className="notiflix-send-attachment"
                >
                  Enviar Anexo
                </ModalButton>
              </ButtonContainerModal>
            </ModalContainer>
          ),
          autoWidth: true,
          closable: true,
        });
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos buscar os dados do servidor.Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [handleOpenAttachment],
  );

  const handleOpenRejectionReason = useCallback(
    async (row: Row, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.get(
          `/company/get-termination-request-reject-motive?requestId=${row.requestId}`,
        );
        const { content } = data;
        const { reasonForRejection, rejectionObservation } = content[0];
        // const message = 'something';
        Modal2.Failure({
          autoWidth: true,
          title: 'Motivo da Solicitação Rejeitada',
          subtitle: `${reasonForRejection || ''}`,
          text: `${rejectionObservation || ''}`,
          children: (
            <Button
              onClick={() => {
                Modal2.Close();
              }}
            >
              Ok
            </Button>
          ),
        });
      } catch (error) {
        if (error.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: error.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.circle(notiflix);
        Notiflix.Block.remove(notiflix);
      }
    },
    [],
  );

  const handleDelete = useCallback(async (row: Row, notiflix: string) => {
    try {
      Notiflix.Block.circle(notiflix);
      const { data } = await api.put(`/company/cancel-termination-request`, {
        requestId: row.requestId,
      });
      const { message } = data;

      Modal2.Success({
        closable: true,
        autoWidth: true,
        title: 'Perfeito!',
        text: message,
        children: (
          <Button modal onClick={() => Modal2.Close()}>
            Ok
          </Button>
        ),
      });
      setTableData((prev) =>
        produce(prev, (draft) => {
          const line = draft.find((i) => i.requestId === row.requestId);
          if (line) {
            line.statusDescription = 'Cancelado';
            line.cancel = 'N';
          }
        }),
      );
    } catch (error) {
      if (error.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: error.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
        );
      }
    } finally {
      Notiflix.Block.remove(notiflix);
    }
  }, []);

  const handleReleaseRecordLineOption = useCallback(
    async (row: Row, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);
        const { data } = await api.put(
          `company-rescission/release-termination-record`,
          {
            requestId: row.requestId,
          },
        );
        const { message } = data;

        Modal2.Success({
          closable: true,
          autoWidth: true,
          title: 'Perfeito!',
          text: message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
        getInitialTableDatas();
        setTableData((prev) =>
          produce(prev, (draft) => {
            const line = draft.find((i) => i.requestId === row.requestId);
            if (line) {
              line.releaseDate = new Date().toLocaleString();
              line.statusDescription = 'Liberado';
            }
          }),
        );
      } catch (error) {
        if (error.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: error.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [],
  );

  const generateReportLineOption = useCallback(
    async (row: Row, notiflix: string) => {
      try {
        Notiflix.Block.circle(notiflix);

        const { data } = await api.get(
          '/report/list-of-company-portal-reports?exhibitionLocation=4',
        );
        const { content } = data;
        const { nrSeqRelatorio, Parametros } = content[0];
        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          {
            nrSeqRelatorio: nrSeqRelatorio ?? '',
            Parametros: [
              {
                id: Parametros[0].cdParametro,
                valor: row.requestId,
              },
            ],
          },
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops, não conseguimos buscar os dados do servidor... Tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflix);
      }
    },
    [],
  );

  const handleEditThisRegister = useCallback(
    (row: Row, notiflix: string) => {
      const STEP_TERMINATE_ON_BENEFICIARY = 3;
      history.push(
        `/beneficiarios/${STEP_TERMINATE_ON_BENEFICIARY}/${row.requestId}/${row.userUnimedId}`,
      );
    },
    [history],
  );

  const handleUndoRelease = useCallback(
    async (row: Row) => {
      try {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
        const resp = localStorage.getItem('@unimedLitoral');
        if (resp) {
          const userData = JSON.parse(resp);
          const { webUsername } = userData;
          const { data } = await api.put(
            `/company-rescission/undo-release-solicitation-rescission`,
            {
              contractId: contract.contractId,
              requestId: row.requestId,
              webUsername,
            },
          );
          const { message } = data;
          Modal2.Success({
            closable: true,
            autoWidth: true,
            title: 'Perfeito!',
            text: message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
          getInitialTableDatas();
          setDataTable((prev) =>
            produce(prev, (draft) => {
              const found = draft.find((i) => i.requestId === row.requestId);
              if (found) {
                found.statusDescription = 'Aguardando liberação';
              }
            }),
          );
        }
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.circle(`.notiflix-release-${row.requestId}`);
      }
    },
    [contract.contractId, getInitialTableDatas],
  );

  const rowExpansionTemplate = useCallback(
    (rowData: Row) => {
      const buttons = [
        {
          title:
            rowData.statusDescription === 'Solicitação rejeitada'
              ? 'Detalhes'
              : '',
          float: 'left',
          onClick: handleOpenRejectionReason,
        },

        {
          title: rowData.attachment === 'S' ? 'Ver / Anexar' : '',
          float: 'left',
          onClick: handleViewAndAttachLineOption,
          last: true,
        },
        {
          title: 'Relatório',
          float: 'right',
          onClick: generateReportLineOption,
        },

        {
          title:
            rowData.statusDescription === 'Pendente de liberação'
              ? 'Excluir'
              : '',
          float: 'left',
          onClick: handleDelete,
          last: true,
        },
        {
          title:
            rowData.statusDescription === 'Pendente de liberação'
              ? 'Liberar registro da solicitação'
              : '',
          float: 'left',
          onClick: handleReleaseRecordLineOption,
        },
        {
          title:
            rowData.statusDescription === 'Pendente de liberação'
              ? 'Editar Registro'
              : '',
          float: 'left',
          onClick: handleEditThisRegister,
        },
        {
          title:
            rowData.statusDescription === 'Liberado' &&
            rowData.iePermitirDesfazerLib === 'S'
              ? 'Desfazer liberação'
              : '',
          float: 'left',
          onClick: handleUndoRelease,
        },
      ].filter((i) => i.title !== '');

      return (
        <ButtonsActionsContainer>
          {buttons.map((button, index) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid}
                ${
                  index === 1 && button.title !== 'Desfazer liberação'
                    ? 'marginRight'
                    : ''
                } ${buttons.length === 1 && 'marginLeft'} ${
                  index === 1 && button.title === 'Desfazer liberação'
                    ? 'marginLeft'
                    : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(rowData, `.notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [
      handleOpenRejectionReason,
      handleViewAndAttachLineOption,
      generateReportLineOption,
      handleDelete,
      handleUndoRelease,
      handleReleaseRecordLineOption,
      handleEditThisRegister,
    ],
  );

  const situationTemplate = useCallback((data) => {
    switch (data.statusDescription) {
      case 'Pendente de liberação':
        return getTag(
          'Status',
          'Pendente de liberação',
          'orange',
          data.statusDescription,
        );
      case 'Cancelado':
        return getTag('Status', 'Cancelado', 'red', data.statusDescription);
      case 'Liberado':
        return getTag('Status', 'Liberado', 'green', data.statusDescription);
      case 'Solicitação rejeitada':
        return getTag(
          'Status',
          'Solicitação rejeitada',
          'red',
          data.statusDescription,
        );
      case 'Rescindido':
        return getTag('Status', 'Rescindido', 'red', data.statusDescription);
      default:
        return getTag(
          'Status',
          data.statusDescription,
          'orange',
          data.statusDescription,
        );
    }
  }, []);

  useEffect(() => {
    if (contract.contractId) {
      getInitialTableDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, filter]);

  return (
    <PageWrapper
      gridTemplateColumns="repeat(10, 1fr)"
      gridTemplateRows="auto auto auto 1fr"
    >
      <Title>Consultar rescisão dos beneficiários</Title>

      <FormWrapperPeriodDate onSubmit={handleSearchDatas} formLook>
        <DatePicker
          name="from"
          onChange={(date: Date) =>
            setSelectedFromDate({ value: date, error: '' })
          }
          selected={selectedFromDate.value}
          error={selectedFromDate.error}
          icon
          formLook
          title="Período de:"
          widthContainerDesktop="25%"
        />
        <DatePicker
          name="to"
          onChange={(date: Date) =>
            setSelectedToDate({ value: date, error: '' })
          }
          selected={selectedToDate.value}
          error={selectedToDate.error}
          icon
          formLook
          title="Até:"
          widthContainerDesktop="25%"
        />

        <Select
          name="status"
          ref={statusRef}
          options={[
            { title: 'Em aberto', value: '1' },
            { title: 'Rescindido', value: '3' },
            { title: 'Rejeitado', value: '5' },
            { title: 'Todos', value: '' },
          ]}
          className="notiflix-parents"
          widthContainerDesktop="25%"
          title="Status:"
          formLook
        />

        {/* <Select
          name="beneficiaryWithReinclusion"
          ref={terminateRef}
          options={[
            { title: 'Todos', value: '3' },
            { title: 'Sim', value: '1' },
            { title: 'Não', value: '2' },
          ]}
          className="notiflix-parents"
          widthContainerDesktop="25%"
          title="Rescindidos com direito a reinclusão RN 279:"
          formLook
        /> */}

        <PeriodButton type="submit" autoWidth grayButton formLook>
          Buscar
        </PeriodButton>
      </FormWrapperPeriodDate>

      <Table
        items={tableData}
        key="duplicate-card-consult-table"
        className="notiflix-table-loading"
        columnResizeMode="fit"
        id="duplicate-card-consult-table"
        expandedRows={expandedRows}
        // onRowToggle={(e) => setExpandedRows(e.data)}
        onRowToggle={(e) => setExpandedRows([e.data[e.data.length - 1]])}
        onRowClick={(e) => {
          if (
            expandedRows.length > 0 &&
            expandedRows[0]?.requestId !== e.data?.requestId
          ) {
            setExpandedRows([]);
            return setExpandedRows([e.data]);
          }
          return expandedRows.length === 0
            ? setExpandedRows([e.data])
            : setExpandedRows([]);
        }}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowExpand={(e) =>
          window.innerWidth <= 768 && setPositionOfRowExpanded(e.originalEvent)
        }
      >
        <Column
          field="requestId"
          header="Número da Solitação"
          sortable
          style={{ width: '240px' }}
          body={(data) => AddField(data.viewRequestId, 'Número da Solitação')}
        />
        <Column
          field="requestDate"
          header="Data solicitação"
          className="date"
          body={(data) => AddField(data.requestDate, 'Data da Solicitação')}
        />
        <Column
          field="beneficiaryName"
          header="Beneficiário"
          sortable
          body={(data) => AddField(data.beneficiaryName, 'Beneficiário')}
        />
        <Column
          field="holderName"
          header="Titular"
          sortable
          body={(data) => AddField(data.holderName, 'Titular')}
        />
        <Column
          field="releaseDate"
          header="Data da Liberação"
          className="date"
          body={(data) => AddField(data.releaseDate, 'Data da Liberação')}
        />
        <Column
          field="terminationDate"
          header="Data da Rescisão"
          className="date"
          body={(data) => AddField(data.terminationDate, 'Data da Rescisão')}
        />
        <Column
          field="statusDescription"
          header="Status"
          className="tag"
          sortable
          style={{ width: '170px' }}
          body={situationTemplate}
        />
        <Column header="" expander headerStyle={{ width: '60px' }} />
      </Table>
    </PageWrapper>
  );
};

export default CompanyTerminationConsult;
