import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import { GrupowSvg, LogoUnimedSvg } from '../Svg';
import NavDropdown from './NavDropdown';
import {
  Container,
  Logoff,
  Navigation,
  Link,
  LogoWrapper,
  CloseWrapper,
  ContainerLogoW,
} from './styles';

interface NavigationAsideProps {
  hidden?: boolean;
  className?: string;
  callbackClose(): void;
}

const NavigationAside: React.FC<NavigationAsideProps> = ({
  hidden = false,
  className,
  callbackClose,
}) => {
  const { logout, user } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const handleLogoff = useCallback(() => {
    logout();
  }, [logout]);

  const addTouchAction = useCallback(() => {
    if (containerRef.current) {
      let left: number;
      let right: number;
      containerRef.current.addEventListener('touchstart', (evt) => {
        right = evt.changedTouches[0].pageX;
      });
      containerRef.current.addEventListener('touchend', (evt) => {
        left = evt.changedTouches[0].pageX;
        if (left - right < -100) {
          callbackClose();
        }
      });
    }
  }, [callbackClose]);

  useEffect(() => {
    addTouchAction();
  }, [addTouchAction]);

  const consultSolicitations = useMemo(() => {
    return [
      // {
      //   url: '/consulta-segunda-via-cartao',
      //   title: 'Consulta 2ª Via do Cartão',
      // },
      {
        url: '/consulta-alteracoes-cadastrais',
        title: 'Consulta de Alterações Cadastrais',
      },
      {
        url: '/consulta-rescisao',
        title: 'Consulta Rescisão de Beneficiário',
      },
      // {
      //   url: '/consulta-alteracoes-contrato-cnpj',
      //   title: 'Consulta Alterações de Contrato/CNPJ',
      // },
      // {
      //   url: '/consulta-alteracoes-produto',
      //   title: 'Consulta Alteração de Produto',
      // },
      {
        url: '/consulta-inclusao-beneficiario',
        title: 'Consulta Inclusão de Beneficiário',
      },
    ];
  }, []);

  const financialInformation = useMemo(() => {
    return [
      {
        url: '/demonstrativos-e-segunda-via-boleto',
        title: 'Demonstrativos e 2ª Via Boleto',
      },
      // {
      //   url: '/indice-reajustes',
      //   title: 'Índice de Reajustes',
      // },
      // {
      //   url: '/sinistralidade',
      //   title: 'Sinistralidade',
      // },
    ];
  }, []);

  const beneficiaryInclude = useMemo(() => {
    return [
      {
        url: '/incluir-beneficiario',
        title: 'Incluir Beneficiário - Manualmente',
      },
      {
        url: '/incluir-beneficiario-gerar-link',
        title: 'Incluir Beneficiário - Gerar Link',
      },
      {
        url: '/incluir-beneficiario-por-lote',
        title: 'Incluir Beneficiário - Por Lote',
      },
    ];
  }, []);

  const auxiliarDocuments = useMemo(() => {
    return [
      {
        url: '/faq',
        title: 'FAQ',
      },
      {
        url: '/tutoriais',
        title: 'Tutoriais',
      },
      {
        url: '/manuais-e-arquivos',
        title: 'Manuais e Arquivos',
      },
      {
        url: '/documentos-contrato',
        title: 'Documentos do Contrato',
      },
      {
        url: '/privacidade-e-seguranca',
        title: 'Privacidade e Segurança',
      },
    ];
  }, []);

  const actualLocale = window.location.pathname
    .split('/')
    .slice(0, 2)
    .join('/');

  return (
    <Container collapsed={hidden} className={className} ref={containerRef}>
      <LogoWrapper>
        <LogoUnimedSvg onClick={() => history.push('/')} />
      </LogoWrapper>
      <CloseWrapper onClick={callbackClose}>
        <FiX size={24} />
      </CloseWrapper>

      {user.profile === 2 && (
        <>
          <Navigation>
            <Link className={`${actualLocale === '/' && 'active'}`} to="/">
              Página Inicial
            </Link>
            <Link
              className={`${actualLocale === '/comunicados' && 'active'}`}
              to="/comunicados"
            >
              Comunicados
            </Link>
            <Link
              className={`${actualLocale === '/beneficiarios' && 'active'}`}
              to="/beneficiarios"
            >
              Gerenciar Beneficiários
            </Link>
            <NavDropdown
              title="Consultar Solicitações"
              links={consultSolicitations}
            />
            <NavDropdown
              title="Informações Financeiras"
              links={financialInformation}
            />
            <NavDropdown
              title="Inclusão de Beneficiário"
              links={beneficiaryInclude}
            />

            <NavDropdown
              title="Documentos Auxiliares"
              links={auxiliarDocuments}
            />
            {/* <Link
              className={`${actualLocale === '/rol-procedimentos' && 'active'}`}
              to="/rol-procedimentos"
            >
              Rol de Procedimentos
            </Link> */}
            <Link
              className={`${actualLocale === '/relatorios' && 'active'}`}
              to="/relatorios"
            >
              Relatórios
            </Link>
            {/* <Link
              className={`${actualLocale === '/ouvidoria' && 'active'}`}
              to="/ouvidoria"
            >
              Ouvidoria
            </Link> */}
            <Link
              className={`${actualLocale === '/listagem-usuarios' && 'active'}`}
              to="/listagem-usuarios"
            >
              Gerenciar Usuários
            </Link>
            <Link
              className={`${actualLocale === '/alterar-senha' && 'active'}`}
              to="/alterar-senha"
            >
              Alterar Senha
            </Link>
            <Logoff type="button" onClick={handleLogoff}>
              Sair
            </Logoff>
            <ContainerLogoW>
              <a
                href="https://www.grupow.com.br/"
                title="GrupoW Softwares para Internet"
                target="_blanck"
              >
                Desenvolvido por <br />
                <strong>GrupoW Softwares para Internet</strong>
                <GrupowSvg />
              </a>
            </ContainerLogoW>
          </Navigation>
        </>
      )}
    </Container>
  );
};

export default memo(NavigationAside);
