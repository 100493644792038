import Notiflix from 'notiflix';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { v4 } from 'uuid';
import axios, { AxiosResponse } from 'axios';
import { FormHandles } from '@unform/core';
import { string } from 'yup/lib/locale';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PageWrapper from '../../components/PageWrapper';
import SelectUnform, { OptionProps } from '../../components/SelectUnform';
import Title, { TitleIntern } from '../../components/Title';
import { Column } from '../../components/Table';
import AddField, {
  getTag,
  setPositionOfRowExpanded,
} from '../../utils/tableUtils';
import { ButtonsActionsContainer } from '../../components/Table/styles';

import * as S from './styles';
import Modal2 from '../../components/Modal2';
import api from '../../services/api/api';
import { useAuth } from '../../hooks/auth';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import DatePickerUnform from '../../components/DatePickerUnform';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import ReportComponent from '../Report/Report';
import { useContract } from '../../hooks/contract';

interface RowProps {
  beneficiaryName: string;
  bulkId: string;
  descriptioStatus: string;
  descriptionOperationType: string;
  holderName: string;
  operationType: string;
  reasonForRejection: string;
  requestId: string;
  solicitationDate: string;
  status: string;
  rejectObservation: string;
}

interface LotProps {
  [key: string]: string;
  showFreeLot: 'S' | 'N';
}

const LotDetails: React.FC = () => {
  const { id, situation: lotSituation } =
    useParams<{ id: string; situation: string }>();
  const [infosOfLot, setInfosOfLot] = useState({} as LotProps);
  const { colors } = useTheme();
  const history = useHistory();
  const { user } = useAuth();
  const formHiringDate = useRef<FormHandles>(null);
  const attachmentsRef = useRef<InputFileHandles>(null);
  const defaultColor = colors.palet.institutional3;
  const { contract } = useContract();
  const [inconsistencia, setInconsistencia] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { name: 'Ambos', id: 'A' },
    { name: 'Com inconsistência', id: 'C' },
    { name: 'Sem inconsistência', id: 'S' },
  ] as OptionProps[]);

  const [dataTable, setDataTable] = useState<RowProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState([] as any[]);

  /**
   * @description Function to call API to remove register and remove the table line
   */
  const handleExcludeRow = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        const data = {
          requestId: row.requestId,
          webUsername: user.webUsername,
          holderBeneficiaryName: row.holderName,
        };
        const response = await api.put(
          `beneficiary-inclusion-bulk-import/delete-beneficiary-inclusion-bulk`,
          data,
        );
        Notiflix.Notify.success(response.data.message);
        setDataTable((prev) =>
          prev.filter((i) => i.requestId !== row.requestId),
        );
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos excluir o registro no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflixClass);
      }
    },
    [user],
  );

  /**
   * @description Function to go to details of the table line
   */
  const handleGoToDetailsOfRow = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        const situation =
          row.descriptioStatus === 'Solicitação rejeitada pela operadora'
            ? 'rejeitado'
            : 'autorizado';
        const owner = row.holderName !== null ? 'dependente' : 'titular';
        const inputDisabled =
          lotSituation.toLowerCase() === 'liberado' ? 'freeLot' : '';

        history.push(
          `/incluir-beneficiario/${row.requestId}/${
            inputDisabled || situation
          }/${owner}/${row.holderName}`,
        );
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos acessar os detalhes do registro no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflixClass);
      }
    },
    [history],
  );

  /**
   * @description Function to print report
   */

  const handleReportRow = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${contract.contractId}&exhibitionLocation=11`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const params = [row.bulkId, row.requestId, user.webUsername];
        // const params = [row.bulkId, row.requestId, row.beneficiaryName]; ESPERANDO CONFIRMAÇÃO DO LADO DELES
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...P.map((p: { ieCampo: any }, index: any) => {
              return {
                id: p.ieCampo,
                valor: params[index],
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(notiflixClass);
      }
    },
    [contract, user],
  );

  /**
   * @description Function to call API to show observation of the table line
   */
  // const handleOpenObservationOfRow = useCallback(
  //   async (row: RowProps, notiflixClass: string) => {
  //     try {
  //       Notiflix.Block.circle(notiflixClass);
  //       // const response = api.post(`${'rota'}/id=${row.code}`);
  //       Modal2.Generic({
  //         autoWidth: true,
  //         closable: true,
  //         title: 'Observações',
  //         text: 'teste',
  //         children: (
  //           <>
  //             <Button
  //               autoWidth
  //               outlined
  //               secondary
  //               onClick={() => Modal2.Close()}
  //             >
  //               Ok
  //             </Button>
  //           </>
  //         ),
  //       });
  //     } catch (err) {
  //       Notiflix.Notify.failure(
  //         'Ops... Não conseguimos obter os dados no servidor. Por favor, tente novamente mais tarde.',
  //       );
  //     } finally {
  //       Notiflix.Block.remove(notiflixClass);
  //     }
  //   },
  //   [],
  // );

  /**
   * @description Function to call API to show Inconsistencies of the table line
   */
  const handleGetInconsistencies = useCallback(
    async (row: RowProps, notiflixClass: string) => {
      try {
        Notiflix.Block.circle(notiflixClass);
        const { data } = await api.get(
          `beneficiary-inclusion-bulk-import/inconsistency-inclusion-bulk?requestId=${row.requestId}`,
        );

        Modal2.Warning({
          autoWidth: true,
          closable: true,
          title: `Inconsistência(s)`,
          // text: inconsistencia,
          text: data.content.map((item: any) => {
            return <p>{item.inconsistencyDescription}</p>;
          }),
          // text: data.content[0].inconsistencyDescription,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } catch (err) {
        console.log(err);
        Notiflix.Notify.failure(
          'Ops... Não conseguimos obter os dados no servidor. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove(notiflixClass);
      }
    },
    [],
  );

  const handleOpenInfoRejected = useCallback((data: RowProps) => {
    Modal2.Warning({
      closable: true,
      autoWidth: true,
      title: 'Motivo',
      text: `${data.reasonForRejection ?? ''} - ${data.rejectObservation}`,
      children: (
        <Button modal onClick={() => Modal2.Close()}>
          Ok
        </Button>
      ),
    });
  }, []);

  /**
   * @description Template of expanded row
   */
  const rowExpansionTemplate = useCallback(
    (row: RowProps) => {
      const buttons = [
        {
          title:
            row.descriptioStatus.toLowerCase() ===
            'solicitação rejeitada pela operadora'
              ? 'Motivo de Rejeição'
              : '',
          onClick: handleOpenInfoRejected,
        },
        {
          title:
            row.descriptioStatus.toLowerCase() === 'contratação concluída' ||
            lotSituation.toLowerCase() === 'liberado'
              ? ''
              : 'Excluir',
          onClick: handleExcludeRow,
        },
        {
          title: 'Detalhes',
          onClick: handleGoToDetailsOfRow,
        },
        {
          title:
            row.descriptioStatus.toLowerCase() ===
              'solicitação com inconsistência' ||
            row.descriptioStatus.toLowerCase() ===
              'solicitação rejeitada pela operadora'
              ? ''
              : 'imprimir',
          onClick: handleReportRow,
        },
        {
          title:
            row.descriptioStatus.toLowerCase() ===
            'solicitação com inconsistência'
              ? 'Ver Inconsistências'
              : '',
          onClick: handleGetInconsistencies,
        },
      ].filter((i) => !!i.title);
      return (
        <ButtonsActionsContainer>
          {buttons.map((button, index) => {
            const uuid = v4();
            return (
              <Button
                key={button.title}
                modal
                autoWidth
                sausageLook
                whiteButton
                className={`notiflix-${uuid} ${index === 0 && 'marginLeft'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  button.onClick(row, `.notiflix-${uuid}`);
                }}
              >
                {button.title}
              </Button>
            );
          })}
        </ButtonsActionsContainer>
      );
    },
    [
      handleOpenInfoRejected,
      handleExcludeRow,
      handleGoToDetailsOfRow,
      handleReportRow,
      handleGetInconsistencies,
      infosOfLot,
    ],
  );

  /**
   * @description Get API data to populate table
   */
  const getDataTable = useCallback(
    async (
      filter = {
        state: 'A',
        beneficiaryName: '',
        // beneficiaryType: '',
        request: '',
      },
    ) => {
      try {
        Notiflix.Block.circle('.notiflix-table');
        // eslint-disable-next-line prefer-const
        let status = filter.state || 'A';
        const { data } = await api.get(
          `beneficiary-inclusion-bulk-import/detail-bulk?bulkId=${id}&status=${status}&beneficiaryName=${filter.beneficiaryName}&requestId=${filter.request}`,
        );
        setDataTable(data.content || []);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data.message);
          setDataTable([]);
        } else {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos buscar os dados do servidor. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-table');
      }
    },
    [id],
  );

  /**
   * @description Apply filter to table
   */
  const handleAppliFilter = useCallback(
    async (data) => {
      try {
        getDataTable(data);
      } catch (err) {
        Notiflix.Notify.failure(
          'Ops... Ocorreu um erro ao tentar aplicar o filtro. Por favor, tente novamente mais tarde.',
        );
      }
    },
    [getDataTable],
  );

  /**
   * @description Get all infos related of this LOT
   */
  const getLotInfo = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-loading-lot');
      // const response = await api.get(`route/${id}`);
      setInfosOfLot({
        id,
        // eslint-disable-next-line no-bitwise
        showFreeLot: lotSituation.toLowerCase() !== 'liberado' ? 'S' : 'N',
      });
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar as informações do lote. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-loading-lot');
    }
  }, [id]);

  /**
   * @description function to verify hiring date and show modal to confirm the change
   */
  const handleChangeHiringDate = useCallback(
    (data) => {
      formHiringDate.current?.setErrors({});
      if (!data.hiringDate) {
        formHiringDate.current?.setErrors({ hiringDate: 'Informe uma data' });
        return;
      }
      const handleSubmit = async () => {
        try {
          Notiflix.Block.circle('.notiflix-change-hiring-date');
          const dataToSend = {
            bulkId: id,
            webUsername: user.webUsername,
            accessionDate: data.hiringDate,
          };
          const response = await api.put(
            `beneficiary-inclusion-bulk-import/update-accession-date-bulk`,
            dataToSend,
          );

          Notiflix.Notify.success(response.data.message);
          Modal2.Close();
        } catch (err) {
          Notiflix.Notify.failure(
            'Ops... Não conseguimos concluir a solicitação com o servidor. Por favor, tente novamente mais tarde.',
          );
        } finally {
          Notiflix.Block.remove('.notiflix-change-hiring-date');
        }
      };
      Modal2.Warning({
        closable: true,
        autoWidth: true,
        title: 'Atenção!',
        text: 'Essa data será aplicada como data de adesão para todos os beneficiários inclusos',
        children: (
          <S.ChangeHiringDateActions>
            <Button modal secondary onClick={() => Modal2.Close()}>
              Cancelar
            </Button>
            <Button
              className="notiflix-change-hiring-date"
              modal
              onClick={() => {
                handleSubmit();
              }}
            >
              Confirmar
            </Button>
          </S.ChangeHiringDateActions>
        ),
      });
    },
    [id, user],
  );

  /**
   * @description function to get attachments from bulk
   */
  const getAttachments = useCallback(async () => {
    try {
      const { data } = await api.get(
        `beneficiary-inclusion-bulk-import/list-attachments-bulk?bulkId=${id}`,
      );
      const { content } = data;
      if (content) {
        // eslint-disable-next-line prefer-const
        let files: File[] = [];
        const allFiles = await Promise.allSettled<AxiosResponse<Blob>[]>(
          content.map((resp: { id: string; name: string }) =>
            api.get(
              `beneficiary-inclusion-bulk-import/return-attachment-inclusion-bulk?fileId=${resp.id}&fileName=${resp.name}`,
              { responseType: 'blob' },
            ),
          ),
        );
        allFiles.forEach((promise) => {
          if (promise.status === 'fulfilled') {
            const field = promise.value.config.url?.split('?')[1].split('&');
            if (field) {
              files.push(
                new File(
                  [promise.value.data],
                  field[0].split('=')[1] + field[1].split('=')[1],
                ),
              );
            }
          }
        });
        attachmentsRef.current?.setFiles(files);
      }
    } catch (err) {
      if (err?.response.status === 400) return;
      Notiflix.Notify.failure(
        'Ops... Não conseguimos buscar os anexos para o servidor. Por favor, tente novamente mais tarde.',
      );
    }
  }, [id, attachmentsRef]);

  /**
   * @description function to send attachments to all beneficiaries on table
   */
  const handleSendAttachments = useCallback(async () => {
    try {
      Notiflix.Block.circle('.notiflix-send-attachments');
      if (attachmentsRef.current) {
        attachmentsRef.current.setError(null);
        if (
          !attachmentsRef.current.files ||
          attachmentsRef.current.files.length === 0
        ) {
          attachmentsRef.current.setError('É preciso informar os arquivos.');
          return;
        }
        const formData = new FormData();
        formData.append('batchId', id);
        attachmentsRef.current.files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
        const response = await api.post(
          `beneficiary-inclusion/bulk-attachment`,
          formData,
        );
        Notiflix.Notify.success('Anexo enviado para o lote com sucesso!');
      }
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops... Não conseguimos enviar os anexos para o servidor. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-send-attachments');
    }
  }, [id]);

  useEffect(() => {
    Promise.all([getLotInfo()]).then(() => {
      getDataTable();
      getAttachments();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper gridTemplateColumns="1fr" gridTemplateRows="auto 1fr">
      <S.Container>
        <Title themeColor={defaultColor}>Detalhes do Lote</Title>
        <S.ContainerTitleIntern formLook onSubmit={() => history.goBack()}>
          <TitleIntern
            themeColor={colors.palet.institutional3}
          >{`Lote: ${id}`}</TitleIntern>
          <Button autoWidth secondary formLook type="submit">
            Voltar
          </Button>
        </S.ContainerTitleIntern>
        <S.FormWrapper
          onSubmit={handleAppliFilter}
          formLook
          className={infosOfLot.showFreeLot === 'S' ? 'wd-70' : ''}
        >
          <SelectUnform
            name="state"
            title="Status:"
            formLook
            options={statusOptions}
            // apiSearch={}
            // personalReducer={(content) => {
            //   return content;
            // }}
            className="notiflix-status-loading"
            widthContainerDesktop="50%"
            themeColor={defaultColor}
          />
          <Input
            name="beneficiaryName"
            title="Nome do Beneficiário:"
            formLook
            widthContainerDesktop="50%"
            themeColor={defaultColor}
          />
          {/* <SelectUnform
            name="beneficiaryType"
            title="Tipo de Beneficiário:"
            formLook
            options={[
              { id: '', name: 'Todos' },
              { id: 'owner', name: 'Titular' },
              { id: 'dependent', name: 'Dependente' },
            ]}
            widthContainerDesktop="30%"
            themeColor={defaultColor}
          /> */}
          <Input
            name="request"
            title="Soliticação:"
            formLook
            widthContainerDesktop="30%"
            themeColor={defaultColor}
          />
          <Button formLook greenButton type="submit">
            Buscar
          </Button>
        </S.FormWrapper>
        {infosOfLot.showFreeLot === 'S' && (
          <S.FormWrapper
            onSubmit={handleChangeHiringDate}
            formLook
            className="mb-24 wd-30 hiring-form mob-mt-24"
            ref={formHiringDate}
          >
            <DatePickerUnform
              name="hiringDate"
              // placeholder="Nova data de adesão para todos os beneficiários inclusos"
              formLook
              themeColor={defaultColor}
              title="Nova data de adesão para todos os beneficiários inclusos:"
            />
            <Button greenButton formLook type="submit">
              Aplicar
            </Button>
          </S.FormWrapper>
        )}

        <S.FormWrapper
          onSubmit={handleSendAttachments}
          formLook
          className="mt-24 mb-24 clear-both form-attachments"
        >
          <InputFile
            ref={attachmentsRef}
            placeholder="Enviar anexos para todo o lote"
            themeColor={defaultColor}
            multiple
          />
          <Button
            formLook
            greenButton
            type="submit"
            className="notiflix-send-attachments"
          >
            Enviar
          </Button>
        </S.FormWrapper>

        <S.Table
          items={dataTable}
          className="notiflix-table"
          // scrollable={window.innerWidth > 768}
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          onRowExpand={(e) =>
            window.innerWidth <= 768 &&
            setPositionOfRowExpanded(e.originalEvent)
          }
          rowExpansionTemplate={rowExpansionTemplate}
          onRowClick={(e) => {
            if (e.data) {
              setExpandedRows([e.data]);
            } else {
              setExpandedRows([]);
            }
          }}
        >
          <Column
            field="requestId"
            header="Solicitação"
            body={(row) => AddField(row.requestId, 'Solicitação')}
            headerClassName="no-rounded"
            sortable
            style={{
              width: '120px',
              overflow: 'initial',
              textOverflow: 'initial',
              whiteSpace: 'pre-wrap',
            }}
          />
          <Column
            field="beneficiaryName"
            header="Beneficiário"
            sortable
            style={{
              // minWidth: '200px',
              overflow: 'initial',
              textOverflow: 'initial',
              whiteSpace: 'pre-wrap',
            }}
            body={(row) => AddField(row.beneficiaryName, 'Beneficiário')}
          />
          <Column
            field="solicitationDate"
            header="Data Solicitação Envio"
            className="date"
            body={(row) =>
              AddField(row.solicitationDate, 'Data Solicitação Envio')
            }
            style={{ width: 200 }}
          />
          <Column
            field="descriptionOperationType"
            header="Tipo"
            body={(row) => AddField(row.descriptionOperationType, 'Tipo')}
            style={{ width: 100 }}
          />

          <Column
            field="descriptioStatus"
            header="Situação"
            className="tag"
            sortable
            style={{ width: '120px' }}
            body={(row) => {
              switch (row.descriptioStatus.toLowerCase()) {
                case 'solicitação com inconsistência':
                  return getTag(
                    'Situação',
                    'C/Inconsistência',
                    'orange',
                    'Com inconsistência',
                  );
                case 'solicitação sem inconsistência':
                  return getTag(
                    'Situação',
                    'S/Inconsistência',
                    'green',
                    'Sem inconsistência',
                  );
                case 'solicitação rejeitada pela operadora':
                  return getTag(
                    'Situação',
                    'Rejeitada',
                    'red',
                    'Solicitação rejeitada pela operadora',
                  );
                case 'solicitação pendente para análise':
                  return getTag(
                    'Situação',
                    'Pendente análise',
                    'orange',
                    'solicitação pendente para análise',
                  );
                case 'solicitação pendente':
                  return getTag(
                    'Situação',
                    'Sol/pendente',
                    'orange',
                    'Pendente análise',
                  );
                case 'contratação concluída':
                  return getTag(
                    'Situação',
                    'Contrat/Concluída',
                    'green',
                    'Contratação concluída',
                  );
                case 'em proposta de adesão':
                  return getTag(
                    'Situação',
                    'Proposta Adesão',
                    'orange',
                    'Em proposta de adesão',
                  );
                case 'aguardando liberação':
                  return getTag(
                    'Situação',
                    'Aguard. liberação',
                    'green',
                    'Aguardando liberação',
                  );
                default:
                  return <>{row.descriptioStatus}</>;
              }
            }}
          />

          <Column
            header=""
            expander
            headerStyle={{ width: '60px' }}
            // className="only-mobile"
          />
        </S.Table>
      </S.Container>
    </PageWrapper>
  );
};

export default LotDetails;
