import styled from 'styled-components';

import TableComponent from '../../components/Table';

export const Title = styled.h1`
  grid-column: 2/10;
  margin: 30px 0;
  font-family: 'Unimed-Slab-Bold';
  text-align: center;
  font-size: 2.55rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

export const Content = styled.div`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0 30px;
  }
`;

export const Table = styled(TableComponent)`
  grid-column: 2/10;
  @media (max-width: 768px) {
    grid-column: 1;
    margin: 0;
    margin-bottom: 30px;
  }
  td.clicable {
    cursor: pointer;
  }
`;

export const ModalContainer = styled.div`
  > button {
    @media (max-width: 768px) {
      width: 100%;
      float: none;
      & + button {
        margin-top: 16px;
      }
    }
  }
`;

export const ModalTitle = styled.h1`
  font-size: 1.7rem;
  font-family: 'Unimed-Slab-Bold';
  color: ${(props) => props.theme.colors.text.primary};
  margin-bottom: 56px;
  text-align: left;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 32px;
  }
`;
export const ModalContent = styled.p`
  font-size: 1rem;
  padding: 0 20px;
  margin-bottom: 30px;
  text-align: left;
  font-family: 'Unimed-Sans-Book';
  color: ${(props) => props.theme.colors.text.primary};
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  @media (max-width: 768px) {
    max-height: calc(100vh - 350px);
  }
`;

export const Tag = styled.span`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.7rem;
  position: relative;
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.pendente {
    color: ${(props) => props.theme.colors.palet.institutional6};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional6};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional6};
      }
    }
  }
  &.concluido {
    color: ${(props) => props.theme.colors.palet.institutional};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional};
      }
    }
  }
`;
