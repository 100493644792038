/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch, Route as RouteDom } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ChangeMyPassword from '../pages/ChangeMyPassword';
import ChangePassword from '../pages/ChangePassword';
import DemonstrativeAndDuplicate from '../pages/DemonstrativeAndDuplicate';
import ChangeRegisterData from '../pages/ChangeRegisterData';
import RequestDuplicateCardConsult from '../pages/RequestDuplicateCardConsult';
import FAQ from '../pages/Faq';
import Releases from '../pages/Releases';
import Terms from '../pages/Terms';
import Tutorials from '../pages/Tutorials';
import ManualAndFiles from '../pages/ManualAndFiles';
import TerminationConsult from '../pages/TerminationConsult';
import ProducersRol from '../pages/ProceduresRol';
import ChangeRegisterDataConsult from '../pages/ChangeRegisterDataConsult';
import Route from './Route';
import Ombudsman from '../pages/Ombudsman';
import CoparticipationExtract from '../pages/CoparticipationExtract';
import BeneficiaryInclusionGenerateLink from '../pages/BeneficiaryInclusionGenerateLink';
// import ContractChange from '../pages/ContractChange';
import ConsultProductChange from '../pages/ConsultProductChange';
// import InclusionOfBeneficiaries from '../pages/InclusionOfBeneficiaries';
import Lossratio from '../pages/Lossratio';
import ReadjustmentsIndex from '../pages/ReadjustmentsIndex';
import ContractDocuments from '../pages/ContractDocuments';
import UsersList from '../pages/UsersList';
import AddUser from '../pages/AddUser';
import ConsultInclusionOfBeneficiaries from '../pages/ConsultInclusionOfBeneficiaries';
import PrivacyAndCookies from '../pages/PrivacyAndCookies';

import ReferenceUnimedTable from '../pages/ReferenceUnimedTable';
import Lack from '../pages/Lack';
import Beneficiaries from '../pages/Beneficiaries';
import ConsultContractChange from '../pages/ConsultContractChange';
import BeneficiaryInclude from '../pages/BeneficiaryInclude';
import Report from '../pages/Report';
import CallTerminate from '../pages/Beneficiaries/CallTerminate';
import CallEditBeneficiaryInclude from '../pages/BeneficiaryInclude/CallEditBeneficiaryInclude';
import CallBeneficiaryIncludeExternal from '../pages/BeneficiaryInclude/CallBeneficiaryIncludeExternal';
import BeneficiaryInclusionPerLot from '../pages/BeneficiaryInclusionPerLot';
import LotDetails from '../pages/LotDetails';
import Error404 from '../pages/Error404';

const Routes: React.FC = () => {
  // const { user, isAuthendicated } = useAuth();
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} isPrivate />
        <Route path="/login" component={Login} />
        <Route path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route path="/trocar-minha-senha" component={ChangeMyPassword} />
        <Route path="/alterar-dados-cadastrais" component={ChangeRegisterData} isPrivate />
        <Route path="/alterar-senha" component={ChangePassword} isPrivate />
        <Route path="/beneficiarios" exact component={Beneficiaries} isPrivate />
        <Route path="/beneficiarios/3/:params+" component={CallTerminate} isPrivate />
        <Route path="/beneficiarios/:step/:params+" component={Beneficiaries} isPrivate />
        <Route path="/comunicados" component={Releases} isPrivate />
        <Route path="/carencia" exact component={Lack} isPrivate />
        <Route path="/carencia/:userUnimedId/:beneficiaryName" component={Lack} isPrivate />
        <Route path="/consulta-alteracoes-cadastrais" component={ChangeRegisterDataConsult} isPrivate />
        <Route path="/consulta-alteracoes-contrato-cnpj" component={ConsultContractChange} isPrivate />
        <Route path="/consulta-alteracoes-produto" component={ConsultProductChange} isPrivate />
        <Route path="/consulta-inclusao-beneficiario" component={ConsultInclusionOfBeneficiaries} isPrivate />
        <Route path="/consulta-rescisao" component={TerminationConsult} isPrivate />
        <Route path="/consulta-segunda-via-cartao" component={RequestDuplicateCardConsult} isPrivate />
        <Route path="/demonstrativos-e-segunda-via-boleto" component={DemonstrativeAndDuplicate} isPrivate />
        <Route path="/detalhes-lote/:id/:situation" component={LotDetails} isPrivate />
        <Route path="/documentos-contrato" component={ContractDocuments} isPrivate />
        <Route path="/extrato-coparticipacao" component={CoparticipationExtract} isPrivate />
        <Route path="/faq" component={FAQ} isPrivate />
        <Route path="/incluir-beneficiario-gerar-link" component={BeneficiaryInclusionGenerateLink} isPrivate />
        <Route path="/incluir-beneficiario-por-lote" component={BeneficiaryInclusionPerLot} isPrivate />
        <Route path="/incluir-usuario" component={AddUser} isPrivate />
        <Route path="/indice-reajustes" component={ReadjustmentsIndex} isPrivate />
        <Route path="/listagem-usuarios" component={UsersList} isPrivate />
        <Route path="/manuais-e-arquivos" component={ManualAndFiles} isPrivate />
        <Route path="/ouvidoria" component={Ombudsman} isPrivate />
        <Route path="/privacidade-e-seguranca" component={PrivacyAndCookies} isPrivate />
        <Route path="/rol-procedimentos" component={ProducersRol} isPrivate />
        <Route path="/sinistralidade" component={Lossratio} isPrivate />
        <Route path="/relatorios" component={Report} isPrivate />
        <Route path="/solicitar-agendamento-consulta" component={Home} isPrivate />
        <Route path="/termos" component={Terms} isPrivate />
        <Route path="/tutoriais" component={Tutorials} isPrivate />
        <Route path="/tabela-referencia-unimed" component={ReferenceUnimedTable} isPrivate />
        <Route path="/incluir-beneficiario" exact component={BeneficiaryInclude} isPrivate />
        <Route path="/incluir-beneficiario/:requestId/:params+" component={CallEditBeneficiaryInclude} isPrivate />
        <RouteDom path="/incluir-beneficiario-externo/:hash+" component={CallBeneficiaryIncludeExternal} />

        {/* <Route path="/alterar-contrato-cnpj"  component={ContractChange} isPrivate/> */}
        {/* criar 404 */}
        <Route path="*" component={Error404} />
      </Switch>
    </>
  );
};

export default Routes;
