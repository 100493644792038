import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

export default function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};

  err.inner.forEach((error) => {
    if (error.path) {
      validationErrors[error.path] = error.message;
    }
  });
  return validationErrors;
}

/**
 * function obtained from the site -> http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
 * @param strCPF cpf string formatted withou masks
 * @returns true if valid and false if invalidDate
 */
export function TestaCPF(strCPF: string): boolean {
  let invalid = false;
  Array.from({ length: 10 }, (_, i) => {
    return Array.from({ length: 11 }, () => i).join('');
  }).forEach((i) => {
    if (i === strCPF) invalid = true;
  });
  if (invalid) return false;

  let Soma;
  let Resto;
  Soma = 0;

  for (let i = 1; i <= 9; i += 1)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;
  Soma = 0;
  for (let i = 1; i <= 10; i += 1)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
}
