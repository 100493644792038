import { shade } from 'polished';
import styled, { css } from 'styled-components';
import ButtonEl from '../../../components/Button';
import DatePickerComponent from '../../../components/DatePicker';
import FormWrapper from '../../../components/FormWrapper';

export const Container = styled.div`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-column: 1;
  }

  @media (min-width: 769px) and (max-width: 1600px) {
    grid-column: 1/11;
    padding: 0 30px;
  }
  @media (min-width: 1600px) {
    grid-column: 2/10;
  }
`;

export const Title = styled.h1`
  font-family: 'Unimed-Slab-Bold';
  font-size: 2.55rem;
  line-height: 2.4rem;

  text-align: center;
  letter-spacing: -1.19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.palet.institutional};
  padding: 30px 0;
  @media (max-width: 1200px) {
    font-size: 2.25rem;
    line-height: 2.1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding: 30px;
  }
`;

export const FormWrapper2 = styled(FormWrapper)`
  box-shadow: none !important;
  padding: 24px 0 8px 0 !important;
  @media (min-width: 769px) {
    min-width: 400px;
  }
  & > button {
    margin-top: 16px;
    min-height: 32px;
    font-size: 1rem;
  }
`;
interface FormProps {
  hiddenTable?: boolean;
}

export const FilterContainer = styled.div<FormProps>`
  position: relative;

  @media (max-width: 768px) {
    max-width: 100vw;
  }
  form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    ${(props) =>
      props.hiddenTable &&
      css`
        grid-template-columns: 2fr;
      `}
    align-items: center;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      display: flex;
      margin: 0 30px 94px;

      > div {
        grid-template-columns: 50% 50%;
        max-width: 100%;
        width: 100%;
      }
    }
    > a {
      font-family: 'Unimed-Sans-SemiBold';
      text-decoration: none;
      text-align: center;
      border-radius: 30px;
      font-size: 1rem;
      padding: 8px;
      color: ${(props) => props.theme.colors.text.inverse};
      border-color: ${(props) => props.theme.colors.palet.institutional};
      background: ${(props) => props.theme.colors.palet.institutional};
      transition: 300ms;

      margin: 8px;
      &:hover {
        border-color: ${(props) =>
          shade(0.2, props.theme.colors.palet.institutional)};
        background: ${(props) =>
          shade(0.2, props.theme.colors.palet.institutional)};
      }
      @media (max-width: 768px) {
        position: absolute;
        top: calc(100% + 24px);
        width: calc(100% - 60px);
        left: 0;
        margin: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 30px;
      }
    }
  }
`;

export const ReportsContainer = styled.div`
  margin: 8px 0 24px;

  > form {
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: flex-end;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    > div {
      @media (min-width: 769px) {
      }
      @media (min-width: 1024px) {
      }
      @media (max-width: 768px) {
      }
    }
  }
`;

export const DatePicker = styled(DatePickerComponent)``;

export const ButtonElement = styled(ButtonEl)`
  @media (min-width: 769px) {
    width: 150px !important;
  }
`;

export const ModalWrapper = styled.div`
  overflow-y: none;
  & > div:nth-child(1) {
    margin-bottom: 24px;
  }

  & > div:nth-child(2) {
    @media (max-width: 768px) {
      & > button:nth-child(1) {
        margin-bottom: 8px;
      }
    }
    @media (min-width: 769px) {
      width: 400px;
      display: flex;
      justify-content: space-between;
      & > button {
        margin-bottom: 0;
        width: 150px;
      }
    }
  }
`;

export const ButtonWrapper = styled.div``;

export const TableContainer = styled.div`
  #demonstrativo-e-segunda-via-do-boleto
    > .p-datatable-scrollable-wrapper
    > .p-datatable-scrollable-view
    > .p-datatable-scrollable-header
    > .p-datatable-scrollable-header-box {
    margin-right: 0px !important;
  }
  .positionRight {
    text-align: right !important;
    padding-right: 1vw !important;
    span {
      text-align: left;
    }
    @media (max-width: 768px) {
      text-align: left !important;
    }
  }

  @media (min-width: 769px) {
    grid-area: 1/11;
  }
`;

export const Tag = styled.span`
  font-family: 'Unimed-Sans-SemiBold';
  font-size: 0.7rem;
  position: relative;
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.disponivel {
    color: ${(props) => props.theme.colors.palet.institutional};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional};
      }
    }
  }
  &.aberto {
    color: ${(props) => props.theme.colors.palet.institutional7};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional7};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional7};
      }
    }
  }
  &.pago {
    color: ${(props) => props.theme.colors.palet.institutional12};
    > svg {
      path {
        stroke: ${(props) => props.theme.colors.palet.institutional12};
      }
      circle {
        fill: ${(props) => props.theme.colors.palet.institutional12};
      }
    }
  }
`;

interface StepContainerProps {
  defineHeight?: string;
}

export const Steps = styled.section<StepContainerProps>`
  position: relative;
  grid-column: 1/4;
  margin-bottom: 30px;
  ${(props) =>
    props.defineHeight &&
    css`
      height: ${props.defineHeight};
    `}
  @media(max-width: 768px) {
    grid-column: 1;
  }
  @media (min-width: 2000px) {
    grid-column: 2/3;
  }
`;

interface StepsProps {
  show: boolean;
}

const initialStateSteps = css`
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  height: 0;
  overflow: hidden;
`;
const showSteps = css`
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  height: initial;
  overflow: initial;
`;

export const Step1 = styled.section<StepsProps>`
  ${initialStateSteps}
  position: initial;
  display: none;

  ${(props) => props.show && showSteps}
  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;

export const Step2 = styled.section<StepsProps>`
  ${initialStateSteps}

  ${(props) => props.show && showSteps}
  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* > div {
      flex: 1;
    } */
    @media (min-width: 769px) {
      margin-bottom: 24px;
    }

    @media (max-width: 768px) {
      > div:nth-of-type(1) {
        width: 100%;
        flex: 1 0 100%;
      }
      > button {
        flex: 1;
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > button {
    width: fit-content;
  }
`;

export const ModalDetailValue = styled.div`
  font-size: 1.55rem;
  word-break: break-word;
  font-family: 'Unimed-Sans-Book';
  text-align: center;
`;

export const ModalObservationContent = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-top: 24px;
  padding: 0 30px 16px;
  color: ${(props) => props.theme.colors.text.primary};
  word-break: break-word;
`;

export const ModalObservation = styled.div`
  font-family: 'Unimed-Sans-Book';
`;
export const ModalObservationTitle = styled.div`
  font-family: 'Unimed-Slab-Bold';
  font-size: 1.55rem;
  text-align: left;
  padding: 0 30px;
  color: ${(props) => props.theme.colors.text.primary};
`;

export const ContainerTable = styled.div`
  .invoice {
    .link {
      color: #00995d;
      text-decoration: none;
      font-weight: 600;
    }
    .linkNo {
      color: #fdcb02;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;
