import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';
import { FiX } from 'react-icons/fi';

export const ModalOverlay = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  min-width: 100vw;
  max-width: 100vw;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  @media (max-width: 748px) {
    padding: 16px;
  }
`;
const fadeIn = keyframes`
  from{
    transform: scale(0.9);
    opacity: 0;
  }
  to{
    transform: scale(1);
    opacity: 1;
  }
`;

interface WrapperProps {
  autoWidth?: boolean;
}

export const ModalWrapper = styled.div<WrapperProps>`
  z-index: 99;
  background: #fff;
  position: relative;

  margin: 0 auto;
  max-height: calc(100vh - 60px);
  height: auto;
  /* overflow-y: auto; */
  border-radius: 30px;
  padding: 24px;
  text-align: center;
  width: 80vw;
  > svg {
    margin-left: 8px;
  }
  &.small {
    width: 475px;
  }
  ${(props) =>
    props.autoWidth &&
    css`
      width: auto;
    `}

  transform: scale(0.9);
  opacity: 0;
  animation: ${fadeIn} 500ms forwards;
`;

export const TitleParagraph = styled.p`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Bold';
  padding-top: 20px;
  margin-bottom: 8px;
`;
export const SubtitleParagraph = styled.p`
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Bold';
  font-size: 1.7rem;
  margin-bottom: 8px;
`;
export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.palet.institutional11};
  font-family: 'Unimed-Sans-Book';
  font-size: 1.7rem;
  font-weight: 300;
  margin-bottom: 8px;
  max-height: calc(100vh - 450px);
  overflow: auto;
`;

export const CloseButton = styled(FiX)`
  position: absolute;
  right: 20px;

  &:hover {
    color: ${shade(0.7, '#fff')};
    cursor: pointer;
  }
`;

export const DivWrapper = styled.div`
  padding-top: 30px;
`;
