/* eslint-disable max-len */
import React, { memo } from 'react';

import styled from 'styled-components';

const Svg = styled.svg`
  .cls-1 {
    fill: #00995d;
  }

  .cls-1,
  .cls-2 {
    stroke-width: 0px;
  }

  .cls-2 {
    fill: #fff;
  }
`;

interface LogoProps {
  onClick?: () => void;
}

const LogoUnimed: React.FC<LogoProps> = ({ onClick }) => {
  return (
    <Svg
      width="250"
      height="117"
      onClick={onClick}
      style={onClick ? { cursor: 'pointer' } : {}}
      xmlns="http://www.w3.org/2000/svg"
      id="Camada_2"
      data-name="Camada 2"
      viewBox="0 0 239.43 88.2"
    >
      <g id="Camada_1-2" data-name="Camada 1">
        <g id="Camada_5" data-name="Camada 5">
          <g>
            <path
              className="cls-1"
              d="m18.9,87.15c-9.84,0-17.85-8.01-17.85-17.85V18.9C1.05,9.06,9.06,1.05,18.9,1.05h201.63c9.84,0,17.85,8.01,17.85,17.85v68.25H18.9Z"
            />
            <path
              className="cls-2"
              d="m220.53,2.1c9.28,0,16.8,7.52,16.8,16.8v67.2H18.9c-9.28,0-16.8-7.52-16.8-16.8V18.9C2.1,9.62,9.62,2.1,18.9,2.1h201.63Zm0-2.1H18.9C8.48,0,0,8.48,0,18.9v50.39c0,10.42,8.48,18.9,18.9,18.9h220.53V18.9c0-10.42-8.48-18.9-18.9-18.9"
            />
            <g>
              <path
                className="cls-2"
                d="m125.78,69.31h-7.83v-11.76h2.12v10.18h5.71v1.58Z"
              />
              <path
                className="cls-2"
                d="m129.68,58.14c0,.77-.52,1.29-1.31,1.29s-1.33-.52-1.33-1.29.54-1.28,1.33-1.28,1.31.5,1.31,1.28Zm-2.35,11.17v-8.4h2.07v8.4h-2.07Z"
              />
              <path
                className="cls-2"
                d="m136.53,69.09c-.62.3-1.23.44-1.98.44-1.68,0-2.57-.87-2.57-2.52v-4.75h-.02l-1.24-.13v-1.21h1.26l.49-2.49h1.58v2.49h2.25v1.34h-2.25v4.42c0,.92.47,1.36,1.39,1.36.39,0,.65-.05.96-.19l.13,1.24Z"
              />
              <path
                className="cls-2"
                d="m137.34,65.23c0-2.87,1.53-4.54,4.22-4.54s4.08,1.5,4.08,4.37-1.56,4.47-4.17,4.47-4.13-1.51-4.13-4.3Zm6.18-.08c0-1.95-.71-2.96-1.96-2.96-1.34,0-2.1,1.01-2.1,2.96s.72,2.89,2,2.89,2.07-1.01,2.07-2.89Z"
              />
              <path
                className="cls-2"
                d="m148.81,61.5c.67-.52,1.48-.81,2.45-.81.35,0,.71.08.92.17l-.22,1.5c-.32-.07-.54-.1-.87-.1-.67,0-1.31.17-1.81.42v6.64h-2.08v-8.4h1.33l.29.59Z"
              />
              <path
                className="cls-2"
                d="m153.07,61.36c1.26-.42,2.35-.67,3.58-.67,2.2,0,3.34,1.01,3.34,2.87v5.75h-1.33l-.3-.57c-.74.52-1.56.79-2.55.79-1.75,0-2.89-.97-2.89-2.45,0-1.71,1.26-2.71,3.44-2.71h1.54v-.49c0-1.06-.54-1.53-1.83-1.53-.69,0-1.65.15-2.74.44l-.27-1.43Zm3.56,4.12c-1.06,0-1.68.54-1.68,1.41,0,.76.57,1.26,1.44,1.26.54,0,1.06-.13,1.51-.37v-2.3h-1.28Z"
              />
              <path
                className="cls-2"
                d="m161.94,67.23v-10.52h2.08v10.2l-2.08.32Zm3.93,1.93c-.57.27-1.09.37-1.66.37-1.46,0-2.27-.74-2.27-2.3l2.08-.32c0,.84.39,1.13,1.14,1.13.18,0,.34-.02.57-.1l.13,1.23Z"
              />
            </g>
            <path
              className="cls-2"
              d="m27.3,23.65c0-.29.25-.54.54-.54h6.48c.29,0,.54.25.54.54v14.54c0,2.7,1.3,4.35,4.25,4.35s4.21-1.66,4.21-4.35v-14.54c0-.29.25-.54.54-.54h6.48c.29,0,.54.25.54.54v14.11c0,6.33-3.17,10.91-11.77,10.91s-11.81-4.57-11.81-10.91v-14.11Zm27.17,24.12v-18c0-.29.25-.54.54-.54h6.12c.29,0,.54.25.54.54v3.28c.86-2.59,3.74-4.17,6.8-4.17,3.6,0,5.87,1.87,5.87,6.84v12.06c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54v-10.65c0-1.73-.58-2.77-2.66-2.77s-2.81.39-2.81,1.04v12.38c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54m26.85-26.13c3.13,0,4,.29,4,2.84s-.86,2.88-4,2.88-4.03-.25-4.03-2.88.9-2.84,4.03-2.84m-3.56,8.13c0-.29.25-.54.54-.54h6.12c.29,0,.54.25.54.54v18c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54v-18Zm10.8,0c0-.29.25-.54.54-.54h6.12c.29,0,.54.25.54.54v3.28c.86-2.59,3.71-4.17,6.55-4.17s4.75,1.19,5.47,3.96c.97-2.48,3.85-3.96,6.69-3.96,3.56,0,5.9,1.87,5.9,6.48v12.42c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54v-10.65c0-1.73-.58-2.77-2.48-2.77s-2.63.39-2.63,1.04v12.38c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54v-10.65c0-1.73-.58-2.77-2.48-2.77s-2.63.39-2.63,1.04v12.38c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54v-18Zm44.99-.9c6.66,0,9.57,3.67,9.57,9.36,0,1.22.04,2.41-1.4,2.41h-11.12c.36,2.27,1.65,3.1,5.94,3.1h5.47c.36,0,.65.32.58.68l-.58,2.91c-.04.18-.11.29-.22.32-1.73.72-4.28,1.01-7.27,1.01-7.85,0-11.37-3.78-11.37-9.97s3.42-9.83,10.4-9.83m2.95,8.24c0-2.23-.65-3.64-2.84-3.64-1.84,0-2.88.86-3.13,3.64h5.97Zm16.05-8.24c2.91,0,4.89,1.4,6.05,3.74v-8.97c0-.29.25-.54.54-.54h6.12c.29,0,.54.25.54.54v24.13c0,.29-.25.54-.54.54h-6.12c-.29,0-.54-.25-.54-.54v-2.84c-1.15,2.34-3.13,3.74-6.05,3.74-4.97,0-6.91-4.1-6.91-9.9s1.94-9.9,6.91-9.9m3.64,14.58c1.69,0,2.41-.76,2.41-1.73v-6.12c0-1.01-.94-1.51-2.41-1.51-2.12,0-2.99,1.47-2.99,4.68s.86,4.68,2.99,4.68"
            />
            <path
              className="cls-2"
              d="m179.38,48.31h7.99c.47,0,.72-.14.97-.47l5.18-7.04c.11-.14.22-.32.22-.5,0-.25-.25-.4-.68-.4h-2.98c-.47,0-.72-.22-.72-.5,0-.18.11-.36.22-.5l4.75-6.38c.11-.14.22-.32.22-.5,0-.29-.25-.5-.72-.5h-3.53c-.47,0-.72-.18-.72-.47,0-.18.11-.4.22-.54l4.61-6.13c.25-.32.61-.43.94-.43s.68.11.94.43l4.61,6.13c.11.14.22.36.22.54,0,.29-.25.47-.72.47h-3.53c-.47,0-.72.22-.72.5,0,.18.11.36.22.5l4.75,6.38c.11.14.22.32.22.5,0,.29-.25.5-.72.5h-2.98c-.43,0-.68.14-.68.4,0,.18.11.36.22.5l5.18,7.04c.25.32.54.47.97.47h7.99c.58,0,.9-.22.9-.58,0-.22-.11-.43-.22-.58l-4.68-6.35c-.11-.14-.22-.32-.22-.5,0-.25.25-.4.68-.4h5.62c.58,0,.86-.22.86-.58,0-.22-.11-.43-.22-.58l-11.49-15.21c-.22-.29-.43-.43-.94-.43h-12.89c-.32,0-.68.07-.94.43l-11.12,15.21c-.14.22-.22.4-.22.58,0,.36.29.58.86.58h5.62c.43,0,.68.14.68.4,0,.18-.11.36-.22.5l-4.68,6.35c-.11.14-.22.36-.22.58,0,.36.32.58.9.58"
            />
          </g>
        </g>
      </g>
    </Svg>
  );
};
export default memo(LogoUnimed);
