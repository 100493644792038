/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import Notiflix from 'notiflix';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import * as Yup from 'yup';
import { v4 } from 'uuid';
import { useTheme } from 'styled-components';
import axios from 'axios';
import { unMask } from 'remask';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import { useAuth } from '../../hooks/auth';
import { downloadFileOfBlob } from '../../utils/geterateFileURL';
import DatePickerUnform from '../../components/DatePickerUnform';
import FormWrapper from '../../components/FormWrapper';
import Input from '../../components/Input';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import SelectUnform from '../../components/SelectUnform';
import api from '../../services/api/api';
import {
  getAllDDIForSelectOptions,
  personalReducerForSelectCities,
  renameFile,
} from '../../utils/formatt';
import getValidationErrors, { TestaCPF } from '../../utils/getValidationErrors';

import {
  OptionalParams,
  DependentIncludeButtonContainer,
  DependentContainer,
  DepententContent,
  OwnerContainer,
} from './styles';

import DependentSvg from '../../components/Svg/dependent.svg';
import DepentendInclude, {
  DependentIncludeHandles,
} from '../Beneficiaries/DepentendInclude';
import { RowProps } from '../Beneficiaries';
import { TitleIntern } from '../../components/Title';
import Modal2 from '../../components/Modal2';

interface ParamsReceived {
  callbackSubmit?: (data: any, files: any[]) => Promise<string | null>;
  showDependentIncludeButton?: boolean;
  allDisabled?: boolean;
  showNewButton?: boolean;
  preloadOfRequestId?: string;
  contractId?: string;
  isOwner?: boolean;
  callbackButtonGoBack?: () => void;
  className?: string;
  webUsername?: string;
  dependentParams?: RowProps;
  showReleaseButton?: boolean;
  isExternal?: boolean;
  requestHolderId?: string;
  hiddenOwnerSave?: boolean;
  noActions?: boolean;
}

interface AttachmentRules {
  beneficiaryType: string;
  id: string;
  mandatory: string;
  name: string;
  relationship: string;
}

const BeneficiaryIncludeForm: React.FC<ParamsReceived> = ({
  callbackSubmit,
  showDependentIncludeButton,
  allDisabled = false,
  showNewButton: showNewButtonProp,
  preloadOfRequestId = '',
  isOwner = true,
  isExternal = false,
  contractId: contractIdProps = '',
  callbackButtonGoBack,
  className,
  webUsername,
  showReleaseButton: showReleaseButtonProps,
  dependentParams,
  requestHolderId,
  hiddenOwnerSave = false,
  noActions = false,
}) => {
  const { user } = useAuth();

  const { colors } = useTheme();
  const defaultColor = colors.palet.institutional8;
  const formRef = useRef<FormHandles>(null);
  const formRef2 = useRef<FormHandles>(null);
  const attachmentsRefs = useRef<InputFileHandles[]>([]);
  const dependentRefs = useRef<DependentIncludeHandles[]>([]);
  // -> somente para rejeitados
  const [disabled, setDisabled] = useState(allDisabled);
  const [showNewButton, setShowNewButton] = useState(showNewButtonProp);
  const [showReleaseButton, setShowReleaseButton] = useState(
    showReleaseButtonProps,
  );
  const [hasInconsistency, setHasInconsistency] = useState(false);

  const [contractId, setContractId] = useState(contractIdProps);

  // -> controle de uma variavel do form
  const [showInputSocialName, setShowInputSocialName] = useState(false);

  const [stateOfBirth, setStateOfBirth] = useState('');
  const [state, setState] = useState('');
  const [includeReason, setIncludeReason] = useState({ name: '', id: '' });
  const [placeType, setPlaceType] = useState('');

  // -> regras de anexo
  const [allAttachmentsTypes, setAllAttachmentsTypes] = useState(
    [] as AttachmentRules[],
  );
  const [attachmentsTypesShown, setAttachmentsTypesShown] = useState(
    [] as AttachmentRules[],
  );
  // -> set de variaveis assincronas
  const [presetBirthOfState, setPresetBirthOfState] = useState({
    name: '',
    code: '',
  });
  const [presetCity, setPresetCity] = useState('');
  const previousProduct = useRef({ actualProduct: '' });
  const [selectedProduct, setSelectedProduct] = useState('');
  const [presetDDI, setPresetDDI] = useState('55');

  // -> todos os formulários de dependentes
  const [formsToDependent, setFormsToDependent] = useState([] as string[]);

  // -> row simulada para o funcionamento do formulário de dependente
  /**
   * necessário:
   *  beneficiaryName: nome do beneficiario titular exibido em cima do form
   *  contractId: contrato
   *  webUsername: só caso seja link externo
   *  userUnimedId: só caso na tela 38
   */
  const [selectedRowSimulated, setSelectedRowSimulated] =
    useState(dependentParams);

  // quantidade de forms de dependentes exibidas em tela
  const handleAddFormToDepentent = useCallback(() => {
    // const productValue = formRef.current?.getFieldValue('product');
    // setSelectedProduct(productValue);
    setFormsToDependent((prev) => [...prev, v4()]);
  }, []);

  /**
   * Check inconsistency when exist requestId on owner form (after save the new request or on edit mode)
   * Not executed on external link
   * @return {boolean} true if has inconsistency
   */
  const checkInconsistency = useCallback(() => {
    if (requestHolderId && !isExternal) {
      api
        .get(
          `/company/get-inconsistency-inclusion?requestNumber=${requestHolderId}`,
        )
        .then((resp) => {
          setHasInconsistency(resp.data.result !== 2);
        });
    }
  }, [requestHolderId, isExternal]);

  // onde ocorre toda a validação do form do titular antes de chamar a função de callback
  const handleSubmit = useCallback(async () => {
    let filesError = false;
    const filesTypeError = [] as string[];
    if (isOwner) {
      attachmentsTypesShown.forEach((type) => {
        if (
          type.mandatory === '1' &&
          (!attachmentsRefs.current[Number(type.id)].files ||
            attachmentsRefs.current[Number(type.id)].files?.length === 0)
        ) {
          filesError = true;
          filesTypeError.push(type.id);
        }
      });
    }
    try {
      formRef.current?.setErrors({});
      // eslint-disable-next-line prefer-const
      let data = formRef.current?.getData();

      // send of description to birth city
      // cityOfBirth
      const birthCityId = document.querySelector<HTMLButtonElement>(
        `button[name="cityOfBirth"][value="${data?.cityOfBirth}"]`,
      );
      if (birthCityId && data) {
        const cityDescription = birthCityId.getAttribute('data-description');
        data.cityOfBirth = cityDescription;
      }

      const files = attachmentsTypesShown.reduce((acc, act) => {
        if (attachmentsRefs.current[Number(act.id)].files !== null) {
          attachmentsRefs.current[Number(act.id)].files?.forEach((f, index) => {
            acc.push({
              name: `${act.name
                .replaceAll('/', ' ')
                .replaceAll(' ', '_')}_${index}.${f.name.split('.')[1]}`,
              file: f,
            });
          });
        }
        return acc;
      }, [] as any);

      const phone = formRef.current?.getFieldValue('phone');
      const cellphone = formRef.current?.getFieldValue('cellphone');
      // eslint-disable-next-line prefer-const
      let shape = {} as any;
      shape.beneficiaryName = Yup.string().required('Informe um nome');
      if (data?.informSocialName) {
        shape.socialName = Yup.string().required(
          'É necessário informar o nome social',
        );
      }
      if (data?.stateOfBirth) {
        shape.cityOfBirth = Yup.string().required('Informe a cidade');
      }

      shape.birthDate = Yup.string().required('Informe sua data de Nascimento');
      shape.hiringDate = Yup.string().required('Informe Data de Adesão.');
      shape.beneficiaryName = Yup.string().required(
        'Informe seu nome completo.',
      );
      shape.birthDate = Yup.string().required(
        'Informe sua data de nascimento.',
      );
      shape.maritalStatus = Yup.string().required('Informe seu estado civil.');
      shape.nationality = Yup.string().required('Informe sua nacionalidade.');
      shape.phisicalSex = Yup.string().required('Informe sua sexualidade.');
      shape.motherName = Yup.string().required('Informe o nome de sua mãe.');

      shape.cpf = Yup.string()
        .required('Informe um CPF Válido.')
        .test('Informe um CPF válido.', 'cpf-test', (value) =>
          TestaCPF(value ?? ''),
        );

      shape.zipCode = Yup.string().required('Informe seu CEP.');
      shape.state = Yup.string().required('Informe o Estado.');
      shape.city = Yup.string().required('Informe a Cidade.');
      shape.neighborhood = Yup.string().required('Informe o Bairro.');
      shape.placeType = Yup.string().required('Informe o tipo de logradouro.');
      shape.address = Yup.string().required('Informe o Endereço.');
      shape.addressNumber = Yup.string().required('Informe o seu Número.');
      shape.DDIPhone = Yup.string().required('Informe o DDI do telefone.');
      shape.DDICellphone = Yup.string().required(
        'Informe o DDI do seu celular.',
      );

      shape.holderHiringDate = Yup.string().required(
        'Informe a data de admissão do titular.',
      );
      shape.includeReason = Yup.string().required(
        'Informe o motivo de inclusão.',
      );
      shape.product = Yup.string().required('Informe o produto.');

      if (!phone && !cellphone) {
        shape.phone = Yup.string().required('Informe um telefone e/ou celular');
        shape.cellphone = Yup.string().required(
          'Informe um telefone e/ou celular',
        );
      }

      shape.filesValidator = Yup.boolean().isFalse(
        'Necessário informar arquivos obrigatórios',
      );

      if (preloadOfRequestId) {
        filesError = false;
      }

      const objToValidate = { ...data, filesValidator: filesError };

      const schema = Yup.object().shape(shape);

      await schema.validate(objToValidate, {
        abortEarly: false,
      });

      if (callbackSubmit) {
        const resp = await callbackSubmit(data, files);
        if (resp) {
          if (previousProduct.current.actualProduct !== selectedProduct) {
            if (previousProduct.current.actualProduct === '') {
              previousProduct.current.actualProduct = selectedProduct;
            } else {
              previousProduct.current.actualProduct = selectedProduct;
              dependentRefs.current?.forEach((formDep) => {
                formDep.dispatchSaveEdit();
              });
            }
          }
        }
        checkInconsistency();

        return resp;
      }
      return null;
    } catch (err) {
      if (filesError) {
        filesTypeError.forEach((id) => {
          attachmentsRefs.current[Number(id)].setError(
            'É necessário informar esse anexo.',
          );
        });
      }
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        const scrollToFirstError = formRef.current?.getFieldRef(
          Object.keys(errors)[0],
        );
        if (scrollToFirstError) {
          const el: HTMLElement = scrollToFirstError.current;
          if (el) {
            smoothScrollIntoView(el, { behavior: 'smooth' });
          }
        }
      }
      return null;
    }
  }, [
    isOwner,
    attachmentsTypesShown,
    preloadOfRequestId,
    callbackSubmit,
    checkInconsistency,
    selectedProduct,
  ]);

  const handleReport = useCallback(
    async (requestId: string) => {
      try {
        Notiflix.Block.circle(`.notiflix-dependent-report-${requestId}`);
        const { data } = await api.get(
          `/report/list-of-company-portal-reports?contractId=${user.id}&exhibitionLocation=2`,
        );
        const { nrSeqRelatorio, Parametros: P } = data.content[0];
        const body = {
          nrSeqRelatorio: nrSeqRelatorio ?? '',
          Parametros: [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...P.map((p: { cdParametro: any }) => {
              return {
                id: p.cdParametro,
                valor: requestId,
              };
            }),
          ],
        };

        const { data: dataReport } = await api.post(
          `/report/generate-report`,
          body,
          {
            responseType: 'blob',
          },
        );
        downloadFileOfBlob(dataReport);
      } catch (err) {
        if (err?.response?.status === 400) {
          Notiflix.Notify.info(err.response.data?.message);
        } else {
          Notiflix.Notify.failure(
            'Não conseguimos buscar seu relatório. Tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove(`.notiflix-dependent-report-${requestId}`);
      }
    },
    [user],
  );

  // busca todas as regras para inclusao de anexos
  useEffect(() => {
    api
      .get(
        `/beneficiary-inclusion/attachment-rules?typeOfBeneficiary=${
          isOwner ? '2' : '1'
        }`,
      )
      .then((resp) => {
        setAllAttachmentsTypes(resp.data.content);
      });
  }, [isOwner]);

  // quando carrega todas as regras de inclusao de anexo, exibe a quantidade de anexos solicitada
  useEffect(() => {
    if (!allAttachmentsTypes) {
      return;
    }
    const found = allAttachmentsTypes?.filter((i) => !i.relationship);

    if (found.length > 0) {
      setAttachmentsTypesShown(found);
    } else {
      setAttachmentsTypesShown([]);
    }
  }, [allAttachmentsTypes]);

  // render dos anexos
  const attachments = useMemo(() => {
    if (attachmentsTypesShown.length > 0) {
      return (
        <FormWrapper
          onSubmit={() => {
            //
          }}
          formLook
          ref={formRef2}
          style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}
        >
          <TitleIntern themeColor={defaultColor}>Anexos</TitleIntern>
          {attachmentsTypesShown.map((rel) => {
            return (
              <InputFile
                key={`attachment-${rel.id}`}
                name={`attachment-${rel.id}`}
                title={rel.name}
                placeholder={rel.name}
                required={rel.mandatory === '1'}
                themeColor={defaultColor}
                multiple
                ref={(el) => {
                  if (el) {
                    attachmentsRefs.current[Number(rel.id)] = el;
                  }
                }}
                callbackHasChanged={() => {
                  window.dispatchEvent(
                    new Event('controllerOfSaveAndReleaseAction'),
                  );
                }}
              />
            );
          })}
        </FormWrapper>
      );
    }
    return <></>;
  }, [attachmentsTypesShown, defaultColor]);

  // desbloqueia todos os inputs para edição
  const unlockAllInputs = useCallback(() => {
    if (formRef.current) {
      Object.keys(formRef.current.getData()).forEach((key) => {
        if (formRef.current?.getFieldRef(key).current) {
          formRef.current?.getFieldRef(key).current.removeAttribute('disabled');
        }
      });
    }
  }, []);

  // reducer especial para estados
  const personalReducerState = useCallback((data) => {
    return data.reduce(
      (acc: { name: string; id: string }[], act: { id: string }) => {
        acc.push({
          name: act.id,
          id: act.id,
        });
        return acc;
      },
      [],
    );
  }, []);

  // caso preloadOfRequestId, pre carrega as infos do id
  useEffect(() => {
    if (preloadOfRequestId && isOwner) {
      api
        .get(
          `/beneficiary-inclusion/consult-solicitation-for-editing?requestId=${preloadOfRequestId}${
            webUsername ? `&webUsername=${webUsername}` : ''
          }`,
        )
        .then((resp) => {
          const { data } = resp;
          const content = data.content[0];

          // seta o contract id da request
          setContractId(content.contractId);
          if (formRef.current) {
            const hiringDate = content.hiringDate?.split(' ')[0];
            const birthDate = content.birthDate?.split(' ')[0];
            const admissionDate = content.admissionDate?.split(' ')[0];
            const emissionDate = content.emissionDate?.split(' ')[0];
            const protocolIssueDate = content.protocolIssueDate?.split(' ')[0];
            const datePlanAgreementOrigin =
              content.datePlanAgreementOrigin?.split(' ')[0];
            if (hiringDate) {
              formRef.current.setFieldValue('hiringDate', hiringDate ?? '');
            }
            if (birthDate) {
              formRef.current.setFieldValue('birthDate', birthDate ?? '');
            }
            if (admissionDate) {
              formRef.current.setFieldValue(
                'holderHiringDate',
                admissionDate ?? '',
              );
            }
            if (emissionDate) {
              formRef.current.setFieldValue('rgIssuerDate', emissionDate ?? '');
            }
            if (protocolIssueDate) {
              formRef.current.setFieldValue(
                'issueProtocolDate',
                protocolIssueDate ?? '',
              );
            }
            if (datePlanAgreementOrigin) {
              formRef.current.setFieldValue(
                'contractDateOfOriginPlan',
                datePlanAgreementOrigin ?? '',
              );
            }

            formRef.current.setFieldValue(
              'beneficiaryName',
              content.beneficiaryName ?? '',
            );
            if (content.socialName) {
              formRef.current.setFieldValue(
                'informSocialName',
                !!content.socialName,
              );
              formRef.current.setFieldValue(
                'socialName',
                content.socialName ?? '',
              );
              setShowInputSocialName(true);
            }

            formRef.current.setFieldValue(
              'maritalStatus',
              content.maritalStatus ?? '',
            );
            formRef.current.setFieldValue(
              'nationality',
              content.nationalityCode ?? '',
            );
            formRef.current.setFieldValue(
              'phisicalSex',
              content.physicalSex ?? '',
            );
            formRef.current.setFieldValue('motherName', content.mother ?? '');
            formRef.current.setFieldValue(
              'fatherName',
              content.fatherName ?? '',
            );
            formRef.current.setFieldValue(
              'stateOfBirth',
              content.initialsBirthState ?? '',
            );

            // fix case not exist birthCityCode and exist descriptionMunicipalityOfBirth
            if (
              content.initialsBirthState &&
              content.descriptionMunicipalityOfBirth
            ) {
              // description of city
              setPresetBirthOfState({
                name: content.descriptionMunicipalityOfBirth,
                code: '',
              });
            }
            // setStateOfBirth(content.initialsBirthState ?? '');
            if (content.initialsBirthState && content.birthCityCode) {
              // code of city
              setPresetBirthOfState({
                name: '',
                code: content.birthCityCode,
              });
            }
            formRef.current.setFieldValue(
              'foreignBirthCity',
              content.descriptionCountyBirthForeign ?? '',
            );
            formRef.current.setFieldValue('email', content.email ?? '');
            formRef.current.setFieldValue('cpf', content.cpf ?? '');
            formRef.current.setFieldValue('rg', content.rg ?? '');

            formRef.current.setFieldValue(
              'rgIssuerCountry',
              content.issuingCountryCode ?? '',
            );
            formRef.current.setFieldValue(
              'rgIssuerState',
              content.issuingAgencyState ?? '',
            );
            formRef.current.setFieldValue(
              'rgAgencyIssuer',
              content.issuingBodyDescriptionRg ?? '',
            );
            formRef.current.setFieldValue(
              'rgForeign',
              content.foreignRgId ?? '',
            );
            formRef.current.setFieldValue('zipCode', content.zipCode ?? '');
            formRef.current.setFieldValue('state', content.sgEstado ?? '');
            formRef.current.setFieldValue('city', content.ibgeCityCode ?? '');
            formRef.current.setFieldValue(
              'neighborhood',
              content.district ?? '',
            );

            if (content.addressTypePublicPlace) {
              formRef.current.setFieldValue(
                'placeType',
                content.addressTypePublicPlace ?? '',
              );
              setPlaceType(content.addressTypePublicPlace);
              formRef.current
                ?.getFieldRef('address')
                .current.removeAttribute('disabled');
            }

            formRef.current.setFieldValue('address', content.address ?? '');
            formRef.current.setFieldValue(
              'addressNumber',
              content.addressNumber ?? '',
            );
            formRef.current.setFieldValue(
              'complement',
              content.complement ?? '',
            );
            formRef.current.setFieldValue(
              'DDIPhone',
              content.DDiNumberPhone ?? '',
            );
            formRef.current.setFieldValue(
              'phone',
              `${content.DDDNumberPhone ?? ''}${content.telephone ?? ''}` ?? '',
            );
            formRef.current.setFieldValue(
              'DDICellphone',
              content.DDINumberCellphone ?? '55',
            );
            formRef.current.setFieldValue(
              'cellphone',
              `${content.dddCellPhone ?? ''}${content.cellphone ?? ''}` ?? '',
            );
            formRef.current.setFieldValue(
              'susCard',
              content.susCardNumber ?? '',
            );
            formRef.current.setFieldValue(
              'declarationOfLive',
              content.birthCertificateCodeAlive ?? '',
            );
            if (content.observation) {
              formRef.current.setFieldValue(
                'observation',
                content.observation
                  .replace(/\r?\n|\r|;/g, '')
                  .substring(0, 4000),
              );
            }
            formRef.current.setFieldValue(
              'registrationCode',
              content.registrationCode ?? '',
            );

            // formRef.current.setFieldValue(
            //   'relationship',
            //   content.relationshipNumber ?? '',
            // );
            formRef.current.setFieldValue(
              'includeReason',
              content.motiveIncludeId ?? '',
            );
            formRef.current.setFieldValue('product', content.planId ?? '');

            formRef.current.setFieldValue(
              'priceOfOriginPlan',
              content.sourcePlanPriceValue ?? '',
            );
            formRef.current.setFieldValue(
              'ANSGuideProtocolNumber',
              content.ansGuideProtocolNumber ?? '',
            );

            formRef.current.setFieldValue(
              'originOperator',
              content.descriptionOperatorOrigin ?? '',
            );
            formRef.current.setFieldValue(
              'coverageGeographicalOriginalPlan',
              content.codeCoveragePlanOrigin ?? '',
            );
            formRef.current.setFieldValue(
              'registerOfOriginalProduct',
              content.sourcePlanRegistrationCode ?? '',
            );
            formRef.current.setFieldValue(
              'typeOfContract',
              content.typePlanContractOrigin ?? '',
            );

            // START INPUT HIDDEN
            formRef.current.setFieldValue(
              'requestHolderId',
              content.nrSeqTitularSolicitacao ?? '',
            );
            formRef.current.setFieldValue(
              'nameRequestHolder',
              content.nmTitularInclusao ?? '',
            );
            formRef.current.setFieldValue(
              'previousRequestId',
              preloadOfRequestId,
            );
            // END INPUT HIDDEN
          }

          // after preload infos, dispatch action to verify if show or not the saveAndRelease button
          if (!noActions) {
            window.dispatchEvent(new Event('controllerOfSaveAndReleaseAction'));
          }
        })
        .catch((err) => {
          if (err.response?.data?.message) {
            Notiflix.Notify.failure(err.response.data.message);
          } else {
            Notiflix.Notify.failure(
              'Ops... Não conseguimos pré carregar as informações do beneficiário. Por favor, tente novamente mais tarde.',
            );
          }
        });
    } else {
      formRef.current?.setFieldValue(
        'hiringDate',
        new Date().toLocaleDateString(),
      );
    }
  }, [preloadOfRequestId, webUsername, isOwner, noActions]);

  // caso peloadOfRequestId e !isOwner carrega formulario de dependente
  useEffect(() => {
    if (preloadOfRequestId && !isOwner) {
      handleAddFormToDepentent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadOfRequestId, isOwner]);

  // controle de variaveis assincronas
  useEffect(() => {
    if (
      !!stateOfBirth &&
      (!!presetBirthOfState.name || !!presetBirthOfState.code)
    ) {
      // if (presetBirthOfState.name) {
      //   console.log('buscando cidade de nascimentp...', presetBirthOfState);
      //   const buttonWithCode = document.querySelector(
      //     `[name="cityOfBirth"][data-description="${presetBirthOfState.name}"]`,
      //   );
      //   if (buttonWithCode) {
      //     console.log('setando valor..', buttonWithCode.getAttribute('value'));
      //     formRef.current?.setFieldValue(
      //       'cityOfBirth',
      //       buttonWithCode.getAttribute('value'),
      //     );
      //   }
      // } else {
      formRef.current?.setFieldValue(
        'cityOfBirth',
        presetBirthOfState.code || presetBirthOfState.name,
      );
      // }
      setPresetBirthOfState({ name: '', code: '' });
    }
  }, [presetBirthOfState, stateOfBirth]);

  // ação para setar a cidade depois de carregar o CEP
  const afterLoadingStateOfCEP = useCallback(() => {
    if (presetCity) {
      const button = document.querySelector(
        `#city-zipcode > button[data-description="${presetCity}"]`,
      );

      if (button) {
        formRef.current?.setFieldValue('city', button.getAttribute('value'));
        setPresetCity('');
      }
    }
  }, [presetCity]);

  const loadingDDI = useCallback(
    (name: string) => {
      if (presetDDI) {
        formRef.current?.setFieldValue(name, presetDDI);
      }
    },
    [presetDDI],
  );

  // ação para libear os forms caso esteja logado
  const handleRelease = useCallback(async () => {
    // validacao de anexos sómente para titular
    let hasError = false;

    let scrollToFirstError: Element | null = null;
    // if (isOwner) {
    //   attachmentsTypesShown.forEach((type) => {
    //     if (
    //       type.mandatory === '1' &&
    //       (!attachmentsRefs.current[Number(type.id)].files ||
    //         attachmentsRefs.current[Number(type.id)].files?.length === 0)
    //     ) {
    //       attachmentsRefs.current[Number(type.id)].setError(
    //         'É necessário informar esse anexo.',
    //       );
    //       hasError = false;
    //       if (!scrollToFirstError) {
    //         scrollToFirstError =
    //           attachmentsRefs.current[Number(type.id)].containerRef;
    //       }
    //     }
    //   });
    //   if (preloadOfRequestId) {
    //     hasError = false
    //   }

    // }

    // validacao de anexos para dependentes
    dependentRefs.current?.forEach((formDep) => {
      if (!formDep.containAllFiles()) {
        if (!scrollToFirstError) {
          scrollToFirstError = formDep.firstContainerRefError();
        }
        hasError = true;
      }
    });
    try {
      await Promise.all([
        ...dependentRefs.current?.map((formDep) => formDep.validateForm()),
      ]);
    } catch (err) {
      hasError = true;
    }

    if (hasError) {
      if (scrollToFirstError) {
        const el: HTMLElement = scrollToFirstError;
        smoothScrollIntoView(el, { behavior: 'smooth' });
      }
      return;
    }

    try {
      Notiflix.Block.circle('.actions-include-beneficiary-release');
      if (isOwner) {
        const requestId = await handleSubmit();
        if (!requestId) return;

        // liberar o titular
        const { data } = await api.put(`company/release-dependent-registered`, {
          requestId,
        });
        const { message } = data;
        Notiflix.Notify.success(message);
        Notiflix.Block.remove('.actions-include-beneficiary-release');
      }
      // liberar os dependentes

      const allStatusOfDependents = await Promise.all([
        ...dependentRefs.current?.map((formDep) => {
          return formDep.saveAndRelease();
        }),
      ]);
      if (allStatusOfDependents.length > 0) {
        const hasErr = allStatusOfDependents.find((i) => !i);
        if (hasErr) {
          Notiflix.Block.remove('.actions-include-beneficiary-release');
          return;
        }
      }

      if (callbackButtonGoBack) {
        Notiflix.Block.remove('.actions-include-beneficiary-release');
        callbackButtonGoBack();
      }

      // Modal2.Success({
      //   closable: true,
      //   autoWidth: true,
      //   title: 'Perfeito!',
      //   text: message,
      //   children: (
      //     <Button modal onClick={() => Modal2.Close()}>
      //       Ok
      //     </Button>
      //   ),
      // });
    } catch (err) {
      Notiflix.Block.remove('.actions-include-beneficiary-release');
      if (axios.isAxiosError(err) && err.response?.data?.message) {
        Modal2.Failure({
          closable: true,
          autoWidth: true,
          title: 'Ooops...',
          text: err.response.data.message,
          children: (
            <Button modal onClick={() => Modal2.Close()}>
              Ok
            </Button>
          ),
        });
      } else {
        Notiflix.Notify.failure(
          'Ops... Não conseguimos enviar sua solicitação para o servidor. Por favor, tente novamente mais tarde.',
        );
      }
    }
  }, [callbackButtonGoBack, handleSubmit, isOwner]);

  // render dos forms dos dependentes
  const dependentsForms = useMemo(() => {
    if (formsToDependent.length > 0 && selectedRowSimulated) {
      return (
        <DependentContainer>
          {formsToDependent.map((key, index) => {
            return (
              <DepententContent key={key}>
                <DepentendInclude
                  contractId={contractId}
                  selectedProduct={selectedProduct}
                  defaultColor={defaultColor}
                  initialAllDisable={allDisabled}
                  callbackSetStep={(_ready) => {
                    if (callbackButtonGoBack) {
                      callbackButtonGoBack();
                    }
                  }}
                  rowsSelecteds={[selectedRowSimulated]}
                  showTitle={!isOwner}
                  showActionsButtons={!isOwner}
                  showNewButton={showNewButton}
                  isExternalLink={isExternal}
                  requestHolderId={requestHolderId}
                  ref={(el) => {
                    if (el) {
                      dependentRefs.current[index] = el;
                    }
                  }}
                  preloadOfRequestId={
                    !isOwner ? selectedRowSimulated.requestId : ''
                  }
                  callbackShowReleaseButton={() => setShowReleaseButton(true)}
                />
              </DepententContent>
            );
          })}
        </DependentContainer>
      );
    }
    return <></>;
  }, [
    formsToDependent,
    selectedRowSimulated,
    defaultColor,
    allDisabled,
    isOwner,
    isExternal,
    requestHolderId,
    contractId,
    selectedProduct,
    callbackButtonGoBack,
    showNewButton,
  ]);

  const [cepToSearch, setCepToSearch] = useState('');
  /**
   * @description Search CEP and in success set default values to State, County, Street and District
   */

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const timer = window.setTimeout(() => {
      if (!cepToSearch) {
        source.cancel();
        return;
      }
      Notiflix.Block.circle('.notiflix-cep-search');
      api
        .get(`/address/search-address-by-zip-code?zipCode=${cepToSearch}`, {
          cancelToken: source.token,
        })
        .then((resp) => {
          const { data } = resp;
          const { content } = data;

          if (content && content[0]) {
            formRef.current?.setFieldValue('neighborhood', content[0].district);
            formRef.current?.setFieldValue('address', content[0].streetName);
            formRef.current?.setFieldValue('state', content[0].states);

            const placeTypeMatch = document.querySelector(
              `#placetype-zipcode > button[data-description=${content[0].streetDescription}]`,
            );
            if (placeTypeMatch) {
              formRef.current?.setFieldValue(
                'placeType',
                placeTypeMatch.getAttribute('value'),
              );

              setPlaceType(content[0].streetDescription);
            }
            setState(content[0].states);
            setPresetCity(content[0].city.toUpperCase());
          }
        })
        .finally(() => {
          Notiflix.Block.circle('.notiflix-cep-search');
          Notiflix.Block.remove('.notiflix-cep-search');
        });
    }, 1000);

    return () => {
      source.cancel();
      window.clearTimeout(timer);
    };
  }, [cepToSearch]);

  useEffect(() => {
    if (hiddenOwnerSave) {
      setDisabled(true);
    }
  }, [hiddenOwnerSave]);

  /**
   * On render this component, add eventListener in the window to control if show or not the saveAndRelease button.
   *  is checked if all mandatory attachments in owner form is included and
   *  check if all mandatory attachments in dependent form is included
   * If all right show the saveAndRelease button.
   */
  useEffect(() => {
    function controllerOfSaveAndReleaseAction(e: Event) {
      if (isOwner) {
        let hasError = hasInconsistency || false;
        attachmentsTypesShown.forEach((type) => {
          if (
            type.mandatory === '1' &&
            (!attachmentsRefs.current[Number(type.id)] ||
              !('files' in attachmentsRefs.current[Number(type.id)]) ||
              attachmentsRefs.current[Number(type.id)].files?.length === 0)
          ) {
            hasError = true;
          }
        });

        dependentRefs.current?.forEach((formDep) => {
          if (!formDep.containAllFiles()) {
            hasError = true;
          }
        });

        const releaseButton = document.querySelector<HTMLButtonElement>(
          '.actions-include-beneficiary-release',
        );

        if (releaseButton) {
          if (!hasError) {
            releaseButton.style.display = 'block';
          } else {
            releaseButton.style.display = 'none';
          }
        }
      }
    }

    window.addEventListener(
      'controllerOfSaveAndReleaseAction',
      controllerOfSaveAndReleaseAction,
    );

    return () => {
      window.removeEventListener(
        'controllerOfSaveAndReleaseAction',
        controllerOfSaveAndReleaseAction,
      );
    };
  }, [attachmentsTypesShown, hasInconsistency, isOwner]);

  /**
   * When exist requestHolderId is call checkInconsistency function
   */
  useEffect(() => {
    checkInconsistency();
  }, [requestHolderId, checkInconsistency]);

  /**
   * When has changed value in hasInconsistency variable, is dispatch event to controllerOfSaveAndReleaseAction
   */
  useEffect(() => {
    window.dispatchEvent(new Event('controllerOfSaveAndReleaseAction'));
  }, [hasInconsistency]);

  return (
    <>
      {isOwner && (
        <OwnerContainer>
          <FormWrapper
            onSubmit={() => {
              //
            }}
            formLook
            style={{ display: 'flex', flexWrap: 'wrap' }}
            ref={formRef}
            className={`full-form-beneficiary ${className}`}
          >
            <DatePickerUnform
              name="hiringDate"
              title="Data de adesão:"
              icon
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
              minDate={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate(),
                )
              }
              maxDate={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 3,
                  new Date().getDate(),
                )
              }
            />
            <Checkbox
              name="informSocialName"
              value="informSocialName"
              title="Informar nome social"
              onChange={(e) => setShowInputSocialName(e.currentTarget.checked)}
              disabled={disabled}
              themeColor={defaultColor}
            />

            <OptionalParams className={showInputSocialName ? 'show' : ''}>
              <Input
                name="socialName"
                title="Nome social:"
                formLook
                widthContainerDesktop="100%"
                disabled={disabled}
                themeColor={defaultColor}
              />
            </OptionalParams>

            <TitleIntern
              themeColor={defaultColor}
              style={{ width: '100%', marginTop: '27px' }}
            >
              Dados Pessoais
            </TitleIntern>

            <Input
              name="beneficiaryName"
              title="Nome:"
              formLook
              widthContainerDesktop="80%"
              disabled={disabled}
              themeColor={defaultColor}
            />

            <DatePickerUnform
              name="birthDate"
              title="Data de nascimento:"
              icon
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="maritalStatus"
              title="Estado civíl:"
              apiSearch="/generic/list-of-marital-status"
              formLook
              widthContainerDesktop="33.33%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="nationality"
              title="Nacionalidade:"
              apiSearch="/generic/list-of-nationality"
              formLook
              widthContainerDesktop="33.33%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="phisicalSex"
              title="Sexo:"
              apiSearch="/generic/list-of-phisical-sex"
              formLook
              widthContainerDesktop="33.33%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="motherName"
              title="Nome da mãe:"
              formLook
              widthContainerDesktop="50%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="fatherName"
              title="Nome do pai:"
              formLook
              widthContainerDesktop="50%"
              disabled={disabled}
              themeColor={defaultColor}
            />

            <SelectUnform
              name="stateOfBirth"
              title="Estado de nascimento:"
              apiSearch="/address/list-of-states"
              formLook
              callbackOnChange={(name, id) => {
                setStateOfBirth(id);
              }}
              widthContainerDesktop="33%.33"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="cityOfBirth"
              title="Cidade de nascimento:"
              apiSearch={
                stateOfBirth
                  ? `/address/list-of-cities?state=${stateOfBirth}`
                  : ''
              }
              formLook
              personalReducer={personalReducerForSelectCities}
              widthContainerDesktop="33.33%"
              disabled={disabled || !stateOfBirth}
              themeColor={defaultColor}
            />

            <Input
              name="foreignBirthCity"
              title="Município de nascimento estrangeiro:"
              formLook
              widthContainerDesktop="33.33%"
              disabled={disabled}
              themeColor={defaultColor}
            />

            <TitleIntern
              themeColor={defaultColor}
              style={{ width: '100%', marginTop: '27px' }}
            >
              Documentos
            </TitleIntern>

            <Input
              name="cpf"
              title="CPF:"
              formLook
              widthContainerDesktop="30%"
              disabled={disabled}
              masks={['999.999.999-99']}
              themeColor={defaultColor}
            />
            <Input
              name="rg"
              title="RG:"
              formLook
              widthContainerDesktop="30%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <DatePickerUnform
              name="rgIssuerDate"
              title="Data de emissão:"
              icon
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="rgIssuerCountry"
              title="País emissor RG:"
              apiSearch="/address/list-of-countries"
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="rgIssuerState"
              title="UF emissora RG:"
              apiSearch="/address/list-of-states"
              formLook
              widthContainerDesktop="10%"
              personalReducer={personalReducerState}
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="rgAgencyIssuer"
              title="Órgão emissor:"
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="rgForeign"
              title="RG estrangeiro:"
              formLook
              widthContainerDesktop="70%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="declarationOfLive"
              title="Declaração de nascido vivo:"
              formLook
              widthContainerDesktop="50%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="susCard"
              title="Cartão SUS:"
              formLook
              widthContainerDesktop="50%"
              disabled={disabled}
              themeColor={defaultColor}
            />

            <TitleIntern
              themeColor={defaultColor}
              style={{ width: '100%', marginTop: '27px' }}
            >
              Endereço
            </TitleIntern>

            <Input
              name="zipCode"
              title="CEP:"
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              masks={['99999-999']}
              onInput={(e) => setCepToSearch(unMask(e.currentTarget.value))}
              className="notiflix-cep-search"
              themeColor={defaultColor}
            />
            <SelectUnform
              name="state"
              title="Estado:"
              apiSearch="/address/list-of-states"
              formLook
              callbackOnChange={(name, id) => {
                setState(id);
              }}
              widthContainerDesktop="20%"
              disabled={disabled}
              personalReducer={personalReducerState}
              themeColor={defaultColor}
            />
            <SelectUnform
              optionsId="city-zipcode"
              name="city"
              title="Município:"
              apiSearch={state ? `/address/list-of-cities?state=${state}` : ''}
              callbackOnLoad={afterLoadingStateOfCEP}
              formLook
              personalReducer={personalReducerForSelectCities}
              widthContainerDesktop="40%"
              disabled={disabled || !state}
              themeColor={defaultColor}
            />
            <Input
              name="neighborhood"
              title="Bairro:"
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              optionsId="placetype-zipcode"
              name="placeType"
              title="Tipo logradouro:"
              apiSearch="/address/list-of-type-of-place"
              formLook
              callbackOnChange={(name, id) => {
                setPlaceType(name);
                formRef.current
                  ?.getFieldRef('address')
                  .current.removeAttribute('disabled');
              }}
              widthContainerDesktop="15%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="address"
              title={placeType ? `${placeType}:` : ''}
              formLook
              widthContainerDesktop="35%"
              disabled={disabled || !placeType}
              themeColor={defaultColor}
            />
            <Input
              name="addressNumber"
              title="Nº:"
              formLook
              widthContainerDesktop="10%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="complement"
              title="Complemento:"
              formLook
              widthContainerDesktop="40%"
              disabled={disabled}
              themeColor={defaultColor}
            />

            <TitleIntern
              themeColor={defaultColor}
              style={{ width: '100%', marginTop: '27px' }}
            >
              Contatos
            </TitleIntern>

            <Input
              name="email"
              title="E-mail:"
              formLook
              widthContainerDesktop="100%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="DDIPhone"
              title="DDI - Telefone:"
              options={getAllDDIForSelectOptions().reduce((acc, act) => {
                acc.push({
                  name: act.title,
                  id: act.value,
                });
                return acc;
              }, [] as { id: string; name: string }[])}
              formLook
              callbackOnLoad={() => loadingDDI('DDIPhone')}
              widthContainerDesktop="15%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="phone"
              title="DDD/Telefone:"
              formLook
              masks={['(99) 9999-9999', '(99) 9 9999-9999']}
              widthContainerDesktop="35%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="DDICellphone"
              title="DDI - Celular:"
              options={getAllDDIForSelectOptions().reduce((acc, act) => {
                acc.push({
                  name: act.title,
                  id: act.value,
                });
                return acc;
              }, [] as { id: string; name: string }[])}
              formLook
              callbackOnLoad={() => loadingDDI('DDICellphone')}
              widthContainerDesktop="15%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <Input
              name="cellphone"
              title="DDD/Celular:"
              formLook
              masks={['(99) 9999-9999', '(99) 9 9999-9999']}
              widthContainerDesktop="35%"
              disabled={disabled}
              themeColor={defaultColor}
            />

            <TitleIntern
              themeColor={defaultColor}
              style={{ width: '100%', marginTop: '27px' }}
            >
              Dados adicionais
            </TitleIntern>

            <Input
              name="registrationCode"
              title="Matrícula:"
              formLook
              widthContainerDesktop="30%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <DatePickerUnform
              name="holderHiringDate"
              title="Data de admissão do titular:"
              icon
              formLook
              widthContainerDesktop="20%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            {/* <SelectUnform
              name="relationship"
              title="Grau de parentesco:"
              apiSearch="/generic/relationship"
              formLook
              widthContainerDesktop="50%"
              callbackOnChange={(name, id) => setRelationship(id)}
              disabled={disabled}
            /> */}
            <SelectUnform
              name="includeReason"
              title="Motivo da inclusão:"
              apiSearch="/beneficiary/list-of-reasons-for-inclusion"
              formLook
              callbackOnChange={(name, id) => {
                setIncludeReason({ name, id });
              }}
              widthContainerDesktop="50%"
              disabled={disabled}
              themeColor={defaultColor}
            />
            <SelectUnform
              name="product"
              title="Produto:"
              apiSearch={
                contractId
                  ? `/company/product-listing-for-selection?contractId=${contractId}`
                  : ''
              }
              formLook
              widthContainerDesktop="50%"
              disabled={disabled}
              themeColor={defaultColor}
              callbackOnChange={(name, id) => {
                setSelectedProduct(id);
              }}
            />

            <OptionalParams
              className={includeReason.name === 'Portabilidade' ? 'show' : ''}
            >
              <DatePickerUnform
                name="contractDateOfOriginPlan"
                title="Data de contratação do plano de origem:"
                icon
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <Input
                name="priceOfOriginPlan"
                title="Preço do plano de origem:"
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <Input
                name="ANSGuideProtocolNumber"
                title="Nº protocolo guia ANS:"
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <DatePickerUnform
                name="issueProtocolDate"
                title="Data de emissão do protocolo:"
                icon
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <Input
                name="originOperator"
                title="Operadora de origem:"
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <SelectUnform
                name="coverageGeographicalOriginalPlan"
                title="Abrangência geográfica do plano de origem:"
                apiSearch="/domain/geographical-coverage"
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <Input
                name="registerOfOriginalProduct"
                title="Registro do produto de origem:"
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
              <SelectUnform
                name="typeOfContract"
                title="Tipo de contratação:"
                apiSearch="/domain/hiring-type-list"
                formLook
                widthContainerDesktop="25%"
                disabled={disabled}
                themeColor={defaultColor}
              />
            </OptionalParams>

            <hr />

            <Input
              name="observation"
              title="Observação:"
              type="textarea"
              formLook
              disabled={disabled}
              maxLength={4000}
              themeColor={defaultColor}
            />

            <Input name="requestHolderId" hidden />
            <Input name="nameRequestHolder" hidden />
            <Input name="previousRequestId" hidden />
          </FormWrapper>
          {!showNewButton && !hiddenOwnerSave && !noActions && (
            <>{attachments}</>
          )}

          {!hiddenOwnerSave && (
            <FormWrapper
              style={isExternal ? { height: '82px', marginTop: '24px' } : {}}
              onSubmit={() => {
                //
              }}
              formLook
              className="actions-include-beneficiary"
            >
              {callbackButtonGoBack && !requestHolderId && (
                <Button
                  secondary
                  autoWidth
                  onClick={() => callbackButtonGoBack()}
                  formLook
                  style={{ marginRight: 'auto' }}
                >
                  Voltar
                </Button>
              )}
              {showNewButton && (
                <Button
                  formLook
                  grayButton
                  autoWidth
                  onClick={() => {
                    setDisabled(false);
                    setShowNewButton(false);
                    unlockAllInputs();
                  }}
                >
                  Novo
                </Button>
              )}
              {!!requestHolderId && (
                <Button
                  greenCriticalButton
                  autoWidth
                  formLook
                  onClick={() => handleReport(requestHolderId)}
                  className={`notiflix-dependent-report-${requestHolderId}`}
                >
                  Imprimir
                </Button>
              )}
              {!showNewButton && !noActions && (
                <Button
                  onClick={handleSubmit}
                  autoWidth
                  formLook
                  greenPrimary
                  float={isExternal ? 'right' : 'left'}
                  className="notiflix-save-beneficiary"
                >
                  Salvar
                </Button>
              )}
            </FormWrapper>
          )}
        </OwnerContainer>
      )}
      {dependentsForms}
      {showDependentIncludeButton && !showNewButton && (
        <DependentIncludeButtonContainer>
          <button type="button" onClick={handleAddFormToDepentent}>
            <div />
            <img src={DependentSvg} alt="Imagem - Adicionar Dependente" />
            <span>Adicionar Dependente</span>
          </button>
        </DependentIncludeButtonContainer>
      )}
      {((!!requestHolderId && !showNewButton) || showReleaseButton) &&
        !isExternal &&
        isOwner && (
          <FormWrapper
            onSubmit={() => {
              //
            }}
            formLook
            className="actions-include-beneficiary"
          >
            {callbackButtonGoBack && (
              <Button
                secondary
                autoWidth
                onClick={() => callbackButtonGoBack()}
                formLook
                style={{ marginRight: 'auto' }}
              >
                Voltar
              </Button>
            )}
            <Button
              formLook
              orangeButton
              autoWidth
              onClick={() => handleRelease()}
              className="actions-include-beneficiary-release"
            >
              Salvar e Liberar
            </Button>
          </FormWrapper>
        )}
    </>
  );
};

export default BeneficiaryIncludeForm;
