import React, { useState, useCallback } from 'react';

import {
  Container,
  ContainerWrapper,
  Header,
  Footer,
  NavigationAside,
} from './styles';

interface PageWrapperProps {
  gridTemplateAreas?: string[];
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  className?: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  gridTemplateColumns,
  children,
  gridTemplateAreas,
  gridTemplateRows,
  className,
}) => {
  const [navAsideHidden, setNavAsideHidden] = useState(true);

  const handleToggleNavAside = useCallback(() => {
    setNavAsideHidden((prev) => {
      if (prev === true) {
        const { body } = document;
        const invisibleClick = document.createElement('div');
        if (!invisibleClick) {
          return false;
        }
        invisibleClick.style.width = '100vw';
        invisibleClick.style.height = '100%';
        invisibleClick.style.zIndex = '500';
        invisibleClick.style.background = 'rgba(255,255,255,0)';
        invisibleClick.style.position = 'fixed';
        invisibleClick.setAttribute('data-navclick', 'true');
        invisibleClick.addEventListener('click', () => {
          const navClicks = document.querySelectorAll('div[data-navclick]');
          navClicks.forEach((e) => {
            e.remove();
          });
          setNavAsideHidden(true);
        });
        body?.appendChild(invisibleClick);
        return false;
      }
      document.querySelector('div[data-navclick]')?.remove();
      return true;
    });
  }, []);

  return (
    <Container>
      <NavigationAside
        hidden={navAsideHidden}
        callbackClose={handleToggleNavAside}
      />
      <Header toggleAside={handleToggleNavAside} />
      <ContainerWrapper
        gridTemplateAreas={gridTemplateAreas}
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateRows={gridTemplateRows}
        className={className}
      >
        {children}
      </ContainerWrapper>
      <Footer />
    </Container>
  );
};

export default PageWrapper;
