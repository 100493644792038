/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Notiflix from 'notiflix';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import Button from '../../components/Button';

import FormWrapper from '../../components/FormWrapper';
import Modal2 from '../../components/Modal2';
import SelectUnform from '../../components/SelectUnform';
import api from '../../services/api/api';

import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import InputFile, { InputFileHandles } from '../../components/InputFile';
import { TitleIntern } from '../../components/Title';
import { RowProps } from '.';
import {
  dateToPTBR,
  getAllDDIForSelectOptions,
  ObjectGetValueOfKey,
  ptBRToDate,
} from '../../utils/formatt';
import { useAuth } from '../../hooks/auth';

interface ChangeRegisterProps {
  defaultColor: string;
  callbackSetStep: (prev: number) => void;
  rowsSelecteds: RowProps[];
}

interface DateProps {
  value: Date | null;
  error: string;
  disabled: boolean;
}

export function ChangeRegister({
  defaultColor,
  callbackSetStep,
  rowsSelecteds,
}: ChangeRegisterProps): JSX.Element {
  const { user } = useAuth();
  const formRefDatasheet = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const attachmentRef = useRef<InputFileHandles>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [prevData, setPrevData] = useState({} as any);
  const [cityIdOptions, setCityIdOptions] = useState([
    { name: 'Cidades', id: '' },
  ]);

  const [birthDate, setBirthDate] = useState<DateProps>({
    value: null,
    error: '',
    disabled: false,
  });
  const [emissionDate, setEmissionDate] = useState<DateProps>({
    value: null,
    error: '',
    disabled: false,
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        Notiflix.Block.circle('.notiflix-request-change-register-send-button');
        data.birthDate = birthDate.value ? dateToPTBR(birthDate.value) : '';
        data.emissionDate = emissionDate.value
          ? dateToPTBR(emissionDate.value)
          : '';
        data.DDDNumber = data.phone.slice(0, 2);
        data.phone = data.phone.slice(2);
        data.DDDNumberCellphone = data.cellphone.slice(0, 2);
        data.cellphone = data.cellphone.slice(2);
        const {
          userUnimedId,
          physicalPersonCode: phisicalPersonCode,
          contractId,
        } = rowsSelecteds[0];
        const userName = user.webUsername;
        const allPromises = [] as {
          userUnimedId: string;
          phisicalPersonCode: string;
          contractId: string;
          field: string;
          userName: string;
          oldValue: string;
          newValue: string;
          accessType: 'E';
        }[];

        // const askAttachment = false;

        Object.entries(data).forEach((fieldArray) => {
          const field = fieldArray[0];
          const newValue = fieldArray[1];
          const oldValueSaved = ObjectGetValueOfKey(prevData, field).value;
          // const oldValueToCompare = oldValueSaved ? String(oldValueSaved) : '';

          // const isNewValue = String(newValue) !== oldValueToCompare;

          // const noRequiredFields = [
          //   'email',
          //   'phone',
          //   'DDDNumber',
          //   'DDINumber',
          //   'cellphone',
          //   'DDDNumberCellphone',
          //   'DDINumberCellphone',
          // ];
          // if (isNewValue && !askAttachment) {
          //   if (!noRequiredFields.includes(field)) {
          //     askAttachment = true;
          //   }
          // }

          if (!(newValue === '' && oldValueSaved === null)) {
            if (String(newValue) !== String(oldValueSaved)) {
              allPromises.push({
                userUnimedId: String(userUnimedId),
                phisicalPersonCode,
                contractId,
                userName,
                field,
                oldValue: oldValueSaved,
                newValue: String(newValue),
                accessType: 'E',
              });
            }
          }
        });
        if (allPromises.length === 0) {
          Notiflix.Notify.info('Não há alterações para enviar...');
          return;
        }

        // if (askAttachment && !attachmentRef.current?.files) {
        //   attachmentRef.current?.setError(
        //     'Ao realizar uma alteração é necessário incluir um anexo.',
        //   );
        //   return;
        // }

        const { data: response } = await api.put(
          '/beneficiary/register-change-request',
          [...allPromises],
        );

        const { content } = response;
        const requestIdApi = content[0].requestId;
        const formData = new FormData();
        formData.append('requestId', requestIdApi);
        formData.append(
          'userUnimedId',
          rowsSelecteds[0].userUnimedId.toString(),
        );

        if ((attachmentRef.current?.files?.length ?? 0) > 0) {
          attachmentRef.current?.files?.forEach((file, index) => {
            formData.append(`changeFile[${index}]`, file);
          });
          formData.append('userName', user.webUsername);
          await api.post('/company/register-attachment-change', formData);
        }

        Modal2.Success({
          closable: true,
          title: 'Perfeito!',
          subtitle: 'Enviamos sua solicitação.',
          children: (
            <>
              <Button
                onClick={() => {
                  Modal2.Close();
                  callbackSetStep(1);
                }}
                modal
              >
                Ok
              </Button>
            </>
          ),
        });
      } catch (err) {
        if (err.response?.data?.message) {
          Modal2.Failure({
            closable: true,
            autoWidth: true,
            title: 'Ooops...',
            text: err.response.data.message,
            children: (
              <Button modal onClick={() => Modal2.Close()}>
                Ok
              </Button>
            ),
          });
        } else {
          Notiflix.Notify.failure(
            'Ops... Algo deu errado ao tentar processar sua solicitação. Por favor, tente novamente mais tarde.',
          );
        }
      } finally {
        Notiflix.Block.remove('.notiflix-request-change-register-send-button');
      }
    },
    [
      birthDate.value,
      callbackSetStep,
      emissionDate.value,
      prevData,
      rowsSelecteds,
      user.webUsername,
    ],
  );

  const getCitysOfState = useCallback(async (name, id) => {
    try {
      Notiflix.Block.circle('.notiflix-city');
      const { data } = await api.get(`/address/list-of-cities?state=${id}`);
      const { content } = data;

      setCityIdOptions(
        content.map((item: { city: string; ibgeCityCode: string }) => ({
          name: item.city,
          id: item.ibgeCityCode,
        })),
      );
      window.dispatchEvent(new Event('state-loaded'));
    } catch (err) {
      Notiflix.Notify.failure(
        'Ops não conseguimos buscar as cidades para seleção. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.notiflix-city');
    }
  }, []);

  const getAllInitialData = useCallback(async () => {
    Notiflix.Block.circle('.form-change-request-datasheet');
    Notiflix.Block.circle('.request-copy-card');
    formRef.current?.setFieldValue('DDINumber', '55');
    formRef.current?.setFieldValue('DDINumberCellphone', '55');
    // DDINumberRef.current?.setValue('', '55');
    // DDINumberCellphoneRef.current?.setValue('', '55');
    try {
      const { data } = await api.get(
        `/beneficiary/data-to-change-fields?physicalPersonalCode=${rowsSelecteds[0].physicalPersonCode}&accessType=E`,
      );
      const { content } = data;
      setPrevData(content);
      const benef = rowsSelecteds[0];
      if (formRefDatasheet.current) {
        formRefDatasheet.current.setFieldValue(
          'cardNumber',
          benef.cardNumber ?? '',
        );
        formRefDatasheet.current.setFieldValue(
          'beneficiary',
          benef.beneficiaryName ?? '',
        );
        formRefDatasheet.current.setFieldValue(
          'cardNumberValidDate',
          benef.cardExpiryDate ?? '',
        );
        formRefDatasheet.current.setFieldValue(
          'hiringDate',
          benef.hiringDate ?? '',
        );

        formRefDatasheet.current?.setFieldValue(
          'product',
          benef.productName ?? '',
        );
        formRefDatasheet.current?.setFieldValue(
          'register',
          benef.registrationCode ?? '',
        );
      }
      if (formRef.current) {
        const setValue = (
          key: string,
          value: string | undefined | null,
          def = '',
        ) => {
          formRef.current!.setFieldValue(key, value ?? def);
        };
        const setDisabledStatus = (key: string, disabled: boolean) => {
          const inputRef = formRef.current!.getFieldRef(key);
          if (inputRef.current) {
            inputRef.current.disabled = disabled ?? false;
          }
        };

        const set = (
          key: string,
          custom?: {
            value?: string | undefined | null;
            disabled?: boolean;
          },
        ) => {
          const obj = content[key];
          if (custom && 'value' in custom) {
            setValue(key, custom.value);
          } else {
            setValue(key, obj.value);
          }
          if (custom && 'disabled' in custom) {
            setDisabledStatus(key, custom.disabled ?? false);
          } else {
            setDisabledStatus(key, !obj.isEditable);
          }
        };

        const {
          birthDate: apiBirthDate,
          emissionDate: apiEmissionDate,
          DDINumberCellphone,
          DDDNumberCellphone,
          cellphone,
          DDINumber,
          DDDNumber,
          phone,
        } = content;

        set('beneficiaryName');

        setBirthDate({
          value: apiBirthDate.value ? ptBRToDate(apiBirthDate.value) : null,
          error: '',
          disabled: !apiBirthDate.isEditable,
        });
        set('maritalStatus');
        set('physicalSex');
        set('cpf');
        set('cnsNumber');
        set('email');
        set('rg');

        setEmissionDate({
          value: apiEmissionDate.value
            ? ptBRToDate(apiEmissionDate.value)
            : null,
          error: '',
          disabled: !apiEmissionDate.isEditable,
        });

        set('brazilianstatesWhichIssuedRg');
        set('countryRgId');

        set('issuingBody');
        set('foreignRgId');

        set('birthCityCode');

        set('zipCode');
        set('brazilianState');
        window.addEventListener(
          'state-loaded',
          () => {
            set('cityId');
          },
          {
            once: true,
          },
        );
        set('address');
        set('addressNumber');
        set('complement');
        set('neighborhood');

        if (DDINumber.value) {
          set('DDINumber');
        }
        set('phone', {
          value: `${DDDNumber.value}${phone.value}`,
        });
        if (DDINumberCellphone.value) {
          set('DDINumberCellphone');
        }
        set('cellphone', {
          value: `${DDDNumberCellphone.value}${cellphone.value}`,
        });
        set('birthCertificateCodeAlive');
        set('fatherName');
        set('motherName');
      }
    } catch (err) {
      console.log(err);
      Notiflix.Notify.failure(
        'Ops... Não conseguimos pré carregar os dados do beneficiário. Por favor, tente novamente mais tarde.',
      );
    } finally {
      Notiflix.Block.remove('.form-change-request-datasheet');
      Notiflix.Block.remove('.request-copy-card');
    }
  }, [rowsSelecteds]);

  useEffect(() => {
    // console.log('Beneficiario selecionado->', rowsSelecteds[0]);
    getAllInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cepToSearch, setCepToSearch] = useState('');
  /**
   * @description Search CEP and in success set default values to State, County, Street and District
   */
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const timer = window.setTimeout(() => {
      if (!cepToSearch) {
        source.cancel();
        return;
      }
      Notiflix.Block.circle('.notiflix-cep-search');
      api
        .get(`/address/search-address-by-zip-code?zipCode=${cepToSearch}`, {
          cancelToken: source.token,
        })
        .then((resp) => {
          const { data } = resp;
          const { content } = data;

          if (content && content[0]) {
            formRef.current?.setFieldValue('neighborhood', content[0].district);
            formRef.current?.setFieldValue('address', content[0].streetName);

            window.addEventListener(
              'state-loaded',
              () => {
                if (content[0].city) {
                  formRef.current?.setFieldValue('cityId', content[0].city);
                }
              },
              {
                once: true,
              },
            );

            formRef.current?.setFieldValue(
              'brazilianState',
              content[0].streetName,
            );

            // brazilianStateRef.current?.setValue('', content[0].states);
            // if (content[0].city) {
            //   api
            //     .get(`/address/list-of-cities?state=${content[0].states}`)
            //     .then((respCity) => {
            //       if (respCity.data.content) {
            //         setCityIdOptions(
            //           respCity.data.content.reduce(
            //             (
            //               acc: { title: string; value: string }[],
            //               act: { city: string; ibgeCityCode: string },
            //             ) => {
            //               acc.push({
            //                 title: act.city,
            //                 value: act.ibgeCityCode,
            //               });
            //               return acc;
            //             },
            //             [],
            //           ),
            //         );
            //         cityIdRef.current?.setValue(
            //           content[0].city.toUpperCase(),
            //           '',
            //         );
            //       }
            //     });
            // }
          }
        })
        .finally(() => {
          Notiflix.Block.circle('.notiflix-cep-search');
          Notiflix.Block.remove('.notiflix-cep-search');
        });
    }, 1000);

    return () => {
      source.cancel();
      window.clearTimeout(timer);
    };
  }, [cepToSearch]);

  return (
    <>
      <TitleIntern themeColor={defaultColor}>
        Titular:{' '}
        {rowsSelecteds[0].holderName ?? rowsSelecteds[0].beneficiaryName}
      </TitleIntern>
      <FormWrapper
        onSubmit={() => console.log('N/A')}
        ref={formRefDatasheet}
        className="form-change-request-datasheet"
      >
        <Input
          name="cardNumber"
          insideInputTitle="Carteirinha:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="beneficiary"
          insideInputTitle="Beneficiário:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="product"
          insideInputTitle="Produto:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="cardNumberValidDate"
          insideInputTitle="Data valid. carteirinha:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="hiringDate"
          insideInputTitle="Data contratação:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
        <Input
          name="register"
          insideInputTitle="Matrícula:"
          widthContainerDesktop="33.33%"
          themeColor={defaultColor}
        />
      </FormWrapper>
      <FormWrapper
        onSubmit={handleSubmit}
        formLook
        className="request-copy-card"
        ref={formRef}
      >
        <Input
          name="beneficiaryName"
          title="Nome:"
          formLook
          widthContainerDesktop="40%"
          themeColor={defaultColor}
        />
        <DatePicker
          name="birhDate"
          title="Data de nascimento"
          selected={birthDate.value}
          error={birthDate.error}
          onChange={(date: Date) =>
            setBirthDate({ value: date, error: '', disabled: false })
          }
          disabled={birthDate.disabled}
          themeColor={defaultColor}
          widthContainerDesktop="20%"
          formLook
          icon
        />
        <SelectUnform
          name="maritalStatus"
          title="Estado civil:"
          apiSearch="/generic/list-of-marital-status"
          className="notiflix-marital"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />
        <SelectUnform
          name="physicalSex"
          title="Sexo:"
          apiSearch="/generic/list-of-phisical-sex"
          className="notiflix-phisical-sex"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
        />

        <Input
          name="cpf"
          title="CPF:"
          masks={['999.999.999-99']}
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
        />
        <Input
          name="cnsNumber"
          title="CNS:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
        />
        <Input
          name="email"
          title="E-mail:"
          formLook
          widthContainerDesktop="50%"
          themeColor={defaultColor}
        />

        <Input
          name="rg"
          title="RG:"
          formLook
          widthContainerDesktop="20%"
          themeColor={defaultColor}
        />
        <DatePicker
          name="emissionDate"
          title="Data Exp. RG:"
          selected={emissionDate.value}
          error={emissionDate.error}
          onChange={(date: Date) =>
            setEmissionDate({ value: date, error: '', disabled: false })
          }
          disabled={emissionDate.disabled}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
          icon
        />
        <SelectUnform
          name="brazilianstatesWhichIssuedRg"
          title="UF emissora RG:"
          apiSearch="/address/list-of-states"
          personalReducer={(content) => {
            return content.map((item: { id: string }) => ({
              id: item.id,
              name: item.id,
            }));
          }}
          className="notiflix-uf"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="10%"
        />
        <SelectUnform
          name="countryRgId"
          title="País emissor:"
          apiSearch="/address/list-of-countries"
          className="notiflix-country"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />
        <Input
          name="issuingBody"
          title="Órgão emissor:"
          formLook
          widthContainerDesktop="10%"
          themeColor={defaultColor}
        />
        <Input
          name="foreignRgId"
          title="RG estrangeiro:"
          formLook
          widthContainerDesktop="20%"
          themeColor={defaultColor}
        />
        <SelectUnform
          name="birthCityCode"
          title="Município de nascimento:"
          apiSearch="/address/list-of-cities"
          personalReducer={(content) => {
            return content.map(
              (item: {
                state: string;
                city: string;
                ibgeCityCode: string;
              }) => ({
                name: `${item.state} - ${item.city}`,
                id: item.ibgeCityCode,
              }),
            );
          }}
          className="notiflix-city-home"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="100%"
        />

        <Input
          name="zipCode"
          title="CEP:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
          onChange={(e) => {
            setCepToSearch(e.currentTarget.value);
          }}
          className="notiflix-cep-search"
        />
        <SelectUnform
          name="brazilianState"
          title="UF:"
          apiSearch="/address/list-of-states"
          callbackOnChange={getCitysOfState}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />

        <SelectUnform
          name="cityId"
          title="Cidade:"
          options={cityIdOptions}
          className="notiflix-city"
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="25%"
        />
        <Input
          name="neighborhood"
          title="Bairro:"
          formLook
          widthContainerDesktop="25%"
          themeColor={defaultColor}
        />

        <Input
          name="address"
          title="Rua:"
          formLook
          widthContainerDesktop="40%"
          themeColor={defaultColor}
        />
        <Input
          name="addressNumber"
          title="Numero:"
          formLook
          widthContainerDesktop="10%"
          themeColor={defaultColor}
        />
        <Input
          name="complement"
          title="Complemento:"
          formLook
          widthContainerDesktop="40%"
          themeColor={defaultColor}
        />

        <SelectUnform
          name="DDINumber"
          title="DDI - Telefone:"
          options={[
            { id: '', name: '' },
            ...getAllDDIForSelectOptions().map((item) => ({
              id: item.value,
              name: item.title,
            })),
          ]}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
        />
        <Input
          name="phone"
          title="DDD/Telefone:"
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          widthContainerDesktop="35%"
          themeColor={defaultColor}
        />
        <SelectUnform
          name="DDINumberCellphone"
          title="DDI - Celular:"
          options={[
            { id: '', name: '' },
            ...getAllDDIForSelectOptions().map((item) => ({
              id: item.value,
              name: item.title,
            })),
          ]}
          formLook
          themeColor={defaultColor}
          widthContainerDesktop="15%"
        />
        <Input
          name="cellphone"
          title="DDD/Celular:"
          masks={['(99) 9999-9999', '(99) 9 9999-9999']}
          formLook
          widthContainerDesktop="35%"
          themeColor={defaultColor}
        />

        <Input
          name="birthCertificateCodeAlive"
          title="Declaração de nascido vivo:"
          formLook
          widthContainerDesktop="33%"
          themeColor={defaultColor}
        />
        <Input
          name="fatherName"
          title="Filiação (pai):"
          formLook
          widthContainerDesktop="33%"
          themeColor={defaultColor}
        />
        <Input
          name="motherName"
          title="Filiação (mãe):"
          formLook
          widthContainerDesktop="33%"
          themeColor={defaultColor}
        />

        <InputFile
          themeColor={defaultColor}
          name="attachments"
          ref={attachmentRef}
          placeholder="Anexos"
          multiple
        />
        <Button
          className="opa"
          secondary
          autoWidth
          formLook
          onClick={() => callbackSetStep(1)}
        >
          Voltar
        </Button>
        <Button
          type="submit"
          greenButton
          autoWidth
          formLook
          className="notiflix-request-change-register-send-button marginLeft"
        >
          Enviar
        </Button>
      </FormWrapper>
    </>
  );
}
