import React from 'react';
import { AuthProvider } from './auth';
import { ThemeProvider } from './theme';
import { ReleasesProvider } from './releasesAndTerms';
import { ContractProvider } from './contract';

const Hooks: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ContractProvider>
        <ReleasesProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </ReleasesProvider>
      </ContractProvider>
    </AuthProvider>
  );
};

export default Hooks;
