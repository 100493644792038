/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Notiflix from 'notiflix';
import jwtDecode from 'jwt-decode';
import api from '../services/api/api';

interface LoginProps {
  password: string;
  user: string;
  typeUser: string;
}

interface ContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  login(props: LoginProps): Promise<boolean>;
  logout(): Promise<boolean>;
  changeAvatar(file: File): Promise<void>;
  changePass(currentPassword: string, password: string): Promise<void>;
  hasPrimaryToken: boolean;
  isAuthendicated: boolean;
}

const userContext = createContext<ContextProps>({} as ContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = useState<any>(() => {
    const savedSession = localStorage.getItem('@unimedLitoral');
    const tokenSavedSession = localStorage.getItem('@unimedLitoralToken');
    if (savedSession && tokenSavedSession) {
      const userSaved = JSON.parse(savedSession);
      const tokenSaved = JSON.parse(tokenSavedSession);
      if (userSaved && tokenSaved) {
        // console.log({ 'usuario salvo': userSaved, tokens: tokenSaved });
        api.defaults.headers.common.Authorization = `Bearer ${tokenSaved.token}`;
        return userSaved;
      }
    }
    return {};
  });
  const [hasPrimaryToken, setHasPrimaryToken] = useState(false);
  const apiKey = process.env.REACT_APP_JWT_API_KEY;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveOnSession = useCallback((userData: any, tokens: any) => {
    localStorage.setItem('@unimedLitoralToken', JSON.stringify(tokens));
    localStorage.setItem('@unimedLitoral', JSON.stringify(userData));
  }, []);
  const removeOnSession = useCallback(() => {
    window.location.reload();
    localStorage.removeItem('@unimedLitoral');
    localStorage.removeItem('@unimedLitoralToken');
    localStorage.removeItem('@unimedLitoralContract');
    sessionStorage.removeItem('@themeUnimed');
  }, []);

  /**
   * @function login
   * @description Method for beneficiary or company
   * @param document For beneficiary
   * @param user For company
   * @param typeUser 2 company
   * @param password
   * @returns boolean for authenticated
   */
  const login = useCallback(
    async ({ password, user: userString, typeUser }: LoginProps) => {
      let valid = false;

      const { data } = await api.put(`/auth/authentication`, {
        document,
        password,
        user: userString,
        typeUser,
      });
      const { content } = data;
      if (content.token) {
        valid = true;
        const { token, refreshToken } = content;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rest: any = jwtDecode(token);

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        saveOnSession(rest, { token, refreshToken });
        setUser({
          token,
          refreshToken,
          ...rest,
        });
      }

      if (!valid) {
        Notiflix.Notify.failure(
          'Suas credenciais não conferem. Por favor tente novamente.',
        );
      }
      return valid;
    },
    [saveOnSession],
  );

  const changePass = useCallback(
    async (currentPassword: string, newPassword: string) => {
      try {
        Notiflix.Block.circle('.notiflix-change-password-button');
        await api.put(`/user/change-password`, {
          currentPassword,
          newPassword,
        });
      } catch (error) {
        Notiflix.Notify.failure(
          'Ops. Ocorreu um erro ao tentar processar sua alteração de senha. Por favor, tente novamente mais tarde.',
        );
      } finally {
        Notiflix.Block.remove('.notiflix-change-password-button');
      }
    },
    [user],
  );

  /**
   * @function logout
   * @description Method for logout
   * @returns true
   */
  const logout = useCallback(async () => {
    const data = localStorage.getItem('@unimedLitoral');
    if (data) {
      const userData = JSON.parse(data);
      const { webUsername } = userData;
      if (!webUsername) {
        // Beneficiário
        localStorage.setItem('@userType', '1');
      } else {
        localStorage.setItem('@userType', '2');
        // Empresa
      }
    }

    setUser({});
    removeOnSession();
    return true;
  }, [removeOnSession]);

  /**
   * @function changeAvatar
   * @description Method for changing Avatar
   * @param file New Avatar
   * @returns void
   */
  const changeAvatar = useCallback(async (file: File) => {
    try {
      Notiflix.Loading.circle();
      const form = new FormData();
      form.append('avatar', file);
      const { data } = await api.post('/changeAvatar', form);
      setUser((prev: any) => ({ ...prev, avatar: data }));
    } catch (error) {
      Notiflix.Notify.failure(
        'Não conseguimos atualizar sua imagem, tente novamente.',
      );
    } finally {
      Notiflix.Loading.remove();
    }
  }, []);

  const getPrimaryToken = useCallback(async () => {
    try {
      const { data } = await api.post('/auth/generate-primary-token', {
        apiKey,
      });
      const { content } = data;
      const { token } = content;
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      setHasPrimaryToken(true);
    } catch (error) {
      Notiflix.Notify.failure(
        'Servidor Indisponível no momento, tente mais tarde.',
      );
    }
  }, [apiKey]);

  useEffect(() => {
    if (!isAuthendicated) {
      getPrimaryToken().then(() => {
        // tryGetSavedUserOnLocalStorage();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAuthendicated = useMemo(() => {
    return user && Object.keys(user).length > 0;
  }, [user]);

  return (
    <userContext.Provider
      value={{
        user,
        login,
        logout,
        changeAvatar,
        changePass,
        isAuthendicated,
        hasPrimaryToken,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export function useAuth(): ContextProps {
  const context = useContext(userContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
